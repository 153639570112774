import React, { Component } from 'react'

import CommonSelect2 from '../../common/CommonSelect2'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import CreatableSelectDropdown from '../../common/CreatableSelect'

import axios from 'axios'
import { el } from 'date-fns/locale'
//redux
import { connect } from 'react-redux'
import { getCallForwadingSettings } from '../../actions/settings'

const apiToken = localStorage.getItem('access_token')

const createOption = (label) => ({
  label : label,
  value: label
});

const components = {
  DropdownIndicator: null,
};


class CallScoreTagSettings extends Component {
  state = {
    selectedAllowedUser: [],
    inputTags : '',
    inputScore: '',
    inputStages: '',
    inputInterested: '',
    selectedInterested : [],
    selectedScoredUser: [],
    selectedScoredLead: [],
    lstAllowedUserData: [],
    lstScoredUserData: [],
    lstScoredLeadData: []
  }

  setSetting = data => {
    var selectedAllowedUser = []

    data.allowed_tags && data.allowed_tags.map((row, index) => {
      let temp = {
        value: row,
        label: row
      }
      selectedAllowedUser.push(temp)
    })

    var selectedInterested = []
    data.allowed_interested && data.allowed_interested.map((row, index) => {
      let temp = {
        value: row,
        label: row
      }
      selectedInterested.push(temp)
    })

   var selectedScoredUser = []
   data.allowed_scores && data.allowed_scores.map((row, index) => {
      let temp = {
        value: row,
        label: row
      }
      selectedScoredUser.push(temp)
    })

    var selectedScoredLead = []
    data?.allowed_stages && data.allowed_stages.map((row, index) => {
      let temp = {
        value: row,
        label: row
      }
      selectedScoredLead.push(temp)
    })

    this.setState({
      selectedInterested,
      selectedAllowedUser,
      selectedScoredUser,
      selectedScoredLead,
      lstAllowedUserData : selectedAllowedUser
    })
  }

  fetchSettings = async () => {
    if (this.props.callForwadingSettings) {
      this.props.loading(false)
      this.setSetting(this.props.callForwadingSettings.data.scores_tags)
    } else {
      this.props.loading(true)
      this.props.getCallForwadingSettings()
    }
    /* const w_id = await localStorage.getItem('widget_id');
    const apToken = await localStorage.getItem('access_token')
    this.props.loading(true)
    let head = {
      headers: {
        Authorization: 'Bearer ' + apToken
      }
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${w_id}`

    axios
      .get(url)
      .then(res => {
        this.props.loading(false)
        if (res.data.data) {
          this.setSetting(res.data.data.scores_tags)
        }
      })
      .catch(error => {
        this.props.loading(false)
        //CommonNotify('Cant Fetch Tags / Score')        
      }) */
  }

  componentDidUpdate(prevProps) {
    if (this.props.callForwadingSettings !== prevProps.callForwadingSettings) {
      this.props.loading(false)
      this.setSetting(this.props.callForwadingSettings.data.scores_tags)
    }
  }

  componentDidMount = () => {
    this.fetchSettings()
  }

  changeAllowedUser = async data => {  
    await this.setState({selectedAllowedUser : data})
  }

  changeScoredUser = data => {   
    this.setState({ selectedScoredUser : data })
  }

  changeScoredLead = data => {
    this.setState({ selectedScoredLead : data })
  }

  changeInterestedLead = data => {
    this.setState({ selectedInterested : data })
  }

  updateAllowedUser = async() => {
    const apiToken = await localStorage.getItem('access_token')
    const w_id = await localStorage.getItem('widget_id');
    

    var selectedAllowedUser = this.state.selectedAllowedUser
    var tags = []

    selectedAllowedUser !== null && selectedAllowedUser.map((row, index) => {
      tags.push(row.label)
    })
   
    const data = {
      tags,
      widget_id: w_id
    }

    if(tags?.length === 0) {
      CommonNotify('Please select atleast 1 tag.')
    }else{
      this.props.loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/set-tags`
    axios
      .post(url, data)
      .then(res => {
        this.props.loading(false)
        if (res.data.message[0] == 'Successfully') {
          CommonNotify('Setting Saved', 'success')        
        }
      })
      .catch(error => {
        this.props.loading(false)
        CommonNotify('Cant Save Setting')
      })
    }

  }

  updateScoredUser = async () => {
    
    const apiToken = await localStorage.getItem('access_token')
    const w_id = await localStorage.getItem('widget_id');


    

    var selectedScoredUser = this.state.selectedScoredUser
    var scores = []

    selectedScoredUser !== null && selectedScoredUser.map((row, index) => {
      scores.push(row.label)
    })

    const data = {
      scores,
      widget_id: w_id
    }

    if(scores?.length === 0)
    {
      CommonNotify('Please select atleast 1 score.')
    }else{
      this.props.loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/customer-add-scores`
    axios
      .post(url, data)
      .then(res => {
        this.props.loading(false)
        if (res.data.message[0] == 'Successfully') {
          CommonNotify('Setting Saved', 'success')        
        }
      })
      .catch(error => {
        this.props.loading(false)
        CommonNotify('Cant Save Setting')        
      })
    }
  }

  updateScoredLead = async() => {

    const apiToken = await localStorage.getItem('access_token')
    const w_id = await localStorage.getItem('widget_id');

    

    var selectedScoredLead = this.state.selectedScoredLead
    var stages = []

    selectedScoredLead !== null && selectedScoredLead.map((row, index) => {
      stages.push(row.label)
    })

    const data = {
      stages,
      widget_id: w_id
    }

    if(stages?.length === 0)
    {
      CommonNotify('Please select atleast 1 stage')
    }else{
      this.props.loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/update-stages`
    axios
      .post(url, data)
      .then(res => {
        this.props.loading(false)
        if (res.data.message[0] == 'Successfully') {
          CommonNotify('Setting Saved', 'success')          
        }
      })
      .catch(error => {
        this.props.loading(false)
        CommonNotify('Cant Save Setting')        
      })
    }
  }

  updateInterestedData = async() => {
    const apiToken = await localStorage.getItem('access_token')
    

    var selectedInterested = this.state.selectedInterested
    var stages = []

    selectedInterested !== null && selectedInterested.map((row, index) => {
      stages.push(row.label)
    })

    const data = {
      interested : stages
    }

    if(stages?.length === 0)
    {
      CommonNotify('Please add atleast 1 field')
    }else{
      this.props.loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/interested`
    axios
      .post(url, data)
      .then(res => {
        this.props.loading(false)
        if (res.data.message == 'Successfully') {
          CommonNotify('Setting Saved', 'success')          
        }
      })
      .catch(error => {
        this.props.loading(false)
        CommonNotify('Cant Save Setting')        
      })
    }
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputTags : inputValue });
  };


  handleScoreInputChange = (inputValue) => {
    this.setState({ inputScore : inputValue });
  };

  handleStageInputChange = (inputValue) => {
    this.setState({ inputStages : inputValue });
  };

  handleInterestedInputChange = (inputValue) => {
    this.setState({ inputInterested : inputValue });
  };



  handleKeyDown = (event) => {
    const { inputTags,selectedAllowedUser } = this.state;
    var arr = selectedAllowedUser ? selectedAllowedUser : []
    if (!inputTags) return;      


    if(inputTags){
      let tags = arr && arr?.filter(a => a.label.toLowerCase() == inputTags.toLowerCase());
     
      if(tags?.length)
      {
        return
      }
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      
        var t =  createOption(inputTags.toUpperCase().replace(/\W/g, ''));
        arr.push(t)        

       
        this.setState({
          inputTags: '',
          selectedAllowedUser: arr,
        });
        event.preventDefault();
    }
  };

  handleScoreKeyDown = (event) => {
    const { inputScore, selectedScoredUser } = this.state;
    var arr = selectedScoredUser ? selectedScoredUser : []
    if (!inputScore) return;
    
    if(inputScore){
      let tags = arr && arr?.filter(a => a.label.toLowerCase() == inputScore.toLowerCase());
    
      if(tags?.length)
      {
        return
      }else{

      }

    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
       
        var t =  createOption(inputScore.toUpperCase().replace(/\W/g, ''));
        arr.push(t)
        this.setState({
          inputScore: '',
          selectedScoredUser: arr,
        });
        event.preventDefault();
    }
  };


  handleStagesKeyDown = (event) => {
    const { inputStages, selectedScoredLead } = this.state;
    var arr = selectedScoredLead ? selectedScoredLead : []
    if (!inputStages) return;
   
    if(inputStages){
      let tags = arr && arr?.filter(a => a.label.toLowerCase() == inputStages.toLowerCase());
     
      if(tags?.length)
      {
        return
      }
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
       
        var t =  createOption(inputStages.toUpperCase().replace(/\W/g, ''));
        arr.push(t)
        this.setState({
          inputStages: '',
          selectedScoredLead: arr,
        });
        event.preventDefault();
    }
  };

  handleInterestedKeyDown = (event) => {
    const {  inputInterested, selectedInterested } = this.state;
    var arr = selectedInterested ? selectedInterested : []
    if (!inputInterested) return;
    
    if(inputInterested){
      let tags = arr && arr?.filter(a => a.label.toLowerCase() == inputInterested.toLowerCase());
      
      if(tags?.length)
      {
        return
      }
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
       
        var t =  createOption(inputInterested.toUpperCase().replace(/\W/g, ''));
        arr.push(t)
        this.setState({
          inputInterested: '',
          selectedInterested: arr,
        });
        event.preventDefault();
    }
  };

  render() {
    const {inputTags, inputScore, inputStages, inputInterested} = this.state
    return (
      <div className='d-flex'>
      <div className="callerID-settings-wrapper">
          <h3>Lead Qualification Data</h3>
        <p className="callerID-title">Tags</p>
        <p style={{color: 'gray'}}>Use tags to help report on your site visitors and conversations.</p>
        <div className="callerId-select">
          <CreatableSelectDropdown
            inputValue={inputTags}
            components={components}
            onChange={this.changeAllowedUser}
            value={this.state.selectedAllowedUser}
            onInputChange={this.handleInputChange}
            handleKeyDown={this.handleKeyDown}
            
          />
          <CommonButtons
            type="Update"
            onClick={this.updateAllowedUser}
            content="Update"
            background="blue"
          />

        </div>
        <p className="callerID-title">Lead Score</p>
        <p style={{color: 'gray'}}>Use lead score to manage sales efficiency and filter leads.</p>
        <div className="callerId-select">
          <CreatableSelectDropdown
            inputValue={inputScore}
            components={components}
            onChange={this.changeScoredUser}
            value={this.state.selectedScoredUser}
            onInputChange={this.handleScoreInputChange}
            handleKeyDown={this.handleScoreKeyDown}
            title="Allowed Scores To Users:"
          />
          <CommonButtons
            onClick={this.updateScoredUser}
            type="Update"
            content="Update"
            background="blue"
          />
        </div>
        <p className="callerID-title">Lead Stage</p>
        <p style={{color: 'gray'}}>Use lead stage to customize the lead stages and pipeline.</p>
        <div className="callerId-select">
          <CreatableSelectDropdown
            inputValue={inputStages}
            components={components}
            onChange={this.changeScoredLead}
            value={this.state.selectedScoredLead}
            onInputChange={this.handleStageInputChange}
            handleKeyDown={this.handleStagesKeyDown}
            title="Allowed Stages To Leads:"
          />
          <CommonButtons
            onClick={this.updateScoredLead}
            type="Update"
            content="Update"
            background="blue"
          />
        </div>

        <p className="callerID-title">Lead Interest</p>
        <p style={{color: 'gray'}}>Use this customizable data field to segment and capture product, service or use case interest.</p>
        <div className="callerId-select">
          <CreatableSelectDropdown
            inputValue={inputInterested}
            components={components}
            onChange={this.changeInterestedLead}
            value={this.state.selectedInterested}
            onInputChange={this.handleInterestedInputChange}
            handleKeyDown={this.handleInterestedKeyDown}
            title="Allowed Interested To Leads:"
          />
          <CommonButtons
            onClick={this.updateInterestedData}
            type="Update"
            content="Update"
            background="blue"
          />
        </div>
        
      </div>
        <div className='callerID-settings-wrapper contact-main'>
        
        <div className='contact-section'>
          <h5>Lead Qualification Data</h5>
          <p>Streamline the inbound lead and call qualification process and segment your leads effectively with customizable score cards and tags.</p>
          
        </div>
      </div>
        </div>
    )
  }
}

const mapStateToProps = state => ({
  callForwadingSettings: state.settings.callForwadingSettings,
});

export default connect(
  mapStateToProps,
  { getCallForwadingSettings }
)(CallScoreTagSettings)
