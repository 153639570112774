import React, { Component, createRef } from 'react'
import axios from 'axios'
import Dropzone from 'react-dropzone-uploader'
import { Accordion, Button, Grid, Dropdown } from 'semantic-ui-react'
import { billingCountryList } from '../../helpers/billingCountryList'
import CommonButtons from '../../common/CommonButtons'
import CommonInput from '../../common/CommonInput'
import { CommonNotify } from '../../common/CommonNotify'
import CommonTextArea from '../../common/CommonTextArea'
import InputValidator from '../../common/validator'
import CommonSelect from '../../common/CommonSelect'

import { connect } from 'react-redux'
import { getProfile } from '../../actions/settings'

const orgLImecall = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1k-5k',
  '5K-10K',
  '10K-50K',
  '50K-100K',
  '100K+'
]

const bussinessLimecall = [
  'Industry',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies/Equipment',
  'Capital Markets/Hedge Fund/Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment/Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education/Acadamia',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual/Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology/IT',
  'Insurance',
  'International Affairs',
  'International Trade/Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management/Hedge Fund/Private Equity',
  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Advertising/Sales',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Facilities/Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
]



class AccountSettings extends Component {
  constructor(props) {
    super(props)
    this.dropzoneRef = createRef()
    this.state = {
      activeIndexs: [0],
      company_size: '',
      company_country: null,
      isSocialMedia: false,
      comp_logo: null,
      data: {
        companyName: '',
        companyDirectoryUrl: '',
        companyWebsiteUrl: '',
        companyDirectoryTitle: '',
        companyAddress: '',
        companyTwitterLink: '',
        companyFacebookLink: '',
        companyLinkedInLink: '',
        companyLogo: {},
        showDropBox: false
      },
      companyName: 'The knight company',
      errors: {},
      isCompanyData: false,
      isLoader: false,
      industry: ''
    }
    this.updateCompanyData = this.updateCompanyData.bind(this)
    this.handleCompnayValidation = this.handleCompnayValidation.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      this.getCompanyData(this.props.profile)
    }
  }

  componentDidMount() {
    this.props.loading(true)
    if (this.props.profile) {
      this.getCompanyData(this.props.profile)
    } else {
      this.props.getProfile()
    }
    this.updateCompanyData()
  }

  getCompanyData = res => {
    this.setState({ isLoader: false })
    const Data = {
      companyName: res.data.company.company_name || '-',
      // companyDescription: res.data.company.company_description || '-',
      companyTwitterLink: res.data.company.company_twitter_link || '-',
      companyLinkedInLink: res.data.company.company_linkedin_link || '-',
      companyDirectoryUrl: res.data.company.company_directory_url || '-',
      companyAddress: res.data.company.company_address || '-',
      companyFacebookLink: res.data.company.company_facebook_link || '-',
      companyLogo: res.data.company.company_logo
        ? `${process.env.REACT_APP_BASE_APP_URL}` +
          res.data.company.company_logo
        : null,
      companyDirectoryTitle: res.data.company.company_directory_title || '-',
      companyWebsiteUrl: res.data.company.company_domain || '-'
    }
    const company_size = res.data.company.company_size || '1-10'
    const company_country = res.data.company.company_country
    const company_industry = res.data.company.company_industry || '-'
    const comp_logo = res.data.company.company_logo
      ? `${process.env.REACT_APP_BASE_APP_URL}` + res.data.company.company_logo
      : null

    this.props.loading(false)
    this.setState({
      isCompanyData: false,
      isSocialMedia: false,
      data: Data,
      company_size: company_size,
      company_country: company_country,
      industry: company_industry,
      comp_logo: comp_logo
    })

    /* const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`
    

    axios
      .get(url)
      .then(res => {
        const Data = {
          companyName: res.data.data.company.company_name || '-',
          companyDescription: res.data.data.company.company_description || '-',
          companyTwitterLink: res.data.data.company.company_twitter_link || '-',
          companyLinkedInLink:
            res.data.data.company.company_linkedin_link || '-',
          companyDirectoryUrl:
            res.data.data.company.company_directory_url || '-',
          companyAddress: res.data.data.company.company_address || '-',
          companyFacebookLink:
            res.data.data.company.company_facebook_link || '-',
          companyLogo: res.data.data.company.company_logo ? `${process.env.REACT_APP_BASE_APP_URL}` + res.data.data.company.company_logo : null,
          companyDirectoryTitle:
            res.data.data.company.company_directory_title || '-',
          companyWebsiteUrl: res.data.data.company.company_domain || '-'
        }
        const company_size = res.data.data.company.company_size || '1-10'
        const company_country = res.data.data.company.company_country
        const company_industry = res.data.data.company.company_industry || '-'
        const comp_logo = res.data.data.company.company_logo ? `${process.env.REACT_APP_BASE_APP_URL}` + res.data.data.company.company_logo : null


        this.props.loading(false)
        this.setState({
          isCompanyData : false,
          isSocialMedia: false,
          data: Data,
          company_size : company_size,
          company_country: company_country,
          industry : company_industry,
          comp_logo : comp_logo
        })
      })
      .catch(error => {       
        CommonNotify('error')
        this.props.loading(false)
      }) */
  }

  /*--------------------- Handle Company Validation And API -----------------*/

  handleCompnayValidation = () => {
    let fields = this.state.data
    let errors = {}
    let formIsValid = true
    let checkIfNotEmptyArr = ['companyName']

    checkIfNotEmptyArr.forEach(item => {
      if (!InputValidator.checkIfNotEmpty(fields[item])) {
        formIsValid = false
        errors[item] = 'This is a required field'
      }
    })

    this.setState({ errors: errors })
    return formIsValid
  }

  updateCompanyData(e) {
    if (this.handleCompnayValidation()) {
      this.props.loading(true)
      e.preventDefault()
      const token = localStorage.getItem('access_token')
      const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/company-data`

      
      
      const form = new FormData()
      form.append('company_name', this.state.data.companyName)
      
      
      form.append('company_address', this.state.data.companyAddress)

      // form.append('company_description', this.state.data.companyDescription)

      form.append(
        'company_directory_title',
        this.state.data.companyDirectoryTitle
      )

      if (this.checkDirLink(this.state.data.companyDirectoryUrl)) {
        form.append(
          'company_directory_link',
          this.state.data.companyDirectoryUrl
        )
      }

      if (this.checkDirLink(this.state.data.companyDirectoryUrl)) {
        form.append('company_domain', this.state.data.companyWebsiteUrl)
      }
      if (this.checkDirLink(this.state.data.companyTwitterLink)) {
        form.append('company_twitter_link', this.state.data.companyTwitterLink)
      }
      if (this.checkDirLink(this.state.data.companyFacebookLink)) {
        form.append(
          'company_facebook_link',
          this.state.data.companyFacebookLink
        )
      }
      if (this.checkDirLink(this.state.data.companyLinkedInLink)) {
        form.append(
          'company_linkedin_link',
          this.state.data.companyLinkedInLink
        )
      }

      form.append('company_size', this.state.company_size)
      form.append('company_country', this.state.company_country)
      form.append('company_industry', this.state.industry)

      if (this.state.data?.companyLogo?.name) {
        form.append('company_logo', this.state.data.companyLogo)
      }
      const sappendettings = {
        url: URL,
        method: 'POST',
        timeout: 0,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token
        },
        processData: false,
        mimeType: 'multipart/form-data',
        contentType: false,
        data: form
      }

      return axios(sappendettings)
        .then(res => {
          if (res) {
            this.props.getProfile()

            CommonNotify('Company Data Updated Successfully', 'success')
            return res
          }
        })
        .catch(err => {
          const errors = { ...err }
          if (errors?.response?.data?.errors) {
            CommonNotify(errors.response.data.errors[0])
          } else {
            CommonNotify('Cant Update Company Data')
          }

          this.props.loading(false)
          //CommonNotify('Upload Company Logo must be Image!')
        })
    }
  }

  checkDirLink = url => {
    if (url) {
      if (url.includes('http://') || url.includes('https://')) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  onChange = e => {
    const { name, value } = e.target
    const { data } = this.state

    if (!value) {
      this.setState({ isCompanyData: false })
    } else {
      this.setState({ isCompanyData: true })
    }
    data[name] = value

    this.setState({ data })
  }

  onChangeSocial = e => {
    const { name, value } = e.target
    const { data } = this.state

    if (!value) {
      this.setState({ isSocialMedia: false })
    } else {
      this.setState({ isSocialMedia: true })
    }
    data[name] = value

    this.setState({ data })
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndexs } = this.state
    const newIndex = activeIndexs
    const currentIndexPosition = activeIndexs.indexOf(index)

    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1)
    } else {
      newIndex.push(index)
    }

    this.setState({ activeIndexs: newIndex })
  }

  handleChangeStatus = ({ meta, file }, status) => {
    const { data } = this.state
    var comp_logo
    if (data) {
      this.setState({ isCompanyData: true })
    } else {
      this.setState({ isCompanyData: false })
    }
    if (status === 'removed') {
      data.companyLogo = ''
      comp_logo = ''
    } else {
      data.companyLogo = file
      comp_logo = file
    }
    this.setState({
      data,
      comp_logo
    })
  }

  onRemoveLogo = () => {
    const myObj = document.getElementsByClassName('dzu-previewButton')[0]
    myObj && myObj.click()

    if (this.state.isCompanyData) {
      this.setState({
        ...this.state,
        isCompanyData: false
      })
    } else {
      const data = { ...this.state.data }
      this.props.loading(true)
      const token = localStorage.getItem('access_token')

      const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/remove-company-logo`
      const pustData = {
        time_zone: 'GMT'
      }

      axios
        .post(URL, pustData)
        .then(res => {
          data.company_logo = null
          this.props.loading(false)
          this.setState({
            ...this.state,
            isCompanyData: false,
            data: data,
            comp_logo: null
          })

          CommonNotify('Logo removed successfully', 'success')
        })
        .catch(err => {
          this.props.loading(false)
          CommonNotify("Can't remove logo")
        })
    }
  }

  handleUserInput = e => {
    this.setState({ company_size: e.target.value })
  }

  setType = (val, data) => {
    this.setState({ company_size: data.value, isCompanyData: true })
  }

  setIndustry = (val, data) => {
    this.setState({ industry: data.value, isCompanyData: true })
  }

  setCountry = (val, data) => {
    this.setState({ company_country: data.value, isCompanyData: true })
  }

  updateIndustry = data => {
    this.setState({ industry: data.value, isCompanyData: true })
  }

  render() {
    const {
      activeIndexs,
      data,
      isCompanyData,
      company_size,
      isSocialMedia
    } = this.state
    return (
      <>
        <div className="account-settings">
          <Accordion className="holder-account">
            <div className="accordion-holder company-information company_main">
              <Accordion.Title
                active={activeIndexs.includes(0)}
                index={0}
                onClick={this.handleClick}
                style={{ marginBottom: '30px' }}
              >
                <p className="bold-text accordion-title">Company Information</p>
                <p className="subtext accordion-desc">
                  Enter your company’s information to help visitors recognize
                  and connect with your brand.
                </p>
              </Accordion.Title>
              <Accordion.Content active={activeIndexs.includes(0)}>
                <form method="" action="">
                  <div className="holder-input">
                    <Grid columns="equal">
                      <CommonInput
                  style={{ width:'75%'}}
                        onChange={this.onChange}
                        name="companyName"
                        title="Name"
                        required={true}
                        background="gray"
                        value={data.companyName}
                      />

                      {/* <div style={{width: '44%'}}> */}
                      <div style={{ width: '24%' }}>
                        <p>{'Company Size '}</p>
                        <CommonSelect
                          isGray
                          value={this.state.company_size}
                          onChange={this.setType}
                          name="widgetLanguage"
                          options={orgLImecall}
                          placeholder="Select Option"
                        />
                      </div>
                    </Grid>

                    <Grid columns="equal">
                      <div style={{ width: '35%', marginBottom: 10 }}>
                        {/* <p>{ "Location "}</p> */}
                        <p>{'Country'}</p>
                        <Dropdown
                          onChange={(e, data) => this.setCountry(e, data)}
                          selection
                          search
                          name="country"
                          placeholder="Select Option"
                          title="Country"
                          required={true}
                          options={billingCountryList}
                          defaultValue={this.state.company_country}
                          value={this.state.company_country}
                        />
                      </div>
                    </Grid>

                    <Grid columns="equal">
                      <div
                        style={{
                          width: '35%',
                          marginBottom: 10,
                          marginTop: 20
                        }}
                      >
                        {/* <p>{ "Field Industry "}</p> */}
                        <p>{'Industry'}</p>

                        <CommonSelect
                          isGray
                          value={this.state.industry}
                          onChange={this.setIndustry}
                          name="widgettLanguage"
                          options={bussinessLimecall}
                          placeholder="Select Industry"
                        />
                      </div>
                    </Grid>

                    <div className="text-area">
                      {/* <CommonTextArea
                        name="companyDescription"
                        title="Description"
                        required={true}
                        background="gray"
                        value={data.companyDescription}
                        onChange={this.onChange}
                      /> */}
                    </div>
                    <div className="dropZone">
                      <div style={{ width: '35%' }}>
                        {this.state.comp_logo ? (
                          <Dropzone
                            multiple={false}
                            canCancel={true}
                            canRemove={true}
                            inputContent={
                              <img src={this.state.data.companyLogo} />
                            }
                            onChangeStatus={this.handleChangeStatus}
                            accept="image/*"
                            ref={this.dropzoneRef}
                          />
                        ) : (
                          <Dropzone
                            multiple={false}
                            canCancel={true}
                            canRemove={true}
                            onChangeStatus={this.handleChangeStatus}
                            accept="image/*"
                            ref={this.dropzoneRef}
                          />
                        )}
                      </div>

                      <div className="dropZone-content">
                        <h3>Upload your logo…</h3>
                        <div>Your logo should be less than 500px x 300px</div>
                        <div>
                          Logo should be either a .jpg, png, or .gif to work
                          properly in emails
                        </div>
                        {this.state.data.companyLogo && (
                          <Button onClick={this.onRemoveLogo} type="button">
                            Clear logo
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  {isCompanyData && (
                    <>
                      <CommonButtons
                        onClick={this.updateCompanyData}
                        type="submit"
                        content="Save"
                        background="blue"
                      />
                      <CommonButtons
                        type="reset"
                        content="Cancel"
                        background="grey"
                      />
                    </>
                  )}
                </form>
              </Accordion.Content>
            </div>

            {/* <div className="accordion-holder company-information company_main">
              <Accordion.Title
                active={activeIndexs.includes(1)}
                index={1}
                onClick={this.handleClick}
                style={{ marginBottom: '30px' }}
              >
                <p className="bold-text accordion-title">Company Social Media</p>
                <p className="subtext accordion-desc">
                  Enter your company’s information to help visitors recognize and connect with your brand.
                </p>
              </Accordion.Title>
              <Accordion.Content active={activeIndexs.includes(1)}>
                <form method="" action="">
                  <div className="holder-input">

                    <Grid columns="equal">
                      
                     <CommonInput
                        onChange={this.onChangeSocial}
                        name="companyWebsiteUrl"
                        title="Website URL"
                        required={true}
                        background="gray"
                        value={data.companyWebsiteUrl}
                      />

                      <CommonInput
                        onChange={this.onChangeSocial}
                        name="companyDirectoryUrl"
                        title="Company Directory URL"
                        required={true}
                        background="gray"
                        value={data.companyDirectoryUrl}
                      />

                      
                    </Grid>
                    <Grid columns="equal">

                    <CommonInput
                        onChange={this.onChangeSocial}
                        name="companyLinkedInLink"
                        title="LinkedIn"
                        required={true}
                        background="gray"
                        value={data.companyLinkedInLink}
                      />
  
                      <CommonInput
                        onChange={this.onChangeSocial}
                        name="companyTwitterLink"
                        title="Twitter"
                        required={true}
                        background="gray"
                        value={data.companyTwitterLink}
                      />
                      <CommonInput
                        onChange={this.onChangeSocial}
                        name="companyFacebookLink"
                        title="Facebook"
                        required={true}
                        background="gray"
                        value={data.companyFacebookLink}
                      />
                    </Grid>
                                        
                  </div>
                  {isSocialMedia && (
                    <>
                      <CommonButtons
                        onClick={this.updateCompanyData}
                        type="submit"
                        content="Save"
                        background="blue"
                      />
                      <CommonButtons
                        onClick={() => this.getCompanyData(this.props.profile)}
                        type="reset"
                        content="Cancel"
                        background="grey"
                      />
                    </>
                  )}
                </form>
              </Accordion.Content>
            </div> */}
          </Accordion>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.settings.profile
})

export default connect(mapStateToProps, { getProfile })(AccountSettings)
