import React, { Component, useState } from 'react'

import IntegrationCard from '../integration/IntegrationCard'
import zapierActive from '../../assets/images/Asset 10.svg'
import googleModal1 from '../../assets/images/google_analytics.png'
import { Button } from 'semantic-ui-react'
import zapierColored from '../../assets/images/zapier-colored.svg'
import modalHubspot from '../../assets/images/hubspotModal.svg'
import modalZapier from '../../assets/images/Dashboard-89.png'
import hubspotInactive from '../../assets/images/hubstop-inactive.svg'
import pipedriveInActive from '../../assets/images/pipedriveInActive.svg'
import hubspotActive from '../../assets/images/hubspot-active.svg'
import slackActive from '../../assets/images/slack-icon.png'
import pipedrive from '../../assets/images/pipedrive.svg'
import slackInactive from '../../assets/images/slack-icon.png'
import slackModal from '../../assets/images/slack-icon.png'
import integromat from '../../assets/images/integromatNew.png'

const LeadSource = ()=>{

   const [selectedApp, setSelectedApp] = useState('')
    const [googleAnalytics, setGoogleAnalytics] = useState(false)

    const onClickSelectedApp = e => {
        const selected = e.target.getAttribute('name')
        setSelectedApp(selected)
    }

    const handleOpen = () => {  }
    
 
        return (
            <div className="integration-container integration_main">
                <div className="integration-wrapper">
                    <div className="integration-card-holder">
                        <div
                            onClick={onClickSelectedApp}
                            name="zapier"
                            className="integration-card"
                        >
                            <div className="integration-img-holder" name="zapier">
                                <img
                                    name="zapier"
                                    src={selectedApp === 'zapier' ? zapierActive : zapierActive}
                                    alt="logo"
                                />
                                <div className="text-wrapper">

                                    <p>{"Connect Zapier to automate your marketing & sales work flows. "}</p>
                                </div>
                            </div>
                            <p
                                // onClick={handleOpen}
                                // name={id}
                                className={`integration-status`}
                            >
                                Connect
                            </p>
                        </div>


                        <div
                            onClick={onClickSelectedApp}
                            className="integration-card"
                            name="googleManager"
                        >
                            <div name="googleManager" className="integration-img-holder">
                                <img
                                    name="googleManager"
                                    src={googleModal1}
                                    alt="logo"
                                />
                                <div className="text-wrapper">

                                    <p>Connect your Marketing Google Manager to sync .</p>
                                    {/* {googleAnalytics === true ? (
                                        <Button className="btnmanageSetting" onClick={() => setOpen(true)}>
                                            {' '}
                                            Manage Settings
                                        </Button>
                                    ) : null} */}
                                </div>
                            </div>
                            <p
                                onClick={handleOpen}
                                // name={id}
                                className={`integration-status`}
                            >
                                Connect
                            </p>
                        </div>

                       


                        <div
                            onClick={onClickSelectedApp}
                            className="integration-card"
                            name="hubSpot"
                        >
                            <div name="hubSpot" className="integration-img-holder">
                                <img
                                    name="hubSpot"
                                    src={hubspotActive }
                                    alt="logo"
                                />
                                <div className="text-wrapper">

                                    <p>
                                        Connect Hubspot to manage your sales calls right from Hubspot CRM.
                                    </p>
                                </div>
                            </div>
                            <p
                                // onClick={handleOpen}
                                // name={id}
                                className={`integration-status`}
                            >
                                Connect
                            </p>
                        </div>
                        <div
                            onClick={onClickSelectedApp}
                            className="integration-card"
                            name="pipedrive"
                        >
                            <div name="pipedrive" className="integration-img-holder">
                                <img
                                    name="pipedrive"
                                    src={ pipedrive }
                                    alt="logo"
                                />
                                <div className="text-wrapper">

                                    <p>
                                        Connect Pipedrive to manage your sales calls right from Pipedrive CRM.
                                    </p>
                                </div>
                            </div>
                            <p
                                // onClick={handleOpen}
                                // name={id}
                                className={`integration-status`}
                            >
                                Connect
                            </p>
                        </div>

                        <div
                            onClick={onClickSelectedApp}
                            className="integration-card"
                            name="slack"
                        >
                            <div name="slack" className="integration-img-holder">
                                <img
                                    name="slack"
                                    src={slackActive }
                                    alt="logo"
                                    className="slack"
                                />
                                <div className="text-wrapper">

                                    <p>
                                        Connect with Slack to receive notifications in your slack
                                        channels.
                                    </p>
                                    {/* {connectedApp?.data?.slack && (
                                        <Button className="btnmanageSetting" onClick={() => setSlackOpen(true)}>
                                            {' '}
                                            Manage Channel
                                        </Button>
                                    )} */}

                                </div>
                            </div>
                            <p
                                // onClick={handleOpen}
                                // name={id}
                                className={`integration-status`}
                            >
                                Connect
                            </p>
                        </div>



                        <div
                            onClick={onClickSelectedApp}
                            className="integration-card"
                            name="integromat"
                        >
                            <div name="integromat" className="integration-img-holder">
                                <img
                                    className="integromat-image"
                                    name="integromat"
                                    src={selectedApp === 'slack' ? integromat : integromat}
                                    alt="logo"
                                />
                                <div className="text-wrapper">
                                    <p>
                                        Automate your business workflows by connecting with Integromat
                                    </p>
                                </div>
                            </div>
                            <p
                                // onClick={handleOpen}
                                // name={id}
                                className={`integration-status`}
                            >
                                Connect
                            </p>
                        </div>
                      
                    </div>
                </div>
            </div>
        )
    
}

export default LeadSource
