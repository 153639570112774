const CountryList = [
  {
    name: 'Argentina',
    regions: []
  },
  {
    name: 'Australia',
    regions: []
  },
  {
    name: 'Austria',
    regions: []
  },
  {
    name: 'Belgium',
    regions: []
  },
  { name: 'Brazil', regions: [] },
  {
    name: 'Bulgaria',
    regions: []
  },

  {
    name: 'Canada',
    regions: [
      {
        name: 'Alberta',
        code: 'AB'
      },
      {
        name: 'British Columbia',
        code: 'BC'
      },
      {
        name: 'Manitoba',
        code: 'MB'
      },
      {
        name: 'New Brunswick',
        code: 'NB'
      },
      {
        name: 'Newfoundland and Labrador',
        code: 'NL'
      },
      {
        name: 'Northwest Territories',
        code: 'NT'
      },
      {
        name: 'Nova Scotia',
        code: 'NS'
      },
      {
        name: 'Nunavut',
        code: 'NU'
      },
      {
        name: 'Ontario',
        code: 'ON'
      },
      {
        name: 'Prince Edward Island',
        code: 'PE'
      },
      {
        name: 'Quebec',
        code: 'QC'
      },
      {
        name: 'Saskatchewan',
        code: 'SK'
      },
      {
        name: 'Yukon',
        code: 'YT'
      }
    ]
  },
  {
    name: 'Colombia',
    regions: []
  },
  {
    name: 'Czech republic',
    regions: []
  },
  {
    name: 'Denmark',
    regions: []
  },
  {
    name: 'Finland',
    regions: []
  },
  { name: 'Hong Kong', regions: [] },
  { name: 'Hungary', regions: [] },
  { name: 'Indonesia', regions: [] },
  { name: 'Ireland', regions: [] },
  { name: 'Israel', regions: [] },
  { name: 'Japan', regions: [] },
  { name: 'Malaysia', regions: [] },
  { name: 'Mexico', regions: [] },
  {
    name: 'New Zealand',
    regions: []
  },
  { name: 'Philippines', regions: [] },
  { name: 'Romania', regions: [] },
  { name: 'Slovakia', regions: [] },
  { name: 'South Africa', regions: [] },
  { name: 'Thailand', regions: [] },

  {
    name: 'Uganda',
    regions: []
  },
  {
    name: 'United Kingdom',
    regions: [
      {
        name: 'Any',
        code: 'Any'
      },
      { name: 'London', code: 'London' },
      { name: 'Birmingham', code: 'Birmingham' },
      { name: 'Manchester', code: 'Manchester' },
      { name: 'Glasgow', code: 'Glasgow' },
      { name: 'Edinburgh', code: 'Edinburgh' },
      { name: 'Liverpool', code: 'Liverpool' },
      { name: 'Leeds', code: 'Leeds' },
      { name: 'Sheffield', code: 'Sheffield' },
      { name: 'Bristol', code: 'Bristol' },
      { name: 'Newcastle', code: 'Newcastle' },
      { name: 'Southampton', code: 'Southampton' },
      { name: 'Nottingham', code: 'Nottingham' },
      { name: 'Leicester', code: 'Leicester' },
      { name: 'Coventry', code: 'Coventry' },
      { name: 'Hull', code: 'HullHull' },
      { name: 'Cardiff', code: 'Cardiff' },
      { name: 'Belfast', code: 'Belfast' },
      { name: 'Stoke-on-Trent', code: 'Stoke-on-Trent' },
      { name: 'Wolverhampton', code: 'Wolverhampton' },
      { name: 'Derby', code: 'Derby' },
      { name: 'Swansea', code: 'Swansea' },
      { name: 'Bradford', code: 'Bradford' },
      { name: 'Reading', code: 'Reading' },
      { name: 'Kingston upon Hull', code: 'Kingston upon Hull' },
      { name: 'Plymouth', code: 'Plymouth' },
      { name: 'Luton', code: 'Luton' },
      { name: 'Warrington', code: 'Warrington' },
      { name: 'York', code: 'York' },
      { name: 'Newport', code: 'Newport' },
      { name: 'Preston', code: 'Preston' },
      { name: 'Aberdeen', code: 'Aberdeen' },
      { name: 'Dundee', code: 'Dundee' },
      { name: 'Milton Keynes', code: 'Milton Keynes' },
      { name: 'Sunderland', code: 'Sunderland' },
      { name: 'Norwich', code: 'Norwich' },
      { name: 'Woking', code: 'Woking' }
    ]
  },
  {
    name: 'United States',
    regions: [
      {
        name: 'Any',
        code: 'Any'
      },
      {
        name: 'Alabama',
        code: 'AL'
      },
      {
        name: 'Alaska',
        code: 'AK'
      },
      {
        name: 'Arizona',
        code: 'AZ'
      },
      {
        name: 'Arkansas',
        code: 'AR'
      },
      {
        name: 'California',
        code: 'CA'
      },
      {
        name: 'Colorado',
        code: 'CO'
      },
      {
        name: 'Connecticut',
        code: 'CT'
      },
      {
        name: 'Delaware',
        code: 'DE'
      },
      {
        name: 'District of Columbia',
        code: 'DC'
      },
      {
        name: 'Florida',
        code: 'FL'
      },
      {
        name: 'Georgia',
        code: 'GA'
      },
      {
        name: 'Hawaii',
        code: 'HI'
      },
      {
        name: 'Idaho',
        code: 'ID'
      },
      {
        name: 'Illinois',
        code: 'IL'
      },
      {
        name: 'Iowa',
        code: 'IA'
      },
      {
        name: 'Kansas',
        code: 'KS'
      },
      {
        name: 'Kentucky',
        code: 'KY'
      },
      {
        name: 'Louisiana',
        code: 'LA'
      },
      {
        name: 'Maine',
        code: 'ME'
      },
      {
        name: 'Maryland',
        code: 'MD'
      },
      {
        name: 'Massachusetts',
        code: 'MA'
      },
      {
        name: 'Michigan',
        code: 'MI'
      },
      {
        name: 'Minnesota',
        code: 'MN'
      },
      {
        name: 'Mississippi',
        code: 'MS'
      },
      {
        name: 'Missouri',
        code: 'MO'
      },
      {
        name: 'Montana',
        code: 'MT'
      },
      {
        name: 'Nebraska',
        code: 'NE'
      },
      {
        name: 'Nevada',
        code: 'NV'
      },
      {
        name: 'New Hampshire',
        code: 'NH'
      },
      {
        name: 'New Jersey',
        code: 'NJ'
      },
      {
        name: 'New Mexico',
        code: 'NM'
      },
      {
        name: 'New York',
        code: 'NY'
      },
      {
        name: 'North Carolina',
        code: 'NC'
      },
      {
        name: 'North Dakota',
        code: 'ND'
      },
      {
        name: 'Ohio',
        code: 'OH'
      },
      {
        name: 'Oklahoma',
        code: 'OK'
      },
      {
        name: 'Oregon',
        code: 'OR'
      },
      {
        name: 'Pennsylvania',
        code: 'PA'
      },
      {
        name: 'Rhode Island',
        code: 'RI'
      },
      {
        name: 'South Carolina',
        code: 'SC'
      },
      {
        name: 'South Dakota',
        code: 'SD'
      },
      {
        name: 'Tennessee',
        code: 'TN'
      },
      {
        name: 'Texas',
        code: 'TX'
      },
      {
        name: 'Utah',
        code: 'UT'
      },
      {
        name: 'Vermont',
        code: 'VT'
      },
      {
        name: 'Virginia',
        code: 'VA'
      },
      {
        name: 'Washington',
        code: 'WA'
      },
      {
        name: 'West Virginia',
        code: 'WV'
      },
      {
        name: 'Wisconsin',
        code: 'WI'
      },
      {
        name: 'Wyoming',
        code: 'WY'
      },
      {
        name: 'Puerto Rico',
        code: 'PR'
      }
    ],
    areaCodes: true
  }
]
export default CountryList
