import React, { useEffect, useState } from 'react'
import { Dimmer, Loader, Modal, Pagination, Segment, Tab, Table } from 'semantic-ui-react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import moment from 'moment'
import { Tooltip } from '@mui/material'
import { connect } from 'react-redux'
import { getDialer } from '../../actions/dialer'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import Axios from 'axios'
import schedule from '../../assets/images/schedule.png'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RightPaneContainer from '../../common/RightPaneContainer'
import Reschedule from '../../common/reScheduleModal'
import EventBusyIcon from '@mui/icons-material/EventBusy';

const MeetingTypeTeam = ({ setDetailPopUp, status, filterTable, fiterAgent, profileData,...props}) => {

  const [selectedLead, setSelectedLead] = useState(0)
  const [open, setOpen] = React.useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
const [totalPages, setTotalPages] = useState(0)
const [tableFullData, setTableFullData] = useState({})
const [pageNumber, setPageNumber] = useState(1)
  const [leadId, setLeadId] = useState(null)
  const [selectedId, setSelectedId] = useState(null)
  const { startDateFilter, endDateFilter } = filterTable
const statusOption = { 1: 'Pending',2: 'Assigned',3:'Closed',4:'Qualified'}
const [loading, setLoading] = useState(false)
const [params, setParams] = useState({
  limit: 10,
  page: 1,
  sort_field: 'id',
  sort_value: 'DESC',
  sort: '-id',
  'filter[type][!in]':'TEXT_MESSAGE',
  'filter[direction][is]': 'Inbound',
  'filter[schedule_call_status][in]': status
})
const deleteModalView = async (bool, lead = 0) => {
  if (lead) {
    setSelectedLead(lead)
  }
  setDeleteModal(bool)
}

const isModalClose = () => {
  setOpen(false)
}
 useEffect(() => {
  const data=tableFullData?.leads
 setTotalPages(data?.last_page)
 
 
 }, [tableFullData])
 
 const trimUrl = (url = '') => {
  if (!url || url == '') return ''
  const newUrl = url.split('/', 3)
  if (!url?.length) return ''

  url = newUrl[newUrl?.length - 1]
  return url
}
const openDialer = () => {
  return props?.getDialer(!props.dialerStatus)
}
const isModalOpen = () => {
  setOpen(!open)
}
const onDeleteLeadHandler = async () => {
  const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
  var postId = selectedLead

  const postData = {
    ids: postId?.length ? [...postId] : [postId]
  }

  Axios
    .post(URL, postData)
    .then(res => {
      setDeleteModal(!deleteModal)
      fetchLeadData()
      CommonNotify('Lead Deleted Successfully', 'success')
      setSelectedLead(0)
    })
    .catch(err => {
      setDeleteModal(!deleteModal)
      CommonNotify('Not able to Delete Lead')
    })
}
const onCancelLeadHandler = async () => {
  const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${cancelModal}/cancel`
  Axios
    .post(URL)
    .then(res => {
      setCancelModal(!cancelModal)
      fetchLeadData()
      CommonNotify('Call Cancelled Successfully', 'success')
      setSelectedLead(0)
    })
    .catch(err => {
      setCancelModal(!cancelModal)
      CommonNotify('Not able to Cancel Lead')
    })
}

  const fetchLeadData = async (page, status1) => {
  var url


    const para = {
      ...params, page: page || params?.page, 'filter[schedule_call_status][in]': (status1 === 'scheduled' || status === 'scheduled') ? 'scheduled,rescheduled' : status1 || status, ...fiterAgent && ({ 'filter[agent][is]': localStorage?.getItem('full_name')}), ...(startDateFilter || endDateFilter) && ({ 'filter[created_at][between]': `${moment(startDateFilter)?.format('YYYY-MM-DD')},${moment(endDateFilter)?.format('YYYY-MM-DD')}`})}

setLoading(true)
  url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
  Axios
    .get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params: para
    })
    .then(res => {
  

      setLoading(false)
        setTableFullData(res.data.data)
    })
    .catch(err => {
      setLoading(false)
    })
}

const onPageChange = (e, a) => {
  setPageNumber(a.activePage)
  setParams({...params,page:a.activePage})
  fetchLeadData(a.activePage)
   
  
}
  const getFormat = date => {
    if (
      moment.utc(date).format('dddd, MMMM Do YYYY') ===
        moment.utc().format('dddd, MMMM Do YYYY')
    )
      
      return <Table.Row col >
        <Table.Cell colSpan={6} style={{ textAlign: "center" }} className='date-separate'>
          Today
          </Table.Cell>
        </Table.Row>
    
    else if (
      moment(date).format('dddd, MMMM Do YYYY') ===
      moment()
        .subtract(1, 'days')
        .format('dddd, MMMM Do YYYY')
    ) {
      return <Table.Row col >
        <Table.Cell colSpan={6} style={{ textAlign: "center" }} className='date-separate'>Yesterday  </Table.Cell>
      </Table.Row>
    } else {
      return <Table.Row col >
        <Table.Cell colSpan={6} style={{ textAlign: "center" }} className='date-separate'>{moment(date).format('LL')}</Table.Cell>
      </Table.Row>
    }
  }
  
  let date1 = null

  const setCreatedDate = data => {
    if (moment.utc(data.request_call_time).format('YYYY-MM-DD') !== date1) {
      date1 = moment.utc(data.request_call_time).format('YYYY-MM-DD')
      return true
    } else {
      return false
    }
  }
useEffect(()=>{
  fetchLeadData(1,status)
},[status])
useEffect(()=>{
  fetchLeadData(1, status)
}, [startDateFilter, endDateFilter, fiterAgent])

const getPlaceholder={
  scheduled: { title: 'No upcoming bookings', subTitle:'There are no upcoming scheduled calls. Your scheduled calls and meetings will show up here'},
  completed:{title:'No past bookings',subTitle:'You have no past bookings. Your past bookings will show up here'},
'canceled':{title:'No canceled bookings',subTitle:'You have no canceled bookings. Your canceled bookings will show up here'},
  
}
return (
  <>
    <Dimmer active={loading} style={{ position: 'fixed' }}>
                            <Loader />
                          </Dimmer>
    <Tab.Pane attached={false}>
   
   {tableFullData?.leads?.data?.length>0 ?   
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell>ID</Table.HeaderCell> */}
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>Contact</Table.HeaderCell>
            <Table.HeaderCell>Source</Table.HeaderCell>
            <Table.HeaderCell>Meeting Details</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
            {tableFullData?.leads?.data?.sort((a, b) => moment(a?.request_call_time)?.valueOf() - moment(b?.request_call_time)?.valueOf())?.map((obj,index)=>   
       <>
       {obj?.id &&
       setCreatedDate(obj)
             ? getFormat(moment.utc(obj.request_call_time))
           : ''}
           <Table.Row onClick={() => {
             setSelectedId(obj?.id)
           }}


           >
             {/* <Table.Cell className="date-set-table">{obj?.id}</Table.Cell> */}
             <Table.Cell className="time-set-table"> {obj.request_call_time
               ? moment.utc(obj.request_call_time)
                 // .utc(data.request_call_time)
                 .format('dddd, MMMM, DD, YYYY')
               : ''}


               <br></br>
               {obj.request_call_time
                 ? moment.utc(obj.request_call_time)
                   // .utc(data.request_call_time)
                   .format('HH:hh A') + " "

                 + moment.tz.guess()
                 // ' to ' +
                 // moment(data.request_call_time)
                 //   // .utc(data.request_call_time)
                 //   .add(tableFullData?.meeting_duration, 'seconds')
                 //   .format('HH:hh A')
                 : ''}</Table.Cell>
             <Table.Cell className="metting-name">
               {obj?.contact_name}
               <br></br>
               {obj?.phone_number}
               <br></br>
               {obj?.email}
             </Table.Cell>
             <Table.Cell >
               <div className={obj?.source === 'Webpage' ? 'webpage' : 'facebook'}>
                 <Tooltip title={obj?.source || ''}>
                   <Link to={obj?.source}>
                     {obj.source === null ? 'Unknown' : trimUrl(obj?.source) || ''}
                   </Link>
                 </Tooltip>
               </div>
             </Table.Cell>
             <Table.Cell>
               <span style={{ fontWeight: 'bold' }}> {obj.agent}</span>
               {/* <br></br>
            {tableFullData?.leads?.data[index]?.request_call_day
              ? tableFullData?.leads?.data[index]?.request_call_day
              : ''} */}
               <br></br>

               Event location <span style={{ fontWeight: 'bold' }}>{obj?.meeting_location}</span>

             </Table.Cell>



             <Table.Cell className="social-icon-set">
               {obj?.schedule_call_status}
             </Table.Cell>
             <Table.Cell>
               <div style={{ display: 'flex', alignItems: 'center' }}>
                 <Tooltip title={'Cancel Meeting'}>
                   <EventBusyIcon
                     fontSize="large"
                     color="action"
                     onClick={(e) => {
                       e.stopPropagation()
                       setCancelModal(obj?.id)
                     }}
                   />
                 </Tooltip>
                 <Tooltip title={'Delete Meeting'}>
                   <DeleteForeverIcon
                     fontSize="large"
                     color="action"
                     onClick={(e) => {
                       e.stopPropagation()
                       deleteModalView(true, obj?.id)
                     }}
                   />
                 </Tooltip>

                 {status === 'scheduled' && <>
                   <div
                     onClick={(e) => {
                       e.stopPropagation()
                       setLeadId(obj)
                       setOpen(true)
                     }
                     }
                     data-tip="Open dialer"
                     style={{
                       marginLeft: 10,
                       marginRight: 10,
                       position: "relative",
                     }}
                   >
                     <Tooltip title={'Reschedule meeting'}>
                       <img
                         style={{ width: '30px' }}
                         src={schedule}
                       />
                     </Tooltip>
                   </div>
                   {/* <a
                  onClick={() => setOpen(true)}
                  data-tip="Send SMS & MMS"
                  href="javascript:void(0)"
                >
                  <Tooltip title={'Send SMS'}>
                   <MoreVertIcon/>
                  </Tooltip>
                </a> */}
                 </>}

                 {/* <Popup
              className="profile_popup"
              content={<AddTagPopup tags={allTags} addTag={addTag} />}
              size="large"
              position="bottom center"
              flowing
              on="click"
              trigger={
                <div style={{ marginTop: '15px' }}>
                  {!tags?.length && (
                    <a href="javascript:void(0)"> + Add New Tag</a>
                  )}{' '}
                </div>
              }
            /> */}
               </div>

             </Table.Cell>
           </Table.Row>
         </>
         )}
        </Table.Body>
      </Table>
    :<Segment style={{display:'flex', minHeight:'400px'}}>
    <div className="no-leads">
      <div className='icon'>
        <CalendarTodayIcon/>
      </div>
      <h5>{getPlaceholder?.[status]?.title}</h5>
      <p>{getPlaceholder?.[status]?.subTitle}</p>
    </div>
  </Segment>  
    }
      {open && (
        <Reschedule
                  closeAfterSend={isModalClose}

          open={open}

          isModalClose={isModalOpen}
          lead={leadId}
          id={leadId?.id}
          fetchData={fetchLeadData}
        // dropDownData={dropDownData}
        // leadData={data}
        />
      )}
      {totalPages > 1 && (
                                  <Pagination
                                    defaultActivePage={pageNumber}
                                    totalPages={totalPages}
                                    onPageChange={onPageChange}
                                  />
                                )}
    </Tab.Pane>

    <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => deleteModalView(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete the meeting {' '}
              {selectedLead}
              ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={() => deleteModalView(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={onDeleteLeadHandler}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    <Modal
        className="local-number-modal"
      open={cancelModal}
        onClose={e => setCancelModal(null)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to cancel the meeting {' '}
              {cancelModal}
              ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
              onClick={() => setCancelModal(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
              onClick={onCancelLeadHandler}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
      
       <RightPaneContainer selectedId={selectedId} setSelectedId={setSelectedId}/>
    </>
  )
}
const mapStateToProps = state => ({
  widget: state.home.widget,
  subscription: state.home.subscription,
  dialerStatus: state.dialer.currentStatus,
  profileData: state.settings?.profile?.data,

})


export default connect(mapStateToProps, {

  getDialer
})(MeetingTypeTeam)
