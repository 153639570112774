import { Grid } from '@mui/material'
import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import CommonButtons from '../../common/CommonButtons'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
const LanguageSettings = () => {
  return (
      <div className='mt-3'>
       <h4 >
        Language & TimeZone
        </h4> 
        <Grid container spacing={4} className='mt-3 w-50' >
            <Grid item md={6}>
                <label>System Language</label>
                  <Dropdown
                  style={{padding:'15px'}}
                      className="dropButton"
                    //   onChange={(e, data) => onActivityChange(e, data)}
                      selection
                    //   options={options}
                  />
            </Grid>
            <Grid item md={6}>
                <label>Dashboard timeZone</label>
                  <Dropdown
            style={{ padding: '15px',  }}


                      className="dropButton"
                    //   placeholder="All"
                    //   onChange={(e, data) => onActivityChange(e, data)}
                      selection
                    //   options={options}
                  />
            </Grid>
            <Grid item md={6}>
                <label>Currency</label>
                  <Dropdown
            style={{ padding: '15px',  }}


                      className="dropButton"
                    //   placeholder="All"
                    //   onChange={(e, data) => onActivityChange(e, data)}
                      selection
                    //   options={options}
                  />
            </Grid>
            <Grid item md={6}>
                <label>Dashboard date and time</label>
                  <Dropdown
            style={{ padding: '15px',  }}


                      className="dropButton"
                    //   placeholder="All"
                    //   onChange={(e, data) => onActivityChange(e, data)}
                      selection
                    //   options={options}
                  />
            </Grid>
        </Grid>
          <p className='mt-3'> <HelpOutlineIcon/> Contact your account advisor to change a currency</p>
          <CommonButtons background="blue">Update Settings</CommonButtons>

        </div>
  )
}

export default LanguageSettings