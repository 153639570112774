import React, { useState, useEffect } from 'react'
import { Accordion, Button, Grid, Modal,  Table } from 'semantic-ui-react'
import moment from 'moment'
import close from '../assets/images/close.png'
import { Rating, Typography } from '@mui/material'
import Axios from 'axios'
import { CommonNotify } from '../common/CommonNotify'

function CallLogs({ leadData, limit=5 }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [callLog, setCallLogs] = useState([])
  const [modal, setModal] = useState(false)
  const [leadInfo, setLeadInfo] = useState(null)


  useEffect(() => {
    if (leadData?.lead?.id) {
      fetchCallData()
    }
  }, [leadData?.lead?.id])
  useEffect(() => {
    if (
      leadInfo?.callData?.related_calls &&
      leadInfo?.callData?.related_calls?.length > 0
    ) {
      const filterValue = leadInfo?.callData?.related_calls?.filter(
        ({ failure_reason, final_status }) => {
          return failure_reason || final_status
        }
      )
      setCallLogs(filterValue || [])
    }
  }, [leadInfo?.callData?.related_calls])

  useEffect(() => {
    if (leadInfo?.related_calls && leadInfo?.related_calls?.length > 0) {
      const filterValue = leadInfo?.related_calls?.filter(
        ({ failure_reason, final_status }) => {
          return failure_reason || final_status
        }
      )
      setCallLogs(filterValue || [])
    }
  }, [leadInfo?.related_calls])

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const activeIndexs = activeIndex
    const newIndex = activeIndexs === index ? -1 : index
    setActiveIndex(newIndex)
  }
  


  const fetchCallData = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/get-call-by-id?id=${leadData?.lead?.id}`
    await Axios.get(url)
      .then(res => {
        if (res.data.data) {
          let pageHistory = []
          if (
            res?.data?.data?.trackingData &&
            res?.data?.data?.trackingData?.length !== 0
          ) {
            pageHistory = res?.data?.data?.trackingData?.browsing_history
              ? res?.data?.data?.trackingData?.browsing_history
              : []
          } else {
            pageHistory = []
          }
          setLeadInfo(res?.data?.data || null)
        }
      })
      .catch(error => {
        CommonNotify('Invalid Lead ID')
      })
  }

  return (
    <div className='contact-card insightTab'>
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
      <Accordion className="Lead_wrapper" fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
          className="proper-title"
        >
          Call Logs
        </Accordion.Title>
        <Accordion.Content
          active={activeIndex === 0}
          style={{ overflow: 'scroll' }}
        >
          <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Feedback</Table.HeaderCell>
                <Table.HeaderCell>Source</Table.HeaderCell>
              </Table.Row>{' '}
            </Table.Header>{' '}
            <Table.Body>
              {callLog &&
                callLog?.length > 0 &&
                callLog?.slice(0, limit ? limit : 10)?.map(call => {
                  return call?.failure_reason || call?.final_status ? (
                    <Table.Row>
                      <Table.Cell style={{ fontSize: '12px' }}>
                        {moment(call?.time).format('MMMM DD YYYY, h:mm a')}
                      </Table.Cell>
                      <Table.Cell style={{ fontSize: '12px' }}>
                        <span>
                          {call?.final_status ? call?.final_status : ''}
                        </span>
                        {call?.failure_reason && (
                          <>
                            <br></br>
                            <span>
                              {call?.failure_reason ? call?.failure_reason : ''}
                            </span>
                          </>
                        )}
                         {/* {call?.agent_feedback_text &&  */}
                       
                         

                        {call?.agent_name && (
                          <>
                            <br></br>
                            <span>
                              {call?.agent_name ? call?.agent_name : ''}
                            </span>
                          </>
                        )}
                        {call?.source && (
                          <>
                            <br></br>
                            <span>{call?.source ? call?.source : ''}</span>
                          </>
                        )}
                      </Table.Cell>
                      <Table.Cell style={{ fontSize: '12px' }}>
                    
                        {
                        call?.agent_feedback_text || call?.agent_feedback_rate ? 
                        <>
                       {call?.agent_feedback_tex && <Typography component="legend">{call?.agent_feedback_text}</Typography>}
                          {call?.agent_feedback_rate && <Rating name="read-only" value={call?.agent_feedback_rate} />}
                           </>
                           :<Typography>-</Typography>
                      }
                      </Table.Cell>
                      <Table.Cell style={{ fontSize: '12px' }}>
                      <Typography component="legend">{call?.original_source || '-'}</Typography>
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    ''
                  )
                })}

              {!callLog && (
                <Table.Row>
                  <Table.Cell>Not Available</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>{' '}
            {callLog && callLog?.length > 0 && (
              <div className="btnSee">
                <Button onClick={() => setModal(true)}>See More</Button>
              </div>
            )}
          </Table>
        </Accordion.Content>
        {modal && (
          <Modal
            onClose={() => setModal(false)}
            onOpen={() => setModal(true)}
            open={modal}
          >
            <div
              style={{
                overflowY: 'scroll',
                minHeight: '600px',
                maxHeight: '700px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  borderBottom: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: 'grey',
                  padding: 15,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <h1
                  style={{
                    fontSize: 23,
                    fontWeight: 'bold',
                    margin: '0 auto',
                    textAlign: 'center',
                    borderBottomWidth: 1,
                    borderBottomColor: 'gray'
                  }}
                >
                  Call Logs
                </h1>
                <div className="close-icon" onClick={() => setModal(false)}>
                  <img src={close} alt="close" />
                </div>
              </div>
              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Feedback</Table.HeaderCell>
                <Table.HeaderCell>Source</Table.HeaderCell>
                  </Table.Row>{' '}
                </Table.Header>{' '}
                <Table.Body>
                  {callLog &&
                    callLog?.length > 0 &&
                    callLog?.map(call => {
                      return call?.failure_reason || call?.final_status ? (
                        <Table.Row>
                          <Table.Cell style={{ fontSize: '12px' }}>
                            {moment(call?.time).format('MMMM DD YYYY, h:mm a')}
                          </Table.Cell>
                          <Table.Cell style={{ fontSize: '12px' }}>
                            <span>
                              {call?.final_status ? call?.final_status : ''}
                            </span>
                            {call?.failure_reason && (
                              <>
                                <br></br>
                                <span>
                                  {call?.failure_reason
                                    ? call?.failure_reason
                                    : ''}
                                </span>
                              </>
                            )}

                            {call?.agent_name && (
                              <>
                                <br></br>
                                <span>
                                  {call?.agent_name ? call?.agent_name : ''}
                                </span>
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell style={{ fontSize: '12px' }}>
                    
                        {
                        call?.agent_feedback_text || call?.agent_feedback_rate ? 
                        <>
                       {call?.agent_feedback_tex && <Typography component="legend">{call?.agent_feedback_text}</Typography>}
                          {call?.agent_feedback_rate && <Rating name="read-only" value={call?.agent_feedback_rate} />}
                           </>
                           :<Typography>-</Typography>
                      }
                      </Table.Cell>
                      <Table.Cell style={{ fontSize: '12px' }}>
                      <Typography component="legend">{call?.original_source || '-'}</Typography>
                      </Table.Cell>
                        </Table.Row>
                      ) : (
                        ''
                      )
                    })}
                </Table.Body>{' '}
              </Table>
            </div>
          </Modal>
        )}
      </Accordion>
        </Grid>
        </div>
    </div>
  )
}

export default CallLogs
