import React from 'react'
import { Modal } from 'semantic-ui-react'

function VerifyNewNumberModal({
  open,
  onClose,
  onOpen,
  children,
  className,
  actions
}) {
  return (
    <Modal
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      dimmer="inverted"
      className={className}
    >
      <Modal.Header>Add a Caller ID</Modal.Header>
      <Modal.Content style={{ padding: '25px' }}>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
      {actions ? <Modal.Actions>{actions}</Modal.Actions> : null}
    </Modal>
  )
}

export default VerifyNewNumberModal
