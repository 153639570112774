import React, { useState } from 'react'
import ColorPickerQuick from './ColorPickerQuick'
import { TextArea, Rail, Ref, Button, Tab } from 'semantic-ui-react'

import CommonInput from '../../../common/CommonInput'
import CommonSelect from '../../../common/CommonSelect'
import ContentFooter from '../ContentFooter'
// import modernTemplate from '../../../assets/images/modern_template.png'
import smartTemplate from '../../../assets/images/smart-template.png'
import classicTemplate from '../../../assets/images/Classic.png'
import modernTemplate from '../../../assets/images/modern.png'
import ultimateTemplate from '../../../assets/images/ultimate.png'
import callbackTemplate from '../../../assets/images/callback.png'
import iconStyle from '../../../assets/images/Dashboard 2-07.png'
import callMeBack from '../../../assets/images/phone-black.svg'
import callMeLetter from '../../../assets/images/mini-clock.png'
import leaveMessage from '../../../assets/images/message.png'
import DialerImage from '../../../assets/images/flaticons/dialer.png'
import callbackIcon from '../../../assets/images/callback-icon.png'
import scheduleIcon from '../../../assets/images/schedule-meeting.png'
import contactUs from '../../../assets/images/contact_us.png'
import addIcon from '../../../assets/images/add.png'
import messageIcon from '../../../assets/images/message.png'
import axios from 'axios'
import { CommonNotify } from '../../../common/CommonNotify'
import { IconButton, List, ListItem, ListItemText } from '@mui/material'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const panes = [
  { menuItem: 'Web Call', render: () => <Tab.Pane>Tab 1 Content</Tab.Pane> },
  {
    menuItem: 'Call Me Back',
    render: () => <Tab.Pane>Tab 2 Content</Tab.Pane>
  },
  {
    menuItem: 'Call Me Later',
    render: () => <Tab.Pane>Tab 3 Content</Tab.Pane>
  },
  {
    menuItem: 'Leave A Message',
    render: () => <Tab.Pane>Tab 3 Content</Tab.Pane>
  }
]
const Customize = props => {
  const { prev , next } = props
  const [language, setLanguage] = useState('English')
  const [companyName, setCompanyName] = useState('')
  const [message, setMessage] = useState('')
  const [theme, setTheme] = useState('Classic')
  const [color, setColor] = useState('#662D91')
  const [tabs, setTabs] = useState({
    clickToCall: true,
    callMeBack: false,
    callMeLater: false,
    leaveMessage: false
  })
  const [onContinueButton, setOnContinueButton] = useState(false)
  const onChangeTextArea = e => {
    const value = e.target.value
    setMessage(value)
  }

  const onChangeInput = e => {
    const companyValue = e.target.value

    setCompanyName(companyValue)
  }

  const onChangeSelect = e => {
    const value = e.target.innerText

    setLanguage(value)
  }

  const onTabChange = e => {
    const target = e.target.id
    if (target === 'clickToCall') {
      const data = {
        clickToCall: true,
        callMeBack: false,
        callMeLater: false,
        leaveMessage: false
      }
      setTabs(data)
    }
    if (target === 'callMeBack') {
      const data = {
        clickToCall: false,
        callMeBack: true,
        callMeLater: false,
        leaveMessage: false
      }
      setTabs(data)
    }
    if (target === 'callMeLater') {
      const data = {
        clickToCall: false,
        callMeBack: false,
        callMeLater: true,
        leaveMessage: false
      }
      setTabs(data)
    }
    if (target === 'leaveMessage') {
      const data = {
        clickToCall: false,
        callMeBack: false,
        callMeLater: false,
        leaveMessage: true
      }
      setTabs(data)
    }
  }

  const onThemeChange = e => {
    const value = e.target.innerText
    setTheme(value)
  }
  const changeHandler = colors => {  
    setColor(colors.color)
  }
  const onClickColor = e => {
    const colorhex = e.target.getAttribute('data')
    setColor(colorhex)
  }

  const onClickingContinue = async() => {
    const apiToken = await localStorage.getItem('access_token')
    setOnContinueButton(true)
    if (theme === '' || color === '' ) {
      CommonNotify('Please fill all felids', 'warning')
      setOnContinueButton(false)
      return
    }
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/quick-setup`
    const updateThemeData = {
      Classic: 1,
      Smart: 2,
      Modern: 3
    }
    const payload = {
      template_type: updateThemeData[theme],
      widget_color: color,
      company_name: companyName,
      bubble_text: "Request a callback in 20 seconds"
    }

    axios
      .post(url, payload)
      .then(res => {
        increaseStep()
      })
      .catch(error => {
        const errors = { ...error }        
        if (errors.response.data.errors) {
          const errorShown = errors.response.data.errors
          CommonNotify(errorShown[0])
          setOnContinueButton(false)
        } else {
          CommonNotify('Some thing went wrong')
        }
      })
  }

  const increaseStep = async () => {
    const apiToken = await localStorage.getItem('access_token')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/increase-onboarding`
    
    axios
      .post(url, {})
      .then(res => {
        setOnContinueButton(false)
        CommonNotify('Successfully updated', 'success')
        next()
      })
      .catch(err => {})
  }

  
  const widgetFeatures={
    Classic: [{ text: "Web calls", icon: DialerImage }, 
      { text: "Click2call", icon: callbackIcon },
      { text: "Schedule meetings", icon: scheduleIcon },
      { text: "Contact messages", icon: contactUs },

      { text: "Custom buttons", icon: addIcon },

],
    Ultimate: [{ text: "Web calls", icon: DialerImage }, 
      { text: "Click2call", icon: callbackIcon },
      { text: "Schedule meetings", icon: scheduleIcon },
      { text: "Contact messages", icon: contactUs },
      { text: "Text messages", icon: messageIcon },
      { text: "Custom buttons", icon: addIcon },

],
    Smart: [{ text: "Web calls", icon: DialerImage }, 
      { text: "Click2call", icon: callbackIcon },
      { text: "Schedule meetings", icon: scheduleIcon },
      { text: "Contact messages", icon: contactUs },
      { text: "Text messages", icon: messageIcon },
      { text: "Custom buttons", icon: addIcon },

],
    Modern: [{ text: "Web calls", icon: DialerImage }, 
      { text: "Click2call", icon: callbackIcon },
      { text: "Schedule meetings", icon: scheduleIcon },
      { text: "Contact messages", icon: contactUs },

      { text: "Custom buttons", icon: addIcon },

],
    Callback: [
      { text: "Click2call", icon: callbackIcon },
      { text: "Schedule meetings", icon: scheduleIcon },


]
  }

  return (
    <>
      <div className="customize">
        <div className='w-100 bg-white'>
          <div className="customize-title-wrapper">
            <h2 className="customize-title">
             Choose a widget template
            </h2>
          </div>
          <div className="language-wrapper align-box">
            <h2 className="title" style={{fontWeight:500}}>Template</h2>
            <CommonSelect
              onChange={e => onThemeChange(e)}
              style={{width:'60%'}}
              name="language"
              options={[' ', 'Classic', 'Smart', 'Modern','Ultimate','Callback']}
              defaultValue={'Classic'}
              value={theme}
              disabled={onContinueButton}
            />
          </div>
          <ColorPickerQuick
            changeHandler={e => changeHandler(e)}
            color={color}
            onClickColor={onClickColor}
            disabled={onContinueButton}
          />
     {/* <div className="company-wrapper align-box">
            <h2 className="title">Company Name</h2>
            <CommonInput
              onChange={onChangeInput}
              name="companyName"
              type="text"
              inputStyle="input-company w-100"
              defaultValue={companyName}
              disabled={onContinueButton}
            />
          </div> */}
          {/* <div className="welcome-wrapper align-box">
            <h2 className="title">Welcome Message</h2>
            <TextArea
              onChange={onChangeTextArea}
              placeholder="e.g. lorem ipsum dolor sit amet"
              className="welcome-textarea"
              rows="5"
              defaultValue={message}
              disabled={onContinueButton}
            />
          </div>  */}

        
          <ContentFooter
            prev={prev}
            next={next}
            hideSkip
            loading={onContinueButton}
            onClick={onClickingContinue}
          />
        </div>
      </div>
      <div className='classic-info'>
        
  
        
        
        
        <Ref>
            {theme === 'Classic' ? (
              <div style={{ color: `${color}` }}>
                <h4>Classic preview</h4>
                <div>
                  <img src={classicTemplate} style={{ width: '100%' }} alt="" />
                </div>
              </div>
            ) : theme === 'Smart' ? (
              <div style={{ color: `${color}` }}>
                <h4>Smart preview</h4>
                <div>
                  <img src={smartTemplate} style={{ width: '100%' }} alt="" />
                </div>
              </div>
            ) : theme === 'Modern' ? (
              <div style={{ color: `${color}` }}>
                <h4>Modern preview</h4>
                <div>
                  <img src={modernTemplate} style={{ width: '100%' }} alt="" />
                </div>
              </div>
            ) : theme === 'Ultimate' ? (
              <div style={{ color: `${color}` }}>
                <h4>Ultimate preview</h4>
                <div>
                  <img src={ultimateTemplate} style={{ width: '100%',height:'315px' ,objectFit:'contain'}} alt="" />
                </div>
              </div>

            ) : theme === 'Callback' ? (
              <div style={{ color: `${color}` }}>
                      <h4>Callback preview</h4>
                <div>
                  <img src={callbackTemplate} style={{ width: '100%' }} alt="" />
                </div>
              </div>
            ) : null}
            
        </Ref>
        <div style={{marginTop:'15px'}}>

          <h4>Template Features</h4>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {widgetFeatures?.[theme]?.map((value) => (
              <ListItem
                key={value}
                disableGutters
                sx={{ py: 0.5 }}
              >
                <ListItemText sx={{ my: 0 }}>
                  <IconButton aria-label="comment" disableRipple>
                    <img
                      style={{ width: '30px', marginTop: '-10px', marginRight: 5 }}
                      src={value?.icon}
                    />
                  </IconButton>
                  <span>
                    {value.text}
                  </span>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </>
  )
}

export default Customize
