import React, { useEffect, useState } from 'react'
import icon from '../../assets/images/settingIcon.png'
import Title from '../../common/Title'
import { Modal, Table } from 'semantic-ui-react'
import CommonButtons from '../../common/CommonButtons'
import Axios from 'axios'
import { CommonNotify } from '../../common/CommonNotify'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { getForms } from '../../actions/home'
import { Switch } from '@mui/material'
import CommonInput from '../../common/CommonInput'



const Forms = ({ forms }) => {
  const dispatch=useDispatch()
  const navigate =useHistory()
  const [myForms, setMyForms] = useState([])
  const [form, setForm] = useState({name:'',description:'',status:false})
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
const [openConfirmationModal, setOpenConfirmationModal] = useState(null)
  useEffect(() => {
    document.title = 'Forms'
  }, [])

  const titleContent = {
    type: 'image',
    titleOne: icon,
    titleTwo: 'Forms'
  }

  const deleteForm = async (id) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/form/${id}`

    Axios.delete(url)
      .then(res => {
        dispatch(getForms())
        setOpenConfirmationModal(null)
        CommonNotify('Deleted Successfully', 'success')
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  useEffect(() => {
    if (!forms) {
      dispatch(getForms())
    }else{
      setMyForms([...forms])

    }
  }, [forms])
  
  const createForm = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/form`
    const formData = new FormData()
    formData.append('name', form?.name)
    formData.append('description', form?.description)
    formData.append('status', form?.status ? 1 : 0)
    
    await Axios.post(url, formData)
      .then(res => {
        CommonNotify('Created Successfully', 'success')
        dispatch(getForms())
setModal(!modal)
      })
      .catch(err => {
        setModal(!modal)

        if (err?.response?.status === 401) {
          
          return
        }
        if (!err?.response) {
        }
      })
  }
  
  
  return (
    <div className="fullwidth-container number-container">
      <div className="manage_number_main">
        <Title data={titleContent} />

        <div className="numbers-banner">
          <div className="banner-icon">
            <img src={icon} alt="icon" />
          </div>
          <div className="banner-description-wrapper">
            <p className="banner-description ml-1" style={{fontWeight:600}}>Manage your Forms</p>
          </div>
        </div>

        <div
          className="my-number-tab manage_table"
          style={{  backgroundColor: '#f4f4f4' }}
        >
          <div className="table-heading-wrapper" style={{display:'flex', justifyContent:'flex-end'}}>
            <CommonButtons
              onClick={() => setModal(!modal)}
              content="Add Form"
              btnClass="btn-blue"
            />
          </div>
          <Table style={{ tableLayout: 'fixed' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Last edited at</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {myForms.map((row, index) => (
                <Table.Row>
                  <Table.Cell>{row?.id}</Table.Cell>
                  <Table.Cell>{row?.name}</Table.Cell>
                  <Table.Cell>{row?.description}</Table.Cell>
                  <Table.Cell>                        {moment(row?.created_at)?.format('LT')}
</Table.Cell>

                  <Table.Cell>
                    <div className="number_button">
                      <CommonButtons
                        type="button"
                        content="Manage"
                        background="blue"
                        onClick={() => navigate?.push(`/manage-form/${row?.id}`)}
                      />

                      <CommonButtons
                        type="button"
                        content="Delete"
                        background="red"
                        onClick={e => setOpenConfirmationModal(row?.id)}
                        style={{ borderRadius: 0, marginLeft: '10px' }}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
       
              <Modal
        className="local-number-modal"
        open={openConfirmationModal}
        onClose={e => setOpenConfirmationModal(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this form ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={e => setOpenConfirmationModal(null)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                    onClick={() => deleteForm(openConfirmationModal)}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
          {/* <Confirm
            className="confirmBox-wrapper"
            open={confirmDel}
            header="Delete Number!"
            content={`Do you want to delete Number :  ${selectedNumber !== null ? selectedNumber.phone_number : ''
              } ?`}
            onCancel={close}
            onConfirm={handleDelete}
          />

          <Confirm
            className="confirmBox-wrapper"
            open={confirmDel2}
            header={`Number Unsubcribed`}
            content={`the selected number will be cancelled at the end of subscription and remove it at end of subscription at ${selectedNumber?.next_billing_at}`}
            onConfirm={close2}
          /> */}
        </div>
      </div>
      <Modal
        style={{ width: '500px', overflow: 'visible' }}
        open={modal}
        onClose={() => setModal(!modal)}
      >
        <Modal.Header>{'Create a new form'}</Modal.Header>
        <Modal.Content>
          <div className="p-4">
          
            <div className='form-field'>
              <label>Name</label>
              <CommonInput
                defaultValue={form?.name}
                value={form?.name}
                inputStyle={'w-100'}
                onChange={e =>
                  setForm({ ...form, name: e.target.value })
                }
              />
            </div>
            <div className='form-field'>
              <label>Description</label>
              <CommonInput
                value={form?.description}

                inputStyle={'w-100'}
                onChange={e =>
                  setForm({ ...form, description: e.target.value })
                }
              />
            </div>  <div className='form-field'>
              <label>Status</label>
              <Switch
                checked={form?.status}
                // inputStyle={'w-100'}
                onChange={e =>
                  setForm({ ...form, status: e.target.checked })
                }
              />
            </div>

            
          </div>
        </Modal.Content>
        <Modal.Actions>
          <CommonButtons onClick={() => setModal(!modal)}>Cancel</CommonButtons>

          <CommonButtons background="blue" onClick={() => createForm()}>
            { 'ADD'}
          </CommonButtons>
        </Modal.Actions>
      </Modal>
   
    </div>
  )
}


const mapStateToProps = state => {

  return { forms: state?.home?.forms }
}
export default connect(mapStateToProps, {})(Forms)
