/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Dimmer, Dropdown, Loader, Modal, Tab } from 'semantic-ui-react'
import { useDrag, useDrop } from 'react-dnd';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import CommonButtons from '../../common/CommonButtons'
import CommonCheckbox from '../../common/CommonCheckbox'
import CommonInput from '../../common/CommonInput'
import { EyeOnIcon, TrashIcon } from 'evergreen-ui'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import icon from '../../assets/images/settingIcon.png'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { ButtonBase, Menu, MenuItem, Slider, Switch } from '@mui/material'
import { CommonNotify } from '../../common/CommonNotify'
import Axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Title from '../../common/Title'
import { connect, useDispatch } from 'react-redux'
import { getForms } from '../../actions/home'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const titleContent = {
    type: 'image',
    titleOne: icon,
    titleTwo: 'Manage Form'
}
const ItemType = 'ROW';


const ManageForm = ({ forms }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()

    const initialQue = { id: '', name: '', type: '', required: false }

    const [fields, setFields] = useState([ ])
    const [modal, setModal] = useState(false)
    const [inputs, setInputs] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [activeIndex, setIndex] = useState(0)

    const [currentField, setCurrentField] = useState({ ...initialQue })
    const [form, setForm] = useState({ })
    const [deleteModal, setDeleteModal] = useState(null)
    const [loading, setLoading] = useState(false)
    
    const submitQuestion = async() => {
        const payload = { ...currentField }
        const formData = new FormData()
        formData?.append('basic_input_id', currentField?.id)
        formData?.append('name', currentField?.name)
        formData.append('is_required', currentField?.required ? 1 : 0)
  
        payload?.options?.forEach((obj, index) => {
            if (obj?.text){
                
                formData?.append(`options[${index + 1}]`, obj?.text)
            }
        })
        setModal(!modal)


        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/form/${form?.id}/input`

        await Axios.post(url, formData)
            .then(res => {


                getForm(form?.id)

                setCurrentField({ ...initialQue })
                setIsEdit(false)
                setModal(!modal)
            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                }
            })


    }



    const createForm = async () => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/form`
        const formData = new FormData()
        formData.append('name', form?.name)
        formData.append('description', form?.description)
        formData.append('status', form?.status ? 1 : 0)
        const aaa = await fields?.forEach((obj, index) => {
            formData.append(`inputs[${index}][id]`, obj?.id)
            formData.append(`inputs[${index}][name]`, obj?.name)
            formData.append(`inputs[${index}][screen]`, obj?.screen)
            formData.append(`inputs[${index}][priority]`, obj?.screen)
            formData.append(`inputs[${index}][is_required]`, obj?.required ? 1 : 0)
            const aa = obj?.options?.map((val, ind) => {
                if (val?.text) {

                    formData.append(`inputs[${index}][options][${ind}]`, val?.text)
                }
            }
            )

        })
        await Axios.post(url, formData)
            .then(res => {
                CommonNotify('Updated Successfully', 'success')
                history.push('/forms')
                dispatch(getForms())

            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                }
            })
    }
   


    const updateForm = async (formData) => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/form/${formData?.id}`
        const mainFormData = {}
        mainFormData['name'] = formData?.name
        mainFormData['description'] = formData?.description
        mainFormData['status'] = formData?.status ? 1 : 0



        await Axios.put(url, mainFormData)
            .then(res => {
                CommonNotify('Updated Successfully', 'success')
             dispatch(getForms())

            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                }
            })

    }
    const deleteForm = async () => {
        setDeleteModal(false)

        setLoading(true)
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/form/input/${deleteModal?.formInputId}`
  



        await Axios.delete(url)
            .then(res => {
                CommonNotify('Deleted Successfully', 'success')
                setLoading(false)

            getForm(form?.id)
            })
            .catch(err => {
                setLoading(false)

                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                }
            })

    }
    const getInputs = async () => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/basic-inputs`

        await Axios.get(url)
            .then(res => {
                const data = res?.data?.data
                setInputs(data?.map((obj) => ({
                    text: obj?.name,
                    id: obj?.id,
                    has_options: obj?.has_options,
                    value: obj?.type,
                    options: []
                })))


            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                }
            })
    }

    useEffect(() => {
        const array = location?.pathname?.split('/')
        const id = array?.[array?.length - 1]
        if (id && inputs?.length){
            getForm(id)
        }     

    }, [ location, inputs])
    
    
const getForm = (id) => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/form/${id}`

    Axios.get(url)
        .then(res => {
            const data = res?.data?.data?.inputs?.map((obj) => {
                const aa = inputs?.filter((val) => obj?.input?.id === val?.id)?.[0]
                if (aa) {
                    const input = obj?.input
                    return ({
                        text: input?.name,
                        id: input?.id,
                        has_options: input?.has_options,
                        value: input?.type,
                        options: obj?.options?.map((d) => ({ text: d })),
                        formInputId: obj?.id,
                        name:obj?.name
                    })
                }
            })
            setFields([...data])
            setForm(res?.data?.data)
            setLoading(false)

        })
        .catch(err => {
            setLoading(false)

            if (err?.response?.status === 401) {
                return
            }
            if (!err?.response) {
            }
        })
 }

  
 


    useEffect(() => {
        getInputs()
    }, [])

    const handleTabChange = (r, data) => setIndex(data.activeIndex)
    const panes = useMemo(
        () => [
            {
                menuItem: { id: 'tab1', content: 'Questions' },
                render: ({ formData, fieldData, currentField,modal,setModal }) => (
                    <>  
                    <div style={{  display: 'flex', flexDirection: 'column' }}>
                        <div className='lead-qualification form-field' style={{border:!fieldData?.length &&'none'}}>
                            {fieldData?.sort((a, b) => a?.screen - b?.screen)?.map((obj, index) => (
                  
                                <div  className='question-set' style={{ borderBottom: index === fieldData?.length - 1 ? '' : '1px solid' }}>
                                    <div className='d-flex w-100' style={{ justifyContent: 'space-between' }}>

                                        <p>
                                            {obj?.name || 'I am '}
                                        </p>
                                        <TrashIcon
                                            style={{ color: 'red' }}
                                            onClick={(event) => {
                                                event.stopPropagation()
                                                setDeleteModal({ ...obj })
                                            }}
                                        />
                                    </div>
                                    <p style={{ color: 'grey' }}>
                                        {`${obj?.text} ${obj?.options?.length > 0 ? `(${obj?.options?.map((val) => val?.text)?.join(', ')})` : ''}`}
                                    </p>
                                </div> 

                            ))}
                        </div>

                        <p className='mt-3 form-field' onClick={() => setModal(true)}>
                            <DataSaverOnIcon /> Add Field
                        </p>

                      


                     
                    </div></>
                )
            },
             {
                menuItem: { id: 'tab3', content: 'Settings' },
                 render: ({ formData }) => (
                    <>    <div style={{  display: 'flex', flexDirection: 'column' }}>
                        <div className='form-field'>
                            <label>Name</label>
                            <CommonInput
                                defaultValue={formData?.name}
                                value={formData?.name}
                                inputStyle={'w-100'}
                                onChange={e =>
                                    setForm({ ...formData, name: e.target.value })
                                }
                            />
                        </div>
                        <div className='form-field'>
                            <label>Description</label>
                            <CommonInput
                                 value={formData?.description}

                                inputStyle={'w-100'}
                                onChange={e =>
                                    setForm({ ...formData, description: e.target.value })
                                }
                            />
                        </div> 
                         <div className='form-field'>
                            <label>Status</label>
                            <Switch
                                 checked={formData?.status}
                                // inputStyle={'w-100'}
                                onChange={e =>
                                    setForm({ ...formData, status: e.target.checked })
                                }
                            />
                        </div>
                         <div className='form-field'>
                             <CommonButtons background={'blue'} style={{ height: "fit-content", marginLeft: "16px" }} onClick={() => updateForm(form)}>
                                 Save
                             </CommonButtons>
                        </div>
                    
                        </div></>
                )
            },
            
        ],
        []
    )
    return (
        <>
            <Dimmer active={loading}>
                <Loader />
            </Dimmer>
            <DndProvider backend={HTML5Backend}>
                <div className="fullwidth-container number-container manage-form" style={{width:'100%'}} >
                    <Title data={titleContent} />
                    <div className="d-flex mt-5 pt-5" style={{marginLeft:'325px'}}>
                        <div style={{ flex: "0 0 auto", maxWidth: 625, width: "100%", }}>
                            <Dropdown
                                name="from"
                                placeholder="Select Screen"
                                selection
                                options={[]}
                                // value={currentField?.value}
                                // onChange={(e, data) => {
                                //     const selected = inputs?.filter((obj) => obj?.value === data?.value)?.[0]
                                //     setCurrentField({ ...currentField, ...selected })

                                // }}
                            />
                            <h2 style={{marginBottom:'50px', background:'#fff', padding:'15px' , textAlign:'center', borderRadius:'10px'}}>{form?.name}</h2>

                            <Tab
                                style={{ background: "#fff", padding: '15px', justifyContent: 'center', borderRadius: '10px' }}
                                menu={{ secondary: true, pointing: true }}
                                panes={panes}
                                activeIndex={activeIndex}
                                onTabChange={handleTabChange}
                                formData={form}
                                fieldData={fields} 
                                currentField={currentField}
                                modal={modal}
                                setModal={setModal}
                            />
                        </div>

                     

                        <CommonButtons background={'blue'} style={{height:"fit-content",marginLeft:"16px"}}>
                            <EyeOnIcon />
                            &nbsp;View form
                        </CommonButtons>
                    </div>
                </div>
            </DndProvider>
            <Modal
                style={{ width: '500px', overflow: 'visible' }}
                open={modal}
                onClose={() => setModal(!modal)}
            >
                <Modal.Header>{isEdit ? 'Edit Field' : 'Add New Field'}</Modal.Header>
                <Modal.Content>
                    <div className="p-4">
                        <div className='form-field mt-3'>
                            <label>Name</label>
                            <CommonInput
                                value={currentField?.name || ''}
                                inputStyle={'w-100'}
                                onChange={(e) =>

                                    setCurrentField({ ...currentField, name: e.target.value })}

                            />
                        </div>
                        <div className="d-flex mt-3">
                            <CommonCheckbox
                                checked={currentField?.required}
                                onChange={(e, value, checked) => {

                                    setCurrentField((prev) => ({ ...prev, required: !checked }))
                                }}


                            />{' '}
                            Required
                        </div>

                        <div className="mt-3">
                            <label>Type</label>
                            <Dropdown
                                name="from"
                                placeholder="Select Type"
                                selection
                                options={inputs?.filter((obj)=>obj?.id!==14)}
                                value={currentField?.value}
                                onChange={(e, data) => {
                                    const selected = inputs?.filter((obj) => obj?.value === data?.value)?.[0]
                                    setCurrentField({ ...currentField, ...selected })

                                }}
                            />
                            <div>

                                {currentField?.has_options ?
                                    <>
                                        {currentField?.options?.map((obj, index) => (
                                            <div
                                                className="d-flex mt-3"
                                                style={{ alignItems: 'center', gap: '10px' }}
                                            >
                                                <CommonInput
                                                    placeholder={`Add option ${index + 1}`}
                                                    value={obj?.text}
                                                    onChange={e => {
                                                        setCurrentField({
                                                            ...currentField, options: [
                                                                ...currentField?.options?.map((obj, ind) =>
                                                                    index === ind
                                                                        ? { ...obj, text: e.target.value }
                                                                        : { ...obj }
                                                                )
                                                            ]
                                                        })
                                                    }}
                                                />{' '}
                                                <TrashIcon
                                                    onClick={() =>
                                                        setCurrentField({
                                                            ...currentField, options: [
                                                                ...currentField?.options?.filter((obj, ind) =>
                                                                    index !== ind
                                                                )
                                                            ]
                                                        })
                                                    }
                                                />
                                            </div>
                                        ))}
                                        <p
                                            className="cursor-pointer mt-3"
                                            onClick={() => {
                                                setCurrentField({
                                                    ...currentField, options: [
                                                        ...currentField?.options, { text: '' }
                                                    ]
                                                })
                                            }}
                                        >
                                            <ControlPointIcon /> Add option{' '}
                                        </p>
                                    </> : ''}
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <CommonButtons onClick={() => setModal(!modal)}>Cancel</CommonButtons>

                    <CommonButtons background="blue" onClick={() => submitQuestion()}>
                        {isEdit ? 'Save' : 'ADD'}
                    </CommonButtons>
                </Modal.Actions>
            </Modal>
            <Modal
                className="local-number-modal"
                open={deleteModal}
                onClose={e => setDeleteModal(null)}
            >
                <Modal.Content>
                    <div className="modal-content">
                        <p style={{ marginTop: 20 }}>
                            Are you sure you want to delete this input ?
                        </p>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                                paddingTop: 10,
                                paddingBottom: 10
                            }}
                        >
                            <CommonButtons
                                onClick={e => setDeleteModal(null)}
                                type="button"
                                content="Cancel"
                                background="red"
                                style={{
                                    borderRadius: 0,
                                    backgroundColor: '#e0e1e2',
                                    color: '#5a5a5a'
                                }}
                            />

                            <CommonButtons
                                onClick={deleteForm}
                                type="button"
                                content="Confirm"
                                background="blue"
                                style={{
                                    marginLeft: 20,
                                    marginRight: 20,
                                    borderRadius: 0,
                                    backgroundColor: '#1071eb',
                                    color: '#ffffff'
                                }}
                            />
                        </div>
                    </div>
                </Modal.Content>
            </Modal>
        </>
    )
}
const mapStateToProps = state => {

    return { forms: state?.home?.forms }
}
export default connect(mapStateToProps, {})(ManageForm)
