import axios from 'axios'
import React from 'react'
import GoogleLogin from 'react-google-login'
import CommonButton from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import ValidateRegApi from '../../helpers/signup/ValidateRegApi'
import { analytics } from '../../Segmant'

const GoogleButton = (props, login = true) => {
  const { content } = props
  const isDailer = sessionStorage?.getItem('isDialer')

  const redirectPage = value => {
    if (+isDailer === 1) {
      window.location.href = '/dialer'
    } else {
      window.location.href = value ? value : '/'
    }
  }

  const googleLogin = async response => {
    if (!login) {
      const userExist = await ValidateRegApi(
        'email',
        response.profileObj.email,
        'api/email-validation'
      )
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/social-login`

    const data = {
      email: response.profileObj.email,
      name: response.profileObj.givenName + '' + response.profileObj.familyName,
      provider_return_id: response.accessToken,
      social_type: 1
    }

    axios
      .post(url, data)
      .then(res => {
        props.loading(false)
        if (res.data.data.access_token) {
          analytics.identify(res.data.data.id, {
            name: res.data.data.first_name,
            email: res.data.data.email,
            phone: res.data.data.official_phone_number,
            company: res.data.data.company_name
          });
          analytics.track('Login');
          localStorage.setItem(
            'phone_numbers',
            JSON.stringify(res.data.data.phone_numbers)
          )
          localStorage.setItem('access_token', res.data.data.access_token)
          localStorage.setItem(
            'working_hours_status',
            res.data.data.working_hours_status
          )
          localStorage.setItem('widget_id', res.data.data.widget_id)
          localStorage.setItem(
            'mobile_verification_status',
            res.data.data.mobile_verification_status
          )
          localStorage.setItem('plan_name', res.data.data.plan_name)
          localStorage.setItem('signed_up_at', res.data.data.signed_up_at)
          localStorage.setItem('status', res.data.data.status)
          localStorage.setItem(
            'profile_pic',
            res.data.data.profile_image
              ? `${process.env.REACT_APP_BASE_APP_URL}/storage` +
                  res.data.data.profile_image
              : null
          )
          localStorage.setItem('quick_setup', 1)
          localStorage.setItem('role_name', res.data.data.role.name)
          if (res.data.data?.role_type?.toLocaleLowerCase() !== 'owner') {
            localStorage.setItem('flowType', 'invited')
          }
          localStorage.setItem('first_name', res.data.data.first_name)
          localStorage.setItem(
            'full_name',
            res.data.data.first_name + res.data.data.last_name
          )
          localStorage.setItem('last_name', res.data.data.last_name)
          localStorage.setItem('skip_onboarding', res.data.data.skip_onboarding)
          localStorage.setItem('email', res.data.data.email)
          localStorage.setItem('id', res.data.data.id)
          localStorage.setItem('email_verification_status', '1')
          localStorage.setItem(
            'admin_verification_status',
            res.data.data.admin_verification_status
          )

          if (res.data.data.account_status) {
            localStorage.setItem('account_status', '1')
          } else {
            localStorage.setItem('account_status', '0')
          }

          if (res.data.data.role.name === 'Admin') {
            if (
              res.data.data.onboarding_step >= 4 ||
              res.data.data.mobile_verification_status === 1 ||
              res.data.data.skip_onboarding === 1
            ) {
              localStorage.setItem('quick_setup', 5)
              redirectPage('/home')
            } else {
              localStorage.setItem('quick_setup', 1)
              redirectPage('/QuickSetup')
            }
          } else {
            if (
              res.data.data.onboarding_step >= 2 ||
              res.data.data.mobile_verification_status === 1 ||
              res.data.data.skip_onboarding === 1
            ) {
              localStorage.setItem('quick_setup', 5)
              redirectPage('/home')
            } else {
              localStorage.setItem('quick_setup', 5)
              redirectPage('/home')
            }
          }

          return
        }
      })
      .catch(async error => {
        props.loading(false)
        const errors = await { ...error }
        if (errors.response && errors.response.data.errors) {
          CommonNotify(errors.response.data.errors[0])
        } else {
          CommonNotify('Some thing went wrong')
        }
      })
  }

  const googleLoginFailed = response => {}

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GLOGIN_CLIENT_ID}
      render={renderProps => (
        <CommonButton
          onClick={renderProps.onClick}
          btnClass="login-with login-google"
          content={content}
        />
      )}
      onSuccess={googleLogin}
      onFailure={googleLoginFailed}
      cookiePolicy={'single_host_origin'}
    />
  )
}

export default GoogleButton
