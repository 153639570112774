import { DialogActions, DialogContent, Grid } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { Tab, TextArea } from 'semantic-ui-react'
import CommonInput from '../../common/CommonInput'
import { CommonNotify } from '../../common/CommonNotify'
import Axios from 'axios'

const KycContainer = ({kycInfo}) => {
    const [activeIndex, setIndex] = useState(0)
    const [kycData, setKycData] = useState({...kycInfo})
    const handleKYCFile = async event => {

        const file = event?.target?.files?.[0]
        let formData = new FormData()
        formData.append('file', file)
        this.setState({ kycData: { ...this.state.kycData, id_image: JSON.stringyfy(file) } })
    
      
    }

    const handleSubmitKYC = async () => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/kyc`
        await Axios.post(url, { ...kycData })
          .then(async res => {
            CommonNotify('Updated successfully.')
          })
          .catch(err => {
            CommonNotify(
              err?.response?.data?.errors?.[0] || 'Something went to wronng!'
            )
          })
      }
      
    const panes = useMemo(
        () => [
          {
            menuItem: { id: 'tab1', content: 'Personal Details' },
            render: () => (
                <Grid spacing={2} container >
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      First Name
                    </h6>
                    <CommonInput
                      style={{ width: '100%' }}
                      value={kycData?.first_name || ''}
                      placeholder="Enter First Name"
                      name="SetCampaign"
                      onChange={e => {
                        setKycData({
                         ...kycData, first_name: e.target.value 
                        })
                      }}
                      defaultValue={kycData?.first_name || ''}
                    />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      Last Name
                    </h6>
                    <CommonInput
                      style={{ width: '100%' }}
                      value={kycData?.last_name || ''}
                      placeholder="Enter Last Name"
                      name="SetCampaign"
                      onChange={e => {
                        setKycData({
                         ...kycData, last_name: e.target.value 
                        })
                      }}
                      defaultValue={kycData?.last_name || ''}
                    />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      City
                    </h6>
                    <CommonInput
                      style={{ width: '100%' }}
                      value={kycData?.city || ''}
                      placeholder="Enter City"
                      name="SetCampaign"
                      onChange={e => {
                        setKycData({
                         ...kycData, city: e.target.value 
                        })
                      }}
                      defaultValue={kycData?.city || ''}
                    />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      Country
                    </h6>
                    <CommonInput
                      style={{ width: '100%' }}
                      value={kycData?.country || ''}
                      placeholder="Enter Country"
                      name="SetCampaign"
                      onChange={e => {
                        setKycData({
                         ...kycData, country: e.target.value 
                        })
                      }}
                      defaultValue={kycData?.country || ''}
  
                    />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      State
                    </h6>
                    <CommonInput
                      style={{ width: '100%' }}
                      value={kycData?.state || ''}
                      placeholder="Enter State"
                      name="SetCampaign"
                      onChange={e => {
                        setKycData({
                         ...kycData, ["state"]: e.target.value 
                        })
                      }}
                      defaultValue={kycData?.state || ''}
                    />
                  </div>
                </Grid>
              
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      Personal Id
                    </h6>
                    <CommonInput
                      style={{ width: '100%' }}
                      value={kycData?.purpose || ''}
                      placeholder="Enter Personal Id"
                      name="SetCampaign"
                      onChange={e => {
                        setKycData({
                         ...kycData, ["personal_id"]: e.target.value 
                        })
                      }}
                      defaultValue={kycData?.purpose || ''}
                    />
                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      ZIP Code
                    </h6>
                    <CommonInput
                    type='number'
                      style={{ width: '100%' }}
                      value={kycData?.zip || ''}
                      placeholder="Enter ZIP"
                      name="SetCampaign"
                      onChange={e => {
                        setKycData({
                         ...kycData, ["zip"]: e.target.value 
                        })
                      }}
                      defaultValue={kycData?.zip || ''}
                    />
                  </div>
                </Grid>
              
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                    Address
                    </h6>
                   
          <TextArea
            name="adress"
            value={kycData?.address}
            onChange={e => {
                setKycData({
                 ...kycData, ["address"]: e.target.value 
                })
              }}
            rows={4}
            title="Adress *"
          />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      Address proof
                    </h6>
                    <input
                      type="file"
                      onChange={handleKYCFile}
                      className="form-control"
                      name="upload_file"
                      style={{ padding: '2px', marginTop: '12px' }}
                    // accept=".wav,.flac,aiff,.mp3"
                    />
                  </div>
                </Grid>
              </Grid>
            )
          },
          {
            menuItem: { id: 'tab2', content: 'Company Details' },
            render: () => (
                <Grid spacing={2} container >
               
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      Company
                    </h6>
                    <CommonInput
                      style={{ width: '100%' }}
                      value={kycData?.business_name || ''}
                      placeholder="Enter Company"
                      name="SetCampaign"
                      onChange={e => {
                        setKycData({
                         ...kycData, ["business_name"]: e.target.value 
                        })
                      }}
                      defaultValue={kycData?.business_name || ''}
                    />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                    Registration Id
                    </h6>
                    <CommonInput
                      style={{ width: '100%' }}
                      value={kycData?.business_name || ''}
                      placeholder="Enter Registration Id"
                      name="SetCampaign"
                      onChange={e => {
                        setKycData({
                         ...kycData, ["business_name"]: e.target.value 
                        })
                      }}
                      defaultValue={kycData?.business_name || ''}
                    />
                  </div>
                </Grid>
               
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      Purpose
                    </h6>
                    <TextArea
            name="adress"
            value={kycData?.purpose}
            onChange={e => {
                setKycData({
                 ...kycData, ["purpose"]: e.target.value 
                })
              }}
            rows={4}
            title="Adress *"
          />
              
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                    Address
                    </h6>
                   
          <TextArea
            name="adress"
            value={kycData?.address}
            onChange={e => {
                setKycData({
                 ...kycData, ["address"]: e.target.value 
                })
              }}
            rows={4}
            title="Adress *"
          />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="assing_opt">
                    <h6 className="accordion-title">
                      Address proof
                    </h6>
                    <input
                      type="file"
                      onChange={handleKYCFile}
                      className="form-control"
                      name="upload_file"
                      style={{ padding: '2px', marginTop: '12px' }}
                    // accept=".wav,.flac,aiff,.mp3"
                    />
                  </div>
                </Grid>
              </Grid>
            )
          }
        ],
        []
      
     )
     const handleTabChange = (r, data) => setIndex(data.activeIndex)
 
  return (
    <div>  
          <DialogContent>
        
          <Tab
    menu={{ secondary: true, pointing: true }}
    panes={panes}
    activeIndex={activeIndex}
    onTabChange={handleTabChange}
  />

        </DialogContent>
        <DialogActions>    <button className="btn btn-primary" onClick={handleSubmitKYC}>Add</button></DialogActions>
      </div>
  )
}

export default KycContainer