import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { teamsAction } from '../../actions/home'
import { getAllTeams } from '../../actions/settings'
import iconStyle from '../../assets/images/Dashboard 2-03.png'
import CommonCheckbox from '../../common/CommonCheckbox'
import { CommonNotify } from '../../common/CommonNotify'


export const WidgetTeamsTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>{' '}
    <div className="accordion-title-holder">
      <h2 className="accordion-title"> Teams </h2>{' '}
      <p className="accordion-description">
        Select teams to be assigned on this widget{' '}
      </p>{' '}
    </div>{' '}
  </div>
)

export const WidgetTeamsContent = ({ widget, loading }) => {
  const dispatch = useDispatch()
  const [allTeams, setAllTeams] = useState([])
  const [widgetTeams, setWidgetTeams] = useState([])
  const [teams, setTeams] = useState([])
  const [defaultTeams, setDefaultTeams] = useState([])
  const [selectedTeam, setSelectedTeam] = useState([])
  const [isStatusChange, setIsStatusChange] = useState(false)
  const teamsData = useSelector(state => state?.settings?.allTeams?.data)

  useEffect(() => {
  
    if (!teamsData) {
      dispatch(getAllTeams())
    } else {
      setAllTeams(teamsData)
    }
  }, [teamsData])

useEffect(() => {
 if(widget?.data?.[0].id){
  fetchWidgetTeams(widget?.data?.[0].id)
 }
}, [widget])

  useEffect(() => {
    if (!widgetTeams?.length) return
    if (!teams?.length) setTeam()
  }, [teams?.length, widgetTeams])


  useEffect(()=>{
if(widgetTeams?.length){
  setTeam()
}
  },[widgetTeams])
  useEffect(() => {
    if (!teams?.length) setTeam()
  }, [allTeams])

  const fetchAllTeams = async () => {
    const apiToken = await localStorage.getItem('access_token')
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/teams`

    await axios
      .get(url)
      .then(res => {
        loading(false)
        if (res.data.data) {
          setAllTeams(res.data.data)
          dispatch(teamsAction(res.data.data))
        }
      })
      .catch(error => {
        loading(false)
      })
  }

  const setTeam = () => {
    let temp_teams = []
    let selected_teams = []

    allTeams.map((item, index) => {
      let obj = {
        id: 0,
        name: '',
        active: 0
      }
      obj.id = item.id
      obj.name = item.name
      if (ExistwidgetTeam(item.id)) {
        obj.active = 1
        selected_teams.push(item.id)
      }
      temp_teams.push(obj)
    })
    // const activeTeam = temp_teams?.some(({ active }) => +active === 1)
    // if (!activeTeam && temp_teams?.length) {
    //   temp_teams[0].active = 1
    // }

    setTeams(temp_teams)
    setDefaultTeams(temp_teams)
    setSelectedTeam(selected_teams)
  }

  const ExistwidgetTeam = team => {

    var flag = false
    widgetTeams.map(function(el) {
      if (el.id === team) {
        flag = true
      }
    })
    return flag
  }

  const fetchWidgetTeams = async (id) => {
    const apiToken = await localStorage.getItem('access_token')
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${id}/teams`

    axios
      .get(url)
      .then(res => {
        loading(false)
        if (res.data.data) {

          setWidgetTeams(res.data.data)
        }
      })
      .catch(error => {
        loading(false)

        if (error.response) {
        } else if (error.request) {
        } else {
        }
      })
  }

  const saveTeam = async sel_team => {
    const apiToken = localStorage.getItem('access_token')

    if (!sel_team?.length) {
      CommonNotify('Please select at least one team')
      return
    }

    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/update-teams`

    let data = {
      widget_id: widget?.data?.[0].id,
      teams: sel_team
    }

    axios
      .post(url, data)
      .then(res => {
        loading(false)
        if (res.data.message == 'Successfully') {
          fetchAllTeams()
          setIsStatusChange(false)
          CommonNotify('Team Updated Successfully', 'success')
        } else {
          CommonNotify('Team Value Not Updated', 'error')
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Team Value Not Updated', 'error')
        if (error.response) {
          CommonNotify('Team Value Not Updated', 'error')
        } else if (error.request) {
        } else {
        }
      })
  }



  const handleCheck = (value, key, status) => {
    status = !status
    if (status || key) {
      setIsStatusChange(true)
    }
    let temp_team = [...teams]
    let sel_team = [...selectedTeam]
    temp_team[key].active = status
    if (status) {
      sel_team.push(temp_team[key].id)
    } else {
      var index = sel_team.indexOf(temp_team[key].id)
      sel_team.splice(index, 1)
    }
    saveTeam(sel_team)
    setSelectedTeam(sel_team)
    setTeams(temp_team)
  }

  const handleCancelButton = () => {
    setTeam(defaultTeams)
    setIsStatusChange(false)
  }

  return (
    <div className="style-widget-wrapper style-widget-team">
      <div
        className="teams-wrapper"
        style={{
          display: 'block !important'
        }}
      >
        {' '}
        {teams.map((item, key) => (
          <div
            style={{
              display: 'block',
              marginTop: '15px'
            }}
          >
            <CommonCheckbox
              text={item.name}
              checkboxStyle="modal-checkbox"
              name="checkbox"
              checked={item.active}
              key={key}
              index={key}
              onChange={e => handleCheck(item.name, key, item.active)}
            />{' '}
          </div>
        ))}{' '}
      </div>{' '}
    </div>
  )
}
