/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'semantic-ui-react'

import DataSaverOnIcon from '@mui/icons-material/DataSaverOn'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import CommonButtons from '../../common/CommonButtons'
import CommonCheckbox from '../../common/CommonCheckbox'
import CommonInput from '../../common/CommonInput'
import { TrashIcon } from 'evergreen-ui'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { Menu, MenuItem } from '@mui/material'
import Axios from 'axios'

const WidgetLeadQualificationContent = ({ reloadWidget }) => {
  const fieldOption = [
    { key: 1, value: 'text', text: 'Text' },
    { key: 2, value: 'multipleLine', text: 'Multi line text' },
    { key: 2, value: 'select', text: 'Select' },
    { key: 2, value: 'multiSelect', text: 'Multi Select' },
    { key: 4, value: 'checkbox', text: 'Checkbox' },
    { key: 5, value: 'number', text: 'Number' },
  ]
  const initialQue = { name: '', field_type: '', is_required: false }

  const [questions, setQuestions] = useState([

  ])
  const [modal, setModal] = useState(false)
  const [options, setOptions] = useState([])

  const [question, setQuestion] = useState({ ...initialQue })
  const [openMenu, setOpenMenu] = useState(null)
const [selected, setSelected] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [inputs,setInputs] =useState([])
  const [deletedId, setDeletedId] = useState('')
  const submitQuestion = async () => {
    const widget_id = localStorage?.getItem('widget_id')
    const payload = { ...question, options }
const formData=new FormData()
    formData?.append('basic_input_id', selected?.id)
Object?.entries(payload)?.forEach(([key,value])=>{
  if (key==='field_type'){
    // formData.append('field_type', selected?.text)

  } else  if (key === 'is_required'){
    formData.append('is_required', selected?.is_required ? 1 :0)

  } else if (key !== 'options') {

    formData.append(key, value)
  }
})
 payload?.options?.forEach((obj,index)=>{
     formData?.append(`options[${index+1}]`,obj?.text)
    })
    setModal(!modal)

 
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget_id}/custom-input`

    await Axios.post(url, formData)
      .then(res => {


        getInputs()
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }
  
  const getBasicInputs = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/basic-inputs`

    await Axios.get(url)
      .then(res => {
        const data = res?.data?.data
        setInputs(data?.map((obj) => ({
          text: obj?.name,
          id: obj?.id,
          has_options: obj?.has_options,
          value: obj?.type,
          options: []
        })))


      })
      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }


  const getInputs = async () => {
    const widget_id = localStorage?.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget_id}/custom-inputs`

    await Axios.get(url)
      .then(res => {
        const data = res?.data?.data
        setQuestions(data)


      })
      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }
  const deleteSelected = async () => {

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/custom-input/${deletedId}`

    await Axios.delete(url)
      .then(res => {
        getInputs()
setDeleteModal(false)
setDeletedId(null)

      })
      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }


  useEffect(() => {
    getInputs()
    getBasicInputs()

  }, [])

  return (
    <div className="style-widget-wrapper accordion-widget social-widget">
      <section className="card-manage-number">
        <div className='lead-qualification'>
          {questions?.map((obj, index) => (
            <div className='question-set' style={{ borderBottom: index === questions?.length - 1 ? '' : '1px solid' }}>
              <div className='d-flex w-100' style={{ justifyContent: 'space-between' }}>

                <p>
                  {' '}
               {`  (${obj?.id}) ${obj?.name}`}
                </p>
                <MoreVertIcon
                  aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={event => {
                    event.stopPropagation()
                    setDeletedId(obj?.id)
                    setOpenMenu(event.currentTarget)
                  }}
                />
              </div>
              <p style={{ color: 'grey' }}>
                {`${obj?.input?.name} ${obj?.options ? `(${obj?.options?.join(',')})`:'' } `}
              </p>
            </div>
          ))}
        </div>

        <p className='mt-3' onClick={() => setModal(true)}>
          <DataSaverOnIcon /> Add Question
        </p>

        <div></div>

      </section>
      <Modal
        style={{ width: '500px', overflow: 'visible' }}
        open={modal}
        onClose={() => setModal(!modal)}
      >
        <Modal.Header>{selected?.id ? 'Edit Question' :'Add New Question'}</Modal.Header>
        <Modal.Content>
          <div className="p-4">
            <div>
              <label>Question</label>
              <CommonInput
                inputStyle={'w-100'}
                onChange={e =>
                 {
                  
                  
                  setQuestion({ ...question, name: e.target.value })}
                }
                defaultValue={question?.name}
              />
            </div>
            <div className="d-flex mt-3">
              <CommonCheckbox
                checked={question?.is_required}
                onChange={(e, value, checked) => {
                  setQuestion({ ...question, is_required: !checked })
                }}
              />{' '}
              Required
            </div>
            <div className="mt-3">
              <label>Answer Type</label>
              <Dropdown
                name="from"
                placeholder="Select Time"
                selection
                options={inputs}
                onChange={(e, data) => {
                  const selectedType = inputs?.filter((obj) => obj?.value === data?.value)?.[0]
                  setSelected(selectedType)
                  setQuestion({ ...question, field_type: data?.value })
                }}
                value={question?.field_type}
              />
              <div>
                {selected?.has_options ? (
                    <>
                      {options?.map((obj, index) => (
                        <div
                          className="d-flex mt-3"
                          style={{ alignItems: 'center', gap: '10px' }}
                        >
                          <CommonInput
                            placeholder={`Add option ${index + 1}`}
                            question={obj?.text}
                            onChange={e => {
                              setOptions([
                                ...options?.map((obj, ind) =>
                                  index === ind
                                    ? { ...obj, text: e.target.value }
                                    : { ...obj }
                                )
                              ])
                            }}
                          />{' '}
                          <TrashIcon
                            onClick={() =>
                              setOptions([
                                ...options?.filter((obj, ind) => index !== ind)
                              ])
                            }
                          />
                        </div>
                      ))}
                      <p
                        className="cursor-pointer mt-3"
                        onClick={() => {
                          setOptions(prev => [...prev, { text: '' }])
                        }}
                      >
                        <ControlPointIcon /> Add option{' '}
                      </p>
                    </>
                  ):''}
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <CommonButtons onClick={() => setModal(!modal)}>Cancel</CommonButtons>

          <CommonButtons background="blue" onClick={() => submitQuestion()}>
            ADD
          </CommonButtons>
        </Modal.Actions>
      </Modal>
      <Menu
        // id="demo-positioned-menu"
        // aria-labelledby="demo-positioned-button"
        className="stage-popup"
        anchorEl={openMenu}
        open={!!openMenu}
        onClose={() => setOpenMenu(null)}
      >
        <MenuItem
onClick={()=>{
            setOpenMenu(null)
  setDeleteModal(true)}}
        >
          Delete
        </MenuItem>
      </Menu>
      <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => setDeleteModal(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this input ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={e => setDeleteModal(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={deleteSelected}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}
export default WidgetLeadQualificationContent
