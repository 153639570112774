import React from 'react'
import { useHistory } from 'react-router-dom'
import CommonButtons from '../../common/CommonButtons'


const ContentFooter = props => {
  const { onClick, disabled, prev, hideSkip =false} = props
  const history = useHistory()
  const skipStep = () => {
    history.push('/home')
  }
  return (
    <div
      className="quicksetup-footer footer-button mt-auto"
      style={{ justifyContent: 'space-between' }}
    >
      <CommonButtons
        content="Back"
        background="grey"
        btnClass="btn-modal-style"
        onClick={prev}
      />
      {/* <span> */}
      {!hideSkip && <CommonButtons
          content="Skip"
          background="grey"
          style={{ height: '50px' }}
          btnClass="btn-modal-style"
          onClick={props.next}
        />}
        <CommonButtons
          content="Next"
          background="blue"
          btnClass="btn-modal-style"
          onClick={onClick}
          disabled={props.disabled}
          loading={props.loading}
        />
      {/* </span> */}
    </div>
  )
}

export default ContentFooter
