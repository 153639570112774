import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

import Title from '../../common/Title'
import CommonButton from '../../common/CommonButtons'

import leadLogo from '../../assets/images/lead-logo.svg'

import iconCall from '../../assets/images/Dashboard 2-04.png'
import axios from 'axios'
import { CommonNotify } from '../../common/CommonNotify'
import ConversationContent from '../ConversationContent'
import { Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { getProfile } from '../../actions/settings'
import { getConversations } from '../../actions/conversation'
import Content from './components/content'

const Conversation = props => {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState(false)
  const [numbers, setNumbers] = useState([])
  const [widgetId, setWidgetId] = useState(0)
  const [widgetScript, setWidgetScript] = useState('')
  const [name, setName] = useState('')
  const [membersTeam, setMembersTeam] = useState([])
  const [members, setMembers] = useState([])
  const [inbox, setInbox] = useState(false)
  useEffect(() => {
    if (props.allConversations) {
      setLoading(false)
      if (props?.allConversations?.conversations?.length > 0) {
        setContent(true)
      } else {
        setContent(false)
      }
    } else if (widgetScript) {
      setLoading(true)
      props.getConversations()
    } else {
      fetchWidgetSript()
    }
  }, [props.allConversations, widgetScript])

  const fetchTeams = async () => {
    const data = []
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/teams?limit=20000&offset=0`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          setMembersTeam(res.data.data)
        }
      })
      .catch(err => {})
  }

  const fetchMembers = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          const dt1 = [...res.data.data.members]
          dt1.push(res.data.data.admin)
          setMembers(dt1)
        }
      })
      .catch(error => {
        CommonNotify('cant fetch Members')
      })
  }

  useEffect(() => {
    document.title = 'LimeCall - Conversations'
    // if (!props.profile) {
    //   props.getProfile()
    // }
    const widgetScript = localStorage.getItem('widgetScript')
    fetchTeams()
    fetchMembers()
    if (widgetScript) {
      if (props.allConversations) {
        if (props?.allConversations?.conversations?.length > 0) {
          setContent(true)
        } else {
          setContent(false)
        }
        setLoading(false)
      } else {
        setLoading(true)

        props.getConversations()
      }
      setWidgetScript(widgetScript)
    } else {
      setLoading(true)
      fetchWidgetSript()
    }
  }, [])

  useEffect(() => {
    getConversation(props.profile)
  }, [props.profile])

  const getConversation = async profile => {
    // fetch number from profile
    setWidgetId(profile?.data?.widget_id)
    setName(profile?.data?.first_name)
    setNumbers(profile?.data?.phone_numbers?.map(number => number.phone_number))
  }

  const fetchWidgetSript = async () => {
    const widget_id = localStorage.getItem('widget_id')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/install?widget_id=${widget_id}`

    axios
      .get(url)
      .then(res => {
        localStorage.setItem('widgetScript', res.data.data.script_id)
        props.getConversations()
        setWidgetId(res.data.data.id)
        setWidgetScript(res.data.data.script_id)
      })
      .catch(error => {})
  }

  const title = {
    type: 'image',
    titleOne: leadLogo,
    titleTwo: 'Conversations'
  }

  return (
    <>
      {loading ? (
        <Dimmer active={loading}>
          <Loader></Loader>
        </Dimmer>
      ) : // <Content members={members} membersTeam={membersTeam} />

      content || inbox ? (
        <Content members={members} membersTeam={membersTeam} />
      ) : (
        <div className="conversation-container">
          <Title data={title} />
          <div className="widget-banner-container">
            <div className="conversation-content-holder conversations-section">
              <div className="welcome-section">
                <div className="welcome-holder">
                  <div className="welcome-image-holder">
                    <img src={iconCall} alt="logo" />
                  </div>
                  <div className="welcome-text-holder">
                    {numbers?.length === 0 ? (
                      <h2 className="banner-title">
                        Add a Virtual Number to start messaging
                      </h2>
                    ) : (
                      <h2 className="banner-title">Good Morning {name}</h2>
                    )}
                    <p className="banner-description">
                      {numbers?.length === 0
                        ? 'You have no chats because you havent subscribed to a virtual phone number yet.'
                        : 'Time to ace those Conversations'}
                    </p>

                    {numbers?.length === 0 ? (
                      <>
                        <Link to="/numbers">
                          <CommonButton
                            btnClass="btn-start sky-blue"
                            content="Add Virtual Number"
                          />
                        </Link>

                        <CommonButton
                          onClick={() => setInbox(!inbox)}
                          btnClass="btn-start sky-blue"
                          content="Continue to inbox"
                        />
                      </>
                    ) : (
                      <CommonButton
                        onClick={() => setInbox(!inbox)}
                        btnClass="btn-start sky-blue"
                        content="Start New Conversation"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  profile: state.settings.profile,
  allConversations: state.conversation.allConversations,
  numbers: state.numbers.numbers
})

export default connect(mapStateToProps, { getProfile, getConversations })(
  Conversation
)
