import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Dialer from '../helpers/dialer/Dialer'
import { getDialer } from '../actions/dialer'
function SeparateDialer(props) {
  useEffect(() => {
    openDialer()
    sessionStorage.removeItem('isDialer')
  }, [])

  const openDialer = () => {
    props.getDialer(!props.dialerStatus)
  }
  
  return (
    <div>
      <Dialer always={true} getDialer={openDialer} />
    </div>
  )
}

export default connect(null, {
  getDialer
})(SeparateDialer)
