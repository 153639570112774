import React, {useState, useEffect} from 'react'
import widgetBubble from '../../assets/images/phone.svg'
import { connect } from 'react-redux'
import messangerIcon from '../../assets/images/messanger.png'
import callbackIcon from '../../assets/images/call-back.png'
import meetingIcon from '../../assets/images/meeting.png'
import DialerImage from '../../assets/images/flaticons/dialer.png'
import CommonButton from '../../common/CommonButtons'



const bubbleDefaultSetting = {
    color: '#0080FF',
    shadow: false,
    textOnline: 'Would you like us to call you now ?',
    textOffline: 'Hey there! We are offline now ?',
    position: '',
    labelColor: '#2B2B2C',
    shape: 'default'
  }

const ButtonPreview = ( {iconURL,widgetObject, widgetBubbleColor, bubbleSettData, bubbleSett,widget} ) => {
    const [bubbleSettingData, setBubbleSettingData] = useState({})
    const [bubbleApiSettingData, setBubbleApiSettingData] = useState({})
    const [apiLoaded, setApiLoaded] = useState(false)
    const [bubbleSetting, setBubbleSetting] = useState(bubbleDefaultSetting)

    useEffect(() => {
        fetchSetting()   
        setApiLoaded(true)
      }, [widgetObject])


      useEffect(() => {
        if(!bubbleSettData) return;
        setBubbleSettingData(bubbleSettData);          
      }, [bubbleSettData])

      useEffect(() => {
        if(!bubbleSett) return;
        setBubbleSetting(bubbleSett);          
      }, [bubbleSett])


    const fetchSetting = () => {
   
      let tmpData = widgetObject;     

      setBubbleSettingData(tmpData);             
      setBubbleApiSettingData(widgetObject)

    }
const url=iconURL || widget?.branding_icon_url

  return (
    <>
    <div id="button-preview">
         <div className="widget-bubble1 widget-bubble-wrapper" style={{padding: "0 !important" }}>              
              <div className="greetings_side_popup" style={{ padding: "0 !important"}}>   
            {bubbleSettingData?.bubble_template ?
              <div className='greeting-text' style={{  padding: "0 !important" }}>
                <p> {bubbleSettingData?.greeting_text}</p>
                <div>
                  {bubbleSettingData?.bubble_sendmessage_status ? <img width={35} className="mr-2" src={messangerIcon} alt="messanger" /> : ''}
                  {bubbleSettingData?.bubble_webcall_status ? <img width={35} className="mr-2" src={DialerImage} alt="messanger" /> : ''}
                  {bubbleSettingData?.bubble_callback_status ? <img width={35} className="mr-2" src={callbackIcon} alt="messanger" /> : ''}
                  {bubbleSettingData?.bubble_meeting_status ? <img width={35} className="mr-2" src={meetingIcon} alt="messanger" /> : ''}
                </div>
                <div className='mt-2'>
                  {bubbleSettingData?.bubble_popup_cta_status ? <CommonButton onClick={()=> null} background="blue">{bubbleSettingData?.bubble_popup_ctas || 'Message us 👋'}</CommonButton> : ''}
                </div>
          
              </div> : ''}           
          {bubbleSettingData.call_button_status ? 
              ( <div
                    className={`widget-bubble ${bubbleSettingData.bubble_position}`}
                  >
                    <div
                      className={`bubble-sec ${bubbleSettingData.shape}`}
                      style={{
                        ...(bubbleSettingData?.border_status && {border:'1px solid black'}),
                        opacity: bubbleSettingData?.popup_background_opacity,
                        height: bubbleSettingData?.button_size,
                        width:( bubbleSettingData?.shape === parseInt(3) || bubbleSettingData?.shape === parseInt(4)) ? 'max-content': bubbleSettingData?.button_size,
                        background: bubbleSettingData?.background_style === 'key-2' ? `linear-gradient(${bubbleSettingData.circle_color}, #000)` :bubbleSettingData.circle_color ,      
                        borderRadius: bubbleSettingData?.shape === parseInt(1) ? '100%': bubbleSettingData?.shape === parseInt(2) ? '0%':`${bubbleSettData?.border_radius}%`,                     
                        boxShadow:
                          `12px -12px ${bubbleSettingData?.shadow}px rgba(0,0,0,0.50)`,
                      }}
                    >
                  <div style={{
                    height: bubbleSettingData?.button_size,
                    width:  bubbleSettingData?.button_size,
                        }}>
                      <img src={`${process.env.REACT_APP_BASE_APP_URL}/${url}`} alt="" style={{width:"100%", objectFit:"contain", padding:'12px',height:"100%"}}/>
                        </div>
                  <p >{(bubbleSettingData?.shape === parseInt(3) || bubbleSettingData?.shape === parseInt(4)) ? bubbleSettingData?.widget_button_text ||'Request a callback' :''}</p>
                    </div>
              {bubbleSettingData?.bubble_popup_status ? <div
                      className="bubble-text"
                      style={{ backgroundColor: widgetBubbleColor }}
                    >
                      {bubbleSettingData.bubble_text}
                    </div>:null}
                  </div>)
                  :
                 bubbleSettingData.widget_button_text &&  <div style={ (bubbleSettingData.bubble_position === 'bottom_right' || bubbleSettingData.bubble_position === 'top_right') ? {backgroundColor: bubbleSettingData.circle_color} : { backgroundColor: bubbleSettingData.circle_color}} className={'template-2-button-container'  + ((bubbleSettingData.bubble_position === 'top_right' || bubbleSettingData.bubble_position === 'bottom_right') ? ' template-2-button-right-side-container': '')}>
                 {bubbleSettingData.widget_button_text} 
                  </div>
                  }       
               
              
    
              </div>

              
             
            </div>
            
       
    </div>
      </>
  )
}

// export default ButtonPreview
const mapStateToProps = state => ({
  iconURL: state.settings.icon_url,
  widget: state.home.widget?.data?.[0],
});

export default connect(
  mapStateToProps,
)(ButtonPreview)