import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Modal } from 'semantic-ui-react'

import GoogleInActive from '../../assets/images/google_analytic_inactive.jpg'
import IntegrationModal from '../../common/IntegrationModal'
import zapierActive from '../../assets/images/Asset 10.svg'
import googleModal1 from '../../assets/images/google_analytics.png'
import zapierColored from '../../assets/images/zapier-colored.svg'
import modalHubspot from '../../assets/images/hubspotModal.svg'
import modalZapier from '../../assets/images/Dashboard-89.png'
import hubspotInactive from '../../assets/images/hubstop-inactive.svg'
import pipedriveInActive from '../../assets/images/pipedriveInActive.svg'
import hubspotActive from '../../assets/images/hubspot-active.svg'
import slackActive from '../../assets/images/slack-icon.png'
import pipedrive from '../../assets/images/pipedrive.svg'
import slackInactive from '../../assets/images/slack-icon.png'
import slackModal from '../../assets/images/slack-icon.png'
import integromat from '../../assets/images/integromatNew.png'
import unbounce from '../../assets/images/unbounce.png'
import salesForce from '../../assets/images/sales-force.jpg'
import zendesk from '../../assets/images/zendesk.png'
import { CommonNotify } from '../../common/CommonNotify'
import Analytic from '../../containers/AnalyticSetting'
import Slack from '../../containers/SlackSetting'
//redux
import { connect } from 'react-redux'
import { getUserIntegrations } from '../../actions/settings'

const IntegrationCard = ({ selectedApp, onClickSelectedApp, userIntegrations, getUserIntegrations, LeadSource }) => {
  const [connectedApp, setConnectedApp] = useState(null)
  const [connectedAppData, setConnectedAppData] = useState(null)
  const [googleAnalytics, setGoogleAnalytics] = useState(false)
  const [open, setOpen] = useState(false)
  const [slackOpen, setSlackOpen] = useState(false)
  const [Id, setId] = useState()

  useEffect(() => {
    if (userIntegrations) {
      setConnectedAppData(userIntegrations.data)
         
      const data = { ...connectedApp }
      
      userIntegrations.data.map(obj => {            
        
        switch (obj.integration) {
          case 'slack':
            data.slack = true
            setConnectedApp({
              ...connectedApp,
              data
            })
            break
          case 'Google Analytics':
              data.analytics = true
              setGoogleAnalytics(true)
              setConnectedApp({
                ...connectedApp,
                data
              })
              break  
          case 'Google Calendar':
            data.googleCl = true
            setConnectedApp({
              ...connectedApp,
              data
            })
            break
          case 'googleManager':
            data.googleCl = true
            setConnectedApp({
              ...connectedApp,
              data
            })
            break
          case 'HubSpot':
            data.hubSpot = true
            setConnectedApp({
              ...connectedApp,
              data
            })
            break
          case 'pipedrive':
            data.pipedrive = true
            setConnectedApp({
              ...connectedApp,
              data
            })
            break
          default:
            break
        }
      })
    } else {
      fetchUserIntegrations()
    }
  }, [userIntegrations])

  const fetchUserIntegrations = () => {

    getUserIntegrations()
    
    /* 
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/user-integrations`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          setConnectedAppData(res.data.data)
         
          const data = { ...connectedApp }
         
          res.data.data.map(obj => {            
           
            switch (obj.integration) {
              case 'slack':
                data.slack = true
                setConnectedApp({
                  ...connectedApp,
                  data
                })
                break
              case 'Google Analytics':
                  data.analytics = true
                  setGoogleAnalytics(true)
                  setConnectedApp({
                    ...connectedApp,
                    data
                  })
                  break  
              case 'Google Calendar':
                data.googleCl = true
                setConnectedApp({
                  ...connectedApp,
                  data
                })
                break
              case 'googleManager':
                data.googleCl = true
                setConnectedApp({
                  ...connectedApp,
                  data
                })
                break
              case 'HubSpot':
                data.hubSpot = true
                setConnectedApp({
                  ...connectedApp,
                  data
                })
                break
              default:
                break
            }
          })
        }
      })
      .catch(er => {
        
      }) */
  }
  const connectPipeDrive=async(code)=>{
 
    const url=`${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/store-pipedrive-integration`
    const res = await axios.post(url,{code})
    if(res){
    }
      }
    
    useEffect(() => {
      const query = window?.location?.search
      if(query?.includes('code=')){
        connectPipeDrive(query?.replace('?code=',''))
      }
    }, [])
  
  const disconnectAnalytics = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/delete-google-analytics`

    axios
      .post(url, null)
      .then(res => {
        if (res) {
          setGoogleAnalytics(false)
          CommonNotify(res.data.message[0], 'success')
          fetchUserIntegrations()
        }
      })
      .catch(err => {
        CommonNotify(err.response.data.errors[0])
      })
  }
  const disconnectSlack = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/delete-slack-account`

    axios
      .post(url, null)
      .then(res => {
        if (res) {
          CommonNotify(res.data.message[0], 'success')
          fetchUserIntegrations()
        }
      })
      .catch(err => {       
        CommonNotify(err.response.data.errors[0])
      })
  }

  const disconnectHubSpot = () => {
    const hubSpot = connectedAppData.find(data => {
      return data.integration === 'HubSpot'
    })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/${hubSpot.id}/disconnect`

    axios
      .delete(url)
      .then(res => {
        if (res) {
          CommonNotify(res.data.message[0], 'success')
          fetchUserIntegrations()
        }
      })
      .catch(err => {        
        CommonNotify(err.response.data.errors[0])
      })
  }
  const integrateGoogleAnalytic = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/connect-google-analytics`
    const query = window?.location?.href
    const code = query?.split('code=')[1]?.split('&')[0]
    const data = {
      code: code
    }
    axios
      .post(url, data)
      .then(res => {
        const data = []
        setOpen(true)
        res.data.result.map(res => {
          return data.push({ key: res[0], value: res[0], text: res[1] })
        })
      })
      .catch(error => {
        
      })
  }
  useEffect(() => {
    const query = window?.location?.href
    const code = query?.split('code=')[1]?.split('&')[0]
    if (!!code) {
      integrateGoogleAnalytic()
    }
  }, [])

  const closeAnalyticsModal = async () => {
    setOpen(false)
    setGoogleAnalytics(true)
  }

  const closeSlackModal = async () => {
    setSlackOpen(false)
   
  }


  return (
    <div className="integration-card-holder">
      <div
        onClick={onClickSelectedApp}
        name="zapier"
        className="integration-card"
      >
        <div className="integration-img-holder" name="zapier">
          <img
            name="zapier"
            src={selectedApp === 'zapier' ? zapierActive : zapierActive}
            alt="logo"
          />
          <div className="text-wrapper">
           
            <p>{"Connect Zapier to automate your marketing & sales work flows. "}</p>
          </div>
        </div>
        <IntegrationModal
          modalImg={modalZapier}
          selectedData={selectedApp}
          title="Zapier"
          id="zapier"
          connected={connectedApp?.data?.zapier}
        />
      </div>



      <div
        onClick={onClickSelectedApp}
        className="integration-card"
        name="googleManager"
      >
        <div name="googleManager" className="integration-img-holder">
          <img
            name="googleManager"
            src={googleAnalytics ? googleModal1 : GoogleInActive}
            alt="logo"
          />
          <div className="text-wrapper">
           
            <p>Connect your Marketing Google Manager to sync .</p>
            {googleAnalytics === true ? (
        <Button className="btnmanageSetting" onClick={() => setOpen(true)}>
          {' '}
          Manage Settings
        </Button>
        ) : null }
          </div>
        </div>
        <IntegrationModal
          modalImg={GoogleInActive}
          selectedData={selectedApp}
          connected={googleAnalytics}
          title="GoogleManager"
          id="googleManager"
          onClick={disconnectAnalytics}
        />
      </div>


      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Analytic closeAnalyticsModal={closeAnalyticsModal} Id={Id} open={open} />
      </Modal>

      <Modal
        onClose={() => setSlackOpen(false)}
        onOpen={() => setSlackOpen(true)}
        open={slackOpen}
      >
        <Slack closeSlackModal={closeSlackModal} Id={Id} open={slackOpen} />
      </Modal>

      


      <div
        onClick={onClickSelectedApp}
        className="integration-card"
        name="hubSpot"
      >
        <div name="hubSpot" className="integration-img-holder">
          <img
            name="hubSpot"
            src={connectedApp?.data?.hubSpot ? hubspotActive : hubspotInactive}
            alt="logo"
          />
          <div className="text-wrapper">
           
            <p>
              Connect Hubspot to manage your sales calls right from Hubspot CRM.
            </p>
          </div>
        </div>
        <IntegrationModal
          modalImg={modalHubspot}
          selectedData={selectedApp}
          title="HubSpot"
          id="hubSpot"
          connected={connectedApp?.data?.hubSpot}
          onClick={disconnectHubSpot}
        />
      </div>
      <div
        onClick={onClickSelectedApp}
        className="integration-card"
        name="pipedrive"
      >
        <div name="pipedrive" className="integration-img-holder">
          <img
            name="pipedrive"
            src={connectedApp?.data?.pipedrive ? pipedrive : pipedriveInActive}
            alt="logo"
          />
          <div className="text-wrapper">
           
            <p>
              Connect Pipedrive to manage your sales calls right from Pipedrive CRM.
            </p>
          </div>
        </div>
        <IntegrationModal
          modalImg={pipedrive}
          selectedData={selectedApp}
          title="pipedrive"
          id="pipedrive"
          connected={connectedApp?.data?.pipedrive}
          onClick={disconnectHubSpot}
        />
      </div>

      <div
        onClick={onClickSelectedApp}
        className="integration-card"
        name="slack"
      >
        <div name="slack" className="integration-img-holder">
          <img
            name="slack"
            src={selectedApp === 'slack' ? slackActive : slackInactive}
            alt="logo"
            className="slack"
          />
          <div className="text-wrapper">
           
            <p>
              Connect with Slack to receive notifications in your slack
              channels.
            </p>
            {connectedApp?.data?.slack && (
              <Button className="btnmanageSetting" onClick={() => setSlackOpen(true)}>
              {' '}
                Manage Channel
              </Button>
            )}          
           
          </div>
        </div>
        <IntegrationModal
          modalImg={slackModal}
          selectedData={selectedApp}
          title="Wordpress"
          id="slack"
          connected={connectedApp?.data?.slack}
          onClick={disconnectSlack}
        />
      </div>



      <div
        onClick={onClickSelectedApp}
        className="integration-card"
        name="integromat"
      >
        <div name="integromat" className="integration-img-holder">
          <img
            className="integromat-image"
            name="integromat"
            src={selectedApp === 'slack' ? integromat : integromat}
            alt="logo"
          />
          <div className="text-wrapper">
            <p>
              Automate your business workflows by connecting with Integromat
            </p>
          </div>
        </div>
        <IntegrationModal
          modalImg={integromat}
          selectedData={selectedApp}
          title="integromat"
          id="integromat"
          connected={connectedApp?.data?.integromat}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  userIntegrations: state.settings.userIntegrations,
});

export default connect(
  mapStateToProps,
  { getUserIntegrations }
)(IntegrationCard)
