import React, { useEffect, useState } from 'react'

import { Dimmer, Dropdown, Loader, Modal, Tab, Table } from 'semantic-ui-react'
import icon from '../assets/images/settingIcon.png'
import CommonButtons from '../common/CommonButtons'
import CommonCheckbox from '../common/CommonCheckbox'
import CommonInput from '../common/CommonInput'
import CommonSelect from '../common/CommonSelectCountry'

import CommonSelect2 from '../common/CommonSelectRegion'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CountryList from '../helpers/CountryList'
import { CommonNotify } from '../common/CommonNotify'
import Axios from 'axios'
import PhoneInput from 'react-phone-input-2'
import FormTracking from './FormTracking'
import TrackingCode from './TrackingCode'
import Title from '../common/Title'
import SubRouters from './SubRouters'

const InboundRouter = () => {


    const [state, setState] = useState({})

    const [tableData, setTableData] = useState({})
const [modal, setModal] = useState(false)
    const [sideTab, setSideTab] = useState(1)
   

   

   
    
    const title = {
        titleTwo: 'Inboud Router'
    }
    return (
        <>

            <div className="meet-container">

                <Title
                    data={title}

                />
            </div>
                
            <div className="sidebarWrap">
                <div className="meetingSidebar" >
               
                    <div className="sideHeader">
                    </div>
                  
                    <label
                        className={sideTab == 1 ? 'sideTab' : ''}
                        onClick={() => {
                            // setMessageConfirmation(false)
                            setSideTab(1)


                        }}
                    >
                        Lead connect
                    </label>
                    
                    <label
                        className={sideTab == 2 ? 'sideTab' : ''}
                        onClick={() => {
                            // setMessageConfirmation(false)
                            setSideTab(2)


                        }}
                    >
                        Routers
                    </label>
                    


                </div>


            </div>
        

            <div
                className="fullwidth-container number-container"
style={{marginTop:'100px'}}
            >
              
                {sideTab === 1 ?
                    <FormTracking />
                    : ''}
                {sideTab === 2 ?
                    <SubRouters />
                    : ''}
            
                <Modal
                    style={{ width: '500px', overflow: 'visible' }}
                    open={modal}
                    onClose={() => setModal(!modal)}
                >
                    <Modal.Header>Form Details</Modal.Header>
                    <Modal.Content>
                        <div className="p-4">
                            <div className='form-field mt-3'>
                                <label>Form Name</label>
                                <CommonInput
                                    // value={currentField?.name}
                                    inputStyle={'w-100'}
                                    // onChange={e =>
                                    //     setCurrentField({ ...currentField, name: e.target.value })
                                    // }
                                />
                            </div>
                          
                            <div className='form-field mt-3'>
                                <label>Enter the webpage url where you form is present</label>
                                <CommonInput
                                    // value={currentField?.name}
                                    inputStyle={'w-100'}
                                    // onChange={e =>
                                    //     setCurrentField({ ...currentField, name: e.target.value })
                                    // }
                                />
                            </div>
                          
                            <div className='form-field mt-3'>
                                <label>Form Selector</label>
                                <CommonInput
                                placeholder='Enter Form ID'
                                    // value={currentField?.name}
                                    inputStyle={'w-100'}
                                    // onChange={e =>
                                    //     setCurrentField({ ...currentField, name: e.target.value })
                                    // }
                                />
                            </div>
                          
                        
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <CommonButtons onClick={() => setModal(!modal)}>Cancel</CommonButtons>

                        <CommonButtons background="blue">
                            Save
                        </CommonButtons>
                    </Modal.Actions>
                </Modal>
            </div>

        </>
    )
}

export default InboundRouter
