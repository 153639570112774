import React from 'react'
import { Form } from 'semantic-ui-react'

import WizardBoxMember from '../components/quicksetup/WizardBoxMember'
import GoLive from '../components/quicksetup/GoLiveTeamMember'
import ConfigureWorkingHour from '../components/quicksetup/customize/ConfigureWorkingHour'
import CallRouting from '../components/quicksetup/CallRouting'


class QuickSetupMember extends React.Component {
  constructor(props) {
    super(props)
    let step = parseInt(localStorage.getItem('quick_setup'))
    
    if(!step){
      step = 1;
    }

    this.state = {
      step: step,
      firstName: '',
      lastName: '',
      email: '',
      gender: '',
      age: '',
      country: '',
      interests: [],
      about: ''
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState(prevState => {
      return {
        ...prevState,
        step: 4
      }
    })
  }

  handleChange = (event, { name, value, checked, type }) => {
    if (type === 'checkbox') {
      let { interests } = this.state
      if (checked) {
        interests = [...interests, value]
      } else {
        interests = interests?.filter(item => item !== value)
      }
      this.setState(prevState => {
        return {
          ...prevState,
          interests: interests
        }
      })
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          [name]: value
        }
      })
    }
  }

  next = () => {
    localStorage.setItem('quick_setup', 3)   

    this.setState(prevState => {
      return {
        ...prevState,
        step: prevState.step + 1
      }
    })
  }

  componentDidMount = () => {
    document.title = 'Quick Setup';
  }

prev = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        step: prevState.step - 1
      }
    })
  }
  
  render() {
    const { step } = this.state
    const {
      firstName,
      lastName,
      email,
      gender,
      age,
      country,
      interests,
      about
    } = this.state
    const values = {
      firstName,
      lastName,
      email,
      gender,
      age,
      country,
      interests,
      about
    }

    return (
      <div className="quicksetup">
       
        <div className="quicksetupInner">
          <WizardBoxMember step={step} />
          <div className="quicksetup-content" style={ step == 2 || step == 4 ? {"width" : "100%"} : { "width" : "100%" }}>
            <Form onSubmit={this.handleSubmit}>
              <Step
                step={step}
                values={values}
                handleChange={this.handleChange}
                next={this.next}
                prev={this.prev}
              />
            </Form>
          </div>
        
        </div>
      </div>
    )
  }
}
export default QuickSetupMember
const Step = ({ step, values, handleChange, next, prev }) => {

 


  switch (step) {
   
    case 1:    
      return <ConfigureWorkingHour next={next} prev={prev} />
    case 2:
      return <GoLive next={next} prev={prev} />

    default:
      return null
  }
}
