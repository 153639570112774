import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux'
import { getWidgetCountries } from '../actions/settings'
import {
  Button,
  Dropdown,
  Header,
  Modal,
  Input,
  TextArea
} from 'semantic-ui-react'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ScheduleIcon from '@mui/icons-material/Schedule'

import { sendMessage } from '../config/leadAPI'
import CommonButtons from './CommonButtons'

import CountryData from '../helpers/data/countryCode.json'
import { CommonNotify } from './CommonNotify'
import Axios from 'axios'
import SavedRepliesModal from './SavedRepliesModal'
import moment from 'moment'

const SendMessageModal = ({
  open,
  isModalClose,
  dropDownData,
  leadData,
  getWidgetCountries,
  widgetCountries
}) => {
  const [allowedCountries, setAllowedCountries] = useState([])
  const [templateList, setTemplateList] = useState([])
  const [loading, setLoading] = useState(false)
  const [openReplayModal, setOpenReplayModal] = useState(false)
  const [openReplayScheduleModal, setOpenReplayScheduleModal] = useState(false)
  const [scheduleInfo, setScheduleInfo] = useState(null)

  const [sendMsgData, setSendMsgData] = useState({
    lead_id: leadData?.id,
    from: null,
    message: '',
    to: leadData?.phone_number
  })

  useEffect(() => {
    getLeadTemplate()
  }, [])

  useEffect(() => {
    if (sendMsgData?.close === true) {
      isModalClose()
      setSendMsgData({
        ...sendMsgData,
        close: false
      })
    }
  }, [sendMsgData])

  useEffect(() => {
    resetWidgetCountries(widgetCountries?.data?.allowed_countries)
  }, [widgetCountries])

  useEffect(() => {
    if (!open) return
    if (allowedCountries?.length) return

    getWidgetCountries()
  }, [open])

  const getLeadTemplate = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-responses?target=Lead`
    await Axios.get(url)
      .then(res => {
        setTemplateList(res?.data?.data || [])
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Somethign went to wrong!'
        )
      })
  }

  const resetWidgetCountries = countries => {
    const allCountry = CountryData
    const allowed = allCountry
      ?.filter(item => {
        if (countries?.includes(item.name)) {
          return item
        }
      })
      .map(item => item.code.toLowerCase())

    setAllowedCountries(allowed)
  }

  const handleSave = value => {
    setSendMsgData({
      ...sendMsgData,
      message: value?.description || ''
    })
  }

  const timeValue = value => {
    setScheduleInfo(value)
  }

  return (
    <Modal
      className="send-message-modal"
      onClose={isModalClose}
      closeIcon
      open={open}
      size="tiny"
    >
      {openReplayModal && (
        <SavedRepliesModal
          {...{
            handleSave,
            templateList,
            getLeadTemplate,
            timeValue,
            scheduleInfo
          }}
          setOpenReplayModal={() => setOpenReplayModal(false)}
        />
      )}
      {openReplayScheduleModal && (
        <SavedRepliesModal
          {...{
            handleSave,
            templateList,
            getLeadTemplate,
            timeValue,
            scheduleInfo
          }}
          schedule={true}
          setOpenReplayModal={() => setOpenReplayScheduleModal(false)}
        />
      )}
      <Header content="Send New Text Message" />
      <Modal.Content style={{ padding: '30px' }}>
        <div className="send-message__dropdown">
          <label>From *</label>
          <Dropdown
            name="from"
            placeholder="Select Number"
            selection
            options={dropDownData}
            onChange={(e, data) =>
              setSendMsgData({ ...sendMsgData, from: data.value })
            }
          />
        </div>
        <div className="send-message__input">
          <div className="d-flex align-items-center mb-2">
            <label className="mb-0">To</label>
         
          </div>

          <PhoneInput
            placeholder="To"
            className="text-color"
            onlyCountries={allowedCountries}
            value={leadData?.phone_number}
            onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
          />
        </div>
        <div className="send-message__textarea">
          <label>Message * </label>
          <TextArea
            name="message"
            value={sendMsgData.message}
            onChange={e =>
              setSendMsgData({ ...sendMsgData, message: e.target.value })
            }
            rows={4}
            className={`${
              sendMsgData?.message?.length > 160 ? 'red-text' : ''
            }`}
            title="Message *"
          />
          <div className="d-flex">
            <BookmarkIcon onClick={() => setOpenReplayModal(true)} />
            <ScheduleIcon onClick={() => setOpenReplayScheduleModal(true)} />
          </div>

          <br />
          <p>Character Count {sendMsgData?.message?.length || 0} </p>
          {scheduleInfo && (
            <p>
              Schdule Date & Time :{' '}
              {moment(scheduleInfo).format('MM-DD-YYYY, h:mm A')}{' '}
            </p>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions className="send-message__btn">
        <Button
          onClick={() => isModalClose(false)}
          className="send-message__btn-grey"
        >
          close
        </Button>
        <CommonButtons
          content="send later"
          btnClass="send-message__btn-blue"
          background="#007bff"
          style={{ color: 'white' }}
          onClick={e => {
            if (scheduleInfo) {
              sendMessage(sendMsgData, setSendMsgData, scheduleInfo)
            } else {
              setOpenReplayScheduleModal(true)
            }
          }}
        />
        <CommonButtons
          content="send"
          btnClass="send-message__btn-blue"
          background="#007bff"
          style={{ color: 'white' }}
          onClick={e => sendMessage(sendMsgData, setSendMsgData)}
        />
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = state => {
  return { widgetCountries: state.settings.widgetCountries }
}

export default connect(mapStateToProps, { getWidgetCountries })(
  SendMessageModal
)
