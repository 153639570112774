/* eslint-disable no-unused-expressions */
import axios from 'axios'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { Link } from 'react-router-dom'
import { Button, Tab, Table } from 'semantic-ui-react'
import { CommonNotify } from '../../common/CommonNotify'
import { SET_FLAG } from '../../actions/types'
import { connect } from 'react-redux'
import moment from 'moment'

class HomeTrial extends Component {
  state = {
    phoneLength: 0,
    subscriptionModal: false,
    leadCounter:{},
    LiveCalls:[]
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.personalLink !== prevProps.personalLink) {
      this.setState({
        personalLink: this.props.personalLink
      })
    }
  }

  fetchLeadStat() {
    const taskURL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/stats-for-home-page?direction=Inbound`
    axios
      .get(taskURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        this.setState({
          Leads: res?.data?.data || {}
        })
      })
      .catch(err => {

      })
  }
  getLiveCalls() {
    const taskURL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/live-calls`
    axios
      .get(taskURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        this.setState({
          LiveCalls: res?.data?.data || {}
        })
      })
      .catch(err => {

      })
  }

  fetchLeads() {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`
    const uFirstName = localStorage.getItem('first_name')
    const uLastName = localStorage.getItem('last_name')

    var params = {
      page: 1,
      sort: '-id'
    }
    params['filter[schedule_call_status][in]'] = 'scheduled,rescheduled'
    params['filter[agent][is]'] = `${uFirstName} ${uLastName}`
    // params['filter[type][in]'] = 'SCHEDULE_CALL'

    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: params
      })
      .then(res => {
        // localStorage?.setItem('pendingLeads',res.data.data.totalPendingLeads || 0)
        // this.props.loadPendingList( {pendingLeads: localStorage?.setItem('pendingLeads',res.data.data.totalPendingLeads || 0)})

        this.setState({ isLoading: false, exportData: [], countItem: 0 })
        this.setState({
          upcomingCallLeads: res?.data?.data?.totalUpcomingScheduleCalls
        })
      })
      .catch(err => {
        this.setState({ isLoading: false })

        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
    const uId = localStorage.getItem('id')

    var par = {
      page: 1,
      sort: '-id'
    }
    par['filter[owner_id][is]'] = uId
    par['filter[status][is not]'] = 3
    axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: par
      })
      .then(res => {
        this.setState({ isLoading: false, exportData: [], countItem: 0 })
        this.setState({ ownedLeads: res?.data?.data?.totalAgentLeads })
        // localStorage?.setItem('pendingLeads',res.data.data.totalPendingLeads || 0)
        // this.props.loadPendingList({pendingLeads: res.data.data.totalPendingLeads })
      })
      .catch(err => {
        this.setState({ isLoading: false })

        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
    const taskURL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/filter-activity`
    var param = {
      user: localStorage.getItem('id')
    }
    // param['filter[final_status][is not]'] = 'completed'
    // param['filter[agent][is]'] = `${uFirstName} ${uLastName}`
    // param['filter[direction][is]'] = 'Inbound'
    axios
      .get(taskURL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: param
      })
      .then(res => {
        this.setState({
          task: res?.data?.data?.length || 0
        })
      })
      .catch(err => {

      })
  }

  componentDidMount = async () => {
    const n = JSON.parse(localStorage.getItem('phone_numbers'))
    if (n !== null && n !== undefined) {
      this.setState({ phoneLength: n?.length })
    }
    this.setState({ personalLink: this.props.personalLink })
    this.fetchLeads()
    this.fetchLeadStat()
    this.fetchLeadCounter()
    this.getLiveCalls()
  }

  modalClick = async () => {
    this.setState({ subscriptionModal: true })
  }

  closeSubscriptionModal = async () => {
    this.setState({ subscriptionModal: false })
  }

  renderlist() {
    const n = JSON.parse(localStorage.getItem('phone_numbers'))
    const { phoneLength } = this.state
    let list = []

    if (localStorage.getItem('phone_numbers') !== null) {
      for (let i = 0; i < (n?.length > 2 ? 2 : n?.length); i++) {
        list.push(
          <div className="trial-box">
            <div className="banner-icon">
              <div
                className="banner-iconTwo"
                style={{ backgroundColor: '#0071eb' }}
              >
                <div className="inner-spacing">
                  <i
                    className="fa fa-phone fa-rotate-90"
                    aria-hidden="true"
                    style={{
                      color: 'white',
                      alignSelf: 'center',
                      width: 20,
                      height: 20
                    }}
                  ></i>
                </div>
              </div>
            </div>
            <div className="banner-description-wrapper">
              <p>
                {'Your ' +
                  (n[i].number_type && n[i].number_type !== null
                    ? n[i].number_type.charAt(0).toUpperCase() +
                      n[i].number_type?.slice(1)
                    : 'Local') +
                  ' Number'}
              </p>
              <p className="fontize">{n[i].phone_number}</p>
            </div>
          </div>
        )
      }
    }
    return list
  }

  renderDemo() {
    return (
      <div
        className="trial-box"
        style={{ border: '1px solid rgb(0, 113, 235)' }}
      >
        <div className="banner-icon"></div>
        <div className="banner-description-wrapper">
          <div className="booking-link">
            <p className="fontize">{this.state.personalLink} </p>
          </div>
          <Button.Group>
            <Button onClick={() => this.onCopyText(this.state.personalLink)}>
              Copy
            </Button>
            <Button.Or />
            <Button
              onClick={() => window.location.assign(this.state.personalLink)}
              style={{ backgroundColor: '#0071eb', color: 'white' }}
            >
              Preview
            </Button>
          </Button.Group>
        </div>
      </div>
    )
  }

  /*   getPersonalLink = async () => {

    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`
   
    
    axios
      .get(url)
      .then(res => {
        const urlData = res.data.data.personal_link.split('me/').pop()
        const personalData = res.data.data
        const roleData = res.data.data.role.name
        const full_name = res.data.data.first_name + ' ' + res.data.data.last_name          
        this.setState({personalLink: res.data.data.personal_link})      
      })
      .catch(error => {       
       
      })
  } */

  onCopyText = async jsCode => {
    try {
      await navigator.clipboard.writeText(jsCode)
      CommonNotify('Copied!', 'success')
    } catch (err) {
      CommonNotify('Failed to copy!')
    }
  }
  fetchLeadCounter = async (date1) => {
    const token = await localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/leads-counters`
    const settings = {
      url: URL,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    axios(settings)
      .then(res => {
        this.setState((prev) => ({...prev, leadCounter :res.data.data || {}}))
      })

      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }
  
   panes = [
    {
      menuItem: 'Lead Status',
      render: () => (
        // <MeetingTypeTeam setDetailPopUp={setDetailPopUp} status={'scheduled'} />
        <>    <Row className="justify-content-center">
          <Col md={11}>
            <Row>
              <Col md={4}>
                <div className="my-leads text-center">
                  <p className="mb-3 ml-0">Pending</p>
                  <h5>{this.state?.leadCounter?.all_leads?.pending || 0}</h5>
                </div>
              </Col>
              <Col md={4}>
                <div className="my-leads text-center">
                  <p className="mb-3 ml-0">Assigned</p>
                  <h5>{this.state?.leadCounter?.all_leads?.assigned || 0}</h5>
                </div>
              </Col>
              <Col md={4}>
                <div className="my-leads text-center">
                  <p className="mb-3 ml-0">My leads</p>
                  {/* <label style={{ fontSize: 14 }}></label> */}
                  <h5>{this.state?.leadCounter?.all_leads?.your_leads || 0}</h5>
                </div>
              </Col>
            </Row>
          </Col>
        </Row></>
      )
    },
    {
      menuItem: 'Personal Stats',
      render: () => (
        // <MeetingTypeTeam setDetailPopUp={setDetailPopUp} status={'completed'} />
        <>     
         <Row className="justify-content-center">
          <Col md={11}>
            <Row>
              <Col md={4}>
                <div className="my-leads text-center">
                  <p className="mb-3 ml-0">My leads</p>
                  <h5>{this.state?.ownedLeads || 0}</h5>
                </div>
              </Col>
              <Col md={4}>
                <div className="my-leads text-center">
                  <p className="mb-3 ml-0">Scheduled Calls</p>
                  <h5>{this.state?.upcomingCallLeads || 0}</h5>
                </div>
              </Col>
              <Col md={4}>
                <div className="my-leads text-center">
                  <p className="mb-3 ml-0">Tasks</p>
                  {/* <label style={{ fontSize: 14 }}></label> */}
                  <h5>{this.state?.task || 0}</h5>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        </>
      )
    },
    {
      menuItem: 'Recent calls',
        render: () => (
            // <MeetingTypeTeam setDetailPopUp={setDetailPopUp} status={'completed'} />
            <>   
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell >Agent</Table.HeaderCell>
                  <Table.HeaderCell>Source</Table.HeaderCell>
                  <Table.HeaderCell>Duration</Table.HeaderCell>
                  <Table.HeaderCell>Time</Table.HeaderCell>
                  <Table.HeaderCell>Caller</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                
                {this.state?.LiveCalls?.length> 0 ? this.state?.LiveCalls?.map((obj)=>
                
                
                <Table.Row>
                  <Table.Cell>{obj?.agent?.name}</Table.Cell>
                  <Table.Cell>{obj?.source}</Table.Cell>
                  <Table.Cell>{obj?.duration}</Table.Cell>
                  <Table.Cell>{moment(obj?.time)?.format('LT')}</Table.Cell>

                    <Table.Cell> <p>{obj?.caller_number}</p><p>{obj?.status}</p></Table.Cell>
                </Table.Row>):
                  <Table.Row >
                    <Table.Cell colSpan={4} style={{textAlign:"center"}}>
                      No Live calls
                    </Table.Cell>
                   </Table.Row>
                }
              
              </Table.Body>
            </Table>
            </>
        )
    },
  
  ]

  render() {
    return (
      <div className="cardbox-wrapper deatil_box">
        <div className="container-trial">
          <div className="banner-description-wrapper">
            {/* <p className="trial-headline"> {this.props.titleLine} <a className="link-color" onClick={this.modalClick}>{this.props.pleaseUpgrade}</a>{this.props.upgradeLine}</p> */}
            <div className="trial-back">
              <div className="row" style={{ width: '100%' }}>
                <div className="col-lg-6 lead-wrapper ">
                <Tab
                    menu={{ secondary: true, pointing: true }}

                  panes={this.panes}
                />
               </div>
        
              <div className="col-lg-6 lead-wrapper">
                <Link to="/manageleads">
                  <h4 className="mb-4 text-center fontsize-label">
                    Today’s Call Stats
                  </h4>
                </Link>
                <Row className="justify-content-center">
                  <Col md={11}>
                    <Row>
                      <Col md={4}>
                        <div className="my-leads text-center">
                          <p className="mb-3 ml-0">Missed</p>
                            <h5>{this.state.leadCounter?.calls?.missed_calls || 0}</h5>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="my-leads text-center">
                          <p className="mb-3 ml-0">Answered</p>
                            <h5>{this.state.leadCounter?.calls?.answered_calls || 0}</h5>

                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="my-leads text-center">
                          <p className="mb-3 ml-0">Voicemail</p>
                          {/* <label style={{ fontSize: 14 }}></label> */}
                            <h5>{this.state.leadCounter?.calls?.voicemail || 0}</h5>

                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapState = (state, ownProps) => ({})

const mapDispatchToProps = dispatch => ({
  loadPendingList: data =>
    dispatch({
      type: SET_FLAG,
      payload: data
    })
})

export default connect(mapState, mapDispatchToProps)(HomeTrial)
