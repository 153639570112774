import 'react-toastify/dist/ReactToastify.css';
import { toaster } from 'evergreen-ui';
import 'react-toastify/dist/ReactToastify.css'

export const CommonNotify = (msg, type = 'error', position = 'BOTTOM_RIGHT') => {


  if(type === "success")
  {
  toaster.success(msg)
  }else{
    toaster.warning(msg)
  }
}
