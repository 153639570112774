/** 
 *  two sub compoment WidgetFromPreview, WidgetButtonPreview
 *   create a scss file
 **/


import React from 'react'
import Form from './FormPreview'
import Button from './ButtonPreview'

const WidgetPreview = ( { activeIndex, widgetBubbleColor, bubbleSettData, bubbleSett,  handleTabActive, widget, allowedTabs, widgetStyledData, widgetSettData, widgetObject, loading, onClickTableCategory} ) => {
  return (      
    <div id="widget-preview-page">
        <div className='fixed-holder'>           
            <div className='row'>
          {(activeIndex !== 13) && <Form widgetStyledData={widgetStyledData} widgetSettData={widgetSettData}  widgetObject={widgetObject} onClickTableCategory={onClickTableCategory}   widgetBubbleColor={ widgetBubbleColor} loading={loading} handleTabActive={handleTabActive} widget={widget} allowedTabs={allowedTabs} />}
          {( activeIndex === 13) &&<Button widget={widget} bubbleSettData={bubbleSettData} bubbleSett={bubbleSett} widgetObject={widgetObject} widgetBubbleColor={widgetBubbleColor} />}
            </div>
        </div>       
    </div>
  )
}

export default WidgetPreview