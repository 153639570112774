import React from 'react'
import { Checkbox } from 'semantic-ui-react'

const CommonCheckbox = ({
  id,
  onClick,
  name,
  checked,
  text,
  index,
  value,
  onChange,
  italicText = undefined,
  checkboxStyle,
  defaultChecked,
  data,
  type,
  style
}) => {
  return (
    <div className="holder-checkbox" style={style}>
      <Checkbox
        onClick={onClick}
        onChange={(e) => onChange(e, index, checked, data, type)}
        id={id}
        name={value}
        defaultChecked={defaultChecked}
        checked={checked}
        value={value}
        className={checkboxStyle}
        label={
          <label>
            {' '}
            {text}{' '}
            {italicText === undefined ? (
              ''
            ) : (
              <span className="text-italic"> {`(${italicText})`} </span>
            )}{' '}
          </label>
        }
      />{' '}
    </div>
  )
}

export default CommonCheckbox
