import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/ModalHeader'
const MemberModal = ({ show, handleClose, data, modalData }) => {
  const [days, setDays] = useState([])

  useEffect(() => {
    setDays(data.working_hours)
  }, [data?.id])

  const getDepartment = data => {
    const dt = data?.map(dep => {
      return dep.name
    })
    return dt?.join(', ')
  }
  const timeSince = date => {
    var seconds = Math.floor((new Date() - date) / 1000)

    var interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' years'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' months'
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' days'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' hours'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' minutes'
    }
    return Math.floor(seconds) + ' seconds'
  }

  return (
    <>
      <Modal show={show} className="lead_page_modal">
        <ModalHeader>
          <Modal.Title>{`${data?.name}  ${
            data?.account_status == 1 ? '(Active)' : '(Suspended)'
          }`}</Modal.Title>
          <p className="close_modal" onClick={handleClose}>
            X
          </p>
        </ModalHeader>
        <Modal.Body>
          <p> Teams : {data?.teams?.map(obj => obj?.name)} </p>
          {/* <p> Virtual Number : {data?.teams?.map(obj => obj?.name)} </p> */}
          <p> Email : {data?.email} </p>
          <p> Call forwarding to : {data?.call_forwarding_to?.join(', ')} </p>
          <p> Departments : {data?.teams && getDepartment(data?.teams)} </p>
          <p> Time zone : {data?.time_zone} </p>
          <p>
            {' '}
            Last Login :{' '}
            {data?.last_login_time &&
              timeSince(new Date(data?.last_login_time + ' UTC')) + ' Ago'}{' '}
          </p>
          <center>
            <p>Working Hours</p>
          </center>

          <table style={{ width: '100%' }}>
            <tr>
              <th>Day</th>
              <th>Timezone</th>
              <th>Start</th>
              <th>End</th>
            </tr>

            {days &&
              days.map(day => {
                return (
                  <tr>
                    <td>{day.day_name}</td>
                    <td>{day.time_zone}</td>
                    <td>{day.working_from}</td>
                    <td>{day.working_to}</td>
                  </tr>
                )
              })}
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MemberModal
