import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import Dropzone from 'react-dropzone-uploader'

import NodeToggle from '../../common/NodeToggle'
import CommonGroupButton from '../../common/CommonGroupButton2'
import CustomizeGroupButton from '../../common/CustomizeGroupButton2'
import CommonColor from '../../common/CommonColor'
import CommonInput from '../../common/CommonInput'
import CommonButton from '../../common/CommonButtons'
import LinkIcon from '@mui/icons-material/Link'
import { TrashIcon } from 'evergreen-ui'

import iconStyle from '../../assets/images/Dashboard 2-07.png'
import widgetBubble from '../../assets/images/phone.svg'
import axios from 'axios'
import { CommonNotify } from '../../common/CommonNotify'
import CommonButtons from '../../common/CommonButtons'
import CommonTextArea from '../../common/CommonTextArea'
import CommonSelect from '../../common/CommonSelect'
import Brightness5Icon from '@mui/icons-material/Brightness5';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Button, Dropdown, Icon, TextArea } from 'semantic-ui-react'
import { Checkbox, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Slider } from '@mui/material'
import Toggle from '../../common/CommonToggle'
import getScript from '../../config/getScript'
import WidgetPreview from './WidgetPreview'
import ButtonPreview from './ButtonPreview'
import { SET_FLAG } from '../../actions/types'
import { useDispatch } from 'react-redux'
import SettingsIcon from '@mui/icons-material/Settings';
import { Input, InputGroup, InputGroupText } from 'reactstrap'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const widgetDefaultSetting = {
  template: 'Classic'
}

const apiToken = localStorage.getItem('access_token')

const bubbleDefaultSetting = {
  color: '#0080FF',
  shadow: false,
  textOnline: 'Would you like us to call you now ?',
  textOffline: 'Hey there! We are offline now ?',
  position: '',
  labelColor: '#2B2B2C',
  shape: 1
}

export const GreetingStyleTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>
    <div className="accordion-title-holder">
      <h2 className="accordion-title">Style your widget</h2>
      <p className="accordion-description">
        Customize your default greetings that your visitors will see on your widget
      </p>
    </div>
  </div>
)
export const CustomizeButtonTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>
    <div className="accordion-title-holder">
      <h2 className="accordion-title">Style your widget</h2>
      <p className="accordion-description">
        Customize your default greetings that your visitors will see on your widget
      </p>
    </div>
  </div>
)
const popupText = {
  callTitle: '',
  callDesc: 'bubble_popup_cta_status',
  callId: 'bubble_popup_cta_status',
  callref: 'bubble_popup_cta_status'
}
export const CustomizeButtonContent = ({
  widget,
  setBubbleSett,
  setBubbleSettData,
  showShadowWidgetToggle,
  widgetShadow,
  widgetShape,
  loading,
  onClick,
  widgetObject,
  reloadWidget,
  dataReloaded,
  widgetBubbleColor
}) => {


  const [widgetAllSetting, setWidgetALlSetting] = useState(null)
  const [widgetSetting, setWidgetSetting] = useState(widgetDefaultSetting)
  const [bubbleSetting, setBubbleSetting] = useState(bubbleDefaultSetting)
  const [bubbleSettingData, setBubbleSettingData] = useState({})
  const [bubbleApiSettingData, setBubbleApiSettingData] = useState({})
  const [apiLoaded, setApiLoaded] = useState(false)
  const [isInputChange, setIsInputChange] = useState(false)
  const [logo, setLogo] = useState(null)
  const [getLogoApi, setGetLogoApi] = useState(null)
  const [file, setFile] = useState()
  const [allIcons, setAllIcons] = useState([])
  const [icons, setIcons] = useState([])
  const [icon, setIcon] = useState(widget?.data?.[0]?.branding_icon_url)
  const [iconOptions, setIconOptions] = useState('')
  const dispatch = useDispatch()
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const fetchSetting = () => {
    let tmpData = widgetObject;
    setBubbleSettingData({ ...tmpData, ['mobile_and_desktop_targeting']: tmpData?.behaviour?.['mobile_and_desktop_targeting']});
    setBubbleApiSettingData(widgetObject)
  }

  const getCompany = async () => {
    const apiToken = await localStorage.getItem('access_token')
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          setLogo(res.data.data.company.company_logo)
          setGetLogoApi(res.data.data.company.company_logo)
        }
      })
      .catch(er => { })
  }
  useEffect(() => {
    getIcon()
    fetchSetting()
    setApiLoaded(true)
    // getIcons()
  }, [widgetObject])



  const updateBubbleSettingGroup = (val, key, name) => {
    updateBubbleSetting(key, name)
  }
  const updateWhiteLabel = (val, value) => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.data?.[0]?.id}/branding-status`
    axios
      .put(url, { [val]: value })
      .then(res => {
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          // CommonNotify('Updated Widget Setting', 'success')
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })

  }
  const updIcon = (val, type) => {

    if (val === 'Upload your own') {
      setIcon(val)
    } else {
      loading(true)

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/branding-icon`
      axios
        .post(url, {
          widget_id: widget?.data?.[0]?.id,

          type: type, branding_icon_url: val
        })
        .then(res => {
          setIcon(val)
          dispatch({
            type: SET_FLAG,
            payload: { ['icon_url']: val }
          })
          getScript()
          loading(false)
          if (res.data.message[0] == 'Successfully') {
            // CommonNotify('Updated Widget Setting', 'success')
          }
        })
        .catch(error => {
          loading(false)
          CommonNotify('Cant Updated Widget Setting', 'error')
        })
    }

  }
  const updateIcon = async(val,type) => {
    if(val==='Upload your own'){
      setIcon(val)
    }else {
      loading(true)
      let formData = new FormData();
      formData.append("branding_icon_url", val);
      formData.append("widget_id", widget?.data?.[0]?.id);
      formData.append("type", type);

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/branding-icon`
      await axios
        .post(url, 
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data', // Ensure you set this content type
            },
          }
         )
        .then(res => {
          setIcon(val)
          reloadWidget()
          dispatch({
            type: SET_FLAG,
            payload: { ['icon_url']: val }
          })
          getScript()
          loading(false)
          if (res.data.message[0] == 'Successfully') {
            // CommonNotify('Updated Widget Setting', 'success')
          }
        })
        .catch(error => {
          loading(false)
          CommonNotify('Cant Updated Widget Setting', error,'error')
        })
    }

  }
  const getIcon = (val, value) => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/default-branding-icons`
    axios
      .get(url, {
        widget_id: widget?.data?.[0]?.id,

        type: 'assign', [val]: value
      })
      .then(res => {
        setIcons(res?.data?.data)
        loading(false)
        if (res.data.message[0] == 'Successfully') {
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })

  }

  const saveBubbleSetting = () => {
    let data = { ...bubbleSettingData }


    if (data.bubble_position === 'btnBottomRight') {
      data.bubble_position = 'bottom_right'
    } else if (data.bubble_position === 'btnBottomLeft') {
      data.bubble_position = 'bottom_left'
    } else if (data.bubble_position === 'btnTopLeft') {
      data.bubble_position = 'top_left'
    } else if (data.bubble_position === 'btnTopRight') {
      data.bubble_position = 'top_right'
    }

    if (data.shape === 'btnRight') {
      data.shape = 'square'
    } else if (data.shape === 'btnLeft') {
      data.shape = 'default'
    }

    postSetting(data)
  }

  const postSetting = data => {
    loading(true)
    delete data?.bubble_popup_logo
    const updatedData = { ...data, ultimate_default_screen: data?.ultimate_default_screen?.toString() }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/set-customizations`
    axios
      .post(url, updatedData)
      .then(res => {
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          reloadWidget()
          setIsInputChange(false)
          if (logo) {
            onUploadLogo(logo)
          }
          reloadWidget()
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }
  
  const updateGreetingLogo = (key,val)=>{
      let formData = new FormData();
      formData.append(key, val ? val : null);

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.data?.[0]?.id}/bubble-popup-logo`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure you set this content type
        },
      })
      .then(res => {
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          reloadWidget()
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }
  const removeLogo = (key,val)=>{
      let formData = new FormData();
      formData.append('column', key );

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.data?.[0]?.id}/remove-icon`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure you set this content type
        },
      })
      .then(res => {
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          reloadWidget()
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }
  
  const getIcons = data => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/default-branding-icons`
    axios
      .get(url, data)
      .then(res => {
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          setAllIcons(res?.data?.data)
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }

  const setWidget = data => {
    let widgetSettings = { ...widgetSetting }
    widgetSettings.template = 'Modern'
    if (data.template_type == 2) {
      widgetSettings.template = 'Smart'
    } else if (data.template_type == 1) {
      widgetSettings.template = 'Classic'
    }

    widgetSettings.classicType =
      data.classic_template_design_type == 1 ? false : true
    widgetSettings.font = data.font

    widgetSettings.timerColor = data.popup_timer_color
    widgetSettings.callColor = data.submit_request_call_color

    widgetSettings.socialProof = data.social_proof
    widgetSettings.legalNote = data.terms_and_conditions
    widgetSettings.legalType = data.terms_type
    widgetSettings.legalStatus = data.terms_and_conditions_status
    widgetSettings.privateStatus = data.privacy_note_status
    widgetSettings.privatePolicy = data.privacy_note

    if (widgetSettings.legalType == 0) {
      widgetSettings.legalType = 'Text'
    } else if (widgetSettings.legalType == 1) {
      widgetSettings.legalType = 'URL'
    } else {
      widgetSettings.legalType = 'HTML'
    }

    widgetSettings.webCall = data.digital_call
    widgetSettings.callLeter = data.call_me_later
    widgetSettings.callBack = data.call_now
    widgetSettings.leaveMessage = data.leave_message

    widgetSettings.webCallHeader = data.digital_call_tab_text
    widgetSettings.webCallButton = data.digital_call_button_text
    widgetSettings.webCallName = data.full_name_in_digital_calls_tab
    widgetSettings.webCallEmail = data.email_in_digital_calls_tab
    widgetSettings.webCallTeam = data.team_in_digital_calls_tab

    widgetSettings.callLeterHeader = data.schedule_call_tab_text
    widgetSettings.callLeterButton = data.offline_schedule_call_message
    widgetSettings.callLeterName = data.full_name_in_call_later_tab

    widgetSettings.callLeterEmail = data.email_in_call_later_tab
    widgetSettings.callLeterTeam = data.team_in_call_later_tab

    widgetSettings.callBackHeader = data.widget_text
    widgetSettings.callBackButton = data.submit_request_call_text
    widgetSettings.callBackName = data.full_name_in_call_now_tab
    widgetSettings.callBackEmail = data.email_in_call_now_tab
    widgetSettings.callBackTeam = data.team_in_call_now_tab

    widgetSettings.leaveMessageHeader = data.leave_message_tab_text
    widgetSettings.leaveMessageName = data.full_name_in_leave_message_tab
    widgetSettings.leaveMessageEmail = data.email_in_leave_message_tab
    widgetSettings.leaveMessageTeam = data.team_in_leave_message

    setWidgetSetting(widgetSettings)
  }

  const setBubble = data => {
    let bubbleSetting1 = { ...bubbleSetting }



    bubbleSetting1.shadow = data.bubble_template == 2 ? true : false
    bubbleSetting1.color = data.circle_color
    bubbleSetting1.textOffline = data.bubble_offline_text
    bubbleSetting1.textOnline = data.bubble_text + " (Online)"

    if (data.bubble_position == 'bottom_right') {

    } else if (data.bubble_position == 'bottom_left') {

    } else if (data.bubble_position == 'top_right') {

    } else if (data.bubble_position == 'top_left') {

    }

    if (data.shape === 'square' || data.shape === 'btnRight') {
      data.shape = 'square'
    } else if (data.shape === 'default' || data.shape === 'btnLeft') {
      data.shape = 'default'
    }

    bubbleSetting1.position = data.bubble_position
    bubbleSetting1.shape = data.shape
    setBubbleSetting(bubbleSetting1)
  }

  const updateBubbleSetting = (index, val) => {


    let bSetting = {
      ...bubbleSetting
    }
    let bSettingData = {
      ...bubbleSettingData
    }
    bSettingData[index] = val
    bSetting[index] = val
    setBubbleSettingData(bSettingData)
    setBubbleSettData(bSettingData)
    setBubbleSetting(bSetting)
    setBubbleSetting(bSetting)

    const check = _.isEqual(bSettingData, bubbleSettingData)
    if (check === false) {
      setIsInputChange(true)
    }

  }


  // const onUploadLogo = file => {
  //   if (getLogoApi === logo) {
  //     return
  //   }
  //   loading(true)

  //   var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/upload-widget-icon`
  //   const form = new FormData()
  //   form.append('widget_image', file)
  //   form.append('widget_id', widget.id)
  //   axios
  //     .post(url, form)
  //     .then(res => {
  //       reloadWidget()
  //       CommonNotify('Upload successfully', 'success')
  //       loading(false)
  //     })
  //     .catch(err => {
  //       const errors = { ...err }
  //       if (errors.response.data.errors) {
  //         CommonNotify(errors.response.data.errors[0])
  //         loading(false)
  //       } else {
  //         CommonNotify('Some thing went wrong')
  //         loading(false)
  //       }
  //     })
  // }
  const onUploadLogo = file => {
    // if (getLogoApi === logo) {
    //   return
    // }
    loading(true)

    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/upload-widget-icon`
    const form = new FormData()
    form.append('widget_image', file)
    form.append('widget_id', widget?.data?.[0]?.id)
    axios
      .post(url, form)
      .then(res => {
        reloadWidget()
        CommonNotify('Upload successfully', 'success')
        loading(false)
      })
      .catch(err => {
        const errors = { ...err }
        if (errors.response.data.errors) {
          CommonNotify(errors.response.data.errors[0])
          loading(false)
        } else {
          CommonNotify('Some thing went wrong')
          loading(false)
        }
      })
  }

  const onUpload = ({ meta, file }, status) => {
    setIsInputChange(true)
    setLogo(file)
  }
  const onCancel = () => {
    setBubbleSettingData(bubbleApiSettingData)
    setIsInputChange(false)
  }

  const popup_font = [
    'Roboto',
    'Source Sans',
    "Rubik",
    'Source Sans Pro',
    'Playfair Display',
    'Open Sans'
  ]
  const checkDisplay = (obj, key) => obj?.['mobile_and_desktop_targeting'] === key || obj?.['mobile_and_desktop_targeting']==='both'
  return (
    <>
      {apiLoaded && (
        <>
          <div className="style-widget-wrapper accordion-widget greetings_wrapper">
            <section className="card-manage-number style-content">

            <div className="button-shape">
              <CommonGroupButton
                title="Call button"
                identity="call_button_status"
                active={bubbleSettingData?.call_button_status ? "btnLeft" : "btnRight"}
                leftBtn="Enable"
                rightBtn="Disable"
                handleGroupBtnData={() => updateBubbleSetting('call_button_status', bubbleSettingData?.call_button_status ? 0 : 1)}
              />
            </div>
              
              {bubbleSettingData?.call_button_status  ? <>
              
                <div className="template-Select mb-5">
                  <p className='widget-sub-heading'>Select Button Icon</p>
                  <div className="popup-font-select" style={{ width: '50%' }}>
                    <CommonSelect
                      name="popupFont"
                      className="widget-button-icon"
                      placeholder="Select Icon"
                      options={[...icons?.map((obj) => <img style={{ maxHeight: '4rem', width: '50%' }} src={`${process.env.REACT_APP_BASE_APP_URL}/${obj}`} alt={obj} />), 'Upload your own']}
                      value={icon === 'Upload your own' ? icon : <img src={`${process.env.REACT_APP_BASE_APP_URL}/${icon}`} style={{ maxHeight: '4rem', width: '50%' }} alt={icon} />}
                      onChange={(event, data) => {
                        updIcon(
                          data?.value?.props?.alt || data?.value, 'assign'
                        )
                      }
                      }
                    />
                  </div>
                </div>
                {(iconOptions === "Upload New" || icon === "Upload your own") && <div className="template-Select">
                  <p className='widget-sub-heading'>Upload Icon</p>
                  <CommonInput
                    id="file"
                    style={{}}
                    name="greeting"
                    inputProps={{ accept: 'audio/*;capture=microphone' }}
                    type="file"
                    onChange={(e) => {
                      updateIcon(e?.target?.files?.['0'],'upload')
                    }}
                  />
                </div>}

            <CommonColor
              widgetColor={bubbleSettingData.circle_color}
              widgetName="Button Colour"
              onChange={color => updateBubbleSetting('circle_color', color)}
            />
          
            {/* <div className="template-Select">
              <p className='widget-sub-heading'>Popup cta button text</p>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '45%' }}>
                <CommonInput
                  onChange={(e) => updateBubbleSetting('bubble_popup_button_text', e.target.value)}
                  value={bubbleSettingData.bubble_popup_button_text || ""}
                  name="bubble_popup_button_text"
                  type="text"
                />

                <Toggle
                  handleToggleData={(e) => updateBubbleSetting('bubble_popup_cta_status', bubbleSettingData.bubble_popup_cta_status ? 0 : 1)}

                  dataStateToggle={popupText}
                  activeDefault={bubbleSettingData.bubble_popup_cta_status}
                  dataToggleActive={bubbleSettingData.bubble_popup_cta_status}
                />
              </div>
            </div> */}
            {/* {(bubbleSettingData.shape) ?
              <div className="button-shape">
                <CommonGroupButton
                  title="Button Shape"
                  identity="shape"
                  active={bubbleSettingData.shape}
                  leftBtn="Default(Round)"
                  rightBtn="Square"
                  handleGroupBtnData={updateBubbleSettingGroup}
                />
              </div>
              : null}
          */}
            {/* <div className="template-Select">
              <p className='widget-sub-heading'>Button template</p>
              <CommonSelect
              style={{width:'50%'}}
                name="popupFont"
                className="popup-font-select"
                placeholder="Select Template"
                options={['Template 1', 'Template 2']}
                value={
                  bubbleSettingData.bubble_template === parseInt(1)
                    ? 'Template 1'
                    : 'Template 2'
                }
                onChange={(event, data) =>
                  updateBubbleSetting(
                    'bubble_template',
                    data.value === 'Template 1' ? 1 : 2
                  )
                }
              />
            </div> */}
          
          
            <div className='flex-items custom-selection'>

              <p className='widget-sub-heading mb-0 pr-5'>Call Button shape</p>
              <div className='chip'>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={parseInt(bubbleSettingData?.shape)}
                  name="radio-buttons-group"
                  sx={{ flexDirection: 'row' }}
                      onChange={(event, data) =>
                     
                       {
                         updateBubbleSetting(
                          'shape',
                           parseInt(data) === 1 ? 1 : parseInt(data) === 2 ? 2 : parseInt(data) === 3? 3:4
                        )}
                      }
                >
                  <FormControlLabel value={1} className='mb-0' control={<Radio />} label="Round" />
                  <FormControlLabel value={2} className='mb-0' control={<Radio />} label="Square" />
                      {/* <FormControlLabel value="modern" className='mb-0' control={<Radio />} label="Modern" /> */}
                  <FormControlLabel value={3} className='mb-0' control={<Radio />} label="Rectangle" />
                  <FormControlLabel value={4} className='mb-0' control={<Radio />} label="Standard" />

                </RadioGroup>
              </div>
            </div>
            {/* if rectagle AND SIDE is selected show below field (button text) */}
                {( parseInt(bubbleSettingData?.shape) === 3 || parseInt(bubbleSettingData?.shape) === 4) ?  <div className="custom-selection">
              <p className='widget-sub-heading'>Button Text</p>
              <div className='chip'>
                      <CommonInput
                        onChange={(e) => updateBubbleSetting('widget_button_text', e.target.value)}
                        value={bubbleSettingData.widget_button_text || "Request a callback"}
                        name="widget_button_text"
                        type="text"
                      />
              </div>
            </div>: null}
            <div className="button-shape">
                  <CommonGroupButton
                    title="Animation"
                    identity="call_button_status"
                    active={bubbleSettingData?.call_button_animation ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('call_button_animation', bubbleSettingData?.call_button_animation ? 0 : 1)}
                  />
            </div>
            <div className="button-shape">
                  <CommonGroupButton
                    title="Border"
                    identity="border_status"
                    active={bubbleSettingData?.border_status ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('border_status', bubbleSettingData?.border_status ? 0 : 1)}
                  />
            </div>
            <div className="button-shape">
                  <CommonGroupButton
                    title="Notification"
                    identity="widget_notification"
                    active={bubbleSettingData?.widget_notification ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('widget_notification', bubbleSettingData?.widget_notification ? 0 : 1)}
                  />
           

            </div>


            <div className='flex-items custom-selection'>
              <p className='widget-sub-heading mb-0 pr-5'>Background style</p>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={bubbleSettingData?.background_style === 'key-2' ? 'gradient' :'solid'}
                name="radio-buttons-group"
                sx={{ flexDirection: 'row' }}
                    onChange={(event, data) =>

                      updateBubbleSetting(
                        'background_style',
                        data === 'solid' ? 'key-1' :  'key-2'
                      )
                    }
              >
                <FormControlLabel value="solid"  control={<Radio />} label="Solid" />
                <FormControlLabel value="gradient" control={<Radio />} label="Gradient" />
              </RadioGroup>
            </div>


            <div className="custom-selection">
              <p className='widget-sub-heading'>Button size
              </p>
              <div className="chip" style={{ width: '15%' }} >

                    <Slider onChange={(event, data) =>

                      updateBubbleSetting(
                        'button_size',
                       event?.target?.value
                      )
                    } defaultValue={50} aria-label="Default" value={bubbleSettingData?.button_size} valueLabelDisplay="auto" />
              </div>

            </div>
            <div className="custom-selection">
              <p className='widget-sub-heading'>Border radius
              </p>

              <div className="chip" style={{ width: '15%' }} >

                    <Slider onChange={(event, data) =>

                      updateBubbleSetting(
                        'border_radius',
                        event?.target?.value
                      )
                    } defaultValue={50} aria-label="Default" value={bubbleSettingData?.border_radius} valueLabelDisplay="auto" />
              </div>


            </div>
            <div className="custom-selection">
              <p className='widget-sub-heading'>Shadow
              </p>

              <div className="chip" style={{ width: '15%' }} >

                    <Slider onChange={(event, data) =>

                      updateBubbleSetting(
                        'shadow',
                        event?.target?.value
                      )
                    } defaultValue={50} aria-label="Default" value={bubbleSettingData?.shadow} valueLabelDisplay="auto" />
              </div>


            </div>
            <div className="custom-selection">
              <p className='widget-sub-heading'>Opacity
              </p>

              <div className="chip" style={{ width: '15%' }} >

                    <Slider onChange={(event, data) =>

                      updateBubbleSetting(
                        'popup_background_opacity',
                        event?.target?.value
                      )
                    } 
                    
                    defaultValue={bubbleSettingData?.popup_background_opacity || 0} aria-label="Default" value={bubbleSettingData?.popup_background_opacity} valueLabelDisplay="auto" />
              </div>


            </div>


                <div className="customize-grp-btn-wrap">
                  <CustomizeGroupButton
                    title="Position On Page"
                    identity="bubble_position"
                    active={bubbleSettingData.bubble_position}
                    top_left="Top Left"
                    top_right="Top Right"
                    bottom_left="Bottom Left"
                    bottom_right="Bottom Right"
                    handleGroupBtnData={updateBubbleSettingGroup}
                  />
                </div>
            <div className="custom-selection">
              <p className='widget-sub-heading' >Display
              </p>
              <div className='chip'>
                    <Checkbox value='desktop' checked={bubbleSettingData?.['mobile_and_desktop_targeting'] === 'desktop' || bubbleSettingData?.['mobile_and_desktop_targeting'] === 'both'}  onChange={(event, data) =>

                    
                    updateBubbleSetting(
                        'mobile_and_desktop_targeting',
                      data ? bubbleSettingData?.['mobile_and_desktop_targeting'] === 'mobile' ? 'both' : 'desktop' : bubbleSettingData?.['mobile_and_desktop_targeting'] === 'both' ? 'mobile' :'' )
                    
                    } />
                <p>Desktop</p>

              </div>
              <div className='chip'>
                    <Checkbox value='mobile' checked={bubbleSettingData?.['mobile_and_desktop_targeting'] === 'mobile' || bubbleSettingData?.['mobile_and_desktop_targeting'] === 'both'} onChange={(event, data) =>

                      updateBubbleSetting(
                        'mobile_and_desktop_targeting',
                        data ? bubbleSettingData?.['mobile_and_desktop_targeting'] === 'desktop' ? 'both' : 'mobile' : bubbleSettingData?.['mobile_and_desktop_targeting'] === 'both' ? 'desktop' : '')

                      } />
                <p>Mobile</p>

              </div>
            </div>


       
                {/* <div className="button-shape">
                  <CommonGroupButton
                    title="Mirror"
                    identity="call_button_status"
                    active={bubbleSettingData?.call_button_status ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('call_button_status', bubbleSettingData?.call_button_status ? 0 : 1)}
                  />


                </div> */}
                
        
          <div className="custom-selection">
            <p className='widget-sub-heading'>Google Analytics
            </p>
            <div className='chip'>
                  
                    <Checkbox checked={!!bubbleSettingData?.callbutton_googleanalytics} onChange={() => updateBubbleSetting('callbutton_googleanalytics', bubbleSettingData?.callbutton_googleanalytics ? 0 : 1)} />
              <p>Track clicks in Google Analytics</p>
            </div>
          </div>
              </>: null
              }
            </section>
            <section className="card-manage-number style-content">
              <div className="button-shape">
                <CommonGroupButton
                  title="Bubble text"
                  identity="call_button_status"
                  active={bubbleSettingData?.bubble_template ? "btnLeft" : "btnRight"}
                  leftBtn="Enable"
                  rightBtn="Disable"
                  handleGroupBtnData={() => updateBubbleSetting('bubble_template', bubbleSettingData?.bubble_template ? 0 : 1)}
                />
              </div>
              {bubbleSettingData?.bubble_template ? <div className="call-tab-text" style={{ marginTop: '15px' }}>
                <div className="input" style={{ width: '100%' }}>
                  <span className="sec-color" style={{ color: "#c1c1c1" }}>Personalize the bubble popup text when widget is online</span>
                  <CommonTextArea
                    placeholder="Hello ! Would you like us to call you now?"
                    name="call-me-now"
                    type="text"
                    value={bubbleSettingData.bubble_text}
                    onChange={event =>
                      updateBubbleSetting('bubble_text', event.target.value)
                    }
                    style={{ width: '200%', marginRight: '8px' }}
                  />
                </div>
                <div className="input" style={{ width: '100%' }}>
                  <span className="sec-color" style={{ color: "#c1c1c1" }}>Personalize the offline bubble popup text</span>
                  <CommonTextArea
                    placeholder="Sorry, we are unavailable now ! Leave a message"
                    name="call-me-now"
                    type="text"
                    value={bubbleSettingData.bubble_offline_text}
                    onChange={event =>
                      updateBubbleSetting(
                        'bubble_offline_text',
                        event.target.value
                      )
                    }
                  />
                </div>
              </div> : null}
            </section>
            <section className="card-manage-number style-content">
              <div className="button-shape">
                <CommonGroupButton
                  title="Greeting Message"
                  identity="bubble_popup_status"
                  active={bubbleSettingData?.bubble_popup_status ? "btnLeft" : "btnRight"}
                  leftBtn="Enable"
                  rightBtn="Disable"
                  handleGroupBtnData={() => updateBubbleSetting('bubble_popup_status', bubbleSettingData?.bubble_popup_status ? 0 : 1)}
                />
              </div>
              {bubbleSettingData?.bubble_popup_status ? <>
              
              
                 <div className="call-tab-text" style={{ marginTop: '15px' }}>
                  <div className="input" style={{ width: '100%' }}>
                    <span className="sec-color" style={{ color: "#c1c1c1" }}>Intro online</span>
                    <CommonInput
                      placeholder="Hello"
                      name="call-me-now"
                      type="text"
                      value={bubbleSettingData.bubble_popup_title}
                      onChange={event =>
                        updateBubbleSetting('bubble_popup_title', event.target.value)
                      }
                      style={{ width: '200%', marginRight: '8px' }}
                    />
                  </div>
                  <div className="input" style={{ width: '100%' }}>
                    <span className="sec-color" style={{ color: "#c1c1c1" }}>Intro offline</span>
                    <CommonTextArea
                      placeholder="Hello"
                      name="call-me-now"
                      type="text"
                      value={bubbleSettingData.bubble_popupoffline_title}
                      onChange={event =>
                        updateBubbleSetting(
                          'bubble_popupoffline_title',
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div> 
                 <div className="call-tab-text" style={{ marginTop: '15px' }}>
                  <div className="input" style={{ width: '100%' }}>
                    <span className="sec-color" style={{ color: "#c1c1c1" }}>Greeting online</span>
                    <CommonInput
                      placeholder="Speak to a live agent"
                      name="call-me-now"
                      type="text"
                      value={bubbleSettingData.bubble_popup_subtitle}
                      onChange={event =>
                        updateBubbleSetting('bubble_popup_subtitle', event.target.value)
                      }
                      style={{ width: '200%', marginRight: '8px' }}
                    />
                  </div>
                  <div className="input" style={{ width: '100%' }}>
                    <span className="sec-color" style={{ color: "#c1c1c1" }}>Greeting offline</span>
                    <CommonTextArea
                      placeholder="We are currently offline. Please leave us a message"
                      name="call-me-now"
                      type="text"
                      value={bubbleSettingData.bubble_popupoffline_subtitle}
                      onChange={event =>
                        updateBubbleSetting(
                          'bubble_popupoffline_subtitle',
                          event.target.value
                        )
                      }
                    />
                  </div>
                </div> 
                <div className="template-Select" style={{marginBottom:'30px'}}>
                  <p className='widget-sub-heading'>Greeting template</p>
                  <CommonSelect
                    style={{ width: '50%' }}
                    name="popupFont"
                    className="popup-font-select"
                    placeholder="Select Template"
                    options={['Template 1', 'Template 2']}
                    value={
                      bubbleSettingData.bubble_popup_key === 'key-1'
                        ? 'Template 1'
                        : 'Template 2'
                    }
                    onChange={(event, data) =>
                      updateBubbleSetting(
                        'bubble_popup_key',
                        data.value === 'Template 1' ? 'key-1' : 'key-2'
                      )
                    }
                  />
                </div>
                <div className="button-shape">
                  <CommonGroupButton
                    title="Send Message Link"
                    identity="bubble_sendmessage_status"
                    active={bubbleSettingData?.bubble_sendmessage_status ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('bubble_sendmessage_status', bubbleSettingData?.bubble_sendmessage_status ? 0 : 1)}
                  />
                </div>
                <div className="button-shape">
                  <CommonGroupButton
                    title="Web call Link"
                    identity="bubble_webcall_status"
                    active={bubbleSettingData?.bubble_webcall_status ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('bubble_webcall_status', bubbleSettingData?.bubble_webcall_status ? 0 : 1)}
                  />
                </div>
                <div className="button-shape">
                  <CommonGroupButton
                    title="Callback Link"
                    identity="bubble_callback_status"
                    active={bubbleSettingData?.bubble_callback_status ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('bubble_callback_status', bubbleSettingData?.bubble_callback_status ? 0 : 1)}
                  />
                </div>
                <div className="button-shape">
                  <CommonGroupButton
                    title="Meeting Link"
                    identity="bubble_meeting_status"
                    active={bubbleSettingData?.bubble_meeting_status ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('bubble_meeting_status', bubbleSettingData?.bubble_meeting_status ? 0 : 1)}
                  />
                </div>
                <div className="popup-font"  >
                  <LinkIcon width={35} style={{
                    width: '35px',
                    height: '30px',
                    marginTop: '-6px',
                  }}
                    className="mr-2" />
                  <h3 className="call-title widget-sub-heading">Custom Link 1</h3>

                  <div className="header-swich" >
                    <div className={bubbleSettingData?.bubble_link1_status ? "w-100" : ""} style={{ maxWidth: 291 }}>
                      { bubbleSettingData?.bubble_link1_status ?
                        <> <CommonInput
                          className="popup-font-select"
                          placeholder="Label"
                          name="call-me-now"
                          type="text"
                          value={bubbleSettingData?.bubble_link1_cta
                          }
                          onChange={event =>
                            updateBubbleSetting(
                              'bubble_link1_cta',
                              event.target.value
                            )
                          }
                        />

                          <CommonInput
                            className="popup-font-select"
                            placeholder="Url link"
                            name="call-me-now"
                            type="text"
                            value={bubbleSettingData?.bubble_link1_link
                            }
                            onChange={event =>
                              updateBubbleSetting(
                                'bubble_link1_link',
                                event.target.value
                              )
                            }
                            example={'example:https://limecall.com'}
                          />
                        </> : null
                      }
                    </div>
                    <NodeToggle
                      style={{
                      }}
                      handleDataRef={e => updateBubbleSetting('bubble_link1_status', e)}
                      dataToggle={{
                        callId: 'bubble_link1_status',
                        callRef: 'bubble_link1_status'
                      }}
                      activeDefault={bubbleSettingData?.bubble_link1_status}
                      setText={{ 'off': 'Hidden', 'on': 'Visible' }}

                    />

                  </div>

                </div>
                <div className="popup-font"  >
                  <LinkIcon width={35} style={{
                    width: '35px',
                    height: '30px',
                    marginTop: '-6px',
                  }}
                    className="mr-2" />
                  <h3 className="call-title widget-sub-heading">Custom Link 2</h3>

                  <div className="header-swich" >
                    <div className={bubbleSettingData?.bubble_link2_status ? "w-100":""} style={{ maxWidth: 291 }}>
                      { bubbleSettingData?.bubble_link2_status ?
                        <> <CommonInput
                          className="popup-font-select"
                          placeholder="Label"
                          name="call-me-now"
                          type="text"
                          value={bubbleSettingData?.bubble_link2_cta
                          }
                          onChange={event =>
                            updateBubbleSetting(
                              'bubble_link2_cta',
                              event.target.value
                            )
                          }
                        />

                          <CommonInput
                            className="popup-font-select"
                            placeholder="Url link"
                            name="call-me-now"
                            type="text"
                            value={bubbleSettingData?.bubble_link2_link
                            }
                            onChange={event =>
                              updateBubbleSetting(
                                'bubble_link2_link',
                                event.target.value
                              )
                            }
                            example={'example:https://limecall.com'}
                          />
                        </> : null
                      }
                    </div>
                    <NodeToggle
                      style={{
                      }}
                      handleDataRef={e => updateBubbleSetting('bubble_link2_status', e)}
                      dataToggle={{
                        callId: 'bubble_link2_status',
                        callRef: 'bubble_link2_status'
                      }}
                      activeDefault={bubbleSettingData?.bubble_link2_status}
                      setText={{ 'off': 'Hidden', 'on': 'Visible' }}

                    />

                  </div>

                </div>
                <div className="customize-grp-btn-wrap">
                  <CustomizeGroupButton
                    title="Position"
                    identity="greeting_location"
                    active={bubbleSettingData.greeting_location === 'side' || bubbleSettingData.greeting_location === 'Side' ? 'top_right': 'top_left' }
                    top_left="Top"
                    top_right="Side"

                    handleGroupBtnData={(data) => updateBubbleSetting('greeting_location',data?.toLowerCase())}
                  />
                </div>
                <div className="button-shape">
                  <CommonGroupButton
                    title="Greeting CTA"
                    identity="bubble_popup_cta_status"
                    active={bubbleSettingData?.bubble_popup_cta_status ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('bubble_popup_cta_status', bubbleSettingData?.bubble_popup_cta_status ? 0 : 1)}
                  />
                </div>
                {/* if Greeting CTA is enable then only show call to action */}
             {bubbleSettingData?.bubble_popup_cta_status ?    <div className="custom-selection">
                  <p className='widget-sub-heading'>Call to action
                  </p>
               
                  <CommonInput
                    onChange={(e) => updateBubbleSetting('bubble_popup_button_text', e.target.value)}
                    value={bubbleSettingData.bubble_popup_button_text || ""}
                    name="bubble_popup_button_text"
                    type="text"
                    placeholder='Message us 👋'

                  />
                
                </div>: null}
         
                

                  <div className="custom-selection">
                    <p className='widget-sub-heading'>Greeting delay, sec
                    </p>
                    <CommonInput
                    onChange={(e) => updateBubbleSetting('greeting_delay', e.target.value)}
                    value={bubbleSettingData.greeting_delay || "0"}
                    name="greeting_delay"
                    type="number"

                  />
                  </div>
               
                <div className="custom-selection">
                  <p className='widget-sub-heading'>Logo
                  </p>
                  
                  <img height={40} width={40} style={{margin:'0 auto auto'}} src={`${bubbleSettingData?.bubble_popup_logo}`} alt="sss" />
                  {bubbleSettingData?.bubble_popup_logo ? <TrashIcon style={{ color: 'red', margin: 'auto' }} onClick={() => removeLogo('bubble_popup_logo', '')} /> : ''}
                   <CommonInput
                    id="file"
                    style={{}}
                    name="bubble_popup_logo"
                    inputProps={{ accept: 'audio/*;capture=microphone' }}
                    type="file"
                    onChange={(e) => {

                      updateGreetingLogo('bubble_popup_logo', e?.target?.files?.[0])
                    }}
                  />

                </div>
                {/* <div className="button-shape">
                  <CommonGroupButton
                    title="Social Links"
                    identity="bubble_social_link_status"
                    active={bubbleSettingData?.bubble_social_link_status ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('bubble_social_link_status', bubbleSettingData?.bubble_social_link_status ? 0 : 1)}
                  />
                </div> */}
                   <div className="button-shape">
                  <CommonGroupButton
                    title="Whatsapp"
                    identity="greetings_social_whatsapp"
                    active={bubbleSettingData?.greetings_social_whatsapp ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('greetings_social_whatsapp', bubbleSettingData?.greetings_social_whatsapp ? 0 : 1)}
                  />
                </div>
                   <div className="button-shape">
                  <CommonGroupButton
                    title="SMS"
                    identity="greetings_social_sms"
                    active={bubbleSettingData?.greetings_social_sms ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('greetings_social_sms', bubbleSettingData?.greetings_social_sms ? 0 : 1)}
                  />
                </div>
                   <div className="button-shape">
                  <CommonGroupButton
                    title="Telegram"
                    identity="greetings_social_telegram"
                    active={bubbleSettingData?.greetings_social_telegram ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('greetings_social_telegram', bubbleSettingData?.greetings_social_telegram ? 0 : 1)}
                  />
                </div>
                <div className="button-shape">
                  <CommonGroupButton
                    title="Virtual number"
                    identity="greetings_social_number"
                    active={bubbleSettingData?.greetings_social_number ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('greetings_social_number', bubbleSettingData?.greetings_social_number ? 0 : 1)}
                  />
                </div>
                <div className="button-shape">
                  <CommonGroupButton
                    title="Facebook"
                    identity="greetings_social_facebook"
                    active={bubbleSettingData?.greetings_social_facebook ? "btnLeft" : "btnRight"}
                    leftBtn="Enable"
                    rightBtn="Disable"
                    handleGroupBtnData={() => updateBubbleSetting('greetings_social_facebook', bubbleSettingData?.greetings_social_facebook ? 0 : 1)}
                  />
                </div>

              </> : null}
            </section>
       
            {isInputChange ? (
              <div className="saveCancelButton">
                <CommonButton
                  onClick={saveBubbleSetting}
                  type="button"
                  content="Save"
                  background="blue"
                />
                <CommonButtons
                  onClick={onCancel}
                  type="reset"
                  content="Cancel"
                  background="grey"
                />
              </div>
            ) : null}
          </div>
           
        </>
      )}
    </>
  )
}
