import React, { Fragment, useState } from 'react'
import { Dimmer, Loader, Modal, Tab, Table } from 'semantic-ui-react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import OwnerModel from './ownerModel'
import CommonButtons from '../../common/CommonButtons'
import axios from 'axios'
import { CommonNotify } from '../../common/CommonNotify'

const ListMain = ({
 listData,
  opendetails,
  onClickMarkCheckbox,
  onClickMarkAllCheckbox,
  isMarkAllCheckbox,
  fetchLists
}) => {
  const [assignedPopUp, setAssigned] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [listToDelete, setListToDelete] = useState()
  const [listToUpdate, setListToUpdate] = useState()
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const [updatedListName, setUpdatedListName] = useState("")

  const updateList = async () => {
    setIsEditModalOpen(false)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list/${listToUpdate}`
    setIsLoading(true)
    
    const formData = new FormData()
    formData.append("name", updatedListName)

    axios
      .post(url, formData)
      .then(res => {
        if (res.data) {
          const onComplete = () => {
            setIsLoading(false)
            CommonNotify('Successfully updated list!', 'success')
          }
          fetchLists(onComplete)
        }
      })
      .catch(err => {
        CommonNotify(err?.response?.data?.errors?.[0] || "Can't update list!")
        setIsLoading(false)
      })
  }

  const deleteList = async () => {
    setIsDeleteConfirmationModalOpen(false)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/list/${listToDelete}`
    setIsLoading(true)
    axios
      .delete(url)
      .then(res => {
        if (res.data) {
          const onComplete = () => {
            setIsLoading(false)
            CommonNotify('Successfully deleted list!', 'success')
          }
          fetchLists(onComplete)
        }
      })
      .catch(err => {
        CommonNotify(err?.response?.data?.errors?.[0] || "Can't delete list!")
        setIsLoading(false)
      })
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
    setUpdatedListName("")
  }

  // useEffect(() => {
  //   onClickUnmarkCheckboxReset()
  // }, [])
  return (
    <Tab.Pane attached={false}>
      <Table singleLine className={opendetails == true ? 'tablepopup' : ''}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {/* <CommonCheckbox
                onChange={onClickMarkAllCheckbox}
                name="isMarkAllCheckbox"
                checked={isMarkAllCheckbox}
              /> */}
            </Table.HeaderCell>
                     
            <Table.HeaderCell  style={{width:'200px'}}>Name</Table.HeaderCell>
            <Table.HeaderCell  style={{width:'175px'}}>Contacts</Table.HeaderCell>
            <Table.HeaderCell  style={{width:'175px'}}>Type</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            {/* <Table.HeaderCell>Actions</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>

        {listData?.map((data, index) => {

          return (
            <Fragment key={index}>
              <Dimmer
                active={isLoading}
                style={{
                  position: 'fixed',
                  backgroundColor: 'rgba(0, 0, 0, 0.2)'
                }}
              >
                <Loader />
              </Dimmer>
              <Table.Body>
                <Table.Row data-key={index} className="lists-table">
                  <Table.Cell data-key={index}>
                    {/* <CommonCheckbox
                        onChange={e => onClickMarkCheckbox(e, index)}
                        name="isChecked"
                        checked={data.isChecked}
                      /> */}
                  </Table.Cell>
                  <Table.Cell
                    className="time-set-table"
                    onClick={() => {
                      // modalOpen()
                      // setActiveType('company')
                      // onClickData(data.id)
                    }}
                  >
                    <span>{data.name}</span>

                    <EditIcon
                      onClick={() => {
                        setListToUpdate(data.id)
                        setUpdatedListName(data.name)
                        setIsEditModalOpen(true)
                      }}
                      className="edit-icon ml-2"
                      fontSize="small"
                    />
                    <DeleteIcon
                      onClick={() => {
                        setListToDelete(data.id)
                        setIsDeleteConfirmationModalOpen(true)
                      }}
                      className="delete-icon"
                      style={{ color: 'red' }}
                      fontSize="small"
                    />
                  </Table.Cell>
                  <Table.Cell
                    className="time-set-table"
                    onClick={() => {
                      // modalOpen()
                      // setActiveType('company')
                      // onClickData(data.id)
                    }}
                  >
                    <span
                      style={{
                        borderRadius: '10px',
                        padding: '5px',
                        border: '1px solid lightgray'
                      }}
                    >
                      {data?.contacts_count} Contacts
                    </span>
                  </Table.Cell>
                  <Table.Cell
                    className="metting-name"
                    onClick={() => {
                      // modalOpen()
                      // onClickData(data.id)
                    }}
                  >
                    <span
                      style={{
                        backgroundColor:
                          data?.access === 'shared' ? '#69bc36' : '#ecf5fd',
                        padding: '5px',
                        borderRadius: '10px',
                        color: data?.access === 'shared' ? '#fff' : '#1c6d95'
                      }}
                    >
                      {' '}
                      {data.access?.toUpperCase()}
                    </span>
                  </Table.Cell>

                  <Table.Cell
                    className="link-table"
                    onClick={() => {
                      // modalOpen()
                      // onClickData(data.id)
                    }}
                  >
                    {data?.created_at}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Fragment>
          )
        })}
        {assignedPopUp ? (
          <OwnerModel
            // fetchId={fetchId}
            selected={selected}
            assignedPopUp={assignedPopUp}
            setAssigned={setAssigned}
          />
        ) : (
          ''
        )}

      {/* Edit list */}
      <Modal
        className="local-number-modal"
        open={isEditModalOpen}
        onClose={closeEditModal}
      >
        <Modal.Header>
          Edit List
          </Modal.Header>
        <Modal.Content>
          <div className="modal-content">
            <div style={{padding:'15px'}}>
              <label style={{marginRight:'10px'}}>Edit List : </label>
              <input style={{height:'40px'}} placeholder="Enter your list here" value={updatedListName} onChange={(e) => setUpdatedListName(e.target.value)} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={closeEditModal}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={updateList}
                type="button"
                content="Update"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>

      {/* Delete Confirmation */}
      <Modal
        className="local-number-modal"
        open={isDeleteConfirmationModalOpen}
        onClose={e => setIsDeleteConfirmationModalOpen(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this list?
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={() => setIsDeleteConfirmationModalOpen(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={deleteList}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>   
      </Table>
    </Tab.Pane>
  )
}

export default ListMain
