import React from 'react'
import { Button, Header, Image, Label, Modal } from 'semantic-ui-react'
import CommonButtons from '../../../common/CommonButtons'


const VerifiedNumberModal = ({
  open,
  isModalOpen,
  isModalClose,
  Title,
  onChangeInput,
  onOtpVerification,
  onConfirmCode
}) => {
  return (
    <div>
      <Modal onClose={() => isModalOpen()} open={open} size="mini">
        <Modal.Header>{Title}</Modal.Header>
        <div
          style={{
            alignItems: 'center',
            padding: '20px 18px'
          }}
        >
          <label>Please enter the code from the SMS message we just sent to your phone.</label>
          <input
          style={{
            height: '33px',
            width: '100%',
          }}
            type="text"
            name="Otp"
            onChange={e => onChangeInput(e)}
            disabled={onConfirmCode}
            placeholder='######'
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '11px 0'
          }}
        >
          <CommonButtons
            content="Submit"
            background="blue"
            btnClass="btn-verify"
            onClick={() => onOtpVerification()}
            loading={onConfirmCode}
          />
         
          <Button onClick={() => isModalClose()}>Cancel</Button>
        </div>
      </Modal>
    </div>
  )
}
export default VerifiedNumberModal
