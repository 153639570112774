import axios from 'axios'
import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import { getWidget } from '../../actions/home'
import { getUserIntegrations } from '../../actions/settings'
import CommonCheckbox from '../../common/CommonCheckbox'
import { CommonNotify } from '../../common/CommonNotify'
import NodeCheckbox from '../../common/NodeCheckbox'

class NotificationSideContent extends Component {
  state = {
    activeIndexes: [0],
    //Notification Data
    widget: 0,
    isSlackIntegrated: false,
    data: {
      checkboxForMobile: false,
      checkboxCallNotif: false,
      selectConvoType: '',
      selectConvoSound: '',
      inputBillingEmail: '',
      checkboxBrowserDeiplayNotif: false,
      checkboxDesktopNotifOnScreen: false,
      selectAdvanceSound: '',
      checkboxNotifRepeat: false,
      selectEmailNotif: '',
      nodeCheckboxEmail: [],
      nodeCheckboxDesktop: [],
      nodeCheckboxMobile: []
    },
    nodeCheckboxEmail: {
      check_box_1: true,
      check_box_2: false,
      check_box_3: false,
      check_box_4: true
    },
    settingsData: null,

    allList: [
      {
        SMSKey: 'sms_notification_schedule_call',
        webKey: false,
        EmailKey: 'mail_schedule_call',
        label: 'Schedule Call',
        EmailChecked: 0,
        SMSChecked: 0,
      },
      {
        SMSKey: 'sms_leave_message',
        webKey: 'web_leave_message',
        EmailKey: 'mail_leave_message',
        label: 'Offline Message',
        EmailChecked: 0,
        SMSChecked: 0,
      },
      {
        SMSKey: 'sms_incoming_sms',
        webKey: 'web_sms_message',
        EmailKey: 'mail_incoming_sms',
        label: 'SMS Received',
        EmailChecked: 0,
        SMSChecked: 0,
      },
      {
        SMSKey: 'sms_incoming_call_summary',
        webKey: false,
        EmailKey: 'mail_incoming_call_summary',
        label: 'Call Summary',
        EmailChecked: 0,
        SMSChecked: 0,
      },
      {
        SMSKey: 'sms_incoming_call_missed',
        webKey: 'web_call_missed',
        EmailKey: 'mail_call_missed',
        label: 'Call Missed',
        EmailChecked: 0,
        SMSChecked: 0,
        webChecked: 0
      },
      {
        SMSKey: 'sms_cancel_schedule_call',
        webKey: 'web_schedule_cancelled',
        EmailKey: 'schedule_call_canceled_mail_notify',
        label: 'Schedule Call Cancelled',
        EmailChecked: 0,
        SMSChecked: 0,
        webChecked: 0
      },
      {
        SMSKey: 'sms_reschedule_schedule_call',
        webKey: 'web_reschedule_call',
        EmailKey: 'mail_reschedule_call',
        label: 'Schedule Call Rescheduled',
        EmailChecked: 0,
        SMSChecked: 0,
        webChecked: 0
      },
      {
        SMSKey: 'sms_daily_report',
        webKey: 'web_call_summary',
        EmailKey: 'mail_daily_report',
        label: 'Upcoming Calls Summary',
        EmailChecked: 0,
        SMSChecked: 0,
        webChecked: 0
      },
      {
        SMSKey: 'leave_your_feedback_message',
        webKey: false,
        EmailKey: false,
        label: 'Leave your feedback message ',
        EmailChecked: 0,
        SMSChecked: 0,
        webChecked: 0
      },
      {
        SMSKey: 'sms_missed_call',
        webKey: 'web_call_missed',
        EmailKey: 'mail_call_missed',
        label: "Couldn't Reach you message if if couldn't reach user",
        EmailChecked: 0,
        SMSChecked: 0,
        webChecked: 0
      },
      {
        SMSKey: 'mail_unsupported_country_call',
        webKey: false,
        EmailKey: false,
        label: 'Unsupported Countries Calls',
        EmailChecked: 0,
        SMSChecked: 0,
        webChecked: 0
      }
    ],

    checkboxesSlack: [
      {
        name: 'mail_schedule_call',
        key: 'mail_schedule_call',
        label: 'Schedule Calls',
        checked: 0
      }
    ],

    checkboxesSlackNotification: [
      {
        name: 'slack_cancel_schedule_call',
        key: 'slack_cancel_schedule_call',
        label: 'Cancel Schedule Call',
        checked: 0
      },

      {
        name: 'slack_daily_report',
        key: 'slack_daily_report',
        label: 'Daily Report  ',
        checked: 0
      },
      {
        name: 'slack_incoming_call',
        key: 'slack_incoming_call',
        label: 'Incoming Call',
        checked: 0
      },
      {
        name: 'slack_incoming_call_missed',
        key: 'slack_incoming_call_missed',
        label: 'Incoming Call Missed',
        checked: 0
      },
      {
        name: 'slack_incoming_call_summary',
        key: 'slack_incoming_call_summary',
        label: 'Incoming Call Summary',
        checked: 0
      },
      {
        name: 'slack_incoming_sms',
        key: 'slack_incoming_sms',
        label: 'Incoming SMS',
        checked: 0
      },
      {
        name: 'slack_leave_message',
        key: 'slack_leave_message',
        label: 'Leave Message',
        checked: 0
      },
      {
        name: 'slack_reschedule_schedule_call',
        key: 'slack_reschedule_schedule_call',
        label: 'Reschedule Call',
        checked: 0
      },
      {
        name: 'slack_schedule_call',
        key: 'slack_schedule_call',
        label: 'Schedule Call',
        checked: 0
      }
    ]
  }

  onChangeInput = e => {
    const { name, value } = e.target
    const { data } = this.state
    data[name] = value

    this.setState({ data })
  }

  onChangeCheckbox = e => {
    const { name } = e.target.parentNode.querySelector('.hidden')
    const { data } = this.state
    data[name] = !data[name]

    this.setState(data)
  }

  componentDidUpdate(prevProps) {
    if (this.props.widget !== prevProps.widget) {
      this.fetchWidget(this.props.widget)
    }
    if (this.props.userIntegrations !== prevProps.userIntegrations) {
      this.fetchUserIntegrations(this.props.userIntegrations)
    }
  }

  componentDidMount = () => {
    if (this.props.widget) {
      this.props.loading(false)
      this.fetchWidget(this.props.widget)
    } else {
      this.props.loading(true)
      this.props.getWidget()
    }
    if (this.props.userIntegrations) {
      this.fetchUserIntegrations(this.props.userIntegrations)
    } else {
      this.props.getUserIntegrations()
    }
  }

  fetchUserIntegrations = res => {
    this.props.loading(false)
    if (res.data) {
      this.props.loading(false)
      res.data.find(obj => {
        obj.integration === 'slack' &&
          this.setState({
            ...this.state,
            isSlackIntegrated: true
          })
      })
    }
  }

  fetchWidget = res => {
    this.fetchNotiSetting(res.data[0].id)
    this.setState({ widget: res.data[0].id })
  }

  fetchNotiSetting = widget => {
    this.props.loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-notification?widget_id=${widget}`

    axios
      .get(url)
      .then(res => {
        this.props.loading(false)
        if (res.data.data) {
          this.setState({ settingsData: res.data.data[0] }, () => {
            this.setsettingData()
          })
        }
      })
      .catch(er => {
        this.props.loading(false)
        //CommonNotify('Cant Fetch Widget Notifications')
      })
  }

  smsSettings = data => {
    let checkSms = [...this.state.allList]
    checkSms.map((row, index, rows) => {
      if (row.SMSKey == data[0] && data[1] == 1) {
        checkSms[index].SMSChecked = 1
        this.setState({ allList: checkSms })
        return
      }
    })
  }

  webSettings = data => {
    let checkWebNotification = [...this.state.allList]
    checkWebNotification.map((row, index, rows) => {
      if (row.webKey == data[0] && data[1] == 1) {
        checkWebNotification[index].webChecked = 1
        this.setState({ allList: checkWebNotification })
        return
      }
    })
  }

  mailSettings = data => {
    let checkEmail = [...this.state.allList]
    checkEmail.map((row, index, rows) => {
      if (row.EmailKey == data[0] && data[1] == 1) {
        checkEmail[index].EmailChecked = 1
        this.setState({ allList: checkEmail })
        return
      }
    })
  }

  slackSettings = data => {
    let checkSlack = [...this.state.checkboxesSlackNotification]
    checkSlack.map((row, index, rows) => {
      if (row.key === data[0] && data[1] == 1) {
        checkSlack[index].checked = 1
        this.setState({ checkboxesSlackNotification: checkSlack })
        return
      }
    })
  }

  setsettingData = () => {
    Object.entries(this.state.settingsData).map(row => {
      if (row[0].includes('mail_')) {
        this.mailSettings(row)
      } else if (row[0].includes('web_')) {
        this.webSettings(row)
      } else if (row[0].includes('slack_')) {
        this.slackSettings(row)
      } else {
        this.smsSettings(row)
      }
    })
  }

  onChangeNodeCheckbox = value => {}

  onChangeSelect = (e, key, ch) => {
    const data = e.target.parentNode.childNodes[0].value

    var res = data
    if (res.includes('mail_')) {
      let temp_mail = [...this.state.allList]
      temp_mail[key].EmailChecked = !ch
      this.setState({ allList: temp_mail })
    } else if (res.includes('web_')) {
      let temp_web = [...this.state.allList]
      temp_web[key].webChecked = !ch
      this.setState({ allList: temp_web })
    } else if (res.includes('sms_')) {
      let temp_sms = [...this.state.allList]
      temp_sms[key].SMSChecked = !ch
      this.setState({ allList: temp_sms })
    } else if (res.includes('slack_')) {
      let temp_slack = [...this.state.checkboxesSlackNotification]
      temp_slack[key].checked = !ch
      this.setState({ checkboxesSlackNotification: temp_slack })
    } else {
      if (data == 'leave_your_feedback_message') {
        let temp_sms = [...this.state.allList]
        temp_sms[key].SMSChecked = !ch
        this.setState({ allList: temp_sms })
      }
    }
    this.onSave()
  }

  onSave = () => {
    this.props.loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/set-customer-notifications`

    let data = {}

    let dataList = [...this.state.checkboxesSlackNotification]

    dataList.map((row, index) => {
      data[row.key] = row.checked
    })

    this.state.allList.map(row => {
      if (row.SMSKey !== false) {
        data[row.SMSKey] = row.SMSChecked
      }
      if (row.webKey !== false) {
        data[row.webKey] = row.webChecked
      }
      if (row.EmailKey !== false) {
        data[row.EmailKey] = row.EmailChecked
      }
    })

    data.widget_id = this.state.widget

    axios
      .post(url, data)
      .then(res => {
        this.props.loading(false)
        if (res.data.message == 'Successfully') {
          CommonNotify('Data successfully updated', 'success')
        } else {
          CommonNotify('Cant updated Notification Setting', 'error')
        }
      })
      .catch(error => {
        this.props.loading(false)
        CommonNotify(error.response.data.errors[0], 'error')
      })
  }

  render() {
    return (
      <div className="account-settings notification-sidebar">
        <div className="holder-notification">
          <form method="" action="">
            <div className="holder-checkboxes notification_detail">
              <p className="title-label">
                Select the notifications you would like to receive
              </p>
              <p className="title-label" style={{fontWeight:400}}>
                You will receive email on {this.props.profile?.email}
              </p>
              {
                <Table
                  style={{
                    border: '1px solid #f4f4f4 !important',
                    width: '50%',
                    margin: '0 20px 0 0'
                  }}
                >
                  <Table.Header style={{ padding: '12px 15px' }}>
                    <Table.Row>
                      <Table.HeaderCell>Notify Me About</Table.HeaderCell>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>SMS</Table.HeaderCell>
                      <Table.HeaderCell>Web</Table.HeaderCell>
                      <Table.HeaderCell>Slack</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body style={{ padding: '10px 12px' }}>
                    {this.state.allList?.map((list, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell className="title-notification">
                            {list?.label}
                          </Table.Cell>
                          <Table.Cell
                            style={{ width: '100px' }}
                            className="text-center"
                          >
                            {list?.EmailKey && (
                              <CommonCheckbox
                                onChange={this.onChangeSelect.bind(this)}
                                index={index}
                                text={''}
                                value={list?.EmailKey}
                                checked={list?.EmailChecked}
                                data={list}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{ width: '100px' }}
                            className="text-center"
                          >
                            {list?.SMSKey && (
                              <CommonCheckbox
                                onChange={this.onChangeSelect.bind(this)}
                                index={index}
                                text={''}
                                value={list?.SMSKey}
                                checked={list?.SMSChecked}
                                data={list}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{ width: '100px' }}
                            className="text-center"
                          >
                            {list?.webKey && (
                              <CommonCheckbox
                                onChange={this.onChangeSelect.bind(this)}
                                index={index}
                                text={''}
                                value={list?.webKey}
                                checked={list?.webChecked}
                                data={list}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell
                            style={{ width: '100px' }}
                            className="text-center"
                          >
                            {this.state.checkboxesSlackNotification?.[index] && (
                              <CommonCheckbox
                                onChange={this.onChangeSelect.bind(this)}
                                name="slack_"
                                index={index}
                                value={this.state.checkboxesSlackNotification?.[index]?.key}
                                text={""}
                                checked={this.state.checkboxesSlackNotification?.[index]?.checked}
                                data={this.state.checkboxesSlackNotification?.[index]}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              }
              {/* {this.state.isSlackIntegrated && (
                <NodeCheckbox
                  onChange={this.onChangeSelect.bind(this)}
                  name="nodeCheckboxSlack"
                  title="Slack"
                  checkboxlist={this.state.checkboxesSlackNotification}
                />
              )} */}
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  widget: state.home.widget,
  userIntegrations: state.settings.userIntegrations,
  profile:state.settings?.profile?.data
})

export default connect(mapStateToProps, { getWidget, getUserIntegrations })(
  NotificationSideContent
)
