import React, { useState, useEffect } from 'react'
import {  Checkbox, Modal } from 'semantic-ui-react'
import { Dimmer, Loader, Input } from 'semantic-ui-react'

import CommonButton from '../common/CommonButtons'
import Title from '../common/Title'
import { CommonNotify } from '../common/CommonNotify'
import axios from 'axios'
import AllCalls from '../components/leads/myleads-tab/AllCalls'
import { logOut } from '../common/ProfileModal'


const title = {
  titleTwo: 'My Leads'
}

const sortOptions = [
  { key: 1, text: 'Newest First', value: 'Newest First' },
  { key: 2, text: 'Oldest First', value: 'Oldest First' }  
]

const options = [
  { key: 1, text: 'All Time', value: 'All Time' },
  { key: 1, text: 'Today', value: 'Today' },
  { key: 2, text: 'Yesterday', value: 'Yesterday' },
  { key: 3, text: 'This week', value: 'This week' },
  { key: 4, text: 'Last week', value: 'Last week' },
  { key: 5, text: 'This month', value: 'This month' },
  { key: 6, text: 'Last month', value: 'Last month' },
  { key: 7, text: 'Custom range', value: 'Select custom' }
]

const countryOptions = [
  { key: 'af', value: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 'al', text: 'Albania' },
  { key: 'dz', value: 'dz', text: 'Algeria' },
  { key: 'as', value: 'as', text: 'American Samoa' },
  { key: 'ad', value: 'ad', text: 'Andorra' },
  { key: 'ao', value: 'ao', text: 'Angola' },
  { key: 'ai', value: 'ai', text: 'Anguilla' },
  { key: 'ag', value: 'ag', text: 'Antigua' },
  { key: 'ar', value: 'ar', text: 'Argentina' },
  { key: 'am', value: 'am', text: 'Armenia' },
  { key: 'aw', value: 'aw', text: 'Aruba' },
  { key: 'au', value: 'au', text: 'Australia' },
  { key: 'at', value: 'at', text: 'Austria' },
  { key: 'az', value: 'az', text: 'Azerbaijan' },
  { key: 'bs', value: 'bs', text: 'Bahamas' },
  { key: 'bh', value: 'bh', text: 'Bahrain' },
  { key: 'bd', value: 'bd', text: 'Bangladesh' },
  { key: 'bb', value: 'bb', text: 'Barbados' },
  { key: 'by', value: 'by', text: 'Belarus' },
  { key: 'be', value: 'be', text: 'Belgium' },
  { key: 'bz', value: 'bz', text: 'Belize' },
  { key: 'bj', value: 'bj', text: 'Benin' }
]

const tagOptions = [
  {
    key: 'ID',
    text: 'ID',
    value: 'id'
  },
  
  {
    key: 'phone_number',
    text: 'Phone Number',
    value: 'phone_number'
  },
  {
    key: 'Type',
    text: 'Type',
    value: 'type'
  },
 
  {
    key: 'Call Status',
    text: 'Call Status',
    value: 'final_status'
  },
  {
    key: 'status',
    text: 'status',
    value: 'status'
  },
  {
    key: 'Agent',
    text: 'Agent',
    value: 'agent'
  },
  {
    key: 'Score',
    text: 'Score',
    value: 'score'
  },
  {
    key: 'Owner',
    text: 'Owner',
    value: 'owner'
  }
]

const conditionOptions = [
  {
    key: 'equals',
    text: 'equals',
    value: 'is'
  },
  {
    key: 'not equal to',
    text: 'not equal to',
    value: '!is'
  },
  {
    key: 'starts with',
    text: 'starts with',
    value: 'begin'
  }
]

const sideList = [
 
  {
    text: 'Digital calls',
    value: 'DIGITAL_CALL',
    key: 1,
    total: 0
  },
  {
    text: 'Live calls',
    value: 'LIVE_CALL',
    key: 2,
    total: 0
  },  
  {
    text: 'Messages',
    value: 'LEAVE_MESSAGE',
    key: 5,
    total: 0
  },
  {
    text: 'Schedule calls',
    value: 'SCHEDULE_CALL',
    key: 6,
    total: 0
  }
]

const filter = {
  type: ['Type', 'Existing', 'Meeting', 'Opportunity'],
  status: ['Civil Status', 'Online', 'Offline'],
  score: ['Score', 'All', 'Qualified', 'Bad Fit'],
  owner: ['Owner', 'All', 'Developer AIPXPERTS'],
  agent: ['Agent', 'All', 'Developer AIPXPI'],
  dateRange: ['Date', 'Today', 'Last 7 Days', 'Last 30 Days', 'Custom Date']
}

const leadSubStatus = [
  { key: 'all', text: 'All', value: 'all' },
  { key: 'new', text: 'Unassigned', value: 'new' },
  { key: 'pending', text: 'Pending', value: 'pending' },
  { key: 'qualified', text: 'Qualified', value: 'Qualified' }
]

const mainTabs = ['call', 'message', 'schedule']

const tabsOptions = {
  call: ['LIVE_CALL', 'DIGITAL_CALL', ''],
  message: ['LEAVE_MESSAGE'],
  schedule: ['SCHEDULE_CALL']
}

const sortObj = {
  sortField: 'id',
  sortValue: 'DESC',
  pageNumber: 1,
  sortMain: '-id'
}

const filterObj = {
  selectedValue: '',
  query: '',
  filterCondition: ''
}

const dateFilters = {
  startDateFilter: '',
  endDateFilter: ''
}

const MyLeads = () => {
  const [sideTab, setSideTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [detailPopUp, setDetailPopUp] = useState(false)
  const [schedulePopUp, setSchedulePopUp] = useState(false)
  const [menuOpen, setMenu] = useState(false)
  const [segments, setSegments] = useState([])
  const [segmentAdded, setSegmentAdded] = useState(0)
  const [loading, setLoading] = useState(false)
  const [activeSegmentsLead, setActiveSegmentsLead] = useState([])
  const [activeSeg, setActiveSeg] = useState(0)
  const [activeTagsLead, setActiveTagsLead] = useState([])

  const [segmentShow, setSegmentShow] = useState(true)
  const [tagShow, setTagShow] = useState(true)
  
  const [activeCallType, setActiveCallType] = useState(['DIGITAL_CALL', 'SCHEDULE_CALL', 'LIVE_CALL', 'LEAVE_MESSAGE'])
  const [notChecked, setNotChecked] = useState(true)
  const [selectedId, setSelectedId] = useState(0)
  const [modal, setModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [labelName, setLabelName] = useState("")
  
  const [sideListU, setSideListU] = useState(sideList)
  const [allTags, setAllTags] = useState([])

  const [tagCount, setTagCount] = useState(null)

  

  useEffect(() => {
    fetchWidget()   
  }, [])  

  useEffect(() => {
    fetchSegments()
  }, [segmentAdded])
 


   const  getTagCount = tag => { 

    console.info({tagCount, tag})

    try {
       const items = tagCount?.filter( (item, row) => {
         if(item === tag){
           return item
         }
       })
       return items?.length
    }
    catch(err) {
     return 0;
    } 
    
   }

   const fetchWidget = () => {
    const token = localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets?limit=3&offset=0`

    const settings = {
      url: URL,
      method: 'GET',
      timeout: 0,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false
    }

    axios(settings)
      .then(res => {
        fetchAllTags(res.data.data[0].id)
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          logOut()
          return
        }
        if (!err?.response) {
         
        }
      })
  } 

  const fetchSegments = () => {
    const apiToken = localStorage.getItem('access_token')
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists`
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        setLoading(false)
        
        setSegments(res.data.data)
      })
      .catch(err => {
        setLoading(false)
        if (err?.response?.status === 401) {
         
          return
        }
        if (!err?.response) {
         
        }
      })
  }

  
  const onChangeSlide = value => {
    let callTypes = [...activeCallType]

    

    if (value === 'all') {
      if (callTypes.includes('all')) {
        
        callTypes = []
        setActiveCallType([...callTypes])
      } else {
        callTypes = [
          'all',
          'LIVE_CALL',
          'DIGITAL_CALL',
          'LEAVE_MESSAGE',
          'SCHEDULE_CALL',
          'USER_BLACKLISTED'
        ]
        setActiveCallType([...callTypes])
      }
      return
    }

    if (callTypes.includes(value)) {
      var index = activeCallType.indexOf(value)
      if (index !== -1) {
        callTypes.splice(index, 1)
      }
    } else {
      callTypes.push(value)
    }
   
    setActiveCallType(callTypes)
    setNotChecked(false)
  }

  const deleteSegment = id => {
    setSelectedId(id)
    setModal(true)
  }

  const updateSegment = (id, seg) => {
    setLabelName(seg.name)
    setSelectedId(id)
    setUpdateModal(true)
  }

  const removeSegment = () => {
    const apiToken = localStorage.getItem('access_token')
    

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/delete-list`
    const postData = {
      list_id: selectedId
    }

    axios
      .delete(url, {
        headers: {
          Authorization: 'Bearer ' + apiToken
        },
        data: {
          ...postData
        }
      })
      .then(res => {
        CommonNotify('Deleted successfully', 'success')
        fetchSegments()
        setModal(false)
      })
      .catch(err => {
        CommonNotify('Not able to Delete')
      })
  }

 
  const updateSegmentData = () => {
    const apiToken = localStorage.getItem('access_token')
    

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/list/lists/${selectedId}`
    const postData = {
      name: labelName
    }

    axios
      .put(url, postData)
      .then(res => {
        CommonNotify('Segments Updated ', 'success')
        fetchSegments()
        setUpdateModal(false)
      })
      .catch(err => {
        CommonNotify('Not able to Update')
      })
  }

  const selectAllTags = tags => {
    let newTags = []
    newTags = tags.map(tag => {
      return {
        tag: tag,
        color: generateRandomColor()
      }
    })
    setAllTags(newTags)
  }

  const generateRandomColor = () => {
    var randomColor = '#' + Math.floor(Math.random() * 16741215).toString(16)
    return randomColor
  }

  const fetchAllTags = widget => {
    const token = localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget}/tags`

    const settings = {
      url: URL,
      method: 'GET',
      timeout: 0,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false
    }

    axios(settings)
      .then(res => {
        let aTags = []
        aTags = res.data.data

      

        selectAllTags(aTags)
      })
      .catch(err => {
        if (err?.response?.status === 401) {
         
          return
        }
        if (!err?.response) {
         
        }
      })
  }

  const updateTagsLead = tag => {
    if(activeTagsLead === tag){
      setActiveTagsLead("")
    }else{
      setActiveTagsLead(tag)
    }
  }

  const processSegment = (leads, id) => {
      setActiveSegmentsLead(leads)
     
      if(id === activeSeg){
        setActiveSeg(0)
        setActiveSegmentsLead([])
      }else{
        setActiveSegmentsLead(leads)
        setActiveSeg(id)
      }
  }

  return (
    <>
      <div className="sidebarWrap">
        <div
          className="myleadSidebar"
          style={{ padding: '20px 15px', marginBottom: '20px' }}
        >
          <div className="sideHeader"></div>
          <div>
            <h3
              style={{
                fontSize: '16px',
                padding: '30px 15px 20px 15px',
                marginBottom: '10px'
              }}
            >
              Call Type
            </h3>
            {sideListU.map( (type, index) => {
              return (
                <>
                  <Checkbox
                    key={index}
                    checked={activeCallType.includes(type.value) ? true : false}
                    activeCallType={activeCallType}
                    label={`${type.text}`}                    
                    onClick={(evt, data) => onChangeSlide(type.value)}
                  />
                  <span className="small-text" style={{ float: 'right' }}>{type.total}</span>
                </>
              )
            })}
          </div>

          {segments?.length ? (

            <>

          <div className="sideHeader"  onClick={ () => setSegmentShow(!segmentShow) }>
            <i aria-hidden="true" className="chevron left icon"></i><p style={{ fontSize: '16px', display: "inline" }}>Segments</p>
          </div>

           { segmentShow && (
             <>
                  {segments.map((seg, index) => {
            return (
              <div key={index}  onClick={ () =>  processSegment(seg.leads, seg.id) } className={ seg.id === activeSeg ? "hover-holder active-tag" : "hover-holder" } style={{   padding: "4px 8px", borderRadius: "4px", marginBottom: "4px",  transition: "0.6s" }}>
                <label
                  style={{ padding: '0px', fontSize: '14px', marginBotom: "0px !important", margin: "0" }}
                  className={''}
                >
                  <p
                    style={{ display: 'inline'}}
                   
                  >
                    {seg.name}{' '}
                  </p>             

                                  
                 <span style={{ float: 'right' }}>
                    <span
                      className="icons small-text"
                      onClick={ () => deleteSegment(seg.id)}
                      style={{color: "#e43d40", fontSize: "12px", marginRight: "3px",  visibility: "hidden" }}
                    >
                      <i className="trash alternate outline icon"></i>
                    </span>

                    <span
                      className="icons small-text"
                      onClick={() => updateSegment(seg.id, seg) }
                      style={{color: "#FFAE42", fontSize: "12px",  marginRight: "3px",  visibility: "hidden" }}
                    >
                       <i className="edit outline icon"></i>
                    </span>

                    <span
                      className="small-text"                                         
                    >
                      {seg.leads?.length}{' '}
                    </span>
                  </span>                  
                </label>
              </div>
            )
          })}
             </>
           ) }

          </>


          ) : null




        }

          

          

         

          <div className="sideHeader" onClick={ () => setTagShow(!tagShow) }>
            <i aria-hidden="true" className="chevron left icon"></i><p style={{ fontSize: '16px', display: "inline" }}>Tags</p>
          </div>

           { tagShow && (
             <>
              {allTags.map( (seg, index) => {
            return (
              <div onClick={() => updateTagsLead(seg.tag)} key={index} className={ seg.tag === activeTagsLead ? "hover-holder active-tag" : "hover-holder" } style={{   padding: "4px 8px", borderRadius: "4px", marginBottom: "4px",  transition: "0.6s" }}>
                <label
                  style={{ padding: '0px', fontSize: '14px', marginBotom: "0px !important", margin: "0" }}
                  className={''}
                >
                  <p
                    style={{ display: 'inline'}}
                    
                  >
                    {seg.tag}
                  </p>                 

                                  
                 
                </label>
              </div>
            )
          })}
             </>
           ) }

        </div>      



        <div className="myleadWrapper">
          <div className="lead-container" style={{paddingTop: "0px"}}>
            <Dimmer active={isLoading} style={{ position: 'fixed' }}>
              <Loader />
            </Dimmer>
            <Title
              data={title}
              setMenu={setMenu}
              menuOpen={menuOpen}
              detailPopUp={detailPopUp}
              schedulePopUp={schedulePopUp}
            />
          </div>
          <AllCalls
            sideList={sideListU}
            setSideList={setSideListU}
            activeCallType={activeCallType}
            activeSegmentsLead={activeSegmentsLead}
            activeSeg={activeSeg}
            activeTagsLead={activeTagsLead}
            setSegmentAdded={setSegmentAdded}
            segmentAdded={segmentAdded}
            allTags={allTags}  
            setAllTags={setAllTags}
            updateTagCount={setTagCount}                  
          />
          <div className="metting-table"></div>{' '}
        </div>
      </div>

      <Modal
        className="local-number-modal"
        open={modal}
        onClose={e => setModal(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this lead segment ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButton
                onClick={() => setModal(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButton
                onClick={removeSegment}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>

       <Modal
        className="local-number-modal"
        open={updateModal}
        onClose={e => setUpdateModal(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              New Segment Label Name 
            </p>

            <Input                    
                    type="text"
                    name="state"
                    title="State"
                    required={true}  
                    onChange={ e => setLabelName(e.target.value) } 
                      value={labelName}                
                    />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButton
                onClick={() => setUpdateModal(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButton
                onClick={updateSegmentData}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
}


export default MyLeads
