import React, { useState } from 'react'
import { Accordion } from 'semantic-ui-react'

function Insights({ data }) {
  const enrichment = data?.enrichment_user_info
  const [activeIndex, setActiveIndex] = useState(0)

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const activeIndexs = activeIndex
    const newIndex = activeIndexs === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <>
      <Accordion className="Lead_wrapper" fluid styled>
        <Accordion.Title
          active={activeIndex === 4}
          index={4}
          onClick={handleClick}
        >
          Insights Info
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 4}>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Company</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Location</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Type</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Role</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Industry</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Revenue</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Employees</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Hq</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Website</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Ip address</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{enrichment?.company_domain}</b>
              </p>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
    </>
  )
}

export default Insights
