import React  from 'react'
import  {useHistory } from 'react-router-dom'

import CommonButton from '../common/CommonButtons'

import googlePlayStore from '../assets/images/google-play-store.png'




const Landing = props => {
    const history = useHistory()



 
   

    return (
        <>
            
            <div className="login">
                <div className="login-box mobile-landing">
                    <h3>Please login from a desktop device to access the web dashboard.  Alternatively please use the below for mobile devices.</h3>
                    <CommonButton onClick={() => window.location.replace('http://dialer.limecall.com/')}>Login to Mobile Dialer</CommonButton>
                    <img onClick={() => window.location.replace('https://play.google.com/store/apps/details?id=com.limecall&hl=en&gl=US')} src={googlePlayStore} alt="play-store" />
                </div>{' '}
            </div>{' '}
        </>
    )
}

export default Landing
