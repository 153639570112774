import React, { Component } from 'react'

import CommonButtons from '../../common/CommonButtons'
import CommonInput from '../../common/CommonInput'
import NodeToggle from '../../common/NodeToggle3'

import axios from 'axios'
import { Dimmer, Loader } from 'semantic-ui-react'
import { CommonNotify } from '../../common/CommonNotify'
//redux
import { connect } from 'react-redux'
import { getCallForwadingSettings } from '../../actions/settings'

const apiToken = localStorage.getItem('access_token')

const items = [
  {
    itemTitle: 'Timeslot interval',
    itemDesc:
      'Intervals between timeslots where customers can choose to schedule calls.',
    name: 'inputTimeslotInterval',
    status: true,
    active: true,
    value: '',
    callId: 'start_time_increment_id',
    callref: 'start_time_increment'
  },
  {
    itemTitle: 'Minimum Notice Time',
    itemDesc: 'Limit the number of calls allowed for each timeshot',
    name: 'inputCallsPerTimeslot',
    status: false,
    active: true,
    value: '',
    callId: 'minimum_notice_time_id',
    callref: 'minimum_notice_time'
  },
  {
    itemTitle: 'Schedule buffer time',
    itemDesc: 'Buffer time before a call can be scheduled',
    name: 'inputBufferTime',
    status: false,
    active: false,
    value: '',
    callId: 'buffer_time_id',
    callref: 'buffer_time'
  }
]

class ScheduleSettings extends Component {
  state = {
    data: {
      inputTimeslotInterval: '',
      inputCallsPerTimeslot: '',
      inputBufferTime: '',
      inputScheduleDays: '',
      inputTimeFormat: ''
    },
    itemsData: items,
    apiCalled: false,
    isValue: false,
    isLoading: true
  }

  componentDidUpdate(prevProps) {
    if (this.props.callForwadingSettings !== prevProps.callForwadingSettings) {
      this.setState({isLoading: false})
      this.setSetting(this.props.callForwadingSettings.data.meeting_settings)
    }
  }

  componentDidMount = () => {
    if (this.props.callForwadingSettings) {
      this.setState({isLoading: false})
      this.setSetting(this.props.callForwadingSettings.data.meeting_settings)
    } else {
      this.fetchSetting()
    }
  }

  updateDataRef = (DataState, DataRef) => {
    let itemsData = [...this.state.itemsData]

    if (DataRef == 'start_time_increment') {
      itemsData[0].status = DataState
    } else if (DataRef == 'minimum_notice_time') {
      itemsData[1].status = DataState
    } else if (DataRef == 'buffer_time') {
      itemsData[2].status = DataState
    }

    this.setState({ itemsData, isValue: true })
  }

  onChange = (e, i) => {
    const { name, value } = e.target
    if (!value) {
      this.setState({ isValue: false })
    } else {
      this.setState({ isValue: true })
    }
    let itemsData = [...this.state.itemsData]
    itemsData[i].value = value
    this.setState({ itemsData })
  }

  onSave = async e => {
    this.props.loading(true)
    this.setState({ isLoading: true })

    

    let itemsData = [...this.state.itemsData]
     const w_id = await localStorage.getItem('widget_id');

    const data = {
      id: w_id,
      start_time_increment_status: itemsData[0].status,
      start_time_increment_value: itemsData[0].value,
      minimum_notice_time_status: itemsData[1].status,
      minimum_notice_time_value: itemsData[1].value,
      buffer_time_status: itemsData[2].status,
      buffer_time_value: itemsData[2].value
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/set-meetings-settings`
    axios
      .post(url, data)
      .then(res => {
        this.props.loading(false)
        this.setState({ isLoading: false })
        if (res.data.message[0] == 'Successfully') {
          CommonNotify('Schedule Setting Updated', 'success')
        }
      })
      .catch(error => {
        this.props.loading(false)
        this.setState({ isLoading: false })
        CommonNotify('Cant Update Schedule Setting')
      })
  }

  setSetting = data => {
    let itemsData = [...this.state.itemsData]
    itemsData[0].status = data.start_time_increment_status
    itemsData[0].active = data.start_time_increment_status
    itemsData[0].value = data.start_time_increment_value

    itemsData[1].status = data.minimum_notice_time_status
    itemsData[1].active = data.minimum_notice_time_status
    itemsData[1].value = data.minimum_notice_time_value

    itemsData[2].status = data.buffer_time_status
    itemsData[2].active = data.buffer_time_status
    itemsData[2].value = data.buffer_time_value

    this.setState({ itemsData, isLoading: false }, () => {
      this.setState({ apiCalled: true })
    })
  }

  fetchSetting = async () => {
    this.setState({isLoading: true})

    this.props.getCallForwadingSettings()
    /* this.setState({ isLoading: true })
    const w_id = await localStorage.getItem('widget_id')
    this.props.loading(true)
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${w_id}`

    axios
      .get(url)
      .then(res => {
        this.props.loading(false)
        if (res.data.data.meeting_settings) {
         
          this.setSetting(res.data.data.meeting_settings)
        }
      })
      .catch(error => {
        this.props.loading(false)
        this.setState({ isLoading: false })
        CommonNotify('Cant Update Schedule Setting')
      }) */
  }

  render() {
    return (
      <>
        <Dimmer style={{ position: 'fixed' }} active={this.state.isLoading}>
          <Loader active={this.state.isLoading} />
        </Dimmer>
        <div className="call-schedules">
          {this.state.itemsData.map((value, index) => (
            <div className="holder-items" key={index}>
              <div className="holder-text">
                <h2 className="item-title">{value.itemTitle}</h2>
                <p className="subtext item-desc">{value.itemDesc}</p>
              </div>

              <div className="holder-toggle">
                {this.state.apiCalled && (
                  <NodeToggle
                    handleDataRef={this.updateDataRef}
                    key={index}
                    dataToggle={value}
                    activeDefault={value.active}
                  />
                )}
              </div>

              <div className="holder-input">
                <CommonInput
                  style={{ minWidth: '70px' }}
                  onChange={e => this.onChange(e, index)}
                  name={value.name}
                  background="gray"
                  type="number"
                  value={value.value}
                  disabled={value.status ? false : true}
                />
              </div>

              <div className="holder-text-static">mins</div>
            </div>
          ))}
          {this.state.isValue && (
            <>
              <CommonButtons
                onClick={this.onSave}
                type="submit"
                content="Save"
                background="blue"
              />
              <CommonButtons
                onClick={this.fetchSetting}
                type="reset"
                content="Cancel"
                background="grey"
              />
            </>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  callForwadingSettings: state.settings.callForwadingSettings,
});

export default connect(
  mapStateToProps,
  { getCallForwadingSettings }
)(ScheduleSettings)
