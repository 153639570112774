import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
export default function UseCaseInfo({ setFlowType, setInitialPage }) {
  const handleSetType = type => {
    setFlowType(type || null)
    localStorage.setItem('flowType', type)
    setInitialPage(false)
  }

  return (
    <div className="h-100 bg-white">
      <div
        // className="border "
        // style={{ borderBottom: '1px solid rgba(34,36,38,.15)' }}
      >
        <h4 className="setUpHead mb-7" style={{paddingBottom:'15px'}}>Whats your main goal with Limecall?</h4>

        <ul className="use-cases">
          <li className="call-back" onClick={() => handleSetType('callback')}>
            Click to call, lead routing and Callback automation{' '}
            <ArrowRightAltIcon />
          </li>
          <li
            className="call-tracking"
            onClick={() => handleSetType('callTraking')}
          >
            Call tracking for website and ad campaigns <ArrowRightAltIcon />
          </li>
          <li
            className="virtual-phone"
            onClick={() => handleSetType('virtualPhone')}
          >
            Buy virtual phone numbers to receive calls and SMS{' '}
            <ArrowRightAltIcon />
          </li>
          {/* <li onClick={() => handleSetType('none')}>None of the above</li> */}
        </ul>
      </div>
    </div>
  )
}
