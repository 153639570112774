import React, { useState, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import { useGetUserIdQuery } from '../features/api/apiSlice'
import {
    Button,
    Dropdown,
    Header,
    Modal,
    Input,
    TextArea
} from 'semantic-ui-react'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { sendMessageUser } from '../config/leadAPI'
import CommonButtons from './CommonButtons'
import Axios from 'axios'
import { CommonNotify } from './CommonNotify'
import SavedRepliesModal from './SavedRepliesModal'
import moment from 'moment'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import TimePicker from 'rc-time-picker'
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker'

const Reschedule = ({ open, isModalClose, id ,lead,fetchData}) => {

    const [value, setValue] = useState(moment())
    const [agentList, setAgentList] = useState([])
const [Agent, setAgent] = useState(null)
const [dateTime, setDateTime] = useState({})
    const [date, setDate] = useState('')
    const apiKey = localStorage?.getItem('data-api-key')
    const [time, setTime] = useState('')


    const handleSubmit = async (second) => {

        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${id}/reschedule`
        const res = await Axios.post(url, {
            schedule_date: moment(date).format("YYYY-MM-DD"),
            schedule_time: time
        }).catch((error)=>{
            CommonNotify('Something went wrong')
            isModalClose()
        })
        if (res?.status===200){
 fetchData && fetchData(1, 'scheduled')
            CommonNotify('Resheduled sucessfully','success')
            isModalClose()
        }
        
        

    }
    useEffect(() => {
        getAgentList()
    }, [])
    
    const getAgentList = async () => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
        await Axios.get(url)
            .then(res => {
                if (res?.data?.data) {

                    setAgentList([res?.data?.data?.admin, ...res?.data?.data?.members] || [])
                }
            })
            .catch(error => {

            })
    }
    const getDateTime = async (id) => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/widget/modify-slots/${apiKey}/GMT+05:30?member_id=${id}`
        await Axios.get(url)
            .then(res => {
                if (res?.data?.data) {
                    setDateTime(res?.data?.data)
                }
            })
            .catch(error => {
            })
    }
    return (
        <Modal
            className="send-message-modal reschedule-modal"
            onClose={isModalClose}
            closeIcon
            open={open}
            size="tiny"
        >


            <Header content="Reschedule Call" />
            <Modal.Content style={{ padding: '30px' }}>
                <div className="send-message__dropdown">
                    <label>Call From *</label>
                    <Dropdown
                        name="from"
                        placeholder="Select Agent"
                        selection
                        options={agentList?.flat().map(option => ({
                            key: option?.id,
                            text: `${option?.first_name} ${option?.last_name}`,
                            value: option?.id,
                            id: option?.id,
                            code: option?.code
                        }))}
                        onChange={(e, data) => {
setAgent(data.value)
                            getDateTime(data.value)
                            // setSendMsgData({
                            //     ...sendMsgData,
                            //     from: data?.value,
                            //     agentId: data?.value || data?.id
                            // })
                            // getDateTime(data?.value || data?.id)
                        }
                        }
                    />
                </div>
         
            
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="send-message__input w-100">
                            <label>Date</label>
                            <Dropdown
                                name="from"

                                placeholder="Select Date"
                                selection
                                options={[...Object?.keys(dateTime)?.map((obj, index) => ({
                                    key: index,
                                    text: obj,
                                    value: obj,
                                    id: index
                                }))]

                                }
                                onChange={(e, data) =>
                                    setDate(data?.value)
                                }
                            />
                        </div>
                    </div>
                        <div className='col-md-6'>
                        <div className="send-message__input w-100">
                            <label>Time</label>
                            <Dropdown
                                name="from"
                                placeholder="Select Time"
                                selection
                                options={date ? [...dateTime?.[date]?.map((obj, index) => ({
                                    key: index,
                                    text: obj,
                                    value: obj,
                                    id: index
                                }))] : []

                                }
                                onChange={(e, data) =>
                                    setTime(data?.value)
                                }
                            />
                        </div>
                        </div>
                </div>

            </Modal.Content>
            <Modal.Actions className="send-message__btn">
                <Button onClick={isModalClose} className="send-message__btn-grey">
                    close
                </Button>

                <CommonButtons
                    content="Reschedule"
                    btnClass="send-message__btn-blue"
                    background="#007bff"
                    style={{ color: 'white' }}
                    onClick={() => handleSubmit()}

                />
                {/* <CommonButtons
          content="Cancel"
          btnClass="send-message__btn-blue"
          background="#007bff"
          style={{ color: 'white' }}
        //   onClick={e => sendMessageUser(sendMsgData, isModalClose)}
        /> */}
            </Modal.Actions>
        </Modal>
    )
}
export default Reschedule
