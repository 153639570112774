import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FormGroup } from 'react-bootstrap'
import { Button, Dimmer, Input, Loader } from 'semantic-ui-react'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import { emailValidation } from '../../common/Regex'
import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
export default function InviteTeamMate(props) {
  const [emails, setEmails] = useState([{}, {}, {}])
  const [emailsError, setEmailsError] = useState({})
  const [refreshFlag, setRefreshFlag] = useState(false)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (refreshFlag) {
      setRefreshFlag(false)
    }
  }, [refreshFlag])

  const sendInvites = async value => {
    const payload = {
      full_name: value?.map(list => {
        return `${list?.name} ${list?.name}`
      }),
      email: value?.map(list => {
        return list?.email
      }),
      role: value?.map(list => {
        return 20
      })
    }
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/dashboard-create-member`
    Axios.post(url, payload)
      .then(res => {
        CommonNotify('Invites send susscessfully.')
        setLoader(false)
        return props?.next()
      })
      .catch(error => {
        setLoader(false)
        CommonNotify(
          error?.response?.data?.errors[0] || 'Something went to wrong!'
        )
      })
  }

  const handleChange = (name, value, index) => {
    let cloneError = { ...emailsError }
    const error = emailValidation(value)
    if (error || !value?.trim()) {
      delete cloneError[`email${index + 1}`]
    } else {
      cloneError[`email${index + 1}`] = `Please enter valid ${name}`
    }
    setEmailsError(cloneError)
    let cloneEmail = emails?.slice()
    cloneEmail[index] = { [name]: value }
    cloneEmail[index]['name'] = value?.split('@')?.[0] || ''
    setEmails(cloneEmail)
  }

  const oridinalNumber = value => {
    if (+value) {
      const first = [1, 21, 31]
      const second = [2, 22]
      const third = [3, 23]
      const fouth = [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        24,
        25,
        26,
        27,
        28,
        29,
        30
      ]
      if (first.includes(+value)) {
        return `st`
      }
      if (second.includes(+value)) {
        return `nd`
      }
      if (third.includes(+value)) {
        return `rd`
      }
      if (fouth.includes(+value)) {
        return `th`
      }
    } else {
      return ''
    }
  }

  const handleContinue = async () => {
    const list = emails?.filter(list => {
      return list?.name && list?.name
    })
    if (emailsError && Object.keys(emailsError)?.length) {
      let error = false
      const data = list?.map((info, index) => {
        if (!error) {
          if (emailsError[`email${index + 1}`]) {
            error = true
            CommonNotify(
              `Please enter valid ${index + 1}${oridinalNumber(
                index + 1
              )} field email`
            )
            return
          }
        }
      })
      return
    }
    if (emails && emails?.length && list && list?.length) {
      sendInvites(list)
    } else {
      return props?.next()
    }
  }

  const handleAddMore = () => {
    let cloneEmail = emails?.slice()
    cloneEmail.push({})
    setEmails(cloneEmail)
  }

  const handleDeleteRow = index => {
    let cloneEmail = emails?.slice()
    cloneEmail.splice(index, 1)
    setEmails(cloneEmail)
    setRefreshFlag(true)
    let errorValue = {}
    if (cloneEmail?.length) {
      const data = cloneEmail.map((list, index) => {
        const error = emailValidation(list?.email)
        if (error || !list?.email?.trim()) {
          return null
        } else {
          return (errorValue[`email${index + 1}`] = `Please enter valid email`)
        }
      })
      setEmailsError(errorValue)
    } else {
      setEmailsError({})
    }
  }

  return (
    <>
      <div className="assigned-screen text-left">
        <Dimmer active={loader}>
          <Loader />
        </Dimmer>
        <h2>Invite Your Team Members</h2>
        <div className="assigned-inner mt-0">
          <h6>Invite by email</h6>
          {emails &&
            !refreshFlag &&
            emails?.length > 0 &&
            emails?.map((list, index) => {
              return (
                <FormGroup className="d-flex">
                  <Input
                    fluid
                    className="w-100"
                    value={list?.[`email`]}
                    placeholder="invite@gmail.com"
                    onChange={e => handleChange(`email`, e?.target?.value, index)}
                  />
                  <DeleteForeverIcon
                    fontSize="large"
                    className="mt-1 ml-1 cursor-pointer"
                    onClick={() => handleDeleteRow(index)}
                  />
                </FormGroup>
              )
            })}

          <Button
            primary
            type="button"
            className="btn-blue"
            onClick={handleAddMore}
          >
            <AddIcon /> Add More
          </Button>
        </div>
      </div>

      <div
        className="quicksetup-footer footer-button mt-auto"
        style={{ justifyContent: 'space-between' }}
      >
        <CommonButtons
          content="Back"
          background="grey"
          style={{ width: '100px' }}
          btnClass="btn-modal-style"
          onClick={props.prev}
        />

        <CommonButtons
          content="Continue"
          background="blue"
          btnClass="btn-modal-style"
          loading={false}
          onClick={handleContinue}
        />
      </div>
    </>
  )
}
