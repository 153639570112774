import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField
} from '@mui/material'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TabPane, Tab, Dropdown } from 'semantic-ui-react'
import CommonButtons from '../common/CommonButtons'
import AddIcon from '@mui/icons-material/Add'
import CommonInput from '../common/CommonInput'

const source = [
  {
    key: 'allSource',
    text: 'All (or remaining) Digital',
    value: 'allSource'
  }
]
const TrackingCode = () => {
  const [swap, setSwap] = useState('')
  const [attribution, setAttribution] = useState('')
  const panes = [
    {
      menuItem: 'Number Swapping',
      render: () => (
        <TabPane className="tracking-code-middle" attached={false}>
          <p>Swap Type:</p>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={swap}
            name="radio-buttons-group"
            onChange={(e, value) => setSwap(value)}
            style={{ display: 'flex' ,flexDirection:'row' }}
          >
            <FormControlLabel
              value="phone"
              control={<Radio />}
              label="Phone Number"
            />
            <FormControlLabel
              value="span"
              control={<Radio />}
              label="Span Tag"
            />
          </RadioGroup>

          {swap === 'phone' ? (
            <div className="m-3">
              <p>Swap Targets (phone numbers on your site to replace):</p>
              <Autocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                options={[]}
                getOptionLabel={(option) => option.title}
                // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                renderInput={(params) => (
                  <TextField {...params} label="Phone numbers as seen on site" placeholder="Favorites" />
                )}
                sx={{ width: '500px' }}
              />
            </div>
          ) : swap === 'span' ? (
            <div className="m-3">
              <p>Swap Targets (html span tags on site to be replaced):</p>
              <div
                className="p-2"
                style={{ backgroundColor: '#e8e8e8' }}
              >{`<span class="nimbata_number"> 555-555-5555 </span>`}</div>
            </div>
          ) : (
            ''
          )}
          <CommonButtons background={'blue'}>
            <AddIcon /> Add swap group
          </CommonButtons>
        </TabPane>
      )
    },
    {
      menuItem: 'Attribution',
      render: () => (
        <TabPane attached={false} className="tracking-code-middle" >
          
          <Grid container rowSpacing={3}>
            <Grid item md={6}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={attribution}
              name="radio-buttons-group"
              onChange={(e, value) => setAttribution(value)}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                value="1st"
                control={<Radio />}
                label="1st Click"
              />
              <FormControlLabel
                value="last"
                control={<Radio />}
                label="Last Click"
              />
            </RadioGroup>
</Grid>
              <Grid item md={6}>

            {attribution === '1st' ? (
              <div>
                <p>Source Override</p>
                <Dropdown
                  placeholder={`Select Sources`}
                  fluid
                  selection
                  options={source}
                />
              </div>
            ) : (
              ''
            )}

            </Grid >
            </Grid >
        </TabPane>
      )
    },
    {
      menuItem: 'Storage',
      render: () => <TabPane attached={false} className="tracking-code-middle" >
        <Grid container>
          <Grid item md={2}>
            <p>Create cookie?</p>
            <Switch/>
          </Grid>
          <Grid item md={5}>
            <p>Max storage days:</p>
<CommonInput placeholder='Default:21' type="number" style={{width:'90%'}}/>
          </Grid>
          <Grid item md={5}>
            <p>Domain:</p>
            <CommonInput placeholder='Default:empty' type="text" style={{ width: '90%' }} />
          </Grid>
          
        </Grid>
        
      </TabPane>
    },
    {
      menuItem: 'Page Exclusions',
      render: () => <TabPane attached={false} className="tracking-code-middle" >
        <p>Page paths to exclude:</p>

        <Autocomplete
          multiple
          limitTags={2}
          id="multiple-limit-tags"
          options={[]}
          getOptionLabel={(option) => option.title}
          // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
          renderInput={(params) => (
            <TextField {...params} label="Path to exclude" placeholder="Favorites" />
          )}
          sx={{ width: '500px' }}
        />
      </TabPane>
    },
    {
      menuItem: 'Server Side Tagging',
      render: () => <TabPane attached={false} className="tracking-code-middle" >
        
        <p>Use custom cookie for GA CID?</p>
        <Switch />

      </TabPane>
    },
    {
      menuItem: 'Advanced',
      render: () => <TabPane attached={false} className="tracking-code-middle" >
        <Grid container>
          <Grid item md={2.5}>
            <p>Phone/Text Redaction:</p>
            <CommonInput type="text" placeholder='Redaction' style={{ width: '90%' }} />

          </Grid>
          <Grid item md={2.5}>
            <p>Remove CC prefix from links?</p>
            <Switch />
          </Grid>
          <Grid item md={2.5}>
            <p>Monitor for HTML changes?</p>
            <Switch />

          </Grid>
          <Grid item md={2.5}>
            <p>Single Page Site:</p>
            <Switch />
            
          </Grid>
          <Grid item md={2}>
            <p>Use Eval:</p>
            <Switch />

          </Grid>

        </Grid>

      </TabPane>
    }
  ]
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            backgroundColor: '#e8e8e8',
            padding: '16px',
            display: 'flex',
            justifyContent: 'center',
            '& .MuiAccordionSummary-content': {
              justifyContent: 'center'
            }
          }}
        >
          {` <script type="text/javascript" src="//cdn.dni.nimbata.com/10307015139.min.js"></script>`}
        </AccordionSummary>
        <AccordionDetails style={{ padding: '20px' }}>
          <>
            <p>{`This is the Form tracking code for your web properties. Copy and paste this code as the last item before the </body> of every page you want tracked.`}</p>
            <p>
              If you already have Tag Manager on your page, simply include the
              snippet below as a new tag. Alternatively, you may incorporate the
              code using our Wordpress plug-in. Learn more about it here.
            </p>
          </>
        </AccordionDetails>
      </Accordion>
      <div style={{ marginTop: '50px' }}>
        <Tab menu={{ pointing: true }} panes={panes} />
      </div>
      <div style={{ marginTop: '50px',border:'1px solid #acacac' }}>
        <p style={{ borderBottom: '1px solid #acacac', padding: '5px', backgroundColor:'#ebf2ff' , fontSize:'15px'}}>Online sources being tracked:</p>
        <div style={{padding:'30px',textAlign:'center'}}>
          <CommonButtons background={'blue'} style={{marginBottom:'15px'}}>
            All (or remaining) Digital
          </CommonButtons>
          <p>1 DNI-ready tracking numbers (of 2 in total)</p>
        </div>
      </div>
    </div>
  )
}

export default TrackingCode
