import React, { Component } from 'react'
import { setDefaultLocale } from 'react-datepicker'
import Toggle from '../../common/CommonToggleClass'
import NodeToggle from '../../common/NodeToggle'
import CommonWorkingHour from '../../common/NumberWorkingHour'
import updateHourStatus from '../../config/togglehoursStatus'
import TimezonePicker from 'react-timezone'
import updateProfile from '../../config/updateProfile'
import { Dimmer, Loader } from 'semantic-ui-react'
import BreakTime from './BreakTimeNumber'
import DayOff from './DayOffNumber'
import axios from 'axios'

//redux
import { connect } from 'react-redux'
import { getProfile, getWidgetDays } from '../../actions/settings'

const apiToken = localStorage.getItem('access_token')

const weekDays = [
  {
    key: 1,
    text: 'Sunday',
    value: 2,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 2,
    text: 'Monday',
    value: 3,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: true
  },
  {
    key: 3,
    text: 'Tuesday',
    value: 4,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 4,
    text: 'Wednesday',
    value: 5,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 5,
    text: 'Thursday',
    value: 6,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 6,
    text: 'Friday',
    value: 7,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  },
  {
    key: 7,
    text: 'Saturday',
    value: 1,
    from: '00:00',
    to: '23:59',
    isDayOff: false,
    isApplyToAll: false
  }
]

class WorkingHoursNumber extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updated: 0,
      activeToggle: false,
      selectedTimeZone: 'UTC',
      working_from: '',
      working_to: '',
      day_name: '',
      day_id: '',
      time_zone: '',
      isLoading: true,
      javaScriptSnippet: false,
      business_hours_status: 0,
      statusLoaded: false,
      days: weekDays,
      data: [],
      widget: null,
      selectedDay: 0,
      dayList: [],
      daysSelected: [],
      isReminderButtonDisplay: false,
      isChecked: false,
      isCheckedBoxData: false,
      branding: false,
      isBranding: false,
      weekDaysValues: [],
      firstRun: true
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      this.setState({
        business_hours_status: this.props.profile.data.working_hours_status,
        selectedTimeZone: this.props.profile.data.time_zone,
        isLoading: false
      })
    }

    if (this.props.widgetDays !== prevProps.widgetDays) {
      let results = [...this.props.widgetDays.data]
      results.reverse()
      const dts = this.state.days
      const rt = results.map((val, key) => {
        dts[key].value = val.id
        dts[key].id = val.id
        dts[key].text = val.name

        return dts[key]
      })
      this.setState({ days: rt })
    }
  }

  componentDidMount() {
    this.getProfileData()
    this.fetchDays()
  }

  setUpdated() {
    let { updated } = this.state
    this.setState({ updated: updated + 1 })
  }

  fetchDays = () => {
    if (this.props.widgetDays) {
      let results = [...this.props.widgetDays.data]
      results.reverse()
      const dts = this.state.days
      const rt = results.map((val, key) => {
        dts[key].value = val.id
        dts[key].id = val.id
        dts[key].text = val.name

        return dts[key]
      })
      this.setState({ days: rt })
    } else {
      this.props.getWidgetDays()
    }
    /* const apiToken = localStorage.getItem('access_token')
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-days`
    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          
          let results = res.data.data
          results.reverse()
          const dts = this.state.days
          const rt = results.map((val, key) => {
            dts[key].value = val.id
            dts[key].id = val.id
            dts[key].text = val.name

            return dts[key]
          })
          this.setState({ days: rt })
        }
      })
      .catch(error => {
       
        if (error?.response?.status === 401) {
          
          return
        }
        if (!error?.response) {
         
        }
      }) */
  }

  getProfileData = () => {
    if (this.props.profile) {
      this.setState({
        business_hours_status: this.props.profile.data.working_hours_status,
        selectedTimeZone: this.props.profile.data.time_zone,
        isLoading: false
      })
    } else {
      this.setState({ isLoading: true })
      this.props.getProfile()
    }
    /* updateProfile()
      .then(async res => {
        const setLocalValue = await res.data.data.working_hours_status
        this.setState({
          business_hours_status: setLocalValue,
          selectedTimeZone: res.data.data.time_zone,
          isLoading: false
        })
      })
      .catch(err => {
        this.setState({ isLoading: false })
      }) */
  }

  async componentWillReceiveProps(nextProps) {
    const { business_hours_status, firstRun } = this.state
  }

  callToggle = {
    callTitle: 'JavaScript Snippet',
    callDesc:
      'When turned on, our system is permitted to make automated calls to your customers when requited',
    callId: 'toogleJavaScriptSnippet',
    callref: 'javaScriptSnippet'
  }
  brandingData = {
    callTitle: 'branding',
    callDesc:
      'When turned on, our system is permitted to make automated calls to your customers when requited',
    callId: 'branding',
    callref: 'branding'
  }
  handleToggleData = async toggleData => {
    this.setState({
      business_hours_status: toggleData
    })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.props.number}/working-hours-status`
    const data = {
      business_hours_status: toggleData ? 1 : 0
    }

    axios
      .put(url, data)
      .then(async res => {
        //
      })
      .catch(err => console.log())
  }

  handleToggleDataBranding = toggleData => {
    if (toggleData) {
      this.setState({
        isBranding: true
      })
    }
  }

  selectTimeZone = e => {
    this.setState({
      selectedTimeZone: e
    })
  }

  setLoader = async loadingState => {
    this.setState({ isLoading: loadingState })
  }

  render() {
    const { business_hours_status } = this.state
    const URL = {
      get: 'user/working-hours',
      post: 'user/working-hours',
      delete: 'user/working-hours'
    }

    const content = {
      header: 'Number availability',
      p: `Set your numbers's business hours `
    }

    return (
      <>
        <Dimmer style={{ position: 'fixed' }} active={this.state.isLoading}>
          <Loader></Loader>
        </Dimmer>
        <div>
          <section className="card-manage-number" id="business-hours">
            <div className="availability-toggle">
              <NodeToggle
                handleDataRef={this.handleToggleData}
                activeDefault={this.state.business_hours_status}
                dataToggle={{
                  callTitle: 'Business hours',
                  callDesc:
                    'Set office hours during which people can call your phone number. Outside of the office hours, the caller will hear an announcement.',
                  callId: 'toggleSMSShowBtn',
                  callRef: 'ShowSMSButton'
                }}
              />

              {/* <p className="title-label-working"></p> */}
            </div>
            {/* {business_hours_status ? (     */}
            <div>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  marginTop: 10
                }}
              >
                {this.state.business_hours_status ? (
                  <>
                    <p
                      style={{
                        marginRight: '20px',
                        fontWeight: '700',
                        color: '#6b5e5e',
                        fontSize: '16px',
                        display: 'flex',
                        marginTop: 5
                      }}
                    >
                      {' '}
                      Timezone{' '}
                    </p>{' '}
                    <div className="timezone-picker" style={{ width: '60%' }}>
                      <TimezonePicker
                        value={this.state.selectedTimeZone}
                        onChange={this.selectTimeZone}
                        inputProps={{
                          placeholder: 'Select Timezone...',
                          name: 'timezone'
                        }}
                      />{' '}
                    </div>{' '}
                  </>
                ) : null}
              </div>

              {this.state.business_hours_status ? (
                <CommonWorkingHour
                  handleStatus={this.handleToggleData}
                  number={this.props.number}
                  days={this.state.days}
                  nextStep={this.props.nextStep}
                  increaseStep={this.props.increaseStep}
                  btnUpdated={this.state.updated}
                  setUpdated={this.setUpdated}
                  setLoading={this.setLoader}
                  timeZone={this.state.selectedTimeZone}
                  content={content}
                  isLoading={this.state.isLoading}
                  isWidgets={true}
                  URL={URL}
                />
              ) : null}
           
               
            </div>

            {this.props.number && this.state.business_hours_status ? (
              <>
                <DayOff loading={this.setLoader} number={this.props.number} />
                {/* <BreakTime
                  number={this.props.number}
                  setLoading={this.setLoader}
                  isLoading={this.setLoader}
                  loading={this.setLoader}
                  days={this.state.days}
                /> */}
              </>
            ) : null}
          </section>
          <selection className="card-manage-number">
          <div  className="availability-toggle">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <h6>After hour voicemail audio</h6>
                      <span className="call-description">
                        What is played when calls are missed after business
                        hours
                      </span>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="upload-btn-wrapper">
                        <button className="btn btn-white">Change</button>
                        <input
                          type="file"
                          onChange={this?.props?.handleBeforeVoiceFile}
                          className="form-control"
                          name="upload_file"
                          style={{ padding: '2px' }}
                          accept=".wav"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <audio
                      className="w-100"
                      controls
                      src={this?.props?.beforeVoiceMail}
                      type="*"
                    />
                  </div>
                  <div className="user_assing_btn">
                    {this?.props?.voiceMailUpdated && (
                      <button
                        onClick={this?.props?.saveBeforeVoiceMail}
                        className="ui button btn-grey"
                        style={{
                          backgroundColor: '#0071eb',
                          marginTop: 10,
                          color: '#ffffff'
                        }}
                      >
                        {' '}
                        <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                      </button>
                    )}
                  </div>
                </div>
                </selection>
         
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.settings.profile,
  widgetDays: state.settings.widgetDays
})

export default connect(mapStateToProps, { getProfile, getWidgetDays })(
  WorkingHoursNumber
)
