export const showFollowUpBrandName = [
  {
    callTitle: '',
    callDesc: '',
    callId: 'toggleFollowShowBtn',
    callRef: 'ShowFollowButton'
  },
  {
    callTitle: 'Voice Message',
    callDesc:
      'Message to play to lead in the beginning of the call. (only wav extension allowed)',
    callId: 'toggleVoiceMessageShowBtn',
    callRef: 'ShowVoiceMessageButton'
  },
  {
    callTitle: '',
    callDesc: '',
    callId: 'toggleThankMessageShowBtn',
    callRef: 'ShowThankMessageButton'
  },
  {
    callTitle: '',
    callDesc: '',
    callId: 'toggleMissedCallShowBtn',
    callRef: 'ShowMissedCallButton'
  },
  {
    callTitle: '',
    callDesc: '',
    callId: 'toggleScheduleCallReminderMessageShowBtn',
    callRef: 'ShowScheduleCallReminderMessageButton'
  },
  {
    callTitle: '',
    callDesc: '',
    callId: 'toggleScheduleCallMessageShowBtn',
    callRef: 'ShowScheduleCallmessageButton'
  }
]

export const showQualification = [
  {
    callTitle: 'Full name',
    callId: 'fullNameQualification',
    callRef: 'ShowfullNameQualificationButton'
  },
  {
    callTitle: 'Email',
    callId: 'emailQualification',
    callRef: 'ShowemailQualificationButton'
  },
  {
    callTitle: 'Team',
    callId: 'teamQualification',
    callRef: 'ShowteamQualificationButton'
  },
  {
    callTitle: 'Enabled',
    callId: 'enabledQualification',
    callRef: 'ShowenabledQualificationButton'
  },
  {
    callTitle: 'Required',
    callId: 'requiredQualification',
    callRef: 'ShowrequiredQualificationButton'
  },
  {
    callTitle: 'Custom Fields',
    callId: 'customFieldsQualification',
    callRef: 'ShowCustomFieldsQualificationButton'
  }
]

export const showWidget = [
  {
    callTitle: 'Show Widget button',
    callDesc: '',
    callId: 'toggleShowBtn',
    callRef: 'ShowButton'
  }
]
export const legalWidget = [
  {
    callDesc: '',
    callId: 'toggleLegalBtn',
    callRef: 'LegalButton'
  }
]

export const chosenPage = [
  {
    callDesc: '',
    callId: 'toggleChosenPage',
    callRef: 'ChosePageButton'
  }
]

export const socialWidget = [
  {
    callDesc: '',
    callId: 'toggleSocialBtn',
    callRef: 'SocialButton'
  }
]

export const socialWidgetActive = [
  {
    callDesc: '',
    callId: 'toggleSocialActiveBtn',
    callRef: 'SocialButtonActive'
  }
]

export const pageLoad = [
  {
    callDesc: '',
    callId: 'togglePageLoad',
    callRef: 'PageLoadButton'
  }
]

export const pageSeconds = [
  {
    callDesc: '',
    callId: 'togglePageSeconds',
    callRef: 'PageSecondsButton'
  }
]

export const includeTagged = [
  {
    callDesc: '',
    callId: 'toggleIncludeTagged',
    callRef: 'IncludeTaggedButton'
  }
]

export const specificUrl = [
  {
    callDesc: '',
    callId: 'toggleSpecificUrl',
    callRef: 'SpecificUrlButton'
  }
]

export const addUrl = [
  {
    callDesc: '',
    callId: 'toggleAddUrl',
    callRef: 'AddUrlButton'
  }
]

export const userPage = [
  {
    callDesc: '',
    callId: 'toggleUserPage',
    callRef: 'UserPageButton'
  }
]

export const customAttribute = [
  {
    callDesc: '',
    callId: 'toggleCustomAttribute',
    callRef: 'CustomAttributeButton'
  }
]

export const haveCookie = [
  {
    callDesc: '',
    callId: 'toggleHaveCookie',
    callRef: 'HaveCookieButton'
  }
]

export const dontHaveCookie = [
  {
    callDesc: '',
    callId: 'toggleDontHaveCookie',
    callRef: 'DontHaveCookieButton'
  }
]

export const knownIdentity = [
  {
    callDesc: '',
    callId: 'toggleKnownIdentity',
    callRef: 'KnownIdentityButton'
  }
]

export const visitorUsing = [
  {
    callDesc: '',
    callId: 'toggleVisitorUsing',
    callRef: 'VisitorUsingButton'
  }
]

export const excludeTagged = [
  {
    callDesc: '',
    callId: 'toggleExcludeTagged',
    callRef: 'ExcludeTaggedButton'
  }
]

export const pageExit = [
  {
    callDesc: '',
    callId: 'togglePageExit',
    callRef: 'PageExitButton'
  }
]

export const pagePercent = [
  {
    callDesc: '',
    callId: 'togglePagePercent',
    callRef: 'PagePercentButton'
  }
]

export const privacyWidget = [
  {
    callDesc: '',
    callId: 'togglePrivacyBtn',
    callRef: 'PrivacyButton'
  }
]

export const callMeBackTelNumber = [
  {
    callDesc: '',
    callId: 'toggleCallMeBackTelNumber',
    callRef: 'CallMeBackTelNumberButton'
  }
]

export const clickToCallName = [
  {
    callTitle: "Name",
    callDesc: ' ',
    callId: 'toggleClickToCallName',
    callRef: 'ClickToCallNameButton'  
  }
]

export const clickToCallEmail = [
  {
    callTitle: "Email",
    callDesc: ' ',
    callId: 'toggleClickToCallEmail',
    callRef: 'ClickToCallEmailButton'
  }
]

export const clickToCallTeam = [
  {
    callTitle: "Team",
    callDesc: ' ',
    callId: 'toggleClickToCallTeam',
    callRef: 'ClickToCallTeamButton'
  }
]

export const clickToCallInterest = [
  {
    callDesc: '',
    callId: 'toggleClickToCallInterest',
    callRef: 'ClickToCallInterestButton'
  }
]


export const clickToCallIndustry = [
  {
    callDesc: '',
    callId: 'toggleClickToCallIndustry',
    callRef: 'ClickToCallIndustryButton'
  }
]

export const clickToCallEmployee = [
  {
    callDesc: '',
    callId: 'toggleClickToCallEmployee',
    callRef: 'ClickToCallEmployeeButton'
  }
]


export const clickToCallRole = [
  {
    callDesc: '',
    callId: 'toggleClickToCallRole',
    callRef: 'ClickToCallRoleButton'
  }
]

export const clickToCallRevenue = [
  {
    callDesc: '',
    callId: 'toggleClickToCallRevenue',
    callRef: 'ClickToCallRevenueButton'
  }
]

export const clickToCallComment = [
  {
    callDesc: '',
    callId: 'toggleClickToCallComment',
    callRef: 'ClickToCallCommentButton'
  }
]


export const clickToCallCustomFields = [
  {
    callDesc: '',
    callId: 'toggleClickToCallCustomFields',
    callRef: 'ClickToCallCustomFieldsutton'
  }
]

export const callMeBackName = [
  {
    callTitle: 'Name',
    callDesc: ' ',
    callId: 'toggleCallMeBackName',
    callRef: 'CallMeBackNameButton'
  }
]

export const callMeBackEmail = [
  {
    callTitle: 'Email',
    callDesc: ' ',
    callId: 'toggleCallMeBackEmail',
    callRef: 'CallMeBackEmailButton'
  }
]

export const callMeBackTeam = [
  {
    callTitle: 'Team',
    callDesc: ' ',
    callId: 'toggleCallMeBackTeam',
    callRef: 'CallMeBackTeamButton'
  }
]
export const modernTitle = [
  {
    callTitle: '',
    callDesc: ' ',
    callId: 'modern_header_title_status',
    callRef: 'modern_header_title_status'
  }
]
export const modernSubTitle = [
  {
    callTitle: '',
    callDesc: ' ',
    callId: 'modern_header_subtitle_status',
    callRef: 'modern_header_subtitle_status'
  }
]


export const callMeBackInterest = [
  {
    callDesc: '',
    callId: 'toggleCallMeInterestTeam',
    callRef: 'CallMeBackInterestButton'
  }
]


export const callMeBackIndustry = [
  {
    callDesc: '',
    callId: 'toggleCallMeBackIndustry',
    callRef: 'CallMeBackIndustryButton'
  }
]


export const callMeBackRole = [
  {
    callDesc: '',
    callId: 'toggleCallMeBackRole',
    callRef: 'CallMeBackRoleButton'
  }
]

export const callMeBackComment = [
  {
    callDesc: '',
    callId: 'toggleCallMeBackComment',
    callRef: 'CallMeBackCommentButton'
  }
]

export const callMeBackEmployee = [
  {
    callDesc: '',
    callId: 'toggleCallMeBackEmployee',
    callRef: 'CallMeBackEmployeeButton'
  }
]

export const callMeBackRevenue = [
  {
    callDesc: '',
    callId: 'toggleCallMeBackRevenue',
    callRef: 'CallMeBackRevenueButton'
  }
]



export const callMeBackCustomFields = [
  {
    callDesc: '',
    callId: 'toggleClickToCallMeBackFields',
    callRef: 'ClickToCallMeBackFieldsutton'
  }
]

export const callMeLaterName = [
  {
    callTitle: 'Name',
    callDesc: ' ',
    callId: 'toggleCallMeLaterName',
    callRef: 'CallMeLaterNameButton'
  }
]

export const callMeLaterEmail = [
  {
    callTitle: 'Email',
    callDesc: ' ',
    callId: 'toggleCallMeLaterEmail',
    callRef: 'CallMeLaterEmailButton'
  }
]

export const callMeLaterTeam = [
  {
    callTitle: 'Team',
    callDesc: ' ',
    callId: 'toggleCallMeLaterTeam',
    callRef: 'CallMeLaterTeamButton'
  }
]

export const callMeLaterInterest = [
  {
    callDesc: '',
    callId: 'toggleCallMeLaterInterest',
    callRef: 'CallMeLaterInterestButton'
  }
]

export const callMeLaterIndustry = [
  {
    callTitle:'Company',
    callDesc: ' ',
    callId: 'company_in_call_later_tab',
    callRef: 'company_in_call_later_tab'
  }
]

export const callMeLaterEmployee = [
  {
    callDesc: '',
    callId: 'toggleCallMeLaterEmployee',
    callRef: 'CallMeLaterEmployeeButton'
  }
]

export const callMeLaterRole = [
  {
    callDesc: '',
    callId: 'toggleCallMeLaterRole',
    callRef: 'CallMeLaterRoleButton'
  }
]

export const callMeLaterRevenue = [
  {
    callDesc: '',
    callId: 'toggleCallMeLaterRevenue',
    callRef: 'CallMeLaterRevenueButton'
  }
]

export const callMeLaterComment = [
  {
    callTitle:'Comments',
    callDesc: ' ',
    callId: 'toggleCallMeLaterComment',
    callRef: 'CallMeLaterCommentButton'
  }
]

export const callMeLaterCompanySize = [
  {
    callTitle:'Company Size',
    callDesc: ' ',
    callId: 'company_size_in_call_later_tab',
    callRef: 'company_size_in_call_later_tab'
  }
]
export const callMeLaterMessage= [
  {
    callTitle:'Message',
    callDesc: ' ',
    callId: 'message_in_call_later_tab',
    callRef: 'message_in_call_later_tab'
  }
]
export const callBackCompanySize = [
  {
    callTitle:'Company Size',
    callDesc: ' ',
    callId: 'company_size_in_call_now_tab',
    callRef: 'company_size_in_call_now_tab'
  }
]
export const callBackMessage= [
  {
    callTitle:'Message',
    callDesc: ' ',
    callId: 'message_in_call_now_tab',
    callRef: 'message_in_call_now_tab'
  }
]


export const callBackIndustry = [
  {
    callTitle:'Company',
    callDesc: ' ',
    callId: 'company_in_call_now_tab',
    callRef: 'company_in_call_now_tab'
  }
]

export const digitalCallCompanySize = [
  {
    callTitle:'Company Size',
    callDesc: ' ',
    callId: 'company_size_in_digital_call_tab',
    callRef: 'company_size_in_digital_call_tab'
  }
]
export const digitalCallMessage= [
  {
    callTitle:'Message',
    callDesc: ' ',
    callId: 'message_in_digital_call_tab',
    callRef: 'message_in_digital_call_tab'
  }
]


export const digitalCallIndustry = [
  {
    callTitle:'Company',
    callDesc: ' ',
    callId: 'company_in_digital_call_tab',
    callRef: 'company_in_digital_call_tab'
  }
]
export const leaveMessageCompanySize = [
  {
    callTitle:'Company Size',
    callDesc: ' ',
    callId: 'company_size_in_digital_call_tab',
    callRef: 'company_size_in_digital_call_tab'
  }
]
export const leaveMessageMessage= [
  {
    callTitle:'Message',
    callDesc: ' ',
    callId: 'message_in_digital_call_tab',
    callRef: 'message_in_digital_call_tab'
  }
]


export const leaveMessgeIndustry = [
  {
    callTitle:'Company',
    callDesc: ' ',
    callId: 'company_in_digital_call_tab',
    callRef: 'company_in_digital_call_tab'
  }
]
export const callMeLaterCustomFields = [
  {
    callDesc: '',
    callId: 'toggleClickToCallMeLaterFields',
    callRef: 'ClickToCallMeLaterFieldsutton'
  }
]

export const leaveMessageName = [
  {
    callTitle: "Name",
    callDesc: ' ',
    callId: 'toggleLeaveMessageName',
    callRef: 'LeaveMessageNameButton'
  }
]


export const leaveMessageEmail = [
  {
    callTitle: "Email",
    callDesc: ' ',
    callId: 'toggleLeaveMessageEmail',
    callRef: 'LeaveMessageEmailButton'
  }
]

export const leaveMessageTeam = [
  {
    callTitle: "Team",
    callDesc: ' ',
    callId: 'toggleLeaveMessageTeam',
    callRef: 'LeaveMessageTeamButton'
  }
]

export const leaveMessageInterest = [
  {
    callDesc: '',
    callId: 'toggleLeaveMessageInterest',
    callRef: 'LeaveMessageInterestButton'
  }
]


export const leaveMessageIndustry = [
  {
    callTitle: "Company",
    callDesc: ' ',
    callId: 'toggleLeaveMessageIndustry',
    callRef: 'LeaveMessageIndustryButton'
  }
]

export const leaveMessageEmployee = [
  {
    callDesc: '',
    callId: 'toggleLeaveMessageEmployee',
    callRef: 'LeaveMessageEmployeeButton'
  }
]


export const leaveMessageRole = [
  {
    callDesc: '',
    callId: 'toggleLeaveMessageRole',
    callRef: 'LeaveMessageRoleButton'
  }
]


export const leaveMessageRevenue = [
  {
    callDesc: '',
    callId: 'toggleLeaveMessageRevenue',
    callRef: 'LeaveMessageRevenueButton'
  }
]

export const leaveMessageComment = [
  {
    callDesc: '',
    callId: 'toggleLeaveMessageComment',
    callRef: 'LeaveMessageCommentButton'
  }
]


export const leaveMessageCustomFields = [
  {
    callDesc: '',
    callId: 'toggleClickToLeaveMessageFields',
    callRef: 'ClickToLeaveMessageFieldsutton'
  }
]

export const classicSmart = [
  {
    callDesc: '',
    callId: 'toggleClassicSmart',
    callRef: 'ClassicSmartButton'
  }
]

export const webCallDisplay = [
  {
    callTitle: "Web Call",
    callDesc: 'Enable voice calls through your website.',   
    callId: 'toggleWebCallDisplay',
    callRef: 'WebCallDisplayButton'
  }
]

export const callMeBackDisplay = [
  {
    callTitle: "Call Back",
    callDesc: 'Offer Phone call-backs proactively on any web page.',   
    callId: 'toggleCallMeBackDisplay',
    callRef: 'CallMeBackDisplayButton'
  }
]
export const contactUsDisplay = [
  {
    callTitle: "Contact Us",
    callDesc: 'Let your website visitors send you a contact message.',   
    callId: 'modern_send_message',
    callRef: 'modern_send_message'
  }
]

export const callMeLaterDisplay = [
  {
    callTitle: "Meetings",
    callDesc: 'Your prospect can schedule phone calls and book appointments.',   
    callId: 'toggleCallMeLaterDisplay',
    callRef: 'CallMeLaterDisplayButton'
  }
]
export const contactUs = [
  {
    callTitle: "Contact Us",
    callDesc: 'Your prospect can schedule phone calls and book appointments.',   
    callId: 'toggleContactUsDisplay',
    callRef: 'ContactUsDisplayButton'
  }
]
export const liveCallBackDisplay = [
  {
    callTitle: "Live Callbacks",
    callDesc: 'Enable live phone callbacks from your widget.',   
    callId: 'toggleCallMeBackDisplay',
    callRef: 'CallMeBackDisplayButton'
  }
]


export const leaveMessageDisplay = [
  {
    callTitle: "Contact form",
    callDesc: 'Generate more leads from your website.',   
    callId: 'toggleLeaveMessageDisplay',
    callRef: 'LeaveMessageDisplayButton'
  }
]

export const callMeLaterTelNumber = [
  {
    callDesc: '',
    callId: 'toggleCallMeLaterTelNumber',
    callRef: 'CallMeLaterTelNumberButton'
  }
]

export const callMeLaterEmailText = [
  {
    callDesc: '',
    callId: 'toggleCallMeLaterEmailText',
    callRef: 'CallMeLaterEmailTextButton'
  }
]

export const leaveMessageEmailText = [
  {
    callDesc: '',
    callId: 'toggleLeaveMessageEmailText',
    callRef: 'LeaveMessageEmailTextButton'
  }
]

export const leaveMessageNameText = [
  {
    callDesc: '',
    callId: 'toggleLeaveMessageNameText',
    callRef: 'LeaveMessageNameTextButton'
  }
]

export const callMeLaterNameText = [
  {
    callDesc: '',
    callId: 'toggleCallMeLaterNameText',
    callRef: 'CallMeLaterNameTextButton'
  }
]

export const widgetShadow = [
  {
    callTitle: 'Highlight Button',
    callDesc: '',
    callId: 'toggleWidgetShadow',
    callRef: 'widgetShadow'
  }
]

export const widgetBehaviour = [
  {
    callTitle: 'Scroll Trigger',
    callDesc: 'Launch the widget when the user scrolls to the end of the page',
    callId: 'scroll_trigger_status',
    callref: 'setTriggerScroll',
    inputName: 'scroll_trigger_value',
    inputData: {
      isDisplayInput: true,
      inputText: '%',
      defaultValue: '',
      change: 'scroll_trigger_value',
      min: 0,
      max: 100
    },
    active: false
  },
  {
    callTitle: 'Exit Intent Trigger',
    callDesc: 'Launch the widget when user attemps to exit page',
    callId: 'exit_intent_trigger_status',
    callref: 'setTriggerIntent',
    active: false
  },
  {
    callTitle: 'Time Trigger seconds',
    callDesc: 'Launch the widget after a certain amount of time',
    callId: 'time_trigger_status',
    callref: 'setTriggerTime',
    active: false,
    inputName: 'time_trigger_value',
    inputData: {
      isDisplayInput: true,
      inputText: '',
      change: 'time_trigger_value',
      min: 0,
      max: 300,
    }
  },
  
  {
    callTitle: 'Trigger once per session',
    callDesc:
      'Widget will only be triggered once per user session (until the user closes their browser tab)',
    callId: 'trigger_once_per_session',
    callref: 'setTriggerOnce',
    active: false
  },
  {
    callTitle: 'Custom Targets',
    callDesc: 'Enable To Trigger the Widget on this custom targets.',
    callId: 'toogleCustomTargets',
    callref: 'CustomTargets'
  }
]

export const lstSpecificPageOptions = [ 
  '',
  'URL starts with',
  'URL ends with',
  'URL contains',
  'URL equals',
  'URL regular expression',
  'URL doesn`t match exactly',
  'URL match exactly',
  'URL doesn`t start with',
  'URL doesn`t end with',
  'URL doesn`t contain'
]

export const lstFieldTypeOptions = [  
  'Select',
  'Show on',
  'Block on'  
]

export const lstCustomFieldTypeOptions = [
  '',
  'Check Box',
  'Drop Down',
  'Paragraph',
  'Text input',
  'Email input',
  'Date & Time'
]

export const lstCustomFieldDisplay = [
  '--Tab--',
  'All Tabs',
  'Call me back',
  'Call me later',
  'Leave message'
]

export const lstCustomFieldDisplayOnCallNow = [
  'Display On Call Now',
  'On call now screen',
  'During Call',
  'After call'
]

export const widgetCustomFieldDisplayOnCallNow = [
  'Before the call screen',
  'After the call screen'
]

export const lstCustomFieldDisplayOnCallLater = [
  'Display On Call Later',
  'Before Schedule',
  'After Schedule'
]
