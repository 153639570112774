import moment from 'moment'
import React, { useState } from 'react'
import { Accordion } from 'semantic-ui-react'

function LeadInfo({ data }) {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const activeIndexs = activeIndex
    const newIndex = activeIndexs === index ? -1 : index
    setActiveIndex(newIndex)
  }

  const { lead, ip_country } = data?.leadInfo || {}
  return (
    <>
      <Accordion className="Lead_wrapper" fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          lead info
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          {
            <>
              <div className="form-group row">
                <div className="col-md-4">
                  <p className="mb-0 text-color lead_title">
                    <img
                      src={
                        process.env.REACT_APP_BASE_APP_URL +
                        '/assets/lead_details_icons/Lead popup edited-33.svg'
                      }
                      className="lead-data-icon"
                    />
                    <label>Agent</label>
                  </p>
                </div>
                <div className="col-auto px-0">: </div>
                <div className="col-md-5">
                  <p className="detail_text">
                    <b>{lead?.customer_name || lead?.company_name}</b>
                  </p>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-4">
                  <p className="mb-0 text-color lead_title">
                    <img
                      src={
                        process.env.REACT_APP_BASE_APP_URL +
                        '/assets/lead_details_icons/Lead popup edited-33.svg'
                      }
                      className="lead-data-icon"
                    />
                    <label>Meeting</label>
                  </p>
                </div>
                <div className="col-auto px-0">: </div>
                <div className="col-md-5">
                  <p className="detail_text">
                    <b>
                      {lead?.request_call_time
                        ? moment(lead?.request_call_time)
                            // .utc(lead?.request_call_time)
                            .format('dddd, MMMM, DD, YYYY')
                        : ''}

                      <br></br>
                    </b>
                  </p>
                  <p className="detail_text">
                    <b>
                      {lead?.request_call_time
                        ? moment(lead?.request_call_time)
                            // .utc(lead?.request_call_time)
                            .format('HH:hh A') +
                          ' to ' +
                          moment(lead?.request_call_time)
                            // .utc(lead?.request_call_time)
                            .format('HH:hh A')
                        : ''}
                    </b>
                  </p>
                  <p className="detail_text">
                    <b>
                      {lead?.final_status &&
                        `Final Status:${lead?.final_status}`}
                      <br></br>
                    </b>
                  </p>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-4">
                  <p className="mb-0 text-color lead_title">
                    <img
                      src={
                        process.env.REACT_APP_BASE_APP_URL +
                        '/assets/lead_details_icons/Lead popup edited-33.svg'
                      }
                      className="lead-data-icon"
                    />
                    <label>Final Status</label>
                  </p>
                </div>
                <div className="col-auto px-0">: </div>
                <div className="col-md-5">
                  <p className="detail_text">
                    <b>{lead?.final_status || '-'}</b>
                  </p>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-4">
                  <p className="mb-0 text-color lead_title">
                    <img
                      src={
                        process.env.REACT_APP_BASE_APP_URL +
                        '/assets/lead_details_icons/Lead popup edited-33.svg'
                      }
                      className="lead-data-icon"
                    />
                    <label>Location</label>
                  </p>
                </div>
                <div className="col-auto px-0">: </div>
                <div className="col-md-5">
                  <p className="detail_text">
                    <b>{lead?.location || '-'}</b>
                  </p>
                </div>
              </div>
            </>
          }
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>IP</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data.callData.ip_address}</b>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Source</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b style={{ lineBreak: 'anywhere' }}>{data.callData.source}</b>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-24.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Score</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data.callData.score}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/i copy-39.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Team</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data.callData.team ? data.callData.team : '-'}</b>
              </p>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
    </>
  )
}

export default LeadInfo
