import React, { useState } from 'react'
import Title from '../../common/Title'
import Navbar from '../navbar/Navbar'
import { Dimmer, Dropdown, Header, Loader, Rating, Table } from 'semantic-ui-react'

import Chart from "react-apexcharts";
import { Autocomplete, Button, Chip, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const title = {
    titleTwo: 'Accounts'
}
const intial = ['Indentified company', 'Visitor Located in US', 'Filter company traffic']
const Accounts = () => {
    const navigate=useHistory()
    const [expand, setExpand] = useState({ info: {}, visited: {} })
    const [segmentList, setSegmentList] = useState([...intial])
    const infoData = ['11-50 Employees', ', Singapore', '$1M-$10M Annual Revenue', 'Internet Software & Services']
    const VisitedPages = ['/company/about-company', '/data-pricing', '/platform/data-as-a-api', '/platform/managed-residential']
    const options = {
        grid: {
            show: false, // Hide grid lines
        },
        dataLabels: {
            enabled: false, // Hide data labels
        },
        chart: {
            background: 'transparent', // Set chart background to transparent
        },
        xaxis: {
            categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            labels: {
                show: false, // Hide x-axis labels
            },
        },
        yaxis: {
            labels: {
                show: false, // Hide y-axis labels
            },
        },
        toolbar: {
            show: false, // Hide toolbar (download and zoom options)
        },
        // xaxis: {
        //     categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        // }
    };
    const series = [
        {
            name: "series-1",
            data: [30, 40, 25, 50, 49, 21, 70, 51]
        },
    ];


    const statusOptions = [
        { key: 1, text: 'Awaiting Review', value: 1 },
        { key: 2, text: 'In progress', value: 2 },
        { key: 3, text: 'Qualified', value: 4 },
        { key: 4, text: 'Closed', value: 3 }
    ]
    
    const handleExpand=(e, id,key)=>{ 
        setExpand({ ...expand, [key]: { ...expand?.[key], [id]: !expand?.[key]?.[id] } })
    }
    return (
        <>
            <Dimmer active={false}>
                <Loader />
            </Dimmer>
            <Title data={title} />
            <Navbar />
            <div className='account-container'>
                {/* <div className="dropdown-column">
                    {[1, 2, 3, 4, 5, 6]?.map((obj) =>
                        <Dropdown
                            placeholder='company value'
                            style={{
                                margin: '0 20px 10px 0px',
                                flex: '0 1 10em',
                                height: 'inherit',
                                boxShadow: 'none'
                            }}
                            options={statusOptions}
                        />
                    )}
                </div> */}
                <div className="mb-3 search-bar">
                    
                <input type="search" placeholder='Search' />
                <Button variant='contained'>Export</Button>
                </div>
                <div className="mb-3 segment-auto">
                    
                    <Autocomplete
                        label="Segment"
                        multiple
                        id="tags-filled"
                        options={segmentList}
                        // defaultValue={}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        onInputChange={(...props)=>console.log('props', props)}
                        onChange={(...props) => {
                            if(props?.[2] ==='createOption')
                            {
                               
                                setSegmentList([...intial, ...props?.[1]])

                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                
                                label='Segment'
                                variant="filled"
                                placeholder="+ Segment"
                            />
                        )}
                    />
                </div>
                <div className="row">
                    <div className="col-lg-2">
                       <div className='segment-main'>
                            {segmentList?.map((val)=>
                                <div className='segment-list'>{val}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-10">
                        <div className="table-responsive">
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Company</Table.HeaderCell>
                                        <Table.HeaderCell>Info</Table.HeaderCell>
                                        <Table.HeaderCell>Research Intent</Table.HeaderCell>
                                        <Table.HeaderCell>Research Topics</Table.HeaderCell>
                                        <Table.HeaderCell>Session Time</Table.HeaderCell>
                                        <Table.HeaderCell>Visitors</Table.HeaderCell>
                                        <Table.HeaderCell>Sessions</Table.HeaderCell>
                                        <Table.HeaderCell>Pages Viewed</Table.HeaderCell>
                                        <Table.HeaderCell>Pages Visited</Table.HeaderCell>
                                        <Table.HeaderCell>Last Seen</Table.HeaderCell>

                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>

                                    {[1, 2, 3, 4, 5]?.map((obj, index) =>
                                        <Table.Row >
                                            <Table.Cell>
                                                <p>Lime VPN</p>
                                                <p>limevpn.com</p>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {infoData?.map((val, ind) =>
                                                    ind <= 1 && <p>{val}</p>
                                                )}
                                                <p className='show-expand cursor-pointer' onClick={(e) => 

                                                    handleExpand(e,index,'info')}
                                                    
                                                    >{expand?.info?.[index] ? <>Show Less <KeyboardArrowUpIcon /></> : <>Show more <KeyboardArrowDownIcon/></> }</p>
                                                {expand?.info?.[index] && infoData?.map((val, ind) =>
                                                    ind > 1 && <p>{val}</p>
                                                )}

                                            </Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell>2 seconds</Table.Cell>
                                            <Table.Cell>
                                                <p>1 Visitors</p>
                                                <p>1 known</p>

                                            </Table.Cell>
                                            <Table.Cell>
                                                {options && series && (<Chart height="100px" options={options} series={series} type="area" />)}
                                            </Table.Cell>
                                            <Table.Cell>2 Pages</Table.Cell>
                                            <Table.Cell>

                                                {VisitedPages?.map((val, ind) =>
                                                    ind <= 1 && <div className='visited-page'>{val}</div>
                                                )}
                                                <p className='show-expand cursor-pointer' onClick={(e) => handleExpand(e,index,'visited')}>{expand?.visited?.[index] ? <>Show Less <KeyboardArrowUpIcon /></> : <>Show more <KeyboardArrowDownIcon /></>}</p>
                                                {expand?.visited?.[index] && VisitedPages?.map((val, ind) =>
                                                    ind > 1 && <div className='visited-page'>{val}</div>
                                                )}
                                            </Table.Cell>
                                            <Table.Cell>

                                                <p>An Unknown User spent Less than a second on /
                                                    3 days ago</p>
                                            </Table.Cell>

                                        </Table.Row>)}

                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accounts