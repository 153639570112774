import axios from 'axios'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, Dimmer, Loader } from 'semantic-ui-react'
import {
  getCallForwadingSettings,
  getTeamMember,
  getWidgetCountries
} from '../../actions/settings'
import CallForwardingToogle from '../../common/CallFowardingToggle'
import { CommonNotify } from '../../common/CommonNotify'
import InvertedModal from '../../common/VerifyModal2'

const webAppData = {
  forwardingTitle: ['Verify Number', 'Timeout', ' Enabled'],
  callId: 'webAppToggle',
  forwardRef: 'webApp',
  phoneValue: 'web',
  enabled: true,
  timeoutRef: 'webAppTime',
  active: false
}


const mobileAppData = {
  callId: 'mobileAppToggle',
  forwardRef: 'mobileApp',
  phoneValue: 'mobile',
  enabled: true,
  timeoutRef: 'mobileappTime'
}
const mobileAppNewData = {
  callId: 'mobileAppToggle',
  forwardRef: 'mobileNewApp',
  phoneValue: 'mobileNewApp',
  enabled: true,
  timeoutRef: 'mobileappTime'
}

const numberData = {
  type: 'select',
  callId: 'numberToggle',
  forwardRef: 'numberApp',
  enabled: false,
  timeoutRef: 'numberAppTime'
}

const sipData = {
  callId: 'sipToggle',
  forwardRef: 'sip',
  phoneValue: 'SIP',
  number: '',
  enabled: false,
  timeoutRef: 'sipAppTime',
  noToggle: true
}

class CallForwarding extends Component {
  constructor(props) {
    super(props)
    const apiToken = localStorage.getItem('access_token')
    const userId = localStorage.getItem('id')
    this.state = {
      sipLoaded: true,
      numberApp: false,
      addForwardNumber: '',
      mobileAppTime: '',
      webAppTime: '',
      sipData: sipData,
      numberAppTime: '',
      isLoading: false,
      sipAppTime: '',
      verifyCode: false,
      verifyCodeDigit: '',
      verifyNum: '',
      verifyObj: null,
      webApp: webAppData,
      mobileApp: mobileAppData,
      mobileNewApp: mobileAppNewData,
      forwardNo: [],
      webLoaded: false,
      mobileLoaded: false,
      isTextButton: false,
      openAddNumber: false,
      openDeleteNumber: false,
      apiToken,
      userId,
      modalLoadingButton: false,
      newNumbers: [],
      timeOutValue:{},
    }
  }

  handleDataRef = (DataRef, DataState) => {
    this.setState({ [DataRef]: DataState })
  }

  onChangeCountry = (value, object) => {
    this.setState({ verifyNum: value, verifyObj: object })
  }

  handleGroupBtnData = (toggleBtn, id) => {
    this.setState({ [id]: toggleBtn })
  }

  handleModalClose = () => {
    this.setState({
      verifyCode: false,
      openDeleteNumber: false,
      openAddNumber: false
    })
    this.setState({ isLoading: false })
  }

  componentDidUpdate(prevProps) {
    if (this.props.callForwadingSettings !== prevProps.callForwadingSettings) {
      this.setState({ isLoading: false })

      this.setNumbers(
        this.props.callForwadingSettings.data.available_forward_no
      )
      this.setWeb(this.props.callForwadingSettings.data.receive_call_browser)
    }
    if (this.props.teamMember !== prevProps.teamMember) {
      this.setState({ isLoading: false })
      this.setSip(this.props.teamMember.data.call_forwarding_to)
    }
    if (this.props.widgetCountries !== prevProps.widgetCountries) {
      if (this.props.widgetCountries.data?.allowed_countries?.length) {
        this.setState({
          allowedCountries: this.props.widgetCountries.data.allowed_countries
        })
      }
    }
  }

  componentDidMount = () => {
    this.initialFunctinalCall()
  }

  initialFunctinalCall = () => {
    this.fetchSettings()
    this.fetchSip()
    this.fetchCountry()
    this.setState({
      newNumbers: []
    })
    this.getTimeOutValue()
  }

  setWeb = data => {
    let webApp = { ...this.state.webApp }
    webApp.active = data
    this.setState({ webApp, webLoaded: true,mobileLoaded:true })
  }

  fetchSip = async () => {
    const uid = await localStorage.getItem('id')
    if (!uid) return
    if (this.props.teamMember) {
      this.setState({ isLoading: false })
      this.setSip(this.props.teamMember.data.call_forwarding_to)
    } else {
      this.setState({ isLoading: true })
      this.props.getTeamMember()
    }
  }

  setSip = data => {
    const ar = data.find(item => {
      if (item.toLowerCase().includes('sip:')) {
        return item
      }
    })
    if (typeof ar !== 'undefined') {
      const obj = { ...this.state.sipData }
      obj.enabled = true
      obj.number = ar
      this.setState({ sipData: obj }, () => {
        this.setState({ sipLoaded: true })
      })
    }
  }

  fetchSettings = () => {
    this.setState({ isLoading: false })
    if (this.props.callForwadingSettings) {
      this.setNumbers(
        this.props.callForwadingSettings.data.available_forward_no
      )
      this.setWeb(this.props.callForwadingSettings.data.receive_call_browser)
    } else {
      this.setState({ isLoading: true })
      this.props.getCallForwadingSettings()
    }
  }

  fetchCountry = () => {
    this.setState({ isLoading: false })

    if (this.props?.widgetCountries) {
      this.setState({
        allowedCountries: this.props.widgetCountries?.data?.allowed_countries
      })
    } else {
      this.setState({ isLoading: true })
      this.props.getWidgetCountries()
    }
  }

  setNumbers = data => {
    let updateForwardNo = []
    updateForwardNo = data.map((row, index) => {
      return {
        phone_number: row?.phone_number,
        id: row?.id,
        active: row?.active,
        phoneValue: 'mobile',
        forwardRef: 'sip_' + row?.id,
        callId: 'sip_' + row?.id,
        primary: true
      }
    })
    this.setState({ forwardNo: updateForwardNo })
  }

  getTimeOutValue = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/call-forwarding`
    axios
      .get(url)
      .then(res => {
        this.setState({
          timeOutValue:res?.data?.data || {}
        })
      })
      .catch(error => {
        CommonNotify(error?.response?.data?.errors?.[0])
      })
  }

  addNumforVerify = () => {
    if (!this.state.verifyObj) {
      CommonNotify('Please Add Number for Forwarding')
      return
    }

    this.setState({ isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/send-verification-phone-number`

    var data = {
      user_id: this.state.userId,
      phone_country: this.state.verifyObj.countryCode,
      full_number: this.state.verifyNum,
      phone_number: this.state.verifyNum
    }

    axios
      .post(url, data)
      .then(res => {
        this.setState({ isLoading: false })
        if (res.data.message == 'Successfully') {
          this.setState({ openAddNumber: true })
          CommonNotify('Verification code sent to Phone', 'success')
        } else {
          CommonNotify('Can`t Send Verification Code', 'error')
        }
      })
      .catch(er => {
        const errors = { ...er }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({ isLoading: false })
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ isLoading: false })
          }
        }
      })
  }

  verifyNumber = () => {
    this.setState({ openAddNumber: true, modalLoadingButton: true })
    if (!this.state.verifyCodeDigit) {
      CommonNotify('Please Enter Verification Code', 'warning')
      this.setState({ modalLoadingButton: false })
      return
    }

    this.setState({ isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/verify-user-phone-number`

    var data = {
      verification_code: this.state.verifyCodeDigit
    }

    axios
      .post(url, data)
      .then(res => {
        this.setState({ isLoading: false })
        this.setState({ verifyCode: false })
        if (res?.data?.message[0] == 'Successfully') {
          CommonNotify('Phone Number Added', 'success')
          this.setState({
            verifyCodeDigit: '',
            verifyCode: false,
            verifyNum: '',
            openAddNumber: false,
            modalLoadingButton: false,
            isTextButton: false,
            newNumbers: []
          })
          this.props.getTeamMember()
          this.props.getCallForwadingSettings()
        } else {
          CommonNotify('Cant Add Phone Number')
          this.setState({ modalLoadingButton: false })
        }
      })
      .catch(er => {
        const errors = { ...er }
        if (errors) {
          if (errors?.response?.data?.errors) {
            CommonNotify(errors?.response?.data?.errors[0])
            this.setState({ modalLoadingButton: false })
            this.setState({ isLoading: false })
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ modalLoadingButton: false })
            this.setState({ isLoading: false })
          }
        }
      })
  }

  saveSetting = (val, index, number) => {
    if (index == 'webApp') {
      this.toggleWeb(val)
      return
    }
    if (index == 'mobileNewApp') {
      this.toggleMobile(val)
      return
    }
    this.togglePhone(val, number)
  }

  toggleWeb = val => {

    const data = val == true ? 1 : 0

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/update-browser-availability/${this.state.userId}/${data}`
    axios
      .get(url)
      .then(res => {
        this.setState({ isLoading: false })
        if (res.data.message.success) {
        } else {
          CommonNotify(res.data.message.error[0])
        }
      })
      .catch(error => {
        const errors = { ...error }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({ isLoading: false })
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ isLoading: false })
          }
        }
      })
  }
  toggleMobile = val => {

 const data = {
      // phone_number: number,
      status: val == true ? 1 : 0
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/mobile-call-status`
    axios
      .post(url,data)
      .then(res => {
        this.setState({ isLoading: false })
        // if (res.data.message.success) {
        // } else {
        //   CommonNotify(res.data.message.error[0])
        // }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        
        const errors = { ...error }
        if (errors) {
        
        }
      })
  }

  togglePhone = (val, number) => {

    const data = {
      phone_number: number,
      status: val == true ? 1 : 0
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/change-number-status`
    axios
      .post(url, data)
      .then(res => {
        this.setState({ isLoading: false })
        if (res.data.message.success) {
        } else {
          CommonNotify(res.data.message.error[0])
        }
      })
      .catch(error => {
        const errors = { ...error }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({ isLoading: false })
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ isLoading: false })
          }
        }
      })
  }

  addTextButton = () => {
    this.setState({ newNumbers: ['new', ...this.state.newNumbers] })
  }

  deleteContactNumber = rowData => {
    this.setState({ isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/delete-phone-number`
    const data = {
      phone_number: rowData.phone_number
    }
    axios
      .post(url, data)
      .then(res => {
        this.setState(
          {
            verifyCodeDigit: '',
            verifyCode: false,
            verifyNum: '',
            openDeleteNumber: false,
            isTextButton: false,
            modalLoadingButton: false
          },
          () => this.fetchSettings()
        )
        CommonNotify('Phone number deleted successfully', 'success')
        this.props.getTeamMember()
        this.props.getCallForwadingSettings()
      })
      .catch(err => {
        const errors = { ...err }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({ isLoading: false })
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ isLoading: false })
          }
        }
      })
  }

  submitDeleteHandle = () => {
    this.setState({ modalLoadingButton: true })
    if (!this.state.verifyCodeDigit) {
      CommonNotify('Please Enter Verification Code')
      this.setState({ modalLoadingButton: false })
      return
    }

    this.setState({ isLoading: true })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/delete-phone-number`

    var data = {
      confirmation_code: this.state.verifyCodeDigit,
      phone_number: this.state.verifyNum
    }

    axios
      .post(url, data)
      .then(res => {
        this.setState({ isLoading: false })

        if (res.data.message == 'Successfully') {
          CommonNotify('Phone number deleted successfully', 'success')
          this.setNumbers(res?.data?.data || [])
          this.props.getTeamMember()
          this.setState(
            {
              verifyCodeDigit: '',
              verifyCode: false,
              verifyNum: '',
              openDeleteNumber: false,
              isTextButton: false,
              modalLoadingButton: false
            },
            () => this.fetchSettings()
          )
        } else {
          CommonNotify('Cant Add Phone Number')
          this.setState({ modalLoadingButton: false })
        }
      })
      .catch(er => {
        const errors = { ...er }
        if (errors) {
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({ modalLoadingButton: false })
            this.setState({ isLoading: false })
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ modalLoadingButton: false })
            this.setState({ isLoading: false })
          }
        }
      })
  }

  render() {
    return (
      <>
        <Dimmer active={this.state.isLoading}>
          <Loader></Loader>
        </Dimmer>
        <div className="call-forwarding-wrapper call_rorwarding_main">
          <p
            className="title-label"
            style={{ fontSize: '16px', marginTop: 52,fontWeight:600 }}
          >
            Choose how you want to receive incoming calls
          </p>
          <div className="call-forwarding-holder call_rorwarding">
            {this.state.webLoaded && (
              <CallForwardingToogle
                handleDataRef={this.saveSetting}
                dataToggle={this.state.webApp}
                active={this.state.webApp.active === 1 ? true : false}
                timeout={true}
                web={true}
                value={this.state.timeOutValue?.browser_call}
                refresh={this.initialFunctinalCall}
              />
            )}
            {this.state.mobileLoaded && (
              <CallForwardingToogle
                handleDataRef={this.saveSetting}
                dataToggle={this.state.mobileNewApp}
                active={this.props?.profile?.data?.mobile_app_call_status === 1 ? true : false}
                timeout={true}
                web={true}
                value={this.state.timeOutValue?.browser_call}
                refresh={this.initialFunctinalCall}
              />
            )}

            {this.state.sipLoaded && (
              <CallForwardingToogle
                noToggle={true}
                handleDataRef={this.saveSetting}
                dataToggle={this.state.sipData}
                active={this.state.sipData.enabled}
                timeout={true}
                sip={true}
                value={this.state.timeOutValue?.sip}
                refresh={this.initialFunctinalCall}
              />
            )}

            {this.state.forwardNo.map((row,index) => (
              <div className="call_forwarding_toogle">
                <CallForwardingToogle
                  handleDataRef={this.saveSetting}
                  dataToggle={row}
                  number={row.phone_number}
                  active={row.active == 1 ? true : false}
                  timeout={true}
                  phone={true}
                  value={this.state?.timeOutValue?.phone_numbers?.[index] || {}}
                  refresh={this.initialFunctinalCall}
                />
                <i
                  className="fas fa-trash-alt delete_icon"
                  onClick={() => this.deleteContactNumber(row)}
                ></i>
              </div>
            ))}
            {this.state.newNumbers.map(el => (
              <CallForwardingToogle
                dataToggle={numberData}
                noToggle={'no need'}
                isButton={true}
                onChangeCountry={this.onChangeCountry}
                dataNumber={this.state.verifyNum}
                addNumforVerify={this.addNumforVerify}
                whitelist={this.state.allowedCountries}
              />
            ))}
            <div className="add-forwarding-wrapper">
              <Button className="add-number" onClick={this.addTextButton}>
                + Add Number
              </Button>
            </div>
          </div>

          <Fragment>
            <InvertedModal
              closeHandle={this.handleModalClose}
              submitHandle={this.verifyNumber}
              changeHandle={e =>
                this.setState({ verifyCodeDigit: e.target.value })
              }
              Number={this.state.verifyNum}
              VerifyCode={this.state.verifyCode}
              open={this.state.openAddNumber}
              contentData="addNumber"
              modalLoadingButton={this.state.modalLoadingButton}
            />
            <InvertedModal
              closeHandle={this.handleModalClose}
              changeHandle={e =>
                this.setState({ verifyCodeDigit: e.target.value })
              }
              Number={this.state.verifyNum}
              VerifyCode={this.state.verifyCode}
              open={this.state.openDeleteNumber}
              contentData="deleteNumber"
              submitDeleteHandle={this.submitDeleteHandle}
              modalLoadingButton={this.state.modalLoadingButton}
            />
          </Fragment>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  callForwadingSettings: state.settings.callForwadingSettings,
  teamMember: state.settings?.teamMember,
  widgetCountries: state.settings.widgetCountries,
  profile: state.settings.profile
})

export default connect(mapStateToProps, {
  getCallForwadingSettings,
  getTeamMember,
  getWidgetCountries
})(CallForwarding)
