import React, { useState, useEffect } from 'react'

import NodeToggle from '../../common/NodeToggle'
import CommonInput from '../../common/CommonInput'
import CommonSelect from '../../common/CommonSelect'
import CommonButtons from '../../common/CommonButtons'

import { CommonNotify } from '../../common/CommonNotify'

import { Table } from 'semantic-ui-react'

import iconAdjust from '../../assets/images/Dashboard 2-06.png'
import iconCicle from '../../assets/images/cicle-plus.png'
import deleteIcon from '../../assets/images/delete-icon.png'
import {
  lstSpecificPageOptions,
  lstFieldTypeOptions
} from '../../lib/WidgetData'

import axios from 'axios'
import { useSelector } from 'react-redux'



const apiToken = localStorage.getItem('access_token')

export const WidgetBehaviourTitle = () => {
  return (
    <div className="accordion-widget-holder">
      <div className="accordion-image-holder">
        <img src={iconAdjust} alt="logo" />
      </div>{' '}
      <div className="accordion-title-holder">
        <h2 className="accordion-title"> When should the widget appear ? </h2>{' '}
        <p className="accordion-description"> Customize the widget's behavior </p>{' '}
      </div>{' '}
    </div>
  )
}

export const WidgetBehaviourContent = ({
  widget,
  widgetBehaviour,
  handleDataRef,
  lstSpecificPage,
  lstUrls,
  onAddRemoveSpecificPage,
  onUpdateSpecificRecord,
  onSubmitSpecificRecords,
  customTargetToggle,
  loading,
  updateDataRef,
  onChange,
  widgetDataAPi,
  onChangeInputBehaviour,
  widgetDataAPiSaveCancel,
  widgetObject,
  resetSpecificPage

}) => {
  const [widgetBehaviourData, setWidgetBehaviourData] = useState(widgetBehaviour)
  const [triggerScroll, setTriggerScroll] = useState(false)
  const [triggerIntent, setTriggerIntent] = useState(false)
  const [triggerTime, setTriggerTime] = useState(false)
  const [triggerCss, setTriggerCss] = useState(false)
  const [triggerOnce, setTriggerOnce] = useState(false)
  const [triggerScrollValue, setTriggerScrollValue] = useState(null)
  const [triggerTimeValue, setTriggerTimeValue] = useState('')
  const [isWidgetBehaviourContent, setIsWidgetBehaviourContent] = useState(false)
  const [lstSpecificPageData, setLstSpecificPageData] = useState([])
  const widgetData = useSelector((state) => state.home?.widget)

  useEffect(() => {
    if (widgetData?.data?.[0]?.target){
      setLstSpecificPageData(widgetData?.data?.[0]?.target)
    }
  }, [widgetData])
  

  const customGetTargetsTableData = async() => {
    const apiToken = await localStorage.getItem('access_token')
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    
    axios
      .get(url)
      .then(res => {
        loading(false)
        setLstSpecificPageData(res.data.data[0].targets)
        localStorage.setItem('widget_active', res.data.data[0].active)
      })
      .catch(err => {
        const errors = { ...err }       
        if (errors) {
         
          loading(false)
          return
        } else {
          
          loading(false)
        }
      })
  }



  const fetchWidget = async() => {
    const apiToken = await localStorage.getItem('access_token')
  
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`    
    axios
      .get(url)
      .then(res => {        
        if (res.data.data[0]) {
          updateBehaviour(res.data.data[0].behaviour)
        }
      })
      .catch(error => {
        this.setState({ ...this.state, isLoading: false })    
        if (error?.response?.status === 401) {
         
          return;
        }
        if(!error?.response){
        
        }      
      })
  }





  const fetchBehaviour = async() => {
    const apiToken = await localStorage.getItem('access_token')
    loading(true)

    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widget-behavior/${widget.id}`

    axios
      .get(url)
      .then(res => {
        loading(false)
        if (res.data.data) {
          setBehaviour(res.data.data)
        }
      })
      .catch(er => {
        loading(false)
        
      })
  }
  useEffect(() => {
    if (widget.id) {
      
      // customGetTargetsTableData()
    }
   
  }, [widget.id])

  const setBehaviour = data => {  
    setTriggerScroll(data.scroll_trigger_status)
    setTriggerIntent(data.exit_intent_trigger_status)
    setTriggerTime(data.time_trigger_status)
    setTriggerCss(data.trigger_css)
    setTriggerOnce(data.trigger_once_per_session)
    setTriggerScrollValue(data.scroll_trigger_value)
    setTriggerTimeValue(data.time_trigger_value)

    let oldData = [...widgetBehaviourData]
    oldData[0] = {
      ...oldData[0],
      active: data.scroll_trigger_status,
      inputData: {
        ...oldData[0].inputData,
        defaultValue: data.scroll_trigger_value
      }
    }
    oldData[1] = {
      ...oldData[1],
      active: data.exit_intent_trigger_status
    }
    oldData[2] = {
      ...oldData[2],
      active: data.time_trigger_status,
      inputData: {
        ...oldData[2].inputData,
        defaultValue: data.time_trigger_value
      }
    }
    oldData[3] = {
      ...oldData[3],
      active: false
    }
    oldData[4] = {
      ...oldData[4],
      active: data.trigger_once_per_session
    }
    setWidgetBehaviourData(oldData)
  }

  const updateBehaviour = dt => {  

    loading(true)
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/`+ widget.id+`/behaviour`;

    dt.css_trigger_value = widgetDataAPi.css_trigger_value
    dt.scroll_trigger_value = widgetDataAPi.scroll_trigger_value
    dt.time_trigger_value = widgetDataAPi.time_trigger_value

    const data = {     
      ...dt,
      css_trigger_value: 0,    
    }

    axios
      .post(url, data)
      .then(res => {
        if (res.data.message[0] === 'Successfully') {
          CommonNotify('Setting Updated', 'success')
          loading(false)
         
        } else {
          CommonNotify('Cant Update Setting', 'error')
          loading(false)
        }
      })
      .catch(er => {
        loading(false)
        CommonNotify('Cant Update Setting', 'error')       
      })
  }






  const customTargetsTableData = () => {
    let updateOperator = {
      'URL starts with': 'url-starts',
      'URL ends with': 'url-ends',
      'URL contains': 'url-contains',
      'URL equals': 'url-equals',
      'URL regular expression': 'url-regex',
      'URL doesn`t match exactly': 'url-not-match',
      'URL match exactly': 'url-match',
      'URL doesn`t start with': 'url-not-starts',
      'URL doesn`t end with': 'url-not-ends',
      'URL doesn`t contain': 'url-not-contains'
    }
    const  moderateData = lstSpecificPage.map(item => { 
      
      
     
        let updateData = {
          option: item.option === 'AND' ? 'and' : 'or',
          type: 0 ,
          value: item.value ,
          key: 'url',
          operator: updateOperator[item.operator]
        }
        return updateData
     
      
    })

    let empty = false;

    const result = moderateData.find(item => {       
      if(item.value == ''){
        empty = true;
      }           
    })




    if(empty){
      CommonNotify("Some field's are missing", 'warning')
      return;
    }

    

    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget.id}/targets`
    const data = {
      triggers: moderateData
    }   
    axios
      .post(url, data)
      .then(res => {


        loading(false)
       
        resetSpecificPage()
        customGetTargetsTableData()
      })
      .catch(err => {
        const errors = { ...err }

        loading(false)

        if(!errors.response){
          CommonNotify('Getting server response 500')
          
          return
        }

        if (errors.response.data.errors) {
          CommonNotify(errors.response.data.errors[0])
        
          return
        } else {
          CommonNotify('Some thing went wrong')
        
        }
      })
  }

  const onDeleteUrl = index => {
    loading(true)
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widget/delete-triggers`
    const data = {
      id: index,
      widget_id: widget.id
    }
    axios
      .post(url, data)
      .then(res => {
        loading(false)
        customGetTargetsTableData()
      })
      .catch(err => {
        loading(false)
      })
  }

 
  return (
    <div className="adjust-behaviour-wrapper">
      <p className="adjust-title"> Widget Behaviour </p>{' '}
      {widgetBehaviour.map((item, i) => {
      
       return i !== widgetBehaviour?.length - 1 ? (
          <NodeToggle
            key={i}
            handleDataRef={(e, data, type) => {
              updateDataRef(e, data, i,  type);
              setTriggerScrollValue(e);
            }}
            dataToggle={item}
            inputData={item.inputData}
            value={widgetDataAPi && widgetDataAPi[item.inputName]}
            inputDisable={item.active ? false : true}
            inputType="number"
            activeDefault={item.active}
          />
        ) : null
      })}
     {' '}
      <div className="custom-target-wrapper">
        {widgetBehaviour.map((item, i) => {
          return i === widgetBehaviour?.length - 1 ? (
            <NodeToggle
              key={i}
              handleDataRef={e => {handleDataRef('customTargetToggle', e, i)}}
              dataToggle={item}
              
              activeDefault={
                widgetObject && widgetObject['custom_triggers_status'] ? true : false
              }             
            />
          ) : null
        })}
       {' '}
        {widgetObject['custom_triggers_status'] ?
          lstSpecificPage.map((item, index) => {
            return (
              <div className="specific-page" key={index}>
                
                <div className='custom-common-select-class'>
                  <CommonSelect
                    name="operator"
                    className="set-hours-select"
                    style={{padding: "16px 5px 40px 40px !important"}}
                    options={lstSpecificPageOptions}
                    defaultValue={item.operator || lstSpecificPageOptions[0]}
                    onChange={(e, result) => {
                      setIsWidgetBehaviourContent(true)
                      onUpdateSpecificRecord(index, result)
                    }}
                  />{' '}
                </div>
                <CommonInput
                  placeholder="URL"
                  style={{padding: "10px 15px", lineHeight: "1.21428571em"}}
                  name="value"
                  type="text"
                  defaultValue={item.value}
                  onChange={e => {
                    setIsWidgetBehaviourContent(true)
                    onUpdateSpecificRecord(index, e.target)
                  }}
                />{' '}
                {index === 0 ? (
                  
                  <CommonSelect
                    name="option"
                    style={{height: "38px"}}
                    className="set-hours-select"
                    options={['', 'AND', 'OR']}
                    defaultValue={item.option == '' ? '' : item.option == 'AND' ? 'AND' : 'OR'}
                    onChange={(e, result) => {
                      setIsWidgetBehaviourContent(true)
                      onUpdateSpecificRecord(index, result)
                    }}
                  />
                ) : (
                  <>
                   <CommonSelect
                    name="option"
                    style={{height: "38px"}}
                    className="set-hours-select"
                    options={['', 'AND', 'OR']}
                    defaultValue=""
                    onChange={(e, result) => {
                      setIsWidgetBehaviourContent(true)
                      onUpdateSpecificRecord(index, result)
                    }}
                  />
                  <img
                    style={{ marginLeft: "10px" }}
                    src={deleteIcon}
                    alt="delete"
                    onClick={() =>
                      onAddRemoveSpecificPage({
                        index
                      })
                    }
                  />
                  </>
                )}{' '}
                {index === 0 ? (
                  <div className="add-more-targets">
                    <img
                      src={iconCicle}
                      alt="plus"
                      onClick={() =>
                        onAddRemoveSpecificPage({
                          item
                        })
                      }
                    />{' '}
                  </div>
                ) : null}{' '}
              </div>
            )
          }) : null }
        {widgetObject['custom_triggers_status'] ? (
          <>
            <CommonButtons
              content="Save"
              btnClass="btn-blue"
              onClick={() => customTargetsTableData()}
            />
           
          </>
        ) : null}{' '}
        {widgetObject['custom_triggers_status'] ? (
          <div className="cutom-targets-listing">
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell> Key </Table.HeaderCell>{' '}
                 
                  <Table.HeaderCell> Operator </Table.HeaderCell>{' '}
                  <Table.HeaderCell> Value </Table.HeaderCell>{' '}
                  <Table.HeaderCell> Option </Table.HeaderCell>{' '}
                  <Table.HeaderCell> Actions </Table.HeaderCell>{' '}
                </Table.Row>{' '}
              </Table.Header>{' '}
              <Table.Body>
                {' '}
                {lstSpecificPageData.map((item, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell> {item.key} </Table.Cell>{' '}
                     
                      <Table.Cell> {item.operator} </Table.Cell>{' '}
                      <Table.Cell> {item.value} </Table.Cell>{' '}
                      <Table.Cell> {item.option} </Table.Cell>{' '}
                      <Table.Cell className="delete-icon">
                        <img
                          src={deleteIcon}
                          alt="placeholder"
                          onClick={() => onDeleteUrl(item.id)}
                        />{' '}
                      </Table.Cell>{' '}
                    </Table.Row>
                  )
                })}{' '}
              </Table.Body>{' '}
            </Table>{' '}
          </div>
        ) : null}{' '}
      </div>{' '}
      {widgetDataAPiSaveCancel ? (
        <>
          <CommonButtons
            style={{ marginTop: "20px" }}
            content="Save"
            btnClass="btn-blue"
            onClick={fetchWidget}
          />
         
        </>
      ) : null }
    </div>
  )
}
