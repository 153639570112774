import {
  GET_ALL_TEAMS,
  GET_BILLING_INFO,
  GET_CALLER_ID,
  GET_CALLFORWADING_SETTINGS,
  GET_CUSTOMER_INVOICES,
  GET_PAYMENT_LOG,
  GET_CHARGE_LOG,
  GET_MEMBER_ROLES,
  GET_NOTIFICATIONS_ACTIONS,
  GET_NOTIFICATIONS_WIDGET,
  GET_PAYMENT_METHOD,
  GET_PROFILE_DATA,
  GET_SMS_PLACEHOLDERS,
  GET_TEAMS,
  GET_TEAM_MEMBER,
  GET_TEAM_MEMBERS,
  GET_USER_INTEGRATIONS,
  GET_WEB_HOOK,
  GET_WIDGET_COUNTRIES,
  GET_WIDGET_DAYS,
  GET_WIDGET_INSTALL,
  GET_WIDGET_SETTINGS,
  SET_FLAG
} from '../actions/types'

const initialState = {}

export default function(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case GET_PROFILE_DATA:
      return {
        ...state,
        profile: payload
      }
    case GET_NOTIFICATIONS_WIDGET:
      return {
        ...state,
        notificationsWidgets: payload
      }
    case GET_NOTIFICATIONS_ACTIONS:
      return {
        ...state,
        notificationsActions: payload
      }

    case GET_CALLFORWADING_SETTINGS:
      return {
        ...state,
        callForwadingSettings: payload || {}
      }

    case GET_WIDGET_COUNTRIES:
      return {
        ...state,
        widgetCountries: payload
      }

    case GET_TEAM_MEMBER:
      return {
        ...state,
        teamMember: payload
      }

    case GET_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: payload
      }

    case GET_WIDGET_DAYS:
      return {
        ...state,
        widgetDays: payload
      }

    case GET_USER_INTEGRATIONS:
      return {
        ...state,
        userIntegrations: payload
      }

    case GET_TEAMS:
      return {
        ...state,
        teams: payload
      }

    case GET_ALL_TEAMS:
      return {
        ...state,
        allTeams: payload
      }

    case GET_MEMBER_ROLES:
      return {
        ...state,
        memberRoles: payload
      }

    case GET_CUSTOMER_INVOICES:
      return {
        ...state,
        customerInvoices: payload
      }

      case GET_PAYMENT_LOG:
        return {
          ...state,
          paymentLog: payload
        }
  
        case GET_CHARGE_LOG:
          return {
            ...state,
            recentChargeLog: payload
          }   

    case GET_BILLING_INFO:
      return {
        ...state,
        billingInfo: payload
      }

    case GET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: payload
      }

    case GET_WEB_HOOK:
      return {
        ...state,
        webHook: payload
      }

    case GET_WIDGET_INSTALL:
      return {
        ...state,
        widgetInstall: payload
      }

    case GET_SMS_PLACEHOLDERS:
      return {
        ...state,
        smsPlaceholders: payload
      }

    case GET_CALLER_ID:
      return {
        ...state,
        callerId: payload
      }

    case GET_WIDGET_SETTINGS:
      return {
        ...state,
        widget: payload
      }
    case SET_FLAG:
      return {
        ...state,
        ...payload
      }

    default:
      return state
  }
}
