import React, { Fragment } from 'react'
import { Button, Icon } from 'semantic-ui-react'

const CommonButtons = ({
  onClick,
  content,
  children,
  background,
  btnClass,
  type = 'button',
  icon = '',
  image = '',
  style = {},
  disabled,
  loading,
  ...rest
}) => {
  return (
    <>
      <Button
        style={style}
        onClick={onClick}
        type={type}       
        disabled={disabled || false}
        loading={loading}
        {...rest}
        className={`btn-${background} ${btnClass}`}
      >
        {image ? <img src={image} alt="img" /> : ''}
        {icon ? <Icon name={icon} /> : ''}
        {content}{children}
      </Button>
    </>
  )
}

export default CommonButtons
