import React from 'react'
import './style.scss'
import ReactFlagsSelect from 'react-flags-select'
import { Dropdown } from 'semantic-ui-react'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios'

const bussinessLimecall = [
  'Industry',
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies/Equipment',
  'Capital Markets/Hedge Fund/Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment/Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education/Acadamia',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual/Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology/IT',
  'Insurance',
  'International Affairs',
  'International Trade/Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management/Hedge Fund/Private Equity',
  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Advertising/Sales',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Facilities/Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
]

class AccountInfoComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentComponent: 'insights'
    }
  }

  componentDidMount = () => {
    this.setUpDropDown()
  }

  setUpDropDown = () => {
    let bussinessArr = []

    bussinessLimecall.forEach((score, index) => {
      const intObj = {
        key: index,
        text: score,
        value: score
      }
      bussinessArr.push(intObj)
    })

    this.setState({ bussinessOptionList: bussinessArr })
  }

  setCurrentComponent = data => {
    this.setState({ currentComponent: data })
  }

  getTimeOnSite = () => {
    const timer = this.props.currentCallData.trackingData
    if (timer && timer.time_on_site) {
      let seconds = parseInt(timer.time_on_site % 60, 10)
      let minutes = parseInt(timer.time_on_site / 60, 10)
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      return minutes + 'm ' + seconds + 's'
    }
    return ''
  }

  getBrowsingHistoryBlock = () => {
    const browserHistory = this.props.currentCallData.trackingData
      ? this.props.currentCallData.trackingData.browsing_history
      : null

    if (browserHistory) {
      const localHistory = browserHistory?.slice(1)?.slice(-10)
      return localHistory.map(data => (
        <div className="info-label-full-width">
          <i className="material-icons call-made-icon">call_made</i>
          <a href={data.page_url}>{data.page_url}</a>
        </div>
      ))
    }
  }

  handleChangeInput = async (value, key = 'callDisposition') => {
    await this.setState(
      {
        [key]: value
      },
      () => {
        this.saveCallData()
      }
    )
  }

  saveCallData = () => {
    let token = localStorage.getItem('access_token')

    const lead_Id = this.props.currentCallData.id
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${lead_Id}/info`

    var re = /\S+@\S+\.\S+/
    var res = re.test(this.state.email)

    let email = ''
    if (res) {
      email = this.state.email
    }

    const postData = {
      customer_name: this.state.customerName,
      email,
      phone_number: this.state.phoneNumber,
      company_name: this.state.companyName,
      industry: this.state.industry,
      country: this.state.country
    }

    axios
      .post(url, postData)
      .then(res => {})
      .catch(err => {})
  }

  render = () => {
    const trackingData = this.props.currentCallData.trackingData
    return (
      <div className="hor-row account-info-container">
        <div className="hor-row account-tab-container">
          <div
            className={
              'quarter-container' +
              (this.state.currentComponent === 'lead-info'
                ? ' active-quarter'
                : '')
            }
            onClick={() => this.setCurrentComponent('lead-info')}
          >
            LEAD INFO
          </div>

          <div
            className={
              'quarter-container' +
              (this.state.currentComponent === 'insights'
                ? ' active-quarter'
                : '')
            }
            onClick={() => this.setCurrentComponent('insights')}
          >
            INSIGHTS
          </div>

          {/* <div className={'quarter-container' + ( this.state.currentComponent === 'technology' ? ' active-quarter' : '') }
                onClick = {() => this.setCurrentComponent('technology')}>
                TECHNOLOGY
              </div> */}
          {/* <div className={'quarter-container' + ( this.state.currentComponent === 'visited' ? ' active-quarter' : '') }
                onClick = {() => this.setCurrentComponent('visited')}>
                VISITED
              </div> */}
        </div>

        {this.state.currentComponent === 'lead-info' && (
          <div className="hor-row account-info-container">
            <div className="hor-row account-info-block">
              <div className="info-label">Name</div>
              <div className="info-value">
                <input
                  style={{
                    width: '194px',
                    background: 'inherit',
                    color: '#fff',
                    border: '1px solid #A4ABC5',
                    fontSize: '12px',
                    borderRadius: '5px',
                    marginTop: '-4px',
                    height: '30px'
                  }}
                  type="text"
                  value={this.state.customerName}
                  onChange={event =>
                    this.handleChangeInput(event.target.value, 'customerName')
                  }
                />
              </div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Email</div>
              <div className="info-value">
                <input
                  style={{
                    width: '194px',
                    background: 'inherit',
                    color: '#fff',
                    border: '1px solid #A4ABC5',
                    fontSize: '12px',
                    borderRadius: '5px',
                    marginTop: '-4px',
                    height: '30px'
                  }}
                  type="text"
                  value={this.state.email}
                  onChange={event =>
                    this.handleChangeInput(event.target.value, 'email')
                  }
                />
              </div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Company</div>
              <div className="info-value">
                <input
                  style={{
                    width: '194px',
                    background: 'inherit',
                    color: '#fff',
                    border: '1px solid #A4ABC5',
                    fontSize: '12px',
                    borderRadius: '5px',
                    marginTop: '-4px',
                    height: '30px'
                  }}
                  type="text"
                  value={this.state.companyName}
                  onChange={event =>
                    this.handleChangeInput(event.target.value, 'companyName')
                  }
                />
              </div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Phone</div>
              <div className="info-value">
                <PhoneInput
                  style={{
                    backgroundColor: 'inherit',
                    color: 'rgb(255, 255, 255)',
                    border: '1px solid rgb(164, 171, 197)',
                    marginTop: '-4px',
                    marginBottom: '5px',
                    height: '30px',
                    borderRadius: '5px',
                    width: '194px',
                    fontSize: '12px',
                    borderRadius: '5px',
                    marginTop: '-4px',
                    height: '30px'
                  }}
                  country={'eg'}
                  value={this.state.phoneNumber}
                  onChange={phone =>
                    this.handleChangeInput(phone, 'phoneNumber')
                  }
                  className="custom-dropdown-class"
                />
              </div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Industry</div>
              <div className="info-value">
                <Dropdown
                  style={{
                    backgroundColor: 'inherit',
                    width: '194px',
                    fontSize: '12px',
                    color: 'rgb(255, 255, 255)',
                    border: '1px solid rgb(164, 171, 197)',
                    marginTop: '-4px',
                    height: '30px',
                    borderRadius: '5px'
                  }}
                  onChange={(e, { value }) =>
                    this.handleChangeInput(value, 'industry')
                  }
                  options={this.state.bussinessOptionList}
                  selection
                  value={this.state.industry}
                  className="custom-dropdown-class"
                />
              </div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Country</div>
              <div className="info-value">
                <ReactFlagsSelect
                  selected={this.state.country}
                  className="custom-dropdown-class"
                  onSelect={code => this.handleChangeInput(code, 'country')}
                  style={{
                    backgroundColor: 'inherit',
                    width: '205px',
                    color: 'rgb(255, 255, 255)',
                    fontSize: '12px',
                    border: '1px solid rgb(164, 171, 197)',
                    height: '30px',
                    borderRadius: '5px'
                  }}
                />
              </div>
            </div>

            <div className="hor-row input-form-row">
              <button
                className="save-button-container"
                onClick={() => this.saveCallData()}
              >
                Save
              </button>
            </div>
          </div>
        )}

        {this.state.currentComponent === 'insights' && (
          <div className="hor-row account-info-container">
            <div className="hor-row account-info-block">
              <div className="info-label">Customer Name</div>
              <div className="info-value">{this.state.customerName}</div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Email</div>
              <div className="info-value">{this.state.email}</div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Phone Number</div>
              <div className="info-value">{this.state.phoneNumber}</div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Company</div>
              <div className="info-value">{this.state.CompanyName}</div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Industry</div>
              <div className="info-value">{this.state.industry}</div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Country</div>
              <div className="info-value">{this.state.country}</div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">IP Address</div>
              <div className="info-value">
                {this.props.currentCallData.ip_address}
              </div>
            </div>

            <div className="hor-row account-info-block">
              <div className="info-label">Browser</div>
              <div className="info-value">
                {trackingData && trackingData.browser
                  ? trackingData.browser.name +
                    ' ' +
                    trackingData.browser.version_major
                  : ' '}
              </div>
            </div>
            <div className="hor-row account-info-block">
              <div className="info-label">OS</div>
              <div className="info-value">
                {trackingData ? trackingData.os : ' '}
              </div>
            </div>
            <div className="hor-row account-info-block">
              <div className="info-label">Device</div>
              <div className="info-value">
                {trackingData && trackingData.device
                  ? trackingData.device.name + ' ' + trackingData.device.brand
                  : ' '}
              </div>
            </div>
          </div>
        )}

        {/* {this.state.currentComponent === 'technology' && <div className='hor-row account-info-container'>
              <div className='hor-row account-info-block'>
                <div className='info-label'>
                  Browser 
                </div>
                <div className='info-value'>
                  {(trackingData && trackingData.browser ) ? (trackingData.browser.name + ' ' + trackingData.browser.version_major)  : ' '  }
                </div>
              </div>
              <div className='hor-row account-info-block'>
                <div className='info-label'>
                  OS
                </div>
                <div className='info-value'>
                  {trackingData ? trackingData.os  : ' '  }
                </div>
              </div>
              <div className='hor-row account-info-block'>
                <div className='info-label'>
                  Device
                </div>
                <div className='info-value'>
                   {(trackingData && trackingData.device) ? (trackingData.device.name + ' ' + trackingData.device.brand)  : ' '  }
                </div>
              </div>
            </div>
            } */}

        {/* {this.state.currentComponent === 'visited' && <div className='hor-row account-info-container'>
              <div className='hor-row account-info-block'>
                <div className='info-label'>
                  Last seen:
                </div>
                <div className='info-value'>
                  { (trackingData && trackingData.last_interaction) ? ( new Date(trackingData.last_interaction).toLocaleDateString() + ' ' +   new Date(trackingData.last_interaction).toLocaleTimeString() ) : ' '  }
                </div>
              </div>
              <div className='hor-row account-info-block'>
                <div className='info-label'>
                  Time On Site
                </div>
                <div className='info-value'>
                  { this.getTimeOnSite()}
                </div>
              </div>
              <div className='hor-row account-info-block'>
                { this.getBrowsingHistoryBlock()}
              </div>
            </div>
            } */}
      </div>
    )
  }
}

export default AccountInfoComponent
