import axios from 'axios'
import React, { useState } from 'react'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import { Input } from 'semantic-ui-react'
import { dynamicId } from '../../helpers/dynamicId'
const useLimeCall = [
  'Text Campaigns',
  'Web to text',
  'Lead distribution or Routing',
  'To buy virtual numbers',
  'To receive inbound calls',
  'To send/receive SMS',
  'Call Tracking',
  'Callback Automation'
]

// const useLimeCall = [
//   "Receive instant call back",
//   "Handle inbound calls",
//   "Handle outbound calls",
//   "Call Tracking"
// ]
const bussinessLimecall = [
  'Travel',
  'Finance',
  'Digital agency',
  'SaaS',
  'Insurance',
  'Automotive',
  'Retail',
  'E-commerce',
  'Real estate',
  'Education',
  'Healthcare',
  'Legal',
  'Others'
]
const roleLimeCall = [
  'Founder',
  'Sale Manager',
  'Marketing / PR Manager',
  'Customer Services Manager',
  'CXO / General Manager',
  'It Manager',
  'Operation Manager',
  'Developer / software Engineer / Analyst',
  'Student / Personal Interest',
  'other'
]

const wherehHearAboutus = [
  'Appsumo',
  'Google Ads',
  'Word Of Mouth',
  'Blog Article',
  'Reddit',
  'Google search',
  'G2crowd',
  'Podcast',
  'Others'
]

const orgLImecall = [
  '1 User',
  '2-10 Users',
  '11-50 Users',
  '51-100 Users',
  '101-500 Users',
  '500+ Users'
]

const CallTrackingLeadInformation = props => {
  const [field, setField] = useState({
    achieve: '',
    company_field: '',
    employee_number: '',
    role: '',
    hearing_source: '',
    domain: ''
  })
  const [errors, setErrors] = useState({
    achieve: '',
    company_field: '',
    employee_number: '',
    role: '',
    hearing_source: '',
    domain: ''
  })

  const [loading, setLoading] = useState(false)

  const validate = (name, value) => {
    switch (name) {
      // case 'achieve':
      //   if (!value) {
      //     return 'Achieve is Required'
      //   } else {
      //     return ''
      //   }
      case 'company_field':
        if (!value) {
          return 'Company field is Required'
        } else {
          return ''
        }
      case 'role':
        if (!value || value.trim() === '') {
          return 'Role is Required'
        } else {
          return ''
        }
      case 'employee_number':
        if (!value) {
          return 'Organization is Required'
        } else {
          return ''
        }
      case 'hearing_source':
        if (!value) {
          return 'Where you heard about us is Required'
        } else {
          return ''
        }
      case 'domain':
        if (!value) {
          return 'Domain URL is Required'
        } else {
          return ''
        }

      default: {
        return ''
      }
    }
  }

  const increaseStep = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/increase-onboarding`
    axios
      .post(url, {})
      .then(res => {
        setLoading(false)
        CommonNotify('Successfully added', 'success')
        props.next()
      })
      .catch(err => {})
  }

  const handleUserInput = e => {
    setErrors({
      ...errors,
      [e.target.name]: validate(e.target.name, e.target.value)
    })
    setField({ ...field, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    let validationErrors = {}

    const setup = localStorage.getItem('quick_setup')

    Object.keys(field).forEach(name => {
      const error = validate(name, field[name])
      if (error && error?.length > 0) {
        validationErrors[name] = error
      }
    })
    if (Object.keys(validationErrors)?.length > 0) {
      setErrors(validationErrors)
      return
    }

    if (setup) {
      setLoading(true)

      const data = {
        achieve: field?.achieve || '',
        company_field: field.company_field,
        employee_number: field.employee_number,
        role: field.role,
        hearing_source: field?.hearing_source,
        domain: field?.domain
      }

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/onboarding-info`
      axios
        .post(url, data)
        .then(res => {
          if (res?.data?.message == 'Successfully') {
            increaseStep()
          }
        })
        .catch(error => {
          setLoading(false)
          CommonNotify('Not able to add information.')
        })
    }
  }

  return (
    <>
      <div className="p-5">
        <h2 className="setUpHead">Tell us a bit about yourself</h2>
        <h6 className="">
          You deserve tools that work just right for you. Please answer a few
          quick questions and help us tailor your software to your needs
        </h6>
        {/* <div>
            <div>
              <label>What will you use LimeCall for?</label>
              <select
                style={field.achieve == '' ? { color: 'grey' } : {}}
                name="achieve"
                value={field.achieve}
                onChange={event => handleUserInput(event)}
              >
                <option value="">Your Purpose</option>
                {useLimeCall.map(option => (
                  <option key={dynamicId()} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <span className="text-danger">{errors.achieve}</span>
            </div>
          </div> */}
        <div>
          <label>What do you want to use LimeCall for?</label>
          <select
            style={field.achieve == '' ? { color: 'grey' } : {}}
            value={field.achieve}
            name="achieve"
            onChange={event => {
              handleUserInput(event)
            }}
          >
            {useLimeCall.map(option => (
              <option key={dynamicId()} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div>
            <span className="text-danger">{errors.employee_number}</span>
          </div>
        </div>
        <div>
          <label>What best describes your business?</label>
          <select
            style={field.company_field == '' ? { color: 'grey' } : {}}
            name="company_field"
            value={field.company_field}
            onChange={event => handleUserInput(event)}
          >
            <option value="">Company Type</option>
            {bussinessLimecall.map(option => (
              <option key={dynamicId()} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div>
            <span className="text-danger">{errors.company_field}</span>
          </div>
        </div>
        <div>
          <label>What is your role ?</label>
          <select
            style={field.role == '' ? { color: 'grey' } : {}}
            value={field.role}
            name="role"
            onChange={event => handleUserInput(event)}
          >
            <option value="">Your Role</option>
            {roleLimeCall.map(option => (
              <option key={dynamicId()} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div>
            <span className="text-danger">{errors.role}</span>
          </div>
        </div>
        <div>
          <label>How did you hear about Limecall ?</label>
          <select
            style={field?.hearing_source == '' ? { color: 'grey' } : {}}
            value={field?.hearing_source}
            name="hearing_source"
            onChange={event => handleUserInput(event)}
          >
            <option value="">Where you hear about us</option>
            {wherehHearAboutus?.map(option => (
              <option key={dynamicId()} value={option}>
                {option}
              </option>
            ))}
          </select>
          <div>
            <span className="text-danger">{errors?.hearing_source}</span>
          </div>
        </div>
        <div>
          <label>How many users in your team will be using limecall?</label>
          <select
            style={field.employee_number == '' ? { color: 'grey' } : {}}
            value={field.employee_number}
            name="employee_number"
            onChange={event => handleUserInput(event)}
          >
            <option value="">No of users</option>
            {orgLImecall.map(option => (
              <option key={dynamicId()} value={option?.split(' ')?.[0]}>
                {option}
              </option>
            ))}
          </select>
          <div>
            <span className="text-danger">{errors.employee_number}</span>
          </div>
        </div>
        <div>
          <label>What website would you be using limecall on?</label>
          <br></br>
          <Input
            name="domain"
            placeholder="Ex:www.google.com"
            style={
              field?.domain == ''
                ? { color: 'grey', width: '100%' }
                : { width: '100%' }
            }
            value={field?.domain}
            onChange={event => handleUserInput(event)}
          />
          <div>
            <span className="text-danger">{errors?.domain}</span>
          </div>
        </div>

        {/* <div className="quicksetup-footer">
          <CommonButtons
            type="button"
            content="Continue"
            background="blue"
            onClick={handleSubmit}
            loading={loading}
          />
        </div> */}
      </div>

      <div
        className="quicksetup-footer footer-button mt-auto"
        style={{ justifyContent: 'space-between' }}
      >
        <CommonButtons
          content="Back"
          // background="grey"
          btnClass="btn-modal-style btn-back"
          onClick={props.prev}
        />
        <CommonButtons
          type="button"
          content="Continue"
          background="blue"
          onClick={handleSubmit}
          loading={loading}
        />
        {/* <CommonButtons
          content="Skip"
          background="grey"
          style={{ height: '50px' }}
          btnClass="btn-modal-style"
          onClick={()=>increaseStep()}
        /> */}
      </div>
    </>
  )
}
export default CallTrackingLeadInformation
