import React, { useState, useEffect } from 'react'
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { CommonNotify } from '../common/CommonNotify'
import Title from '../common/Title'
import icon from '../assets/images/settingIcon.png'
import Navbar from '../components/navbar/Navbar'

function PipedriveRedirection(props) {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [channle, setChannle] = useState(null)
  const [savedChannle, setSavedChannle] = useState({})
  const [selectChannle, setSelectChannle] = useState(null)

  const titleContent = {
    type: 'image',
    titleOne: icon,
    titleTwo: 'Pipedrive Redirection'
  }
  useEffect(() => {
    integrateSlack()
    document.title = 'Slack Redirection'
  }, [])

  const integrateSlack = () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/store-pipedrive-integration`

    const query = props.location.search
    const code = query.split('code=')[1].split('&')[0]
    const data = {
      code: code
    }
    axios
      .post(url, data)
      .then(res => {
        setLoading(false)
        const data = []
        res.data.result.map(res => {
          return data.push({ key: res[0], value: res[0], text: res[1] })
        })
        onSaveChannleHandler()
        history.push('/settings/integration')

        setChannle(data)
        setSavedChannle(res.data.saved)
      })
      .catch(error => {
        CommonNotify('something went wrong')
      })
  }

  const updateDropDownValues = (ele, data) => {
    const { name, value } = data
    setSelectChannle(value)
  }

  const onSaveChannleHandler = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/save-channels`
    axios
      .post(url, { channel: selectChannle })
      .then(res => {
        CommonNotify(res.data.message[0], 'success')
        history.push('/settings')
      })
      .catch(error => {})
  }
  return (
    <div className="slack_wpapper">
      <Title data={titleContent} />
      <Navbar />
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
    </div>
  )
}

export default PipedriveRedirection
