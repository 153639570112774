/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import home from '../../assets/images/Icons-nav-home.svg'
import homeActive from '../../assets/images/home-black.svg'
import phone from '../../assets/images/Icons-nav-leads.png'
import phoneActive from '../../assets/images/phone-black.svg'
import conversation from '../../assets/images/conversations-icon.svg'
import myPhone from '../../assets/images/Icons-nav-meetings.svg'
import myPhoneActive from '../../assets/images/phone-black.svg'
import square from '../../assets/images/Icons-nav-widgets.svg'
import squareActive from '../../assets/images/square-black.svg'
import twoPerson from '../../assets/images/Icons-nav-meetings.svg'
import twoPersonActive from '../../assets/images/two-person-black.svg'
import settings from '../../assets/images/Icons-nav-settings.svg'
import settingsActive from '../../assets/images/settings-black.svg'
import analytics from '../../assets/images/analytics-white.svg'
import analyticsActive from '../../assets/images/analytics-black.svg'
import campaign from '../../assets/images/campaign-white.svg'
import analyticsCampaign from '../../assets/images/campaign-black.svg'
import analyticsNew from '../../assets/images/Analytics.svg'
import widgetNew from '../../assets/images/Widget.svg'
import leadsNew from '../../assets/images/Leads.svg'
import numberNew from '../../assets/images/Numbers.svg'
import contactNew from '../../assets/images/Contact.svg'
import campaignNew from '../../assets/images/Campaign-v2.svg'
import settingsNew from '../../assets/images/settings.svg'
import inboxNew from '../../assets/images/Inbox.svg'
import ContactBook from '../../assets/images/ContactIcon.svg'
import clarity from '../../assets/images/clarity.png'
import FlagIcon from '@mui/icons-material/Flag';
import Mynumber from '../../assets/images/Icons-nav-numbers.svg'
import notification from '../../assets/images/Icons-nav-notifications.svg'
import StartIcon from '../../assets/images/start-icon.svg';
import CardView from '../../assets/images/cardView.svg';
import MeetingIcon from '../../assets/images/meetingIcon.png';
import MyForms from '../../assets/images/forms.png';
import callTrack from '../../assets/images/call-track.png';
import MeetingIconActive from '../../assets/images/meetingIconActive.png';

export const menus = [
  // {
  //   handle: 'menuStart',
  //   stateKey: 'activeStart',
  //   url: '/get-started',
  //   inactiveIcon: StartIcon,
  //   activeIcon: <StartIcon />,
  //   hoverName: 'Start'
  // },
  {
    handle: 'menuHome',
    stateKey: 'activeHome',
    url: '/home',
    inactiveIcon: home,
    activeIcon: homeActive,
    hoverName: 'Home'
  },
  {
    handle: 'menuPhone',
    stateKey: 'activePhone',
    url: '/leads',
    inactiveIcon: phone,
    activeIcon: phoneActive,
    hoverName: 'Leads',
    showCount:true,
    count:localStorage.getItem('pendingLeads')
  }, 
 
  // {
  //   handle: 'menuPipeline',
  //   stateKey: 'activePipeline',
  //   url: '/pipeline',
  //   inactiveIcon: CardView,
  //   activeIcon: CardView,
  //   hoverName: 'Pipeline'
  // },
  {
    handle: 'menuConversation',
    stateKey: 'activeConversation',
    url: '/conversation',
    inactiveIcon: conversation,
    activeIcon: conversation,
    hoverName: 'Inbox',
    showCount:true,
    count:localStorage.getItem('unread_message')
  },
  //  {
  //    handle: 'menuMyPhone',
  //    stateKey: 'activeMyPhone',
  //    url: '/my-leads',
  //    inactiveIcon: myPhone,
  //    activeIcon: myPhoneActive,
  //    hoverName: 'My Leads'
  //  },
  // {
  //   handle: 'menuSquare',
  //   stateKey: 'activeSquare',
  //   url: '/widgets',
  //   inactiveIcon: square,
  //   activeIcon: squareActive,
  //   hoverName: 'Widget'
  // },

  {
    handle: 'menuContact',
    stateKey: 'activeContact',
    url: '/contacts',
    inactiveIcon: ContactBook,
    activeIcon: squareActive,
    hoverName: 'Contacts'
  },
  {
    handle: 'menuNumbers',
    stateKey: 'activeNumbers',
    url: '/numbers',
    inactiveIcon: Mynumber,
    activeIcon: Mynumber,
    hoverName: 'Numbers'
  },
  // {
  //   handle: 'menuForms',
  //   stateKey: 'activeForms',
  //   url: '/forms',
  //   inactiveIcon: MyForms,
  //   activeIcon: MyForms,
  //   hoverName: 'Forms'
  // },
  {
    handle: 'menuCampaign',
    stateKey: 'activeCampaign',
    url: '/campaign',
    inactiveIcon: campaign,
    activeIcon: analyticsCampaign,
    hoverName: 'Campaign'
  },
  // {
  //   handle: 'activeMeeting',
  //   stateKey: 'activeMeeting',
  //   url: '/meetings',
  //   inactiveIcon: MeetingIcon,
  //   activeIcon: MeetingIcon,
  //   hoverName: 'Meetings',
  // }, 
  // {
  //   handle: 'menuCallTracking',
  //   stateKey: 'activeCallTracking',
  //   url: '/call-tracking',
  //   inactiveIcon: callTrack,
  //   activeIcon: callTrack,
  //   hoverName: 'Call tracking'
  // },
  {
    handle: 'menuAnalytics',
    stateKey: 'activeAnalytics',
    url: '/analytics',
    inactiveIcon: analytics,
    activeIcon: analyticsActive,
    hoverName: 'Analytics'
  },
 
  {
    handle: 'menuSettings',
    stateKey: 'activeSettings',
    url: '/settings/personal',
    inactiveIcon: settings,
    activeIcon: settingsActive,
    hoverName: 'Settings'
  },
 
  // {
  //   handle: 'menuForms',
  //   stateKey: 'activeForms',
  //   url: '/custom-forms',
  //   inactiveIcon: analytics,
  //   activeIcon: analyticsActive,
  //   hoverName: 'Forms'
  // },
 
 
]

export const Operatormenus = [
  {
    handle: 'menuHome',
    stateKey: 'activeHome',
    url: '/home',
    inactiveIcon: home,
    activeIcon: homeActive,
    hoverName: 'Home'
  },
  {
    handle: 'menuPhone',
    stateKey: 'activePhone',
    url: '/manageleads',
    inactiveIcon: phone,
    activeIcon: phoneActive,
    hoverName: 'Leads'
  },
  {
    handle: 'menuNumbers',
    stateKey: 'activeNumbers',
    url: '/numbers',
    inactiveIcon: Mynumber,
    activeIcon: Mynumber,
    hoverName: 'Numbers'
  },
  {
    handle: 'menuCampaign',
    stateKey: 'activeCampaign',
    url: '/campaign',
    inactiveIcon: campaign,
    activeIcon: analyticsCampaign,
    hoverName: 'Campaign'
  },
  {
    handle: 'menuAnalytics',
    stateKey: 'activeAnalytics',
    url: '/analytics',
    inactiveIcon: analytics,
    activeIcon: analyticsNew,
    hoverName: 'Analytics'
  },
  {
    handle: 'menuSettings',
    stateKey: 'activeSettings',
    url: '/settings/personal',
    inactiveIcon: settings,
    activeIcon: settingsActive,
    hoverName: 'Settings'
  },

]
