import React, { useMemo, useState, useEffect } from 'react'
import { Tab, Table } from 'semantic-ui-react'

import messageIcon from '../../assets/images/messageIcon.png'
import celender from '../../assets/images/celender.png'
import contactprofile from '../../assets/images/contactprofile.png'
import Ellipse from '../../assets/images/Ellipse-39.png'
import callIcon from '../../assets/images/call_icon.png'
import personIcon from '../../assets/images/person.png'
import OwnerModel from './ownerModel'
import CommonCheckbox from '../../common/CommonCheckbox'

const CompanyDetailsMain = ({
  contactData,
  modalOpen,
  opendetails,
  onClickData,
  fetchId,
  setActiveType,
  setisMarkAllCheckbox,
  isMarkAllCheckbox,
  onClickMarkAllCheckbox,
  onClickMarkCheckbox,
  onClickUnmarkCheckboxReset
}) => {
  const [assignedPopUp, setAssigned] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    onClickUnmarkCheckboxReset()
  }, [])

  return (
    <Tab.Pane attached={false}>
      <Table singleLine className={opendetails == true ? 'tablepopup' : ''}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <CommonCheckbox
                onChange={onClickMarkAllCheckbox}
                name="isMarkAllCheckbox"
                checked={isMarkAllCheckbox}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {contactData?.map((data, index) => {

          return (
            <>
              {data.type == 'organization' ? (
                <Table.Body>
                  <Table.Row data-key={index}>
                    <Table.Cell data-key={index}>
                      <CommonCheckbox
                        onChange={e => onClickMarkCheckbox(e, index)}
                        name="isChecked"
                        checked={data.isChecked}
                      />
                    </Table.Cell>
                    <Table.Cell
                      className="time-set-table"
                      onClick={() => {
                        modalOpen()
                        setActiveType('company')
                        onClickData(data.id)
                      }}
                    >
                      {data.image ? (
                        <div className="date-set-table-boxes">
                          <img
                            className="ui medium circular image"
                            src={data?.image}
                            alt=""
                          />
                          <div>
                            <p>{data?.organization_name}</p>
                          </div>
                        </div>
                      ) : (
                        data?.organization_name
                      )}
                    </Table.Cell>
                    <Table.Cell
                      className="time-set-table"
                      onClick={() => {
                        modalOpen()
                        setActiveType('company')
                        onClickData(data.id)
                      }}
                    >
                      {data?.phone_number}
                    </Table.Cell>
                    <Table.Cell
                      className="metting-name"
                      onClick={() => {
                        modalOpen()
                        onClickData(data.id)
                      }}
                    >
                      {data.email}
                    </Table.Cell>

                    <Table.Cell
                      className="link-table"
                      onClick={() => {
                        modalOpen()
                        setActiveType('company')
                        onClickData(data.id)
                        setActiveType('company')
                      }}
                    >
                      {data?.owner?.first_name}
                    </Table.Cell>
                    <Table.Cell
                      className="link-table"
                      onClick={() => {
                        modalOpen()
                        onClickData(data.id)
                      }}
                    >
                      {data?.created_at}
                    </Table.Cell>

                    <Table.Cell
                      className="host-table"
                      onClick={() => {
                        modalOpen()
                        setActiveType('company')
                        onClickData(data.id)
                      }}
                    >
                      <div className="leadtable">
                        <img
                          className="ui medium circular image"
                          src={Ellipse}
                          alt=""
                        />
                        <p>LEAD</p>
                      </div>
                    </Table.Cell>

                    <Table.Cell className="social-icon-set">
                      <div className="social-box-wrapper">
                        <div
                          className="social-boxes"
                          onClick={() => {
                            onClickData(data.id)
                            setSelected(data)
                            setAssigned(true)
                          }}
                        >
                          <img
                            className="ui medium circular image"
                            src={personIcon}
                            alt=""
                          />
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : (
                ''
              )}
            </>
          )
        })}
        {assignedPopUp ? (
          <OwnerModel
            fetchId={fetchId}
            selected={selected}
            assignedPopUp={assignedPopUp}
            setAssigned={setAssigned}
          />
        ) : (
          ''
        )}
      </Table>
    </Tab.Pane>
  )
}

export default CompanyDetailsMain
