import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import Dropzone from 'react-dropzone-uploader'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import NodeToggle from '../../common/NodeToggle'
import CommonGroupButton from '../../common/CommonGroupButton2'
import CustomizeGroupButton from '../../common/CustomizeGroupButton2'
import CommonColor from '../../common/CommonColor'
import CommonInput from '../../common/CommonInput'
import CommonButton from '../../common/CommonButtons'

import iconStyle from '../../assets/images/Dashboard 2-07.png'
import widgetBubble from '../../assets/images/phone.svg'
import axios from 'axios'
import { CommonNotify } from '../../common/CommonNotify'
import CommonButtons from '../../common/CommonButtons'
import CommonTextArea from '../../common/CommonTextArea'
import CommonSelect from '../../common/CommonSelect'
import Brightness5Icon from '@mui/icons-material/Brightness5';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Button, Dropdown, Icon } from 'semantic-ui-react'
import { IconButton } from '@mui/material'
import Toggle from '../../common/CommonToggle'
import getScript from '../../config/getScript'
import WidgetPreview from './WidgetPreview'
import ButtonPreview from './ButtonPreview'
import { SET_FLAG } from '../../actions/types'
import { useDispatch } from 'react-redux'
import { getToggleObj } from './WidgetStyles'

const widgetDefaultSetting = {
  template: 'Classic'
}

const apiToken = localStorage.getItem('access_token')

const bubbleDefaultSetting = {
  color: '#0080FF',
  shadow: false,
  textOnline: 'Would you like us to call you now ?',
  textOffline: 'Hey there! We are offline now ?',
  position: '',
  labelColor: '#2B2B2C',
  shape: 'default'
}

export const GreetingStyleTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>
    <div className="accordion-title-holder">
      <h2 className="accordion-title">Design your brand style</h2>
      <p className="accordion-description">
        Personalize your widget branding, colours and style
      </p>
    </div>
  </div>
)
export const CustomizeButtonTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>
    <div className="accordion-title-holder">
      <h2 className="accordion-title">Customize your call button</h2>
      <p className="accordion-description">
        Customize your call button, manage greetings and triggers
      </p>
    </div>
  </div>
)
const popupText = {
  callTitle: '',
  callDesc: 'bubble_popup_cta_status',
  callId: 'bubble_popup_cta_status',
  callref: 'bubble_popup_cta_status'
}
export const WidgetGreetingStyleContent = ({
  widget,
  setBubbleSett,
  setBubbleSettData,
  showShadowWidgetToggle,
  widgetShadow,
  widgetShape,
  loading,
  onClick,
  widgetObject,
  reloadWidget,
  dataReloaded,
  widgetBubbleColor,
  subScription
}) => {


  const [widgetAllSetting, setWidgetALlSetting] = useState(null)
  const [widgetSetting, setWidgetSetting] = useState(widgetDefaultSetting)
  const [bubbleSetting, setBubbleSetting] = useState(bubbleDefaultSetting)
  const [bubbleSettingData, setBubbleSettingData] = useState({})
  const [bubbleApiSettingData, setBubbleApiSettingData] = useState({})
  const [apiLoaded, setApiLoaded] = useState(false)
  const [isInputChange, setIsInputChange] = useState(false)
  const [logo, setLogo] = useState(null)
  const [getLogoApi, setGetLogoApi] = useState(null)
  const [file, setFile] = useState()
  const [allIcons, setAllIcons] = useState([])
  const [icons, setIcons] = useState([])
  const [icon, setIcon] = useState(widget?.data?.[0]?.branding_icon_url)
  const [iconOptions, setIconOptions] = useState('')
  const dispatch = useDispatch()
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const fetchSetting = () => {
    let tmpData = widgetObject;
    setBubbleSettingData(tmpData);
    setBubbleApiSettingData(widgetObject)
  }

  const getCompany = async () => {
    const apiToken = await localStorage.getItem('access_token')
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          setLogo(res.data.data.company.company_logo)
          setGetLogoApi(res.data.data.company.company_logo)
        }
      })
      .catch(er => { })
  }
  useEffect(() => {
    getIcon()
    fetchSetting()
    setApiLoaded(true)
    setLogo(widgetObject?.company_logo)
    // getIcons()
  }, [widgetObject])


  const updateLogo = async ({ file }) => {
    if (getLogoApi === logo) {
      return
    }
    loading(true)

    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/company-logo`
    const form = new FormData()
    form.append('company_logo', logo)

    axios
      .post(url, form)
      .then(res => {
        loading(false)
        if (res.data.message == 'Successfully') {
          CommonNotify('Logo successfully updated...', 'success')
          // getCompany()
        } else {
          CommonNotify('Data cannot saved System error occurred...')
        }
      })
      .catch(err => {
        loading(false)
      })
  }

  const deleteLogoURL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/remove-company-logo`;

  const updateBubbleSettingGroup = (val, key, name) => {

    updateBubbleSetting(key, name)
  }
  const updateWhiteLabel = (val, value) => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.data?.[0]?.id}/branding-status`
    axios
      .put(url, { [val]: value })
      .then(res => {
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          CommonNotify('Updated Widget Setting', 'success')
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })

  }
  const updateIcon = (val, type) => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/branding-icon`
    axios
      .post(url, {
        widget_id: widget?.data?.[0]?.id,

        type: type, branding_icon_url: val
      })
      .then(res => {
        setIcon(val)
        dispatch({
          type: SET_FLAG,
          payload: { ['icon_url']: val }
        })
        getScript()
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          CommonNotify('Updated Widget Setting', 'success')
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })

  }
  const getIcon = (val, value) => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/default-branding-icons`
    axios
      .get(url, {
        widget_id: widget?.data?.[0]?.id,

        type: 'assign', [val]: value
      })
      .then(res => {
        setIcons(res?.data?.data)
        loading(false)
        if (res.data.message[0] == 'Successfully') {
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })

  }

  const saveBubbleSetting = () => {
    let data = { ...bubbleSettingData }


    if (data.bubble_position === 'btnBottomRight') {
      data.bubble_position = 'bottom_right'
    } else if (data.bubble_position === 'btnBottomLeft') {
      data.bubble_position = 'bottom_left'
    } else if (data.bubble_position === 'btnTopLeft') {
      data.bubble_position = 'top_left'
    } else if (data.bubble_position === 'btnTopRight') {
      data.bubble_position = 'top_right'
    }

    if (data.shape === 'btnRight') {
      data.shape = 'square'
    } else if (data.shape === 'btnLeft') {
      data.shape = 'default'
    }

    postSetting(data)
  }

  const postSetting = data => {
    loading(true)

    const updatedData = { ...data, ultimate_default_screen: data?.ultimate_default_screen?.toString() }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/set-customizations`
    axios
      .post(url, updatedData)
      .then(res => {
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          reloadWidget()
          CommonNotify('Updated Widget Setting', 'success')

          setIsInputChange(false)
          if (logo) {
            uploadLogo(logo)
          }
          reloadWidget()
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }
  const getIcons = data => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/default-branding-icons`
    axios
      .get(url, data)
      .then(res => {
        loading(false)
        if (res.data.message[0] == 'Successfully') {
          setAllIcons(res?.data?.data)
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }

  const setWidget = data => {
    let widgetSettings = { ...widgetSetting }
    widgetSettings.template = 'Modern'
    if (data.template_type == 2) {
      widgetSettings.template = 'Smart'
    } else if (data.template_type == 1) {
      widgetSettings.template = 'Classic'
    }

    widgetSettings.classicType =
      data.classic_template_design_type == 1 ? false : true
    widgetSettings.font = data.font

    widgetSettings.timerColor = data.popup_timer_color
    widgetSettings.callColor = data.submit_request_call_color

    widgetSettings.socialProof = data.social_proof
    widgetSettings.legalNote = data.terms_and_conditions
    widgetSettings.legalType = data.terms_type
    widgetSettings.legalStatus = data.terms_and_conditions_status
    widgetSettings.privateStatus = data.privacy_note_status
    widgetSettings.privatePolicy = data.privacy_note

    if (widgetSettings.legalType == 0) {
      widgetSettings.legalType = 'Text'
    } else if (widgetSettings.legalType == 1) {
      widgetSettings.legalType = 'URL'
    } else {
      widgetSettings.legalType = 'HTML'
    }

    widgetSettings.webCall = data.digital_call
    widgetSettings.callLeter = data.call_me_later
    widgetSettings.callBack = data.call_now
    widgetSettings.leaveMessage = data.leave_message

    widgetSettings.webCallHeader = data.digital_call_tab_text
    widgetSettings.webCallButton = data.digital_call_button_text
    widgetSettings.webCallName = data.full_name_in_digital_calls_tab
    widgetSettings.webCallEmail = data.email_in_digital_calls_tab
    widgetSettings.webCallTeam = data.team_in_digital_calls_tab

    widgetSettings.callLeterHeader = data.schedule_call_tab_text
    widgetSettings.callLeterButton = data.offline_schedule_call_message
    widgetSettings.callLeterName = data.full_name_in_call_later_tab

    widgetSettings.callLeterEmail = data.email_in_call_later_tab
    widgetSettings.callLeterTeam = data.team_in_call_later_tab

    widgetSettings.callBackHeader = data.widget_text
    widgetSettings.callBackButton = data.submit_request_call_text
    widgetSettings.callBackName = data.full_name_in_call_now_tab
    widgetSettings.callBackEmail = data.email_in_call_now_tab
    widgetSettings.callBackTeam = data.team_in_call_now_tab

    widgetSettings.leaveMessageHeader = data.leave_message_tab_text
    widgetSettings.leaveMessageName = data.full_name_in_leave_message_tab
    widgetSettings.leaveMessageEmail = data.email_in_leave_message_tab
    widgetSettings.leaveMessageTeam = data.team_in_leave_message

    setWidgetSetting(widgetSettings)
  }

  const setBubble = data => {
    let bubbleSetting1 = { ...bubbleSetting }



    bubbleSetting1.shadow = data.bubble_template == 2 ? true : false
    bubbleSetting1.color = data.circle_color
    bubbleSetting1.textOffline = data.bubble_offline_text
    bubbleSetting1.textOnline = data.bubble_text + " (Online)"

    if (data.bubble_position == 'bottom_right') {

    } else if (data.bubble_position == 'bottom_left') {

    } else if (data.bubble_position == 'top_right') {

    } else if (data.bubble_position == 'top_left') {

    }

    if (data.shape === 'square' || data.shape === 'btnRight') {
      data.shape = 'square'
    } else if (data.shape === 'default' || data.shape === 'btnLeft') {
      data.shape = 'default'
    }

    bubbleSetting1.position = data.bubble_position
    bubbleSetting1.shape = data.shape
    setBubbleSetting(bubbleSetting1)
  }

  const updateBubbleSetting = (index, val) => {

    let bSetting = {
      ...bubbleSetting
    }
    let bSettingData = {
      ...bubbleSettingData
    }
    bSettingData[index] = val
    bSetting[index] = val
    setBubbleSettingData(bSettingData)
    setBubbleSettData(bSettingData)
    setBubbleSetting(bSetting)
    setBubbleSetting(bSetting)

    const check = _.isEqual(bSettingData, bubbleSettingData)
    if (check === false) {
      setIsInputChange(true)
    }

  }


  // const onUploadLogo = file => {
  //   if (getLogoApi === logo) {
  //     return
  //   }
  //   loading(true)

  //   var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/upload-widget-icon`
  //   const form = new FormData()
  //   form.append('widget_image', file)
  //   form.append('widget_id', widget.id)
  //   axios
  //     .post(url, form)
  //     .then(res => {
  //       reloadWidget()
  //       CommonNotify('Upload successfully', 'success')
  //       loading(false)
  //     })
  //     .catch(err => {
  //       const errors = { ...err }
  //       if (errors.response.data.errors) {
  //         CommonNotify(errors.response.data.errors[0])
  //         loading(false)
  //       } else {
  //         CommonNotify('Some thing went wrong')
  //         loading(false)
  //       }
  //     })
  // }
  const uploadLogo = file => {
    if (getLogoApi === logo) {
      // getSettings()
      return
    }
    loading(true)

    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/company-logo`
    const form = new FormData()
    form.append('company_logo', logo)

    axios
      .post(url, form)
      .then(res => {
        loading(false)
        if (res.data.message == 'Successfully') {
          CommonNotify('Logo successfully updated...', 'success')
          // getSettings()
          // getCompany()
        } else {
          CommonNotify('Data cannot saved System error occurred...')
        }
      })
      .catch(err => {
        loading(false)
      })
  }

  const onUpload = ({ meta, file }, status) => {
    setIsInputChange(true)
    setLogo(file)
  }
  const onCancel = () => {
    setBubbleSettingData(bubbleApiSettingData)
    setIsInputChange(false)
  }

  const popup_font = [
    'Roboto',
    'Source Sans',
    "Rubik",
    'Source Sans Pro',
    'Playfair Display',
    'Open Sans'
  ]
  const onUploadLogo = ({ file }, status) => {
    setLogo(file)
    setIsInputChange(true)
  }

  return (
    <>
      {apiLoaded && (
        <>
          <div className="style-widget-wrapper accordion-widget greetings_wrapper">
           

            <div className="popup-font popup-select">
              <h3 className="call-title widget-sub-heading">Widget Font</h3>

              <CommonSelect
                name="popupFont"
                className="popup-font-select"
                placeholder="Select font"
                options={popup_font}
                value={bubbleSettingData.font}
                onChange={(e, data) => updateBubbleSetting('font', data.value)}
              />
            </div>
            

            <CommonColor
              widgetColor={bubbleSettingData.submit_request_call_color}
              widgetName="Cta Button Colour"
              onChange={color => updateBubbleSetting('submit_request_call_color', color)}
            />

            <CommonColor
              widgetColor={bubbleSettingData.popup_timer_color}
              widgetName="Timer Colour"
              onChange={color => updateBubbleSetting('timerColor', color)}
            />
            <CommonColor
              widgetColor={bubbleSettingData.widget_background}
              widgetName="Background Colour"
              onChange={color => updateBubbleSetting('widget_background', color)}
            />
            <CommonColor
              widgetColor={bubbleSettingData.ultimate_callback_cta_test_call_later_colour}
              widgetName="CTA Call Later Colour"
              onChange={color => updateBubbleSetting('ultimate_callback_cta_test_call_later_colour', color)}
            />


            <div className="widget-bubble-wrapper">
              {/* <p className="style-widget-title widget-sub-heading">Title</p> */}
              {/* <div className="button-shape">
                <CommonGroupButton
                  title="Popup Bubble"
                  identity="bubble_popup_status"
                  active={bubbleSettingData.bubble_popup_status}
                  leftBtn="Enable"
                  rightBtn="Disable"
                  handleGroupBtnData={updateBubbleSettingGroup}
                />
              </div>
              <div className="template-Select">
                <p className='widget-sub-heading'>Popup Bubble Title</p>
             
          <CommonInput
            onChange={(e)=>updateBubbleSetting('bubble_popup_title',e.target.value)}
value={bubbleSettingData.bubble_popup_title || ""}
            name="bubble_popup_title"
            type="text"
          />
              </div> 
              <div className="template-Select">
                <p className='widget-sub-heading'>Popup Bubble Sub Title</p>
                <CommonInput
            onChange={(e)=>updateBubbleSetting('bubble_popup_subtitle',e.target.value)}
            value={bubbleSettingData.bubble_popup_subtitle || ""}
            name="bubble_popup_subtitle"
            type="text"
          />
              </div>  */}
           







              {/* <div className="call-tab-text">
                <div className="input">
                  <span className="sec-color" style={{color: "#c1c1c1"}}>Customize the text visitors see when widget is offline</span>
                  <CommonTextArea
                    placeholder="Sorry, we are unavailable now ! Leave a message"
                    name="call-me-now"
                    type="text"
                    value={bubbleSettingData.bubble_offline_text}
                    onChange={event =>
                      updateBubbleSetting(
                        'bubble_offline_text',
                        event.target.value
                      )
                    }
                  />
                </div>
              </div> */}

            

           


             

            

              {/* <div className="template-Select">
                <p className='widget-sub-heading'>Select the icon</p>
                <div>
                  {allIcons?.map((icon=>
                           <IconButton width={20} onClick={()=>updateIcon('branding_icon_url',icon)}>
                           <img width={20} src={`${process.env.REACT_APP_BASE_APP_URL}/${icon}`} alt="icon"/>
                           </IconButton>
                    ))}
          </div>
              </div>             */}


              {/* <CommonGroupButton
                  title="Set an image as the button"
                  identity="shape"
                  active={bubbleSettingData.shape}
                  leftBtn={   
                    <Brightness5Icon/>
                   }
                  rightBtn={
                    <AccountBoxIcon/>
                  }
                  handleGroupBtnData={updateBubbleSettingGroup}
                />
     */}

              <div className="button-shape">
                <CommonGroupButton
                  title="Whitelabel"
                  identity="branding_status"
                  active={bubbleSettingData?.branding_status ? "btnLeft" : "btnRight"}
                  leftBtn="Enable"
                  rightBtn="Disable"
                  handleGroupBtnData={() => subScription?.plan_name === 'Free' || subScription?.status === 'in_trial' ? null : updateWhiteLabel('branding_status', bubbleSettingData?.branding_status ? 0 : 1)}
                />
              </div>
          
             
                <div className="popup-font popup-select">
                <p className="groupbtn-title widget-sub-heading">Brand Logo</p>
                  {''}
                {' '}
                <div className="general-content-holder-right w-50">
                  {logo ? (
                    <Dropzone
                      canCancel={true}
                      canRemove={true}
                      canRestart={true}
                      maxFiles={1}
                      inputContent={
                        <img
                          className="companyLogoImage"
                          src={`${logo}`}
                          alt=""
                        />
                      }
                      onChangeStatus={(e, status) => onUploadLogo(e, status)}
                      accept="image/*"
                      multiple={false}
                    />
                  ) : (
                    <Dropzone
                        maxFiles={1}

                      canCancel={true}
                        multiple={false}

                      canRemove={true}
                      canRestart={true}
                      inputContent={
                        <Icon name="upload" />
                      }
                        onChangeStatus={(e, status) => onUploadLogo(e, status)}
                      accept="image/*"
                    />
                  )}

                  <p>Supports jpg, png, jpeg, svg</p>
                  <p> Max File size 5 MB
                    <Icon
                      name="trash alternate"
                      className="remove-logo"
                      onClick={(e) => {

                        if (!logo) {
                          return;
                        }
                        const form = new FormData()
                        form.append('time_zone', 'GMT')
                        axios
                          .post(deleteLogoURL, form)
                          .then(res => {

                            loading(false)
                            if (res.data.message == 'Successfully') {
                              setLogo(null)
                              CommonNotify('Logo successfully Removed...', 'success')

                            } else {
                              CommonNotify('Data cannot saved System error occurred...')
                            }
                          })
                          .catch(err => {
                            loading(false)
                          })
                      }}
                    >

                    </Icon>
                  </p>
                </div>{' '}

              </div>
              {/* <IconButton><ReviewsIcon/></IconButton> */}

           
            </div>
            {/* <div className="widget-bubble1 widget-bubble-wrapper" style={{ padding: "0 !important" }}>              
              <div className="greetings_side_popup" style={{position:"relative", padding: "0 !important"}}>              
              { bubbleSettingData.bubble_template === parseInt(1) ? ( <div
                    className={`widget-bubble ${bubbleSettingData.bubble_position}`}
                  >
                    <div
                      className={`bubble-sec ${bubbleSettingData.shape}`}
                      style={{
                        backgroundColor: bubbleSettingData.circle_color,                        
                        boxShadow:
                          bubbleSetting.shadow &&
                          '0px 0px 0px 0px rgba(0,0,0,0.50)'
                      }}
                    >
                      <img src={widgetBubble} alt="" />
                    </div>
                    <div
                      className="bubble-text"
                      style={{ backgroundColor: widgetBubbleColor }}
                    >
                      {bubbleSettingData.bubble_text}
                    </div>
                  </div>)
                  :( <div style={ (bubbleSettingData.bubble_position === 'bottom_right' || bubbleSettingData.bubble_position === 'top_right') ? {backgroundColor: bubbleSettingData.circle_color} : { backgroundColor: bubbleSettingData.circle_color}} className={'template-2-button-container'  + ((bubbleSettingData.bubble_position === 'top_right' || bubbleSettingData.bubble_position === 'bottom_right') ? ' template-2-button-right-side-container': '')}>
                    {bubbleSettingData.bubble_text} 
                  </div>)
                  }       
               
              
              </div>

              
             
            </div> */}


       
            {isInputChange ? (
              <div className="saveCancelButton">
                <CommonButton
                  onClick={saveBubbleSetting}
                  type="button"
                  content="Save"
                  background="blue"
                />
                <CommonButtons
                  onClick={onCancel}
                  type="reset"
                  content="Cancel"
                  background="grey"
                />
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  )
}