import React, { Component } from 'react'
import CommonInput from '../../common/CommonInput'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'

import key from '../../assets/images/copy.png'

import CommonButton from '../../common/CommonButtons'
import circlePlus from '../../assets/images/cicle-plus.png'
import deleteIcon from '../../assets/images/delete-icon.png'

class ApiSettings extends Component {
  state = {
    activeIndexes: [0],
    setOfficeHour2: {
      addOfficeHour2: '',
      link: 'Link',
      officeHourFrom: '',
      officeHourTo: ''
    },
    addOfficeHour2: [1],
    api_key: localStorage.getItem('api_key')
  }

  render() {
   
    return (
      <div className="api-wrapper api_wrapper_main">
        <div className="api-holder">
          <p>Seamlessly integrate with LimeCall using our powerful APIs</p>
          <h3 className="call-title">
            API tokens are personal and can be used to access information on all
            your websites in LimeCall, so treat them like passwords. You can
            find more information and the documentation on our
             <a href = 'https://help.limecall.com/en/articles/4103760-api' 
              style={{textDecoration: 'none', marginLeft: '5px'}}>
               API documentation.
              </a>
          </h3>
        </div>
       
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Token</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
           
            <Table.Row>
              <Table.Cell>
                <CommonInput
                  name="key"
                  type="text"
                  defaultValue={this.state.api_key}
                />
              </Table.Cell>
              <Table.Cell>
                <div className="api-add-item">Active</div>
              </Table.Cell>
            </Table.Row>
           
          </Table.Body>
        </Table>
        <div className="filter-add">
          
        </div>
      </div>
    )
  }
}

export default ApiSettings
