export const setLoginLocalStorage = res => {
  localStorage.setItem('api_key', res.data.data.api_key)
  localStorage.setItem('role_name', res.data.data.role.name)
  localStorage.setItem('quick_setup', res.data.data.onboarding_step)
  localStorage.setItem('access_token', res.data.data.access_token)
  localStorage.setItem('working_hours_status',res.data.data.working_hours_status)
  localStorage.setItem('widget_id', res.data.data.widget_id)
  localStorage.setItem('plan_name', res.data.data.plan_name)
  localStorage.setItem('status', res.data.data.status)
  localStorage.setItem('profile_pic', res.data.data.profile_image ? `${process.env.REACT_APP_BASE_APP_URL}/storage` + res.data.data.profile_image : null)
  localStorage.setItem('first_name', res.data.data.first_name)
  localStorage.setItem(
    'full_name',
    res.data.data.first_name + ' ' + res.data.data.last_name
  )
  localStorage.setItem('last_name', res.data.data.last_name)
  localStorage.setItem('email', res.data.data.email)
  localStorage.setItem('id', res.data.data.id)
  localStorage.setItem(
    'admin_verification_status',
    res.data.data.admin_verification_status
  )
  localStorage.setItem(
    'mobile_verification_status',
    res.data.data.mobile_verification_status
  )
}

export const getAudioFileName = (string = '') => {
  const splitBySlash = string.split('/')
  const fileName = splitBySlash[splitBySlash?.length - 1]
  return fileName
}
