import React from 'react'
import { Form } from 'semantic-ui-react'
import ReactPhoneInput from 'react-phone-input-2'

import { billingCountryList } from '../../helpers/billingCountryList'
import CommonInput from '../../common/CommonInput'

const AddCompanyDetails = ({
  companyData,
  handleCompanyInputChange,
  contactData,
  members
}) => {
  return (
    <>
      <div className="companyDetail companyWrap">
        <Form>
          <Form.Field>
            <label>Company</label>
            <CommonInput
              type="text"
              className="form-control"
              placeholder="Company"
              required
              value={companyData.name}
              onChange={e => handleCompanyInputChange(e)}
              name="name"
            />
          </Form.Field>
          <Form.Field>
            <label>Owner Name</label>
            <Form.Field>
              <select
                name="owner"
                value={companyData.owner}
                placeholder="Owner list"
                onChange={e => handleCompanyInputChange(e)}
              >
                {members?.map((e, key) => {
                  return (
                    <option key={key} value={e.value}>
                      {e.value}
                    </option>
                  )
                })}
              </select>
            </Form.Field>
          </Form.Field>
          <Form.Field>
            <label>Phone number</label>

            <ReactPhoneInput
              inputExtraProps={{
                name: 'phone_number',
                required: true,
                autoFocus: true
              }}
              country={'us'}
              value={companyData.phone_number}
              onChange={(e, c) =>
                handleCompanyInputChange(e, c, 'phone_number')
              }
            />
          </Form.Field>
         

          <Form.Field>
            <label>Country</label>

            <select
              name="country"
              onChange={e => handleCompanyInputChange(e)}
              value={companyData.country}
              placeholder="country"
            >
              {billingCountryList.map((country, key) => (
                <option key={key} value={country.text}>
                  {country.text}
                </option>
              ))}
            </select>
          </Form.Field>
          <Form.Field>
            <label>City</label>
            <CommonInput
              type="text"
              className="form-control"
              onChange={e => handleCompanyInputChange(e)}
              name="city"
              placeholder="city"
              value={companyData.city}
            />
          </Form.Field>

         
        </Form>
      </div>
    </>
  )
}

export default AddCompanyDetails
