import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import rightBottom from '../../assets/images/rightBottom.png';
import leftBottom from '../../assets/images/leftBottom.png';

export default class HomeBottomSupport extends Component {



  render() {
    return (
        <div className="container-trial">
          <div className="banner-description-wrapper">
            <p className="help-title">
Help And Answers
</p>
<div className="help-box"> 
  

      {/* ----------------------------- First Row -------------------------- */}

      <div className="one">
        <div className="help-titlediv">
        <p className="help-subtitle">Get in touch with support via <a onClick={()=> window.open("https://limecall.com/contact-us/", "_blank")}> <span className="help-spantitle">Live Chat</span></a> </p>
        </div>
        <img className="help-image" src={leftBottom}></img>
      </div>

      {/* ----------------------------- Second Row ---------------------------- */}
      <div className="two">
        <div className="help-titlediv">
        <p className="help-subtitle">Check out our<a onClick={()=> window.open("https://help.limecall.com/en/", "_blank")}> <span className="help-spantitle"> Help & Documentation </span></a> to learn more about  <a onClick={()=> window.open("https://limecall.com/", "_blank")}> <span className="help-spantitle">LimeCall</span> </a></p>
        </div>
        <img className="help-image" src={rightBottom}></img>
       </div>
   
    </div>
  </div>
</div>
)
}
}
