import React, { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'

import WizardBox from '../components/quicksetup/WizardBox'
import Install from '../components/quicksetup/Install'
import GoLive from '../components/quicksetup//GoLive'
import Customize from '../components/quicksetup/customize/Customize'
import CallRouting from '../components/quicksetup/CallRouting'
import LeadInformation from '../components/quicksetup/LeadInformation'
import UseCaseInfo from '../components/quicksetup/UseCaseInfo'
// import quickSetupImage from '../assets/images/quick-setup-image.jpg'
import quickSetupImage from '../assets/images/quick-setup-image-latest.png'
import CreaditDetails from '../components/quicksetup/CreaditDetails'
import CallTrackingLeadInformation from '../components/quicksetup/CallTrackingLeadInfo'
import VirtualCallLeadInformation from '../components/quicksetup/VirtualCallLeadInfo'
import YourPhone from '../components/quicksetup/YourPhone'
import VirtualCreaditDetails from '../components/quicksetup/VirtualBillingInfo'
import InviteTeamMate from '../components/quicksetup/InviteTeamMate'
import WelcomeNew from '../components/quicksetup/WelcomeNew'
import Availablity from '../components/quicksetup/Availability'
import { analytics } from '../Segmant'

export default function QuickSetup() {
  const [step, setStep] = useState(
    parseInt(localStorage.getItem('quick_setup')) || 1
  )
  const [flowType, setFlowType] = useState(
    localStorage.getItem('flowType') || null
  )
  const [initialPage, setInitialPage] = useState(true)
  const [values, setValues] = useState({
    step: step,
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    age: '',
    country: '',
    interests: [],
    about: ''
  })
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (localStorage.getItem('role_name')?.toLocaleLowerCase() === 'owner') {
      setInitialPage(false)
      localStorage.setItem('flowType', 'invited')
      setFlowType('invited')
    } else {
      if (localStorage.getItem('flowType')) {
        setInitialPage(false)
      } else {
        setInitialPage(true)
      }
    }
  }, [])

  useEffect(() => {
    redirectPage()
  }, [window.location?.pathname])

  useEffect(() => {
    if (+step === 1 && !flowType) {
      setInitialPage(true)
    } else {
      setInitialPage(false)
    }
  }, [step])

  const handleSubmit = e => {
    e.preventDefault()
    setStep(4)
  }

  const handleChange = (event, { name, value, checked, type }) => {
    if (type === 'checkbox') {
      let { interests } = this.state
      if (checked) {
        interests = [...interests, value]
      } else {
        interests = interests?.filter(item => item !== value)
      }
      setValues({
        ...values,
        interests: interests
      })
    } else {
      setValues({
        ...values,
        [name]: value
      })
    }
  }

  const next = () => {
    const count = +step + 1
    setStep(count)
    localStorage.setItem('quick_setup', count)
  }

  const prev = () => {
    setStep(+step - 1)
  }

  const redirectPage = () => {
    if (localStorage.getItem('access_token')) {
      document.title = 'Quick Setup'
      let step = parseInt(localStorage.getItem('quick_setup')),
        skipProccess = parseInt(localStorage.getItem('skip_onboarding'))
      if (flowType === 'virtualPhone') {
        if (step >= 6 || skipProccess === 1) {
          localStorage.setItem('quick_setup', 5)
          analytics.track('Signed Up', {
            plan: localStorage?.getItem('plan_name')
          });
          window.location.href = '/home'
        }
      } else {
        if (step >= 5 || skipProccess === 1) {
          localStorage.setItem('quick_setup', 5)
          analytics.track('Signed Up', {
            plan: localStorage?.getItem('plan_name')
          });
          window.location.href = '/home'
        }
      }
    } else {
      window.location.href = '/login'
    }
  }

  const handleManageStepsComponents = () => {
    let title = localStorage.getItem('flowType')
    switch (title) {
      case 'callback':
        return stepCallback()
      case 'callTraking':
        return stepCallTracking()
      case 'virtualPhone':
        return stepVirtualNumber()
      case 'invited':
        return stepAccount()
      default:
        return stepCallback()
    }
  }

  const stepVirtualNumber = () => {
    switch (step) {
      case 3:
        return (
          <VirtualCreaditDetails
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 1:
        return (
          <VirtualCallLeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 2:
        return (
          <YourPhone
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 4:
        return (
          <CallRouting
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 5:
        return <InviteTeamMate next={next} prev={prev} />

      case 6:
        return <GoLive next={next} prev={prev} redirectURL="/numbers" />

      default:
        return (
          <VirtualCreaditDetails
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
    }
  }
  const stepCallTracking = () => {
    switch (step) {
      case 1:
        return (
          <CreaditDetails
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 2:
        return (
          <CallTrackingLeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 3:
        return (
          <CallRouting
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 4:
        return <Install next={next} prev={prev} />
      case 5:
        return <GoLive next={next} prev={prev} />

      default:
        return (
          <CreaditDetails
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
    }
  }

  const stepCallback = () => {
    switch (step) {
      case 1:
        return (
          <LeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 2:
        return (
          <CallRouting
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 3:
        return <Customize next={next} prev={prev} />
      case 4:
        return <Install next={next} prev={prev} />
      case 5:
        return <GoLive next={next} prev={prev} />

      default:
        return (
          <LeadInformation
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
    }
  }

  const stepAccount = () => {
    switch (step) {
      case 1:
        return (
          <WelcomeNew handleChange={handleChange} next={next} prev={prev} />
        )
      // case 2:
      //   return (
      //     <LeadInformation
      //       values={values}
      //       handleChange={handleChange}
      //       next={next}
      //       prev={prev}
      //     />
      //   )
      // case 2:
      //   return <Availablity next={next} prev={prev} />
      case 2:
        return (
          <CallRouting
            values={values}
            handleChange={handleChange}
            next={next}
            prev={prev}
          />
        )
      case 3:
        return <GoLive next={next} prev={prev} />

      default:
        return (
          <WelcomeNew handleChange={handleChange} next={next} prev={prev} />
        )
    }
  }

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div className="quicksetup-step">
      <div className="quicksetup-step-wrap">
        {flowType ? (
          <WizardBox step={step} />
        ) : (
          <>
          {/* {width > 768 ? (
            <img
            src={quickSetupImage}
            className="p-3 bg-white"
            style={{ maxHeight: '570px' }}
            alt=""
          />
          ) : null} */}
          
          </>
        )}
        <div
          // className={`quicksetup-content ${
          //   (flowType === 'callTraking' || flowType === 'virtualPhone') &&
          //   step === 1
          //     ? 'w-100'
          //     : ''
          // }`}
          className="bg-white w-100 p-md-5 p-2"
          // style={
          //   (flowType === 'callTraking' || flowType === 'virtualPhone') &&
          //   step === 1
          //     ? { width: '100%' }
          //     : (flowType === 'virtualPhone' && step === 5) ||
          //       (flowType === 'invited' && step === 3)
          //     ? { width: '40%' }
          //     : flowType === 'virtualPhone' && step === 6
          //     ? { width: '70%' }
          //     : step == 3 || step == 5
          //     ? { width: '70%' }
          //     : { width: '40%' }
          // }
        >
          <Form
            onSubmit={handleSubmit}
            // className={
            //   (flowType === 'callTraking' || flowType === 'virtualPhone') &&
            //   step === 1
            //     ? 'w-100'
            //     : 'h-100'
            // }
            className="h-100 d-flex flex-column"
          >
            {!flowType ? (
              <>
                <UseCaseInfo
                  setFlowType={setFlowType}
                  setInitialPage={setInitialPage}
                />
              </>
            ) : (
              handleManageStepsComponents()
            )}
          </Form>
        </div>
      </div>
    </div>
  )
}
