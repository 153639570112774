import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DangerousIcon from '@mui/icons-material/Dangerous'
import axios from 'axios'
import classnames from 'classnames'
import React, { Component } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
//redux
import { connect } from 'react-redux'
import TimezonePicker from 'react-timezone'
import { Accordion, Dimmer, Loader } from 'semantic-ui-react'
import { getProfile } from '../../actions/settings'
import CommonButtons from '../../common/CommonButtons'
import CommonInput from '../../common/CommonInput'
import { CommonNotify } from '../../common/CommonNotify'
import EmailValidationModal from '../../common/EmailValidationModal'
import InputValidator from '../../common/validator'
import { getCurrentTimeZoneName } from '../../helpers/commonFunction'
import { Button } from '@mui/material'
class AccountSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndexs: [0, 1, 2, 3],
      role_name: '',
      isLoading: false,
      data: {
        companyName: '',
        timeZone: '',
        fullName: '',
        first_name: '',
        last_name: '',
        bio: '',
        email: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        selectedFile: null,
        personalUrl: '',
        lastName: '',
        isVerified: '1'
      },
      companyName: 'The knight company',
      errors: {},
      isConfirmed: false,
      isProfileData: false,
      isPasswordData: false,
      isAccountData: false,
      isPersonalLink: false,
      open: false,
      constEmail: '',
      passwordUpdateLoading: false,
      verificationCode: '',
      isWaitRes: false
    }
    this.updateCompanyData = this.updateCompanyData.bind(this)
    this.updateUserData = this.updateUserData.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.handleCompnayValidation = this.handleCompnayValidation.bind(this)
    this.handleProfileValidation = this.handleProfileValidation.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile !== prevProps.profile) {
      this.setProfileData(this.props.profile)
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    if (this.props.profile) {
      this.setProfileData(this.props.profile)
    } else {
      this.props.getProfile()
    }
  }

  onChangeCountry = value => {
    if (value) {
      this.setState({ isAccountData: true })
    }
    const { data } = this.state
    data['officialNumber'] = value
    this.setState({ data })
  }

  setProfileData(res) {
    let d = {
      companyName: res.data.company_name,
      email: res.data.email,
      bio: res.data.bio,
      timeZone: res.data.time_zone || getCurrentTimeZoneName(),
      fullName: res.data.first_name,
      first_name: res.data?.first_name,
      last_name: res.data?.last_name,
      lastName: res.data.last_name,
      bio: res.data.bio,
      officialNumber: res.data.official_phone_number,
      isVerified: res?.data?.verified?.toString() || '0'
    }
    localStorage.setItem('user_working_hours', res.data.working_hours_status)
    this.setState({
      data: d,
      constEmail: d.email,
      isLoading: false
    })
  }

  /*--------------------- Handle Company Validation And API -----------------*/

  handleCompnayValidation = () => {
    let fields = this.state.data
    let errors = {}
    let formIsValid = true
    let checkIfNotEmptyArr = ['companyName']

    checkIfNotEmptyArr.forEach(item => {
      if (!InputValidator.checkIfNotEmpty(fields[item])) {
        formIsValid = false
        errors[item] = 'This is a required field'
      }
    })

    this.setState({ errors: errors })
    return formIsValid
  }

  updateCompanyData(e) {
    if (this.handleCompnayValidation()) {
      this.setState({ isLoading: true })
      e.preventDefault()
      const token = localStorage.getItem('access_token')
      const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/update-company-profile`

      const form = new FormData()
      form.append('company_name', this.state.data.companyName)

      const settings = {
        url: URL,
        method: 'POST',
        timeout: 0,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token
        },
        processData: false,
        mimeType: 'multipart/form-data',
        contentType: false,
        data: form
      }

      return axios(settings)
        .then(res => {
          this.setState({ isLoading: false })
          CommonNotify('successfuly updated', 'success')
          return res
        })
        .catch(err => {
          this.setState({ isLoading: false })
          CommonNotify('some error occured!')
        })
    }
  }

  /*----------------------------- Handle Profile Data ---------------------------*/

  updateUserData(e) {
    e.preventDefault()
    if (this.state.data.email !== this.state.constEmail) {
      const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const testEmail = regExp.test(this.state.data.email)
      if (testEmail) {
        e.preventDefault()
        const token = localStorage.getItem('access_token')
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/update-email`
        const data = {
          email: this.state.data.email
        }
        axios
          .post(URL, data)
          .then(res => {
            CommonNotify(
              'Please check your email we sent verification code',
              'success'
            )
            this.setState({ open: true })
            this.saveName()
              .then(res => {
                localStorage.setItem('first_name', res.data.data.first_name)
                this.setState({ isLoading: false })
                return res
              })
              .catch(err => {
                const errors = { ...err }
                if (errors.response.data.errors) {
                  CommonNotify(errors.response.data.errors[0])
                } else {
                  CommonNotify('Some thing went wrong')
                }
                this.setState({ isLoading: false })
              })
          })
          .catch(err => {
            const errors = { ...err }
            if (errors.response.data.errors) {
              CommonNotify(errors.response.data.errors[0])
            } else {
              CommonNotify('Some thing went wrong')
            }
          })
      }
    } else {
      if (this.handleProfileValidation()) {
        this.saveName()
          .then(res => {
            localStorage.setItem('first_name', res?.data?.data?.first_name)
            CommonNotify('successfully updated', 'success')
            this.setState({ isAccountData: false })
            this.setState({ isLoading: false })
            return res
          })
          .catch(err => {
            const errors = { ...err }
            if (errors?.response?.data?.errors) {
              CommonNotify(errors?.response?.data?.errors?.[0])
            } else {
              CommonNotify('Some thing went wrong')
            }
            this.setState({ isLoading: false })
          })
      }
    }
  }

  saveName = () => {
    const token = localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/update-profile-api`
    const form = new FormData()
    const full_name =
      this.state.data?.first_name + ' ' + this.state.data?.last_name
    if (!this.state.data?.first_name) {
      CommonNotify('First name is required.')
      return
    }
    if (!this.state.data?.last_name) {
      CommonNotify('Last name is required.')
      return
    }
    this.setState({ isLoading: true })
    form.append('full_name', full_name)
    form.append(
      'time_zone',
      this.state.data?.timeZone || getCurrentTimeZoneName()
    )
    form.append('bio', this.state.data.bio)
    form.append('official_phone_number', this.state.data.officialNumber)

    const settings = {
      url: URL,
      method: 'POST',
      timeout: 0,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      },
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false,
      data: form
    }

    return axios(settings)
  }

  handleProfileValidation = () => {
    let fields = this.state.data
    let errors = {}
    let formIsValid = true
    let checkIfNotEmptyArr = ['email', 'fullName']
    checkIfNotEmptyArr.forEach(item => {
      if (!InputValidator.checkIfNotEmpty(fields[item])) {
        formIsValid = false
        errors[item] = 'This is a required field'
      }
    })

    this.setState({ errors: errors })
    return formIsValid
  }

  /*-----------------------Password & Validation & API Calling  --------------------*/

  handleValidation = () => {
    let fields = this.state.data
    let errors = {}
    let formIsValid = true

    let checkIfNotEmptyArr = [
      'confirmPassword',
      'newPassword',
      'currentPassword'
    ]

    if (
      !InputValidator.checkIfEqual(
        fields['newPassword'],
        fields['confirmPassword']
      )
    ) {
      this.setState({ isConfirmed: true })
      formIsValid = false
    } else {
      this.setState({ isConfirmed: false })
    }

    checkIfNotEmptyArr.forEach(item => {
      if (!InputValidator.checkIfNotEmpty(fields[item])) {
        formIsValid = false
        errors[item] = 'This is a required field'
      }
    })

    this.setState({ errors: errors })
    return formIsValid
  }

  onChangeAccountData = e => {
    const { name, value } = e.target
    this.setState({ isEmailValidation: false })
    if (value) {
      this.setState({ isAccountData: true })
    }
    const { data } = this.state

    data[name] = value

    this.setState({ data })
  }

  onChangeProfileData = e => {
    const { name, value } = e.target
    if (!value) {
      this.setState({ isProfileData: false })
    } else {
      this.setState({ isProfileData: true })
    }
    const { data } = this.state

    data[name] = value

    this.setState({ data })
  }

  onChangePasswordData = e => {
    const { name, value } = e.target

    if (value && value !== '') {
      this.setState({ isPasswordData: true })
    }
    const { data } = this.state

    data[name] = value

    this.setState({ data })
  }

  onChangePersonalData = e => {
    if (!e.target.value) {
      this.setState({ isPersonalLink: false })
    } else {
      this.setState({ isPersonalLink: true })
    }
  }
  onUpdate(e) {
    e.preventDefault()
    this.setState({ passwordUpdateLoading: true })
    if (this.state.data.newPassword !== this.state.data.confirmPassword) {
      CommonNotify('Password does not match', 'warning')
      this.setState({ passwordUpdateLoading: false })
    }
    if (this.handleValidation()) {
      this.setState({ passwordUpdateLoading: true })
      e.preventDefault()
      const token = localStorage.getItem('access_token')
      const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/update-password`

      const form = new FormData()
      form.append('current_password', this.state.data.currentPassword)
      form.append('password', this.state.data.newPassword)
      form.append('password_confirmation', this.state.data.confirmPassword)

      const settings = {
        url: URL,
        method: 'POST',
        timeout: 0,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token
        },
        processData: false,
        mimeType: 'multipart/form-data',
        contentType: false,
        data: form
      }

      return axios(settings)
        .then(res => {
          CommonNotify('successfully updated', 'success')
          this.setState({ passwordUpdateLoading: false })
          return res
        })
        .catch(err => {
          const errorMessage = { ...err }
          if (errorMessage.response.data.errors) {
            this.setState({ passwordUpdateLoading: false })
            CommonNotify(errorMessage.response.data.errors[0])
          } else {
            this.setState({ passwordUpdateLoading: false })
            CommonNotify('Some thing went wronged')
          }
        })
    }
  }

  fileChangedHandler = event => {
    this.setState({ selectedFile: event.target.files[0] })
  }

  cancelButton = () => {
    let cloneData = this.state?.data || {}
    cloneData.email = this.state?.constEmail
    this.setState({ isAccountData: false, data: cloneData })
  }
  cancelPasswordButton = () => {
    this.setState({ isPasswordData: false })
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndexs } = this.state
    const newIndex = activeIndexs
    const currentIndexPosition = activeIndexs.indexOf(index)
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1)
    } else {
      newIndex.push(index)
    }

    this.setState({ activeIndexs: newIndex })
  }
  handleModalClose = () => {
    this.setState({ open: false })
  }

  updateEmail = () => {
    this.setState({ isWaitRes: true })
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/verify-new-email`
    const data = {
      activation_token: this.state.verificationCode
    }
    axios
      .post(URL, data)
      .then(res => {
        this.setState({ isWaitRes: false })
        CommonNotify('Update successfully', 'success')
        this.setState({ open: false })
      })
      .catch(err => {
        const errors = { ...err }
        if (errors.response.data.errors) {
          this.setState({ isWaitRes: false })
          CommonNotify(errors.response.data.errors[0])
        } else {
          this.setState({ isWaitRes: false })
          CommonNotify('Some thing went wrong')
        }
      })
  }

  onChangeVerifyCode = e => {
    this.setState({ verificationCode: e.target.value })
  }

  setRole = data => {
    this.setState({ role_name: data.value })
  }

  selectTimeZone = e => {
    let cloneData = { ...this.state?.data }
    cloneData.timeZone = e || getCurrentTimeZoneName()
    this.setState({
      data: cloneData
    })
    if (e) {
      this.setState({ isAccountData: true })
    }
  }

  render() {
    const { activeIndexs, data } = this.state
    const emailVerification =
      data?.isVerified || localStorage.getItem('email_verification_status')
    return (
      <>
        <Dimmer active={this.state.isLoading}>
          <Loader></Loader>
        </Dimmer>
        <div className="account-settings">
          <Accordion className="holder-account">
            <div className="accordion-holder account-information account_main">
              <Accordion.Title
                active={activeIndexs.includes(1)}
                index={1}
                onClick={this.handleClick}
                style={{ padding: '15px' }}
              >
                <p className="bold-text accordion-title">Account Information</p>
                <p className="subtext accordion-desc">
                  Manage your personal infomation
                </p>
              </Accordion.Title>
              <Accordion.Content active={activeIndexs.includes(1)}>
                <form method="" action="">
                  <div className="holder">
                    <div className="input-styles">
                      <CommonInput
                        onChange={this.onChangeAccountData}
                        name="first_name"
                        type="text"
                        title="First Name"
                        background="gray"
                        required={true}
                        value={data?.first_name}
                      />
                      <CommonInput
                        onChange={this.onChangeAccountData}
                        name="last_name"
                        type="text"
                        title="Last Name"
                        background="gray"
                        required={true}
                        value={data?.last_name}
                      />
                      <CommonInput
                        name="email"
                        type="email"
                        title="Email Address"
                        background="gray"
                        required={this.state.isEmailValidation ? true : false}
                        value={data.email}
                        onChange={this.onChangeAccountData}
                        defaultValue={this.state.data.email}
                      />
                      
                     <div className='d-flex align-items-center'>
                      <div
                        style={
                          emailVerification === '1' &&
                          this.state?.data?.email === this.state.constEmail
                            ? {
                                paddingTop: '5px',
                                backgroundColor: '#e0f9f4',
                                width: '45%',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '5px',
                                color: '#5fc0a3',
                                marginTop:'-30px'
                              }
                            : {
                                paddingTop: '5px',
                                backgroundColor: 'red',
                                width: '45%',
                                height: '30px',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '0px',
                                color: '#fff',
                                marginTop:'-30px'
                              }
                        }
                      >
                        <div  className='d-flex align-items-center'>
                          
                        {emailVerification === '1' &&
                        this.state?.data?.email === this.state.constEmail ? (
                          <>
                            <CheckCircleIcon />
                            Your email was verified successfully.
                          </>
                        ) : (
                          <>
                            <DangerousIcon />
                            Your email verification is pending.
                          </>
                        )}
                       
                     
                        </div>
                      
                     
                      </div>
                      {emailVerification === '1' &&
                        this.state?.data?.email !== this.state.constEmail  &&
                        <CommonButtons
                        style={{ marginTop:'-30px'}}
                        onClick={this.updateUserData}
                        type="submit"
                        content={
                           'Verify Email'
                        }
                        background="blue"
                      />}
                     </div>

                      <label>Phone Number</label>
                      <style>
                        {`
                        .react-tel-input > input {
                          background-color: #f4f4f4 !important;
                        }
                        `}
                      </style>
                      <ReactPhoneInput
                        containerClass="input-wrapper"
                        inputStyle={{
                          backgroundColor: '#f4f4f4',
                          border: '1px solid #f4f4f4',
                          borderRadius: 0,
                          color: 'black'
                        }}
                        value={data.officialNumber}
                        onChange={this.onChangeCountry}
                        placeholder="+1 (702) 123-4567"
                      />
                      <label className="mb-1">Time Zone</label>
                      <div
                        className="timezone-picker mb-2"
                        style={{ width: '45%' }}
                      >
                        <TimezonePicker
                         className="time-zone-input"
                          value={
                            this.state?.data?.timeZone ||
                            getCurrentTimeZoneName()
                          }
                          onChange={this.selectTimeZone}
                          inputProps={{
                            placeholder: 'Select Timezone...',
                            name: 'timezone'
                          }}
                        />{' '}
                      </div>{' '}
                    </div>
                    <EmailValidationModal
                      open={this.state.open}
                      handleModalClose={this.handleModalClose}
                      updateEmail={this.updateEmail}
                      onChangeVerifyCode={this.onChangeVerifyCode}
                      isWaitRes={this.state.isWaitRes}
                    />
                    <div className="holder-input"></div>
                  </div>
                  {this.state.isAccountData && (
                    <>
                
                     <CommonButtons
                        onClick={this.updateUserData}
                        type="submit"
                        content={
                           'Save'
                        }
                        background="blue"
                      />
                      <CommonButtons
                        onClick={this.cancelButton}
                        type="reset"
                        content="Cancel"
                        background="grey"
                      />
                    </>
                  )}
                </form>
              </Accordion.Content>
            </div>
            <div className="accordion-holder change-password change_password">
              <Accordion.Title
                active={activeIndexs.includes(2)}
                index={2}
                onClick={this.handleClick}
                style={{ padding: '15px' }}
              >
                <p className="bold-text accordion-title">Change Password</p>
                <p className="subtext accordion-desc">
                  Update your secret combination of letters, numbers and symbols
                </p>
              </Accordion.Title>
              <Accordion.Content active={activeIndexs.includes(2)}>
                <form method="" action="">
                  <div className="holder-change-password">
                    <div
                      className={classnames('input-invi-wrapper', {
                        'on-error': this.state.errors.currentPassword
                      })}
                    >
                      <CommonInput
                        onChange={this.onChangePasswordData}
                        name="currentPassword"
                        type="password"
                        title="Current Password"
                        background="gray"
                        required={true}
                      />
                    </div>
                    <div
                      className={classnames('input-invi-wrapper', {
                        'on-error': this.state.errors.newPassword
                      })}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <CommonInput
                        onChange={this.onChangePasswordData}
                        name="newPassword"
                        type="password"
                        title="New Password"
                        background="gray"
                        required={true}
                        passwordBar={true}
                        passwordData={data['newPassword']}
                      />
                      <CommonInput
                        onChange={this.onChangePasswordData}
                        onKeyUp={() => this.handleValidation()}
                        name="confirmPassword"
                        type="password"
                        title="Confirm Password"
                        background="gray"
                      />
                      {this.state.isConfirmed && (
                        <span className="password-confirmation-text">
                          Password is not confirmed yet!
                        </span>
                      )}
                    </div>
                  </div>
                  {this.state.isPasswordData && (
                    <>
                      <CommonButtons
                        onClick={this.onUpdate}
                        type="submit"
                        content="Save"
                        background="blue"
                        loading={this.state.passwordUpdateLoading}
                      />
                      <CommonButtons
                        onClick={this.cancelPasswordButton}
                        type="reset"
                        content="Cancel"
                        background="grey"
                      />
                    </>
                  )}
                </form>
              </Accordion.Content>
            </div>
          </Accordion>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.settings.profile
})

export default connect(mapStateToProps, { getProfile })(AccountSettings)
