import { Accordion, AccordionDetails, AccordionSummary, Grid, Switch } from '@mui/material'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dropdown, Modal, Table } from 'semantic-ui-react';
import CommonButtons from '../common/CommonButtons';
import CommonInput from '../common/CommonInput';
const pages = [
  {
    key: 'allPages',
    text: 'All pages',
    value: 'allPages',
  },
  {
    key: 'somePage',
    text: 'Some pages',
    value: 'somePage',
  },
  {
    key: 'exceptPage',
    text: 'All except some pages',
    value: 'exceptPage',
  },
]
const source = [
  {
    key: 'allSource',
    text: 'All source',
    value: 'allSource',
  },
  {
    key: 'someSource',
    text: 'Some source',
    value: 'someSource',
  },
  
]
const FormTracking = () => {
  const [modal, setModal] = useState(false)

  return (
    <div>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ backgroundColor: '#e8e8e8', padding: '16px', display: 'flex', justifyContent: 'center', "& .MuiAccordionSummary-content":{
            justifyContent:'center'
          } }}
        >
        { ` <script type="text/javascript" src="//cdn.dni.nimbata.com/10307015139.min.js"></script>`}
        </AccordionSummary>
        <AccordionDetails style={{ padding: '20px'}}>
          <>
          <p>{`This is the Form tracking code for your web properties. Copy and paste this code as the last item before the </body> of every page you want tracked.`}</p>
          <p>If you already have Tag Manager on your page, simply include the snippet below as a new tag. Alternatively, you may incorporate the code using our Wordpress plug-in. Learn more about it here.</p>
          </>
       </AccordionDetails>
      </Accordion>
      <Grid container padding={5} rowSpacing={3}>
      <Grid item md={6}>
          Enable Form Tracking?
      </Grid>
      <Grid item md={6}>
        <Switch/>
      </Grid>
      <Grid item md={6}>
          Pages to monitor:
      </Grid>
      <Grid item md={6}>
          <Dropdown
            placeholder={`All Pages`}
            fluid
            selection
            options={pages}
          />
      </Grid>
      <Grid item md={6}>
          Visitors from:
      </Grid>
      <Grid item md={6}>
          <Dropdown
            placeholder={`All Source`}
            fluid
            selection
            options={source}
          />
      </Grid>
    
      </Grid>
      <div
        className="my-number-tab manage_table"
        style={{ backgroundColor: '#f4f4f4' }}
      >
        <div className="table-heading-wrapper" style={{ justifyContent: 'end' }}>
          <CommonButtons
            onClick={() => setModal(!modal)}
            content="Add Form"
            btnClass="btn-blue"
          />
        </div>
        <Table style={{ tableLayout: 'fixed' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Form Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Route to</Table.HeaderCell>

            </Table.Row>
          </Table.Header>
          <Table.Body>

          </Table.Body>
        </Table>
      </div>

      <Modal
        style={{ width: '500px', overflow: 'visible' }}
        open={modal}
        onClose={() => setModal(!modal)}
      >
        <Modal.Header>Form Details</Modal.Header>
        <Modal.Content>
          <div className="p-4">
            <div className='form-field mt-3'>
              <label>Form Name</label>
              <CommonInput
                // value={currentField?.name}
                inputStyle={'w-100'}
              // onChange={e =>
              //     setCurrentField({ ...currentField, name: e.target.value })
              // }
              />
            </div>

            <div className='form-field mt-3'>
              <label>Enter the webpage url where you form is present</label>
              <CommonInput
                // value={currentField?.name}
                inputStyle={'w-100'}
              // onChange={e =>
              //     setCurrentField({ ...currentField, name: e.target.value })
              // }
              />
            </div>

            <div className='form-field mt-3'>
              <label>Form Selector</label>
              <CommonInput
                placeholder='Enter Form ID'
                // value={currentField?.name}
                inputStyle={'w-100'}
              // onChange={e =>
              //     setCurrentField({ ...currentField, name: e.target.value })
              // }
              />
            </div>


          </div>
        </Modal.Content>
        <Modal.Actions>
          <CommonButtons onClick={() => setModal(!modal)}>Cancel</CommonButtons>

          <CommonButtons background="blue">
            Save
          </CommonButtons>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default FormTracking