import React, { useMemo, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Table,
  Popup,
  Dropdown,
  Image,
  Tab,
  Icon,
  Modal
} from 'semantic-ui-react'
import callbackIcon from '../../../assets/images/callback-icon.png'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import MissedCall from '../../../assets/images/orangeCall.png'
import PickedCall from '../../../assets/images/greenCall.png'
// import smsIcon from '../../../assets/images/sms.png'
import leadsIcon from '../../../assets/images/lead-icon.png'
import letterIcon from '../../../assets/images/letter-l.png'
import incomingIcon from '../../../assets/images/incoming-call.png'
import outboundIcon from '../../../assets/images/outbound-phone.png'
// import telephone from '../../../assets/images/telephone.png'
import scheduleIcon from '../../../assets/images/schedule.png'
import blockedCall from '../../../assets/images/no-call.png'
import scheduledCall from '../../../assets/images/timer.png'
import cutCall from '../../../assets/images/callRed.jpeg'
// import deleteIcon from '../../../assets/images/delete-icon.png'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';

// import Select from 'react-dropdown-select'
import {
  onCallRequest,
  onChangeInterseted3,
  onLeadStageHandler,
  onChangeOwner3,
  setLeadStage,
  onChangeInterseted
} from '../../../config/leadAPI'
import DialerImage from '../../../assets/images/flaticons/dialer.png'

import SendMessageModal from '../../../common/SendMessageModal'
import DummyProfileImage from '../../../assets/images/dummy_profile.webp'
import emailicon from '../../../assets/images/mail_icons.png'
import smallcall from '../../../assets/images/small_call.png'
import smallEmail from '../../../assets/images/small_email.png'
import CommonCheckbox from '../../../common/CommonCheckbox'
import callicons from '../../../assets/images/call_icons.png'
import CommonButtons from '../../../common/CommonButtons'

import AddTagPopup from '../leadRightPane/AddTagPopup'

import axios from 'axios'
import { CommonNotify } from '../../../common/CommonNotify'
import { getDialer } from '../../../actions/dialer'
import { getSubscription } from '../../../config/subscription'
import getWidget from '../../../config/getWidget'
import { Menu, MenuItem, Tooltip } from '@mui/material'
import CallbackModal from '../../../common/CallbackModal';

const leadStatusIcons = {
  initiated: 'initiated',
  canceled: 'canceled',
  suspended: 'suspended',
  no_agents_available: 'no_agents_available',
  not_assigned: 'not_assigned',
  busy: 'busy',
  completed: 'completed',
  failed: 'failed',
  in_progress: 'in-progress',
  no_answer: 'no-answer',
  widget_not_available: 'widget_not_available',
  customer_connected: 'customer_connected',
  agent_connected: 'agent_connected',
  ringing: 'ringing',
  scheduled: 'scheduled',
  rescheduled: 'rescheduled',
  failed_to_connect_agent: 'failed_to_connect_agent',
  failed_to_connect_customer: 'failed_to_connect_customer',
  customer_call_completed: 'customer_call_completed',
  agent_call_completed: 'agent_call_completed',
  connecting_agent: 'connecting_agent',
  connecting_customer: 'connecting_customer',
  agent_failure: 'attempting to reach not available agent',
  waiting_for_agent: 'waiting_for_agent'
}

const successIcon = [
  'completed',
  'agent_call_completed',
  'customer_call_completed',
  'completed'
]
const warningIcon = [
  'in-progress',
  'waiting_for_agent',
  'busy',
  'no-answer',
  'customer_connected',
  'scheduled',
  'cancelled',
  'rescheduled',
  'ringing',
  'agent_connected',
  'connecting_agent',
  'connecting_customer',
  'connected'
]

const statusOption = [
  {
    key: 'Awaiting Review',
    text: <><i style={{ color: 'orange' }} className="fa fa-hourglass-half mr-3" ></i>Pending</>,
    value: 1
  },
    
  
  {
    key: 'Reviewed', text: <><i  style={{ color: 'green' }} class="fas fa-user-check mr-3"></i>Assigned </>, value: 2 },
  {
    key: 'Disqualified',
    text: <> <i style={{ color: 'red' }} class="fas fa-phone-slash mr-3"></i>Closed </>,
    value: 3
  },

]

const LeadTableContent = props => {
  const {
    index,
    data,
    onClickMarkCheckbox,
    leadType,
    dropDownData,
    leadOwner,
    leadScore,
    rightPane,
    leadStage,
    handleRightPaneOpen,
    setUpdatedTable,
    updatedPane,
    allTags,
    reloadTable,
    deleteLead,
    selectedRecordIds,
    tableFullData,
    fvLeadList,
    fetchFLead,
    filterTable,
    pipeline
  } = props
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [playingAudio, setPlayingAudio] = useState(0)
  const [playingAudioContext, setPlayingAudioContext] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedLead, setSelectedLead] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [stage, setStage] = useState([])
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState([])
  const [tagsUpdated, setTagsUpdated] = useState(false)
  const [leadData, setLeadData] = useState(null)
const [menu, setMenu] = useState(false)
const [openCallback, setOpenCallback] = useState(false)
const [stageOptions, setStageOptions] = useState({})

  const [selectedIndex, setSelectedIndex] = useState(1);


  const handleMenuItemClick = (
    event,
    index,
    data
  ) => {
    setSelectedIndex(index);

    setMenu(null);
   if(index===0){
     openDialer()
   }else if(index===1){
     setOpen(true)
   }
   else if(index===2){
     changesInterested(data?.id, 2)
   }
   else if(index===3){
     setOpenCallback(!openCallback)
   }
  };
  const changesInterested = async (id, value) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-interested`
    axios
      .post(url, { interested: value, lead_id: id.substring(1) })
      .then(res => {
        reloadTable()
      })
        
      .catch(err => {})
  }

  useEffect(() => {
    if (!tagsUpdated) return
    if (!tags?.length) return
    saveTags()
  }, [tags])

  useEffect(() => {
    if (!allTags?.length) return

    let str = data.id
    if (str[0] == '#') {
      str = str.substring(1)
    }
    fetchTag(str)
  }, [allTags])
  
  useEffect(() => {
    const stages = pipeline?.filter((obj) => obj?.id === data?.funnel_id)?.[0]
    if (stages){
      
      setStageOptions(stages)
    }else{
      const defaultStage = pipeline?.filter((obj) => obj?.is_default)?.[0]
      setStageOptions(defaultStage)
    }
  }, [pipeline])
  

  const onStatusChange = (e, data, lead_id) => {
    if (lead_id[0] === '#') {
      lead_id = lead_id.substring(1)
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/change-status`
    const postData = {
      lead_id: lead_id,
      status: data?.value
    }
    axios
      .post(url, postData)
      .then(res => {
        CommonNotify('Lead status updated successfully.')
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update lead status')
      })
  }

  const onStatusChangeHandlerUpdate = (e, dat, data) => {
    onChangeInterseted3(e, dat, data)
      .then(res => {
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update lead status')
      })
  }

  const onChangeIntersetedUpdate = (e, dat, data) => {
    onChangeInterseted3(e, dat, data)
      .then(res => {
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update  lead interseted')
      })
  }

  const onChangeOwnerUpdate = (e, dat, data) => {
    onChangeOwner3(e, dat, data)
      .then(res => {
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update  lead Owner')
      })
  }

  const stopAudio = id => {
    if (playingAudio != id) return
    const audio = playingAudioContext
    audio.pause()
    setPlayingAudioContext(null)
    setPlayingAudio(0)
  }

  const addTag = (tag, index) => {
    let avilable = false

    tags.forEach(tg => {
      if (tg.tag.toLowerCase() === tag.toLowerCase()) {
        avilable = true
      }
    })

    if (avilable) return
    let newTags = [...tags]
    newTags.push(allTags[index])
    setTags(newTags)
    setTagsUpdated(true)
  }

  const playAudio = (id, url) => {
    if (playingAudio) return
    setPlayingAudio(id)

    const audio = new Audio(url)
    audio.play()
    setPlayingAudioContext(audio)
  }

  const fetchTag = id => {
    if (data.tags) {
      if (data.tags?.length) {
        fetchTags(data.tags)
        return
      }
    }

    fetchTags([])
  }

  const fetchTags = tags => {
    let tgs = [...tags]
    const tg = tgs

    tg.forEach((item, index) => {
      if (!getTag(item)) {
        tgs.splice(index, 1)
      }
    })

    const newTags = tgs.map(item => {
      return getTag(item)
    })

    setTags(newTags)
  }

  const saveTags = () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/add-tags`

    const pTags = tags.map(tag => {
      return tag.tag
    })

    let str = data.id
    if (str[0] == '#') {
      str = str.substring(1)
    }

    const postData = {
      tags: pTags,
      lead_id: str
    }

    axios
      .post(URL, postData)
      .then(res => { })
      .catch(err => {
        CommonNotify('Not able to set tags')
      })
  }

  const checkIcon = status => {
    if (status === "suspended") {
      return blockedCall
    }
    if (status === "scheduled") {
      return scheduledCall
    }
    if (successIcon.includes(status)) {
      return PickedCall
    }
    if (warningIcon.includes(status)) {
      return MissedCall
    }
    return cutCall
  }
  
  

  const getTag = tag => {
    let selectedTag = -1
    allTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    if (selectedTag == -1) return false
    return allTags[selectedTag]
  }

  const removeTag = tag => {
    let selectedTag = -1
    const selTags = [...tags]
    selTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    selTags.splice(selectedTag, 1)
    setTags(selTags)
    setTagsUpdated(true)
  }

  const handelClick = () => {
    history.push(`/leads/${data.id.substring(1)}`)
  }

  const isModalClose = () => {
    setOpen(false)
  }

  const isModalOpen = () => {
    setOpen(true)
  }

  const openDialer = () => {
    return props?.getDialer(!props.dialerStatus)
  }

  const showStatus = {
    suspended: "Blocked",
    failed_to_connect_agent: "Missed",
    failed_to_connect_customer: "Missed",
    failed: "Missed",
    no_answer: "Missed",
    completed: "Connected"
  }

  const data_popup = () => {
    return (
      <>
        <div className="dispaly_profile1" style={{ width: '70% !important' }}>
          <div className="profile_boxes1">
            <div className="profile_left1">
              <div className="profile_images1">
                <img src={DummyProfileImage} />
              </div>
              <div className="profile_detail1">
                <p style={{ marginTop: 7 }}>
                  {data.customerName !== null && data.customerName !== 'null'
                    ? data.customerName
                    : ''}
                </p>
              </div>
            </div>
          </div>
          <div className="dispaly_profile_detail1">
            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                ID : <span>{data.id} </span>
              </pre>
            </div>

            {data.phone_number &&
              data.phone_number !== '' &&
              data.phone_number !== null ? (
              <div className="profile_content1">
                <div className="call_icon1">
                  <img src={smallcall} style={{ height: '130px' }} />
                </div>
                <pre>
                  Contact : <span>{data.phone_number}</span>
                </pre>
              </div>
            ) : (
              ' '
            )}
            {data.email && data.email !== 'null' ? (
              <div className="profile_content1">
                <div className="call_icon1">
                  <img src={smallEmail} style={{ height: '130px' }} />
                </div>
                <pre>
                  Email : <span>{data.email} </span>
                </pre>
              </div>
            ) : (
              ' '
            )}

            {leadData && (
              <>
                {leadData.company_name && leadData.company_name !== null ? (
                  <div className="profile_content1">
                    <div className="call_icon1">
                      <Icon style={{ color: '#fff' }} name="trademark" />
                    </div>
                    <pre>
                      Company : <span>{leadData.company_name} </span>
                    </pre>
                  </div>
                ) : (
                  ' '
                )}

                {leadData.country && leadData.country !== null ? (
                  <div className="profile_content1">
                    <div className="call_icon1">
                      <Icon style={{ color: '#fff' }} name="flag" />
                    </div>
                    <pre>
                      Country : <span>{leadData.country}</span>
                    </pre>
                  </div>
                ) : (
                  ' '
                )}

                {leadData.ip_address && leadData.ip_address !== null ? (
                  <div className="profile_content1">
                    <div className="call_icon1">
                      <Icon style={{ color: '#fff' }} name="world" />
                    </div>
                    <pre>
                      IP : <span>{leadData.ip_address}</span>
                    </pre>
                  </div>
                ) : (
                  ' '
                )}
              </>
            )}

            {data.type === 'LIVE_CALL' ? (
              <div className="display_button1">
                <div className="row">
                  <a
                    style={{
                      height: '30px',
                      padding: '3px 10px',
                      width: '100px'
                    }}
                    className="call_set1 col"
                    onClick={() => onCallRequest(data.id)}
                  >
                    <img src={callicons} style={{ maxHeight: '15px' }} />
                  </a>
                  <a
                    style={{
                      height: '30px',
                      padding: '3px 10px',
                      width: '100px'
                    }}
                    className="mail_set1 col"
                    onClick={() => setOpen(true)}
                  >
                    <img src={emailicon} style={{ maxHeight: '15px' }} />
                  </a>
                </div>
              </div>
            ) : (
              ' '
            )}
          </div>
        </div>
      </>
    )
  }

  const data_popup2 = () => {
    return (
      <>
        <div className="dispaly_profile1">
          <div className="profile_boxes1">
            <div className="profile_left1">
              <div className="profile_images1">
                {/* <img src={DummyProfileImage} /> */}
              </div>
              <div className="profile_detail1">
                <p style={{ marginTop: 7 }}>Call Information</p>
              </div>
            </div>
          </div>
          <div className="dispaly_profile_detail1">
            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Direction : <span>{data.direction ? data.direction : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Duration :{' '}
                <span>{data.duration} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Type :{' '}
                <span>{data.type ? data.type.replace('_', ' ') : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Team : <span>{data.team_name ? data.team_name : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Agent : <span>{data.agent ? data.agent : ''} </span>
              </pre>
            </div>
            {data?.type === 'SCHEDULE_CALL' && (
              <div className="profile_content1">
                <div className="call_icon1">
                  <span style={{ color: '#fff' }}>#</span>
                </div>
                <pre>
                  Scheduled Status :{' '}
                  <span>
                    {data.schedule_call_status ? data.schedule_call_status : ''}{' '}
                  </span>
                </pre>
              </div>
            )}

            {data.final_status !== 'completed' ? (
              <div className="profile_content1">
                <div className="call_icon1">
                  <span style={{ color: '#fff' }}>#</span>
                </div>
                <pre>
                  Failure Reason :{' '}
                  <span>{data.failure_reason ? data.failure_reason : ''} </span>
                </pre>
              </div>
            ) : (
              <div></div>
            )}

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Source : <span>{data?.source ? data?.source : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Message :{' '}
                <span>{data?.widget_message ? data?.widget_message : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Team name :{' '}
                <span>{data?.team_name ? data?.team_name : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Score : <span>{data?.score ? data?.score : ''} </span>
              </pre>
            </div>

            <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
                Final Status :{' '}
                <span>
                  {data?.final_status}{' '}
                </span>
              </pre>
            </div>

            {/* <div className="profile_content1">
              <div className="call_icon1">
                <span style={{ color: '#fff' }}>#</span>
              </div>
              <pre>
              Technical Code : <span>{data?.tech_code ? data?.tech_code : ''} </span>
              </pre>
            </div>        */}
          </div>
        </div>
      </>
    )
  }



  const statusOptions = [
    { key: 2, text: <><i style={{ color: 'green' }} className="fa fa-thumbs-up mr-3"> </i>Good Fit</>, value: 1 },
    { key: 1, text: <><i style={{ color: 'orange' }}  className="fa fa-hourglass-half mr-3" ></i>Pending</>, value: 2 },
    { key: 3, text: <><i style={{ color: 'red' }} className="fa fa-thumbs-down mr-3" ></i> Bad Fit</>, value: 3 }
    // { key: 4, text: 'Closed', value: 3 }
  ]

  const leadScoreOption = [
    { key: 1, text: 'Bad Fit', value: 'Bad fit' },    
    { key: 2, text: 'Qualified', value: 'Qualified' }
  ]
  const ownerDropdown = [
    {
      key: 1,
      text: 'Assigned',
      value: 'Assigned'
    },
    {
      key: 2,
      text: 'Qualified',
      value: 'Qualified'
    },
    {
      key: 3,
      text: 'Negotiation',
      value: 'Negotiation'
    },
    {
      key: 4,
      text: 'Won',
      value: 'Won'
    },
    {
      key: 5,
      text: 'Lost',
      value: 'Lost'
    }
  ]
  
  
  const options=[
    'Callback',
    'Send a SMS',
    'Mark as SPAM',
    'Schedule a call',
    'Block lead'
  ]
  const iconOptions=[
    <img style={{width:'20px'}}
      src={DialerImage}
    />,
    <i
      className="fa fa-envelope"
      aria-setsize="20"
      style={{ color: '#2185d0',width:'20px'}}
      aria-hidden="true"
    ></i>,
    <i style={{ color: 'orange',width:'20px' }}  className="fa fa-hourglass-half" ></i>,
    <img
      style={{  width: '20px' }}
      src={callbackIcon}
    />,
    <i style={{ color: 'red',width:'20px' }} class="fas fa-phone-slash"></i>
    
  ]
  
  
  const onStageSelected = data => {
    setLeadStage(466, stage, setLoading)
  }

  const onStageValueChanged =async (funnel,stages,data) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${data?.id?.replace('#','')}/funnel`
  
    await axios
      .put(url, { funnel_id: funnel?.id, stage: stages })
      .then(res => {
        CommonNotify('Updated Successfully', 'success')
        setUpdatedTable()


      })
      .catch(error => { })

  }
  const leadId = data?.id?.replace('#', '')

  const getContact = (contact, id) => {
    const invalidResponse = ['null', 'undefined', '', false]
    let result = invalidResponse.find(item => {
      if (item === contact) return item
    })
    if (result) {
      return 'Contact ' + id
    }
    return contact
  }

  const deleteModalView = async (bool, lead = 0) => {
    if (lead) {
      setSelectedLead(lead)
    }
    setDeleteModal(bool)
  }

  const onDeleteLeadHandler = async () => {
    setIsLoading(true)
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
    var postId = selectedRecordIds?.length ? selectedRecordIds : [selectedLead]

    const postData = {
      ids: postId
    }

    axios
      .post(URL, postData)
      .then(res => {
        CommonNotify('Lead Deleted Successfully', 'success')
        setSelectedLead(0)
        setIsLoading(false)
        reloadTable()
      })
      .catch(err => {
        setIsLoading(false)
        CommonNotify('Not able to Delete Lead')
      })
  }

  const toggleFLead = async (id, fav) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL
      }/api/v1/leads/${id?.replace('#', '')}/flags`
    if (fav) {
      axios
        .post(url, {
          flag: 1
        })
        .then(res => {
          if (res.data.message[0] == 'Successfully') {
            // fetchFLead()
            reloadTable()
          }
        })
        .catch(error => {
          CommonNotify(error?.response?.data?.errors?.[0], 'warning')
        })
    } else {
      axios
        .delete(url, {
          data: {
            flag: 1
          }
        })
        .then(res => {
          if (res.data.message[0] == 'Successfully') {
            // fetchFLead()
            reloadTable()
          }
        })
        .catch(error => {
          CommonNotify(error?.response?.data?.errors?.[0], 'warning')
        })
    }
  }

  const isFLead = (id, data) => {
    if (data?.is_fav?.toString() === '1') {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Table.Row className="table-content-row">
        <Table.Cell data-key={index}>
          <div style={{ display: 'flex' }}>
            <CommonCheckbox
              onChange={() => onClickMarkCheckbox(index)}
              name="isChecked"
              checked={data.isChecked ? true : false}
            />
            <Tooltip title={'Mark as favourite'}>

            <i
              onClick={e => {
                toggleFLead(
                  data?.id?.replace('#', ''),
                  +data?.is_fav === 1 ? false : true
                )
              }}
              style={
                isFLead(data?.id?.toString()?.replace('#', ''), data)
                  ? {
                    fontSize: '20px',
                    margin: '10px',
                    color: '#0071eb',
                    marginTop: '-2px',
                    paddingLeft: '21px'
                  }
                  : {
                    fontSize: '20px',
                    margin: '10px',
                    color: 'rgb(203, 211, 227)',
                    marginTop: '-2px',
                    paddingLeft: '21px'
                  }
              }
              className="icon star"
            ></i>
            </Tooltip>
          </div>
        </Table.Cell>

        <Table.Cell style={{}} className="image-size">
          <div
            onClick={() => handleRightPaneOpen(data.id.substring(1))}
            title={data?.type || ''}
          >
            <Tooltip title={data?.interseted === 1 ? 'Good Fit' : data?.interseted === 2 ? 'Pending' : 'Bad Fit'}>
              <div style={{
                width: '15px',
                height: '15px',
                backgroundColor: data?.interseted === 1 ? 'green' : data?.interseted === 2 ? 'orange' : 'red',
                borderRadius: '50%',
                marginRight: '5px'

              }}></div>
            </Tooltip>
            {!data.isHoverDisplay && data.contact ? (
              
              <Popup
                className="profile_popup"
                content={data_popup}
                size="mini"
                position="bottom left"
                flowing
                hoverable
                trigger={<p>{getContact(data.contact, data.id)}</p>}
              />
            ) : (
              <p>{data.contact ? data.contact : 'Contact ' + data.id}</p>
            )}
            <img
              src={
                data?.type === 'LIVE_CALL' || data?.type === 'DIGITAL_CALL'
                  ? incomingIcon
                  : data?.type === 'LEAVE_MESSAGE'
                    ? leadsIcon
                    : data?.type === 'SCHEDULE_CALL'
                      ? scheduleIcon
                      : data?.type === 'OUTBOUND_CALL'
                        ? outboundIcon
                        : letterIcon
              }
              alt="icon"
            />
          </div>

          <div className="preview" style={{ margin: '12px auto' }}>
            <CommonButtons
              style={{ display: 'block', fontSize: '8px', padding: '7px 8px' }}
              content="Preview"
              background="blue"
              btnClass="btn-verify"
              onClick={() => handleRightPaneOpen(data.id.substring(1))}
            />
          </div>
        </Table.Cell>

        <Table.Cell onClick={() => handleRightPaneOpen(data.id.substring(1))}>
          {moment
            .utc(
              data?.created_at || data?.time
            )
            .local()
            .startOf('seconds')
            .fromNow()}
        </Table.Cell>

        {/* <Table.Cell>
          <div style={{display:'flex'}}>
            <Tooltip title={data?.interseted===1 ? 'Good Fit' :data?.interseted ===2 ? 'Pending':'Bad Fit'}>
          <div style={{
            width:'15px',
            height:'15px',
            backgroundColor:data?.interseted===1 ? 'green' :data?.interseted ===2 ? 'orange':'red',
            borderRadius:'50%',
            marginRight:'5px'
            
          }}></div>
            </Tooltip>
          {!data.isHoverDisplay && data.contact ? (
            <Popup
              className="profile_popup"
              content={data_popup}
              size="mini"
              position="bottom left"
              flowing
              hoverable
              trigger={<p>{getContact(data.contact, data.id)}</p>}
            />
          ) : (
            <p>{data.contact ? data.contact : 'Contact ' + data.id}</p>
          )}
          </div>
        </Table.Cell> */}

        <Table.Cell onClick={() => handleRightPaneOpen(data.id.substring(1))}>
          <div className={data?.source === 'Webpage' ? 'webpage' : 'facebook'}>
            <Tooltip title={tableFullData?.leads?.data[index]?.source || ''}>
              <span>
                {data.source === null ? 'Unknown' : data.source || ''}
              </span>
            </Tooltip>
          </div>
        </Table.Cell>

     

        <Table.Cell
          className="Lead Owner"
          onClick={() => handleRightPaneOpen(data.id.substring(1))}
        >
          <Dropdown
            options={leadOwner}
            placeholder="Unassigned"
            basic
            defaultValue={
              data.owner_id === null ? data?.interseted : data.owner_id
            }
            onChange={(e, dat) => onChangeOwnerUpdate(e, dat, leadId)}
          />
        </Table.Cell>

        {/* <Table.Cell className="Lead Owner">
          <Dropdown
            options={options}
            placeholder="Rate as"
            defaultValue={data?.interseted}
            onChange={(e, dat) => onChangeIntersetedUpdate(e, dat, data.id)}
          />

          {data?.interseted === 3 && (
            <>
              {deleteLead && (
                <img
                  onClick={() => deleteModalView(true, data.id.substring(1))}
                  src={deleteIcon}
                  className="delete-icon"
                  style={{ marginLeft: '5px' }}
                  alt="delete"
                />
              )}
            </>
          )}
        </Table.Cell> */}

        <Table.Cell className="Lead Owner">
          <Dropdown
            options={statusOptions}
            placeholder="Lead Status"
            value={parseInt(data.interseted)}
            onChange={(e, dat) => onStatusChangeHandlerUpdate(e, dat, data.id)}
          />
        </Table.Cell>
          <Table.Cell onClick={() => handleRightPaneOpen(data.id.substring(1))}>
            <div>
              <Dropdown
              options={[{
                key: 0,
                text: 'Unassign',
                value: '',
              }, ...(stageOptions?.stages?.map((obj,index)=>({
                key:index,
                text:obj,
                value:obj
              })) || [])]}
                placeholder="Set lead stage"
                defaultValue={data.stage ? data.stage : 'null'}
                onChange={(e, dat) =>
                 { e.stopPropagation()
                  onStageValueChanged(stageOptions, dat?.value,data)}
                }
              />
            </div>
          {stageOptions?.name}
          </Table.Cell>

        {(leadType === 'call' ||
          leadType === 'message' ||
          leadType === 'digital') && (
            <Table.Cell className="Lead Owne">
              {data && (
                <>
                  {data?.type === "SCHEDULE_CALL" ? 
                  <>
                    <div> {data.agent}</div>
                    <div className="d-flex mb-0">
                      {data?.request_call_time
                        ? moment(data?.request_call_time)
                          .utc(data?.request_call_time)
                          .format('dddd, MMMM, DD, YYYY')
                        : ''}
                      <Popup
                        trigger={
                          <Image
                            src={checkIcon(data?.schedule_call_status)}
                            className={rightPane ? 'rightPopUp' : ''}
                            style={{ width: 19, height: 19, margin: '-7px 7px' }}
                          />
                        }
                        position="top center"
                        size="mini"
                        flowing
                        hoverable
                        content={data_popup2}
                      >
                        {/* {data.call_status
                  ? data.call_status.replace(/_/g, ' ')
                  : 'Call status not assigned'} */}
                      </Popup>

                    </div>

                    {data?.request_call_time
                      ? moment.utc(data?.request_call_time).local()
                        // .utc(data?.request_call_time)
                        .format('HH:hh A') + " "

                      + moment.tz.guess()
                      // ' to ' +
                      // moment(data?.request_call_time)
                      //   .utc(data?.request_call_time)
                      //   .format('HH:hh A')
                      : ''}
                    <br></br>

                    {data?.meetingLocation}{" "}{data.duration > 0 && data.duration + ' secs'}



                  </> :
                    <>
                      {data.agent}
                      <div style={{ display: 'flex' }}>
                        {data.duration > 0 && data.duration + ' secs'}
                        <Popup
                          trigger={
                            <Image
                              src={checkIcon(data.call_status)}
                              className={rightPane ? 'rightPopUp' : ''}
                              style={{ width: 19, height: 19, margin: '0 auto' }}
                            />
                          }
                          position="top center"
                          size="mini"
                          flowing
                          hoverable
                          content={data_popup2}
                        >
                          {/* {data.call_status
                  ? data.call_status.replace(/_/g, ' ')
                  : 'Call status not assigned'} */}
                        </Popup>
                      </div>
                      {data?.schedule_call_status || data?.final_status !=="completed" &&
                      <div style={{ marginTop: "16px", textAlign: 'center' }}>
                        {showStatus[data?.schedule_call_status || data?.final_status]}
                      </div>
}
                    
                    </>
                  }
                </>
              )}
              {data && (
                <>
                  {data.recording_url !== null && (
                    <>
                      {playingAudio != data.id && (
                        <Icon
                          style={{ marginLeft: '10px', fontSize: '1.8em' }}
                          name="video play"
                          onClick={e => playAudio(data.id, data.recording_url)}
                        />
                      )}

                      {playingAudio == data.id && (
                        <Icon
                          style={{ marginLeft: '10px', fontSize: '1.8em' }}
                          name="pause circle"
                          onClick={e => stopAudio(data.id)}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {leadType === 'call' || leadType === 'digital' ? null : (
                // <>
                //   <Popup
                //     trigger={
                //       <Image
                //         src={checkIcon(data.call_status)}
                //         className={rightPane ? 'rightPopUp' : ''}
                //         style={{ width: 25, height: 25 }}
                //       />
                //     }
                //     position="top center"
                //     size="mini"
                //     flowing
                //     hoverable
                //     content={data_popup2}
                //   >
                //     {/* {data.call_status
                //     ? data.call_status.replace(/_/g, ' ')
                //     : 'Call status not assigned'} */}
                //   </Popup>
                // </>
                <></>
              )}
            </Table.Cell>
          )}
    
        {leadType === 'call' ||
          leadType === 'message' ||
          leadType === 'digital' ? (
          <>
            {/* <Table.Cell
              onClick={() => handleRightPaneOpen(data.id.substring(1))}
            >
              {data.agent}
            </Table.Cell>{' '} */}
          </>
        ) : leadType === 'SCHEDULE_CALL' ? (
          <Table.Cell>
            {data.agent}
            {/* <br></br>
            {tableFullData?.leads?.data[index]?.request_call_day
              ? tableFullData?.leads?.data[index]?.request_call_day
              : ''} */}
            <br></br>

            {data.request_call_time
              ? moment.utc(data.request_call_time).local()
                // .utc(data.request_call_time)
                .format('dddd, MMMM, DD, YYYY')
              : ''}


            <br></br>
            {data.request_call_time
              ? moment.utc(data.request_call_time).local()
                // .utc(data.request_call_time)
                .format('HH:hh A') + " "

              + moment.tz.guess()
              // ' to ' +
              // moment(data.request_call_time)
              //   // .utc(data.request_call_time)
              //   .add(tableFullData?.meeting_duration, 'seconds')
              //   .format('HH:hh A')
              : ''}      <br></br>

            {data?.meetingLocation}

          </Table.Cell>
        ) : (
          <Table.Cell></Table.Cell>
        )}
       
        <Table.Cell>
          {leadType === 'SCHEDULE_CALL' ? (
            <>
              <Popup
                trigger={
                  <Image
                    src={checkIcon(data.schedule_call_status)}
                    className={rightPane ? 'rightPopUp' : ''}
                    style={{ width: 25, height: 25, margin: '0 auto' }}
                  />
                }
                content={data_popup2}
                position="top center"
                size="mini"
                flowing
                hoverable
              >
                {/* {data.final_status
                ? data.final_status.replace(/_/g, ' ')
                : 'Call status not assigned'} */}
              </Popup>
              <div style={{ marginTop: "16px", textAlign: 'center' }}>{data?.final_status}</div>
            </>
          ) : null}
        </Table.Cell>

        
                <Table.Cell>
          <div style={{ display: 'inline-block' }}>
            {tags.map(data => (
              <span
                style={{
                  backgroundColor: data.color,
                  padding: '4px 10px',
                  color: '#fff',
                  borderRadius: '3px',
                  marginRight: '5px'
                }}
              >
                {' '}
               
                {data.tag}{' '}
                            <CloseIcon onClick={() =>  removeTag(data.tag)} />
              </span>
            ))}

            <Popup
              className="profile_popup"
              content={<AddTagPopup tags={allTags} addTag={addTag} />}
              size="large"
              position="bottom center"
              flowing
              on="click"
              trigger={
                <div style={{ marginTop: '15px' }}>
                  {/* {!tags?.length && ( */}
                    <a href="javascript:void(0)"> + Add New Tag</a>
                  {/* )}{' '} */}
                </div>
              }
            />
          </div>
        </Table.Cell>

        <Table.Cell className="Lead Owner">
          <Dropdown
            options={statusOption}
            placeholder="Lead Status"
            defaultValue={parseInt(+data?.status)}
            onChange={(e, dat) => onStatusChange(e, dat, data.id)}
          />
        </Table.Cell>

        <Table.Cell>
          <div style={{ display: 'block' }}>     
            <div style={{ display: 'flex',alignItems:"center", justifyContent:"center" }}>
              <Tooltip title={'Delete Lead'}>
                <DeleteForeverIcon
                  fontSize="large"
                  style={{fill:'red'}}
                  onClick={() => deleteModalView(true, data?.id?.substring(1))}
                />
              </Tooltip>
              <Tooltip title="View more">
                <MoreVertIcon onClick={(event) => setMenu(event.currentTarget)}/>
              </Tooltip>
              <Menu
                id="lock-menu"
                anchorEl={menu}
                open={!!menu}
                onClose={()=>setMenu(null)}
                MenuListProps={{
                  'aria-labelledby': 'lock-button',
                  role: 'listbox',
                }}
              >
                {/* <MenuItem
                  key={'text'}
disabled={true}

                >
                  Move lead to
                </MenuItem> */}
                {options?.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index,data)}
                  >
                    <div style={{display:'flex', minWidth:'130px',width:'100%'}}>
                      <div style={{marginRight:'5px'}}>
                        {iconOptions[index]}
                    </div>
                    <p>
                      
                    {option}
                    </p>
                      {/* <div style={{ marginLeft: '10px' }}>
                    {index===3 && data?.type==='SCHEDULE_CALL' ? 
                      <i class="fas fa-check"></i> :''
                  
                  }
                    {index===2 && data?.interseted===2 ? 
                        <i class="fas fa-check"></i> :''
                  
                  }
                 </div> */}
                    </div>
                  </MenuItem>
                ))}
              </Menu>
              {/* {data?.phone_number && (
                <>
                  <a
                    onClick={() => openDialer()}
                    data-tip="Open dialer"
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      verticalAlign: 'top'
                    }}
                  >
                    <Tooltip title={'Callback'}>
                      <img
                        style={{ width: '30px', marginTop: '-5px' }}
                        src={DialerImage}
                      />
                    </Tooltip>
                  </a>
                  <a
                    onClick={() => setOpen(true)}
                    data-tip="Send SMS & MMS"
                    href="javascript:void(0)"
                  >
                    <Tooltip title={'Send SMS'}>
                      <i
                        className="fa fa-envelope"
                        aria-setsize="20"
                        style={{
                          color: '#2185d0',
                          fontSize: 21,
                          marginRight: 10
                        }}
                        aria-hidden="true"
                      ></i>
                    </Tooltip>
                  </a>
                </>
              )} */}
              {/* <Popup
                className="profile_popup"
                content={<AddTagPopup tags={allTags} addTag={addTag} />}
                size="large"
                position="bottom center"
                flowing
                on="click"
                trigger={
                  <div style={{ marginTop: '15px' }}>
                    {!tags?.length && (
                      <a href="javascript:void(0)"> + Add New Tag</a>
                    )}{' '}
                  </div>
                }
              /> */}
            </div>
          </div>
          {open && (
            <SendMessageModal
              closeAfterSend={isModalClose}
              isModalClose={isModalClose}
              isModalOpen={isModalOpen}
              open={open}
              dropDownData={dropDownData}
              leadData={data}
            />
          )}
        </Table.Cell>
      </Table.Row>

      <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => deleteModalView(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete the lead id{' '}
              {selectedRecordIds?.length
                ? selectedRecordIds.join(', ')
                : selectedLead}
              ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={() => deleteModalView(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={onDeleteLeadHandler}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
      {openCallback && (
        <CallbackModal
          isModalClose={() => {
            setTimeout(() => {
              setOpenCallback(false)
            }, 300)
          }}
          isModalOpen={() => console.log(`open`)}
          open={openCallback}
        // dropDownData={dropDownData || []}
        // leadData={leadAbout}
        // phone_numbber={leadAbout?.phone_number}
        />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  widget: state.home.widget,
  subscription: state.home.subscription,
  pipeline:state.home?.pipeline,
  dialerStatus: state.dialer.currentStatus
})

export default connect(mapStateToProps, {
  getWidget,
  getSubscription,
  getDialer
})(LeadTableContent)
