import React, { Component } from 'react'
import icon from '../assets/images/settingIcon.png'
import InnerNavigation from '../common/InnerNavigation'
import Title from '../common/Title'
import Country from '../helpers/CountryList'

const titleContent = {
  type: 'image',
  titleOne: icon,
  titleTwo: 'Buy a Number'
}

const settingsData = [
  {
    mainSidebar: 'AddNumbers',
    innerTabs: ['Local', 'TollFree', 'Mobile']
  }
]

class AddNumber extends Component {
  componentDidMount = () => {
    document.title = 'Add Number'
  }
  render() {
    return (
      <div className="fullwidth-container number-container">
        <Title data={titleContent} />
        <div className="numbers-banner-container add-number add_number_inner">
          <div
            className="numbers-banner"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              padding: '50px 0'
            }}
          >
            <h1 className="banner-title">
              Find your perfect number
              {/* <div className='banner-info-text'>
              Set up local and toll-free numbers to increase contact rate with your prospects
            </div> */}
            </h1>
            <p style={{ fontSize: '18px' }}>
              Search for available numbers by country, area code, or features.
            </p>
          </div>

          <InnerNavigation
            dataComponent={settingsData}
            activeTab={this.props.activeTab}
            activeComponent={this.props.activeComponent}
            dataTitle={titleContent}
            CountryList={Country}
          />
        </div>
      </div>
    )
  }
}

export default AddNumber
