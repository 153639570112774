import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { Dimmer, Dropdown, Input, Loader, Tab } from 'semantic-ui-react'
import { CommonNotify } from '../../common/CommonNotify'
import Title from '../../common/Title'
import { getLeadOwner } from '../../config/leadAPI'
import { useGetLeadsQuery } from '../../features/api/apiSlice'
import LeadPanels from './components/LeadsTable'
import leadLogo from '../../assets/images/lead-logo.svg'
import SearchIcon from '@mui/icons-material/Search'
import calendarIcon from '../../assets/images/calendar.png'
import CommonInput from '../../common/CommonInput'
import { Grid, List, ListSubheader } from '@mui/material'
import RightPane from '../../components/leads/leadRightPane/rightPopUp'
import RightSide from './components/RightSide'

const LeadsV2 = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [Loading, setLoading] = useState(true)
  const [contactData, setContactData] = useState()
  const [companyData, setCompanyData] = useState([])
  const [filter, setFilter] = useState(contactData)
  const [opendetails, setOpen] = React.useState(false)
  const [saveSegment, setSegment] = useState(false)
  const [dataVip, setData] = useState([contactData])
  const [segmentText, setTextSegment] = useState('')
  const [activeType, setActiveType] = useState('contact')
  let [isMarkAllCheckbox, setisMarkAllCheckbox] = useState(false)
  const [params, setParams] = useState({
    limit: 15,
    page: 1,
    sort_field: 'id',
    sort_value: 'DESC',
    sort: '-id',
    'filter[type][in]':
      'LIVE_CALL,DIGITAL_CALL,UNSUPPORTED_COUNTRY,USER_BLACKLISTED',
    'filter[direction][is]': 'Inbound',
    'filter[status][is]': 1
  })
  const [leadId, setLeadId] = useState(null)
  const [leads, setLeads] = useState([])
  const [dataLoaded, setDataLoaded] = useState([])
  const [leadData, setLeadData] = useState(null)
  const [membersList, setMemberList] = useState([])
  const [membersTeam, setMembersTeam] = useState([])
  const [members, setMembers] = useState([])
  const [queryValue, setQueryValue] = useState({ phone_number: '' })
  const [leadOwner, setLeadOwner] = useState([
    {
      key: 'unassign owner',
      text: 'Unassign owner',
      value: null
    }
  ])
  const res = useGetLeadsQuery(params)

  const title = {
    type: 'image',
    titleOne: leadLogo,
    titleTwo: 'Leads'
  }

  const options = [
    { key: 1, text: 'All Time', value: 'All Time' },
    { key: 2, text: 'Today', value: 'Today' },
    { key: 3, text: 'Yesterday', value: 'Yesterday' },
    { key: 4, text: 'This week', value: 'This week' },
    { key: 5, text: 'Last week', value: 'Last week' },
    { key: 6, text: 'This month', value: 'This month' },
    { key: 7, text: 'Last month', value: 'Last month' },
    { key: 8, text: 'Custom range', value: 'Select custom' }
  ]

  useEffect(() => {
    if (res.isSuccess) {
      setLeadId(res?.data?.data?.leads?.data[0].id)
      setLeads(res?.data?.data?.leads?.data)
    }
  }, [res])

  useEffect(() => {
    fetchTeams()
    getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin

        setLeadOwner(prev => [
          ...prev,
          {
            key: admin.id,
            text: admin.first_name + ' ' + admin.last_name,
            value: admin.id
          }
        ])

        await res.data.data.members.map(team => {
          setLeadOwner(prev => [
            ...prev,
            {
              key: team.id,
              text: team.first_name + ' ' + team.last_name,
              value: team.id
            }
          ])
        })
      })
      .catch(err => {})
  }, [])

  useEffect(() => {
    processList()
  }, [members, membersTeam])

  useEffect(() => {
  }, [leadId])

  const fetchTeams = async () => {
    const data = []
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/teams?limit=20000&offset=0`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          setMembersTeam(res.data.data)
          fetchMembers()
        }
      })
      .catch(err => {})
  }

  const processList = () => {
    const allList = [...members, ...membersTeam]
    const processList = allList.map(item => {
      return {
        key: item.first_name?.length ? item.id : item.id + '-team',
        value: item.id,
        text: item.first_name?.length ? item.first_name : item.name + ' team'
      }
    })
    setMemberList(processList)
  }

  const fetchMembers = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          const dt1 = [...res.data.data.members]
          dt1.push(res.data.data.admin)
          setMembers(dt1)
        }
      })
      .catch(error => {
        CommonNotify('cant fetch Members')
      })
  }
  const panes = useMemo(() => {
    const data = [
      {
        menuItem: `My Leads (${leads?.length || 0})`,
        render: ({ leadData, leadId }) => (
          <LeadPanels
            leadData={leadData}
            setLeadId={setLeadId}
            leadId={leadId}
          />
        )
      },
      {
        menuItem: `All Leads (${leads?.length || 0})`,
        render: ({ leadData }) => <LeadPanels leadData={leadData} />
      }
    ]
    setLoading(false)
    return data
  }, [leads])
  const handleTabChange = (e, { activeIndex }) => {
    setActiveIndex(activeIndex)
  }

  const handleChangeNew2 = (data, param) => {
    const qValue = { ...queryValue }

    qValue[param].value = data
    setQueryValue(qValue)
  }

  const handleChangeNew3 = e => {
    if (e.key === 'Enter' || queryValue['phone_number'].value == '') {
    }
  }

  return (
    <div className="lead-container">
      <Dimmer active={Loading} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      <Title data={title} />
      <Grid container spacing={2}>
        <Grid xs={6} display="flex">
          <div
            style={{
              width: '30%',
              paddingLeft: '5%',
              paddingTop: '5%',
              borderRight: '1px solid #E6E8EE'
            }}
          >
            <List
              sx={{
                width: '100%',
                maxWidth: 300,
                bgcolor: 'background.paper'
              }}
              component="nav"
              aria-labelledby="nested-list-subheader-2"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader-2">
                  Filters
                </ListSubheader>
              }
            >
              <Dropdown
                className="condition-select"
                style={{ margin: '5%', maxWidth: '85%', boxShadow: 'none' }}
                selection
                placeholder="Lead Owner"
              />

              <Dropdown
                className="condition-select"
                style={{ margin: '5%', maxWidth: '85%', boxShadow: 'none' }}
                selection
                placeholder="Agent"
              />

              <Dropdown
                className="condition-select"
                style={{ margin: '5%', maxWidth: '85%', boxShadow: 'none' }}
                selection
                placeholder="Call Type"
              />

              <Dropdown
                className="condition-select"
                style={{ margin: '5%', maxWidth: '85%', boxShadow: 'none' }}
                selection
                placeholder="Score"
              />

              <Dropdown
                className="condition-select"
                style={{ margin: '5%', maxWidth: '85%', boxShadow: 'none' }}
                selection
                placeholder="Source"
              />

              <Dropdown
                className="condition-select"
                style={{ margin: '5%', maxWidth: '85%', boxShadow: 'none' }}
                selection
                placeholder="interseted"
              />
              <Dropdown
                className="condition-select"
                style={{ margin: '5%', maxWidth: '85%', boxShadow: 'none' }}
                placeholder="select date"
                options={options}
                selection
              />
              <Input
                placeholder="Search"
                style={{ margin: '5%', maxWidth: '85%', boxShadow: 'none' }}
                onChange={e => handleChangeNew2(e.target.value, 'phone_number')}
                onKeyPress={handleChangeNew3}
              />

              {/* <div style={{ marginRight: 30 }} className="filterSection">
                {selectedPickerOption ? (
                  <CommonDateRangePicker
                    handleApply={handleApply}
                    handleCancel={handleCancel}
                    initialSettings={{
                      startDate: startDateFilter,
                      endDate: endDateFilter,
                      maxDate: moment()
                    }}
                  />
                ) : null}
              </div> */}
            </List>
          </div>
          <div style={{ width: '70%' }}>
            <div
              className="metting-event"
              style={{
                display: 'flex',
                padding: '49px 0 15px 0',
                marginTop: 7
              }}
            >
              <Tab
                style={{ width: '100%' }}
                activeIndex={activeIndex}
                menu={{ secondary: true, pointing: true }}
                panes={panes}
                leadData={leads}
                onTabChange={handleTabChange}
                leadId={leadId}
              />
            </div>
          </div>
        </Grid>
        <Grid xs={6}>
          <RightSide
            id={leadId}
            leadScore={[]}
            leadStage={[]}
            leadOwner={leadOwner}
            allTags={[]}
            rightPane={true}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default LeadsV2
