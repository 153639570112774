import axios from 'axios'
import React from 'react'
import goLiveRocket from '../../assets/images/goliverocket.svg'
// import welcomeCall from '../../assets/images/welcome-call.png'
import dashboardMobile from '../../assets/images/dashboard-mobile.png'
// import welcomedash from '../../assets/images/welcome-dashboard.png'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import { useHistory } from 'react-router-dom'

const GoLive = props => {
  const history = useHistory()
  const skipOnboarding = async () => {
    localStorage.setItem('quick_setup', props?.redirectURL ? 8 : 7)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/skip-onboarding`
    axios
      .post(url, {})
      .then(res => {
        CommonNotify('Successfully updated', 'success')
        if (props?.redirectURL) {
          window.location.assign(props?.redirectURL)
        } else {
          window.location.assign('/home')
        }
      })
      .catch(err => {})
  }

  const goToTeam = () => {
    window.location.href = '/settings/team/manage_teammates'
  }

  // const redirectToMobile = () =>{
  //   window.location.href = 'https://dialer.limecall.com'
  //   history.go(1)
  // }

  return (
    <div className="golive">
      <div className="golive-content py-4">
        <img src={goLiveRocket} alt="" />
        <p className="golive-title">You are good to go</p>
        <p className="golive-subtitle">It's time to grow with LimeCall</p>
        <div className="welcome-go-live">
          {/* <img src={welcomeCall} alt="welcomeCall" />
          <img src={welcomedash} alt="welcomedash" /> */}
          <img src={dashboardMobile} alt="welcomedash" />
        </div>
        <p className="golive-desc">
          I would like a one to one personalized demo
        </p>
        <div className="golive-btn-holder text-center">
          <CommonButtons
            content="Continue to Web Admin Dashboard"
            background="blue"
            btnClass="btn-modal-style mb-3 p-3"
            onClick={() => skipOnboarding()}
          />
          <CommonButtons
            content="Continue to Web Dialer"
            background="blue"
            btnClass="btn-modal-style p-3"
            // onClick={() => redirectToMobile()}
          />
          {/* <CommonButtons
            content="Go to Dashboard"
            background="blue"
            btnClass="btn-modal-style"
            onClick={() => skipOnboarding()}
          /> */}
          <CommonButtons
            onClick={() =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.limecall&hl=en&gl=US&pli=1'
              )
            }
            content="Download Android App"
            background="alt-blue"
            btnClass="btn-modal-style p-3"
          />
        </div>
        {/* <p className="golive-anchor">
          Alternatively,{' '}
          <a href="/settings/users_&_teams/users">Invite teammates.</a>
        </p> */}

        <div
          className="quicksetup-footer footer-button mt-auto w-100"
          style={{ justifyContent: 'space-between' }}
        >
          <CommonButtons
            content="Back"
            btnClass="btn-modal-style  btn-back"
            onClick={props.prev}
          />
        </div>
      </div>
    </div>
  )
}

export default GoLive
