import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import NodeToggle from '../../common/NodeToggle'

import CommonInput from '../../common/CommonInput'

import CommonButton from '../../common/CommonButtons'
import iconStyle from '../../assets/images/Dashboard 2-07.png'
import whatsappIcon from '../../assets/images/whatsapp.png'
import telegramIcon from '../../assets/images/telegram.png'
import lineIcon from '../../assets/images/line.png'
import messangerIcon from '../../assets/images/messanger.png'
import email from '../../assets/images/email.png'
import sms from '../../assets/images/sms.png'
import axios from 'axios'
import { CommonNotify } from '../../common/CommonNotify'
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import LinkIcon from '@mui/icons-material/Link'


import CommonButtons from '../../common/CommonButtons'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'
import CommonGroupButton from '../../common/CommonGroupButton2'
import CommonSelect from '../../common/CommonSelect'
import { Modal } from 'semantic-ui-react'
import CustomizeGroupButton from '../../common/CustomizeGroupButton2'





export const WidgetCustomsTitle = () => (
  <div className="accordion-widget-holder">
    <div className="accordion-image-holder">
      <img src={iconStyle} alt="logo" />
    </div>
    <div className="accordion-title-holder">
      <h2 className="accordion-title">Custom buttons</h2>
      <p className="accordion-description">
        On your website, you can direct your visitors to your website, social media, or other link.
        {/* Decide how the call widget on your website will look, behave and what information it will offer. */}
      </p>
    </div>
  </div>
)

export const WidgetCustoms = ({
  reloadWidget,
  handleTabActive,
  widget,
  styleRef,
  closeWidgetStyle,
  handleDataRef,
  handleToggleData,
  clickToCallName,
  clickToCallNameToggle,
  clickToCallEmail,
  clickToCallEmailToggle,
  clickToCallTeam,
  clickToCallInterest,
  clickToCallIndustry,
  clickToCallEmployee,
  clickToCallRevenue,
  clickToCallRole,
  clickToCallComment,
  clickToCallTeamToggle,
  clickToCallCustomFields,

  widgetSettDataUpdate,
  widgetStyledDataUpdate,

  clickToCallCustomFieldsToggle,
  lstCustomFields,
  onUpdateCustomeFields,
  handleQualification,
  onAddRemoveCustomeFields,
  onSubmitCustomFields,
  callMeBackName,
  callMeBackEmail,
  callMeBackTeam,
  callMeBackInterest,
  callMeBackIndustry,
  callMeBackEmployee,
  callMeBackRevenue,
  callMeBackRole,
  callMeBackComment,
  callMeBackCustomFields,
  callMeBackCustomFieldsToggle,
  callMeLaterName,
  callMeLaterNameToggle,
  callMeLaterEmail,
  callMeLaterEmailToggle,
  callMeLaterTeam,
  callMeLaterInterest,
  callMeLaterIndustry,
  callMeLaterEmployee,
  callMeLaterRevenue,
  callMeLaterRole,
  callMeLaterComment,
  callMeLaterTeamToggle,
  callMeLaterCustomFields,
  callMeLaterCustomFieldsToggle,
  leaveMessageName,
  leaveMessageEmail,
  leaveMessageTeam,
  leaveMessageInterest,
  leaveMessageIndustry,
  leaveMessageComment,
  leaveMessageEmployee,
  leaveMessageRevenue,
  leaveMessageRole,
  leaveMessageCustomFields,
  leaveMessageCustomFieldsToggle,
  classicSmart,
  contextRef,
  legalWidget,
  legalToggle,
  socialWidget,
  privacyWidget,
  privacyToggle,
  onClickTableCategory,
  widgetStyleTabs,
  activeIndexWidget,
  handleClickWidget,
  allowedTabs,
  webCallDisplay,
  callMeBackDisplay,
  contactUsDisplay,
  callMeLaterDisplay,
  leaveMessageDisplay,
  loading,
  fullNameToggle,
  emailToggle,
  onClickAllowedTabs,
  widgetObject,
  intilizeTab
}) => {
  const [buttonColor, setButtonColor] = useState('#662D91')
  const [isInputChange, setIsInputChange] = useState(false)
  const [icons, setIcons] = useState([])


  const [clickToCallTabText, setClickToCallTabText] = useState(
    'Click to connect to agent!'
  )
  const [clickToCallButtonText, setClickToCallButtonText] = useState(
    'Start Call'
  )

  const [callMeLaterTabText, setCallMeLaterTabText] = useState(
    'Would you like us to call you later ?'
  )
  const [callMeLaterButtonText, setCallMeLaterButtonText] = useState('Schedule')

  const [leaveMessageButtonText, setLeaveMessageButtonText] = useState(
    'Send Message'
  )
  const [widgetApiData, setWidgetApiData] = useState()


  const [legalText, setLegalText] = useState('Terms')

  const [widgetAllSetting, setWidgetALlSetting] = useState(null)
  const [widgetSetting, setWidgetSetting] = useState()
  const [bubbleSetting, setBubbleSetting] = useState()
  const [widgetStyleData, setWidgetStyleData] = useState({})
  const [apiLoaded, setApiLoaded] = useState(false)
  const [widgetApiResData, setWidgetApiResData] = useState({})
  const [socialData, setSocialData] = useState({})
  const [socialAPI, setSocialAPI] = useState({})
  const [social, setSocial] = useState([])
  const [interestData, setInterestData] = useState([])
  const [teamData, setTeamData] = useState([])
  const [customLinks, setCustomLinks] = useState([])
  const [existingLinks, setExistingCustomLinks] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)


  const teamsData = useSelector((state) => state.home?.teamsData)
  const modernHeader = [, 'modern_header_title_status', 'modern_header_subtitle_status']


  useEffect(() => {

    if (!widget.id) return
    fetchSetting()

  }, [widget, widgetObject])

  useEffect(() => {
    if (teamsData) {
      const teams = teamsData?.map(team => {
        return team.name
      })
      setTeamData(teams);
    }

  }, [teamsData])

  useEffect(() => {
    getIcon()
  }, [])

  // useEffect(() => {
  //   if (bubbleSetting.position == '') return
  //   setApiLoaded(true)
  // }, [bubbleSetting.position])

  const getIcon = (val, value) => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/default-branding-icons`
    axios
      .get(url, {
        widget_id: widget?.data?.[0]?.id,

        type: 'assign', [val]: value
      })
      .then(res => {
        setIcons(res?.data?.data)
        loading(false)
        if (res.data.message[0] == 'Successfully') {
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })

  }
  const fetchSetting = async () => {


    await setWidgetALlSetting(widgetObject)
    await setWidgetApiData(widgetObject)
    await setWidget(widgetObject)
    await setBubble(widgetObject)
    let widgetData = widgetObject;
    widgetData["full_name_display_on_call_now"] = "on_call_now_screen";
    widgetData["full_name_display_on_digital_calls"] = "before_call";
    widgetData["full_name_display_on_call_later"] = "before_schedule";
    widgetData["email_display_on_call_now"] = "on_call_now_screen";
    widgetData["email_display_on_digital_calls"] = "before_call";
    widgetData["email_display_on"] = "before_schedule";
    widgetData["email_display_on_call_later"] = "before_schedule";


    setInterestData(widgetObject.interested)


    await setWidgetStyleData(widgetData)
    await setWidgetApiResData(widgetData)

    handleTabActive()




  }

  const postSetting = async (data) => {
    loading(true)

    const updatedData = { ...data, ultimate_default_screen: data?.ultimate_default_screen?.toString() }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/set-customizations`
    await axios.post(url, updatedData)?.then((res) => {
      if (res) {
        loading(false)
        reloadWidget()

      }
    }

    )



  }
  const postModernSetting = data => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/modern-tabs`
    return axios.put(url, data)



  }
  const postModernHeaderSetting = data => {
    loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/modern-header`
    return axios.put(url, data)



  }
  const templateSetting = data => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/callback-template-config`
    return axios.put(url, data)



  }
  const mordenconfigSetting = data => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/modern-template-config`
    return axios.put(url, data)



  }

  const setWidget = data => {
    let widgetSettingData = { ...widgetSetting }
    const dataTemplateType = parseInt(data.template_type);
    if (dataTemplateType == 2) {
      widgetSettingData.template = 'Smart'
    } else if (dataTemplateType == 1) {
      widgetSettingData.template = 'Classic'
    } else if (dataTemplateType == 3) {
      widgetSettingData.template = 'Modern'
    }

    widgetSettingData.classicType =
      data.classic_template_design_type == 1 ? false : true
    widgetSettingData.font = data.font

    widgetSettingData.timerColor = data.popup_timer_color
    widgetSettingData.callColor = data.submit_request_call_color

    widgetSettingData.socialProof = data.social_proof
    widgetSettingData.legalNote = data.terms_and_conditions
    widgetSettingData.legalType = data.terms_type
    widgetSettingData.legalStatus = data.terms_and_conditions_status
    widgetSettingData.privateStatus = data.privacy_note_status
    widgetSettingData.privatePolicy = data.privacy_note

    if (widgetSettingData.legalType == 0) {
      widgetSettingData.legalType = 'Text'
    } else if (widgetSettingData.legalType == 1) {
      widgetSettingData.legalType = 'URL'
    } else {
      widgetSettingData.legalType = 'HTML'
    }

    widgetSettingData.webCall = data.digital_call
    widgetSettingData.callLeter = data.call_me_later
    widgetSettingData.callBack = data.call_now
    widgetSettingData.leaveMessage = data.leave_message

    widgetSettingData.webCallHeader = data.digital_call_tab_text
    widgetSettingData.webCallButton = data.digital_call_button_text
    widgetSettingData.webCallName = data.full_name_in_digital_calls_tab
    widgetSettingData.webCallEmail = data.email_in_digital_calls_tab
    widgetSettingData.webCallTeam = data.team_in_digital_calls_tab

    widgetSettingData.callLeterHeader = data.schedule_call_tab_text
    widgetSettingData.callLeterButton = data.offline_schedule_call_message
    widgetSettingData.callLeterName = data.full_name_in_call_later_tab

    widgetSettingData.callLeterEmail = data.email_in_call_later_tab
    widgetSettingData.callLeterTeam = data.team_in_call_later_tab

    widgetSettingData.callBackHeader = data.widget_text
    widgetSettingData.callBackButton = data.submit_request_call_text
    widgetSettingData.callBackName = data.full_name_in_call_now_tab
    widgetSettingData.callBackEmail = data.email_in_call_now_tab
    widgetSettingData.callBackTeam = data.team_in_call_now_tab

    widgetSettingData.callBackCompHeader = data.headline_in_callback_template
    widgetSettingData.callBackCompButton = data.cta_btn_callback_template
    widgetSettingData.callBackCompName = data.name_in_callback_template
    widgetSettingData.callBackCompEmail = data.email_in_callback_template
    widgetSettingData.callBackCompTeam = data.team_in_call_now_tab
    widgetSettingData.callBackCompComment = data.comment_in_callback_template




    widgetSettingData.leaveMessageHeader = data.leave_message_tab_text
    widgetSettingData.leaveMessageName = data.full_name_in_leave_message_tab
    widgetSettingData.leaveMessageEmail = data.email_in_leave_message_tab
    widgetSettingData.leaveMessageTeam = data.team_in_leave_message

    setWidgetSetting(widgetSettingData)
    setWidgetApiData(widgetSettingData)
  }

  const socialPost = () => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/${widget?.id}/social`
    return axios.put(url, { ...socialData })
      .then(res => {
        reloadWidget()
        loading(false)
        if (res.data.message[0] === 'Successfully') {
          CommonNotify('Updated Widget Setting', 'success')
          handleTabActive()
          setIsInputChange(false)
          reloadWidget()
        }
      })
      .catch(error => {
        loading(false)
        CommonNotify('Cant Updated Widget Setting', 'error')
      })
  }
  const submitWidget = () => {
    if (Object?.keys(socialData)?.length > 0) {
      setSocialAPI({ ...socialData })
      postSetting({
        ...widgetStyleData, ...socialData
      })
    }

    const data = widgetStyleData

    if (data.shape === 'btnRight') {
      data.shape = 'square'
    } else {
      data.shape = 'default'
    }

    if (parseInt(widgetStyleData.template_type) === parseInt(6)) {
      templateSetting(data)
    }
    if (parseInt(widgetStyleData.template_type) === parseInt(3)) {
      postModernHeaderSetting(data)

    }
    if (parseInt(widgetStyleData.template_type) === parseInt(3)) {
      mordenconfigSetting(data)

    }
    createCustomLink()

    if (JSON.stringify(customLinks) !== JSON.stringify(existingLinks))
      updateCustomLink()
    // postSetting(data).then(res => {
    //   reloadWidget()
    //   loading(false)
    //   if (res.data.message[0] === 'Successfully') {
    //     CommonNotify('Updated Widget Setting', 'success')
    //     handleTabActive()
    //     setIsInputChange(false)
    //     reloadWidget()
    //   }
    // })
    //   .catch(error => {
    //     loading(false)
    //     CommonNotify('Cant Updated Widget Setting', 'error')
    //   })
  }





  const changeSocial = (index, value) => {

    let data = socialData
    let widgetStyleDataNew = { ...widgetStyleData }
    if (value === true) {
      value = 1
    } else if (value === false) {
      value = 0
    }
    data[index] = value
    widgetStyleDataNew[index] = value
    setWidgetStyleData({ ...widgetStyleDataNew })
    if (value === 0 || value === 1 || value ==='left' || value==='right') {
      setSocialData({ ...data })
      // socialPost()
      postSetting({
        ...widgetStyleData, ...data
      })
    } else {
      const check = _.isEqual(data, socialAPI)
      if (check === false) {
        setIsInputChange(true)
        setSocialData({ ...data })
      } else {
        setIsInputChange(false)
      }

    }


  }
  // const changeWidgetToggle = async (index, value) => {

  //   loading(true)

  //   let widgetStyleDataNew = { ...widgetStyleData }

  //   if (index == 'privacy_note_status' || index == 'call_me_later' || index == 'call_now' || index == 'digital_call' || index == 'call_btn_text_callback_template' ) {
  //     widgetStyleDataNew[index] = value;
  //   } else {
  //     widgetStyleDataNew[index] = value ? 1 : 0
  //   }


  //   if (widgetStyleDataNew.shape === 'btnRight') {
  //     widgetStyleDataNew.shape = 'square'
  //   } else {
  //     widgetStyleDataNew.shape = 'default'
  //   }

  //   await setWidgetStyleData({ ...widgetStyleDataNew })

  //   widgetStyledDataUpdate({ ...widgetStyleDataNew })
  //   if (modernHeader?.includes(index)) {
  //     postModernHeaderSetting(widgetStyleDataNew).then(res => {
  //       reloadWidget()
  //       loading(false)
  //       if (res.data.message[0] === 'Successfully') {
  //         CommonNotify('Updated Widget Setting', 'success')
  //       }
  //     })
  //       .catch(error => {
  //         loading(false)
  //       })
  //   } else if (index?.includes('template')) {

  //     templateSetting(widgetStyleDataNew).then(res => {
  //       reloadWidget()
  //       loading(false)
  //       if (res.data.message[0] === 'Successfully') {
  //         CommonNotify('Updated Widget Setting', 'success')
  //       }
  //     })
  //       .catch(error => {
  //         loading(false)
  //       })
  //       postSetting(widgetStyleDataNew).then(res => {
  //         reloadWidget()
  //         loading(false)
  //         if (res.data.message[0] === 'Successfully') {
  //           CommonNotify('Updated Widget Setting', 'success')
  //         }
  //       })
  //         .catch(error => {
  //           loading(false)
  //         })

  //   } else if (index?.includes('modern')) {

  //     if (index === 'modern_call_now' || index === 'modern_call_later' || index === 'modern_send_message') {

  //       postModernSetting(widgetStyleDataNew).then(res => {
  //         reloadWidget()
  //         loading(false)
  //         if (res.data.message[0] === 'Successfully') {
  //           CommonNotify('Updated Widget Setting', 'success')
  //         }
  //       })
  //         .catch(error => {
  //           loading(false)
  //         })
  //     } else {
  //       mordenconfigSetting(widgetStyleDataNew).then(res => {
  //         reloadWidget()
  //         loading(false)
  //         if (res.data.message[0] === 'Successfully') {
  //           CommonNotify('Updated Widget Setting', 'success')
  //         }
  //       })
  //         .catch(error => {
  //           loading(false)
  //         })
  //         postSetting(widgetStyleDataNew).then(res => {
  //           reloadWidget()
  //           loading(false)
  //           if (res.data.message[0] === 'Successfully') {
  //             CommonNotify('Updated Widget Setting', 'success')
  //           }
  //         })
  //           .catch(error => {
  //             loading(false)
  //           })

  //     }
  //   }



  //   else {
  //     postSetting(widgetStyleDataNew).then(res => {
  //       reloadWidget()
  //       loading(false)
  //       if (res.data.message[0] === 'Successfully') {

  //       }
  //     })
  //       .catch(error => {
  //         loading(false)
  //       })
  //   }
  // }
  // const changeModernToggle = async (index, value) => {

  //   loading(true)

  //   let widgetStyleDataNew = { ...widgetStyleData }

  //   if (index == 'privacy_note_status' || index == 'call_me_later' || index == 'call_now' || index == 'digital_call') {
  //     widgetStyleDataNew[index] = value;
  //   } else {
  //     widgetStyleDataNew[index] = value ? 1 : 0
  //   }


  //   if (widgetStyleDataNew.shape === 'btnRight') {
  //     widgetStyleDataNew.shape = 'square'
  //   } else {
  //     widgetStyleDataNew.shape = 'default'
  //   }

  //   await setWidgetStyleData({ ...widgetStyleDataNew })

  //   widgetStyledDataUpdate({ ...widgetStyleDataNew })

  //   postModernSetting(widgetStyleDataNew).then(res => {
  //     reloadWidget()
  //     loading(false)
  //     if (res.data.message[0] === 'Successfully') {
  //       CommonNotify('Updated Widget Setting', 'success')
  //     }
  //   })
  //     .catch(error => {
  //       loading(false)
  //     })

  // }

  const setBubble = data => {
    let bubbleSetting1 = { ...bubbleSetting }

    bubbleSetting1.shadow = data.bubble_template == 2 ? true : false
    bubbleSetting1.color = data.circle_color
    bubbleSetting1.textOffline = data.bubble_offline_text
    bubbleSetting1.textOnline = data.bubble_text

    if (data.bubble_position == 'bottom_right') {

    } else if (data.bubble_position == 'bottom_left') {

    } else if (data.bubble_position == 'top_right') {

    } else if (data.bubble_position == 'top_left') {

    }

    if (data.shape == 'square') {
      data.shape = 'btnRight'
    } else {
      data.shape = 'btnLeft'
    }

    bubbleSetting1.position = data.bubble_position
    bubbleSetting1.shape = data.shape

    setBubbleSetting(bubbleSetting1)
  }
  const Options = ['Classic', 'Smart', 'Modern', 'Callback', 'Ultimate']
  const termOption = ['Text', 'URL', 'HTML']
  const popup_font = [
    'Roboto Slab',
    'Karma',
    'acumin-pro',
    'objektiv',
    'museo-sans',
    'Roboto',
    'Source Sans',
    'poppins',
    'new-hero',
    'proxima-nova',
    'expressway'
  ]
  const onCancel = () => {
    setWidgetStyleData(widgetApiResData)
    setIsInputChange(false)
  }

  const createCustomLink = async () => {

    const a = social?.forEach((obj) => {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/custom-button`
      return axios.post(url, { type: obj?.type || 'other', ...obj }).then((res) => res)
    })
    getCustomLink()
    setIsInputChange(false)

  }

  const updateCustomLink = async () => {

    const updated = existingLinks?.filter((obj, index) => JSON.stringify(obj) !== JSON.stringify(customLinks?.[index]))

    const a = await updated?.forEach((obj) => {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/update-custom-button/${obj?.id}`
      axios.post(url, { type: obj?.type || 'other', ...obj }).then((res) => res)
    })
    getCustomLink()
    setIsInputChange(false)

  }


  const getCustomLink = () => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/custom-buttons`
    return axios.get(url,)
      .then(res => {
        setCustomLinks(res.data.data)
        setSocial([])
        loading(false)

      })
      .catch(error => {
        loading(false)

      })

  }

  const changeCustomLink = (index, key, value) => {
    setSocial((prev) => [...prev?.map((obj, ind) => index === ind ? { ...obj, [key]: value } : { ...obj })])
    setIsInputChange(true)
  }

  useEffect(() => {
    getCustomLink()
  }, [])

  const deleteSelected = () => {
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/custom-button/${deleteModal}`
    return axios.delete(url)
      .then(res => {
        setDeleteModal(null)
        loading(false)
        getCustomLink()

      })
      .catch(error => {
        loading(false)

      })
  }
  useEffect(() => {
    setExistingCustomLinks(customLinks)
  }, [customLinks])


  const updateExistingLinks = (index, key, value) => {
    setExistingCustomLinks((prev) => [...prev?.map((obj, ind) => index === ind ? { ...obj, [key]: value } : { ...obj })])
    setIsInputChange(true)
  }

  return (
    <div className="style-widget-wrapper accordion-widget social-widget">


      <section className="card-manage-number">
       
        <div style={{ maxWidth: '74%', width: '100%' }} className="customize-grp-btn-wrap">
          <CustomizeGroupButton
            title="Position On Page"
            identity="custom_button_position"
            active={widgetStyleData.custom_button_position === 'right' ? 'top_right' :'top_left'   }
            top_left="Left"
            top_right="Right"
            handleGroupBtnData={(val,name)=>changeSocial('custom_button_position', val?.toLowerCase())}
          />
        </div>
        <div className="popup-font" >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px'
          }}>

            <img width={35} className="mr-2" src={whatsappIcon} alt="whatsapp" />
            <h3 className="call-title widget-sub-heading" style={{ fontSize: '18px', flex: 1 }}>Whatsapp</h3>

          </div>
          <div className="header-swich">


            <NodeToggle
              handleDataRef={e => changeSocial('social_home_visible_whatsapp', e)}
              dataToggle={{
                callId: 'social_home_visible_whatsapp',
                callRef: 'social_home_visible_whatsapp'
              }}
              activeDefault={widgetStyleData?.social_home_visible_whatsapp}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}

            />
          </div>

        </div>
        {/* <div className="button-shape">
          <CommonGroupButton
            title=" "
            identity="social_home_visible_whatsapp"
            active={widgetStyleData?.social_home_visible_whatsapp ? "btnLeft" : "btnRight"}
            leftBtn="Visible"
            rightBtn="Hidden"
            handleGroupBtnData={() => changeSocial('social_home_visible_whatsapp', widgetStyleData?.social_home_visible_whatsapp ? 0 : 1)}
          />
        </div> */}
        {/* <div className="popup-font ">
        <h3 className="call-title widget-sub-heading">Instagram</h3>
        <div className="header-swich">
        <CommonInput
               className="popup-font-select"
                        placeholder="Type URL here.."
                        name="call-me-now"
                        type="text"
                        value={
                          widgetStyleData.instagram_social_url

                            ? widgetStyleData.instagram_social_url

                            : null
                        }
                        onChange={event =>
                          changeSocial(
                            'instagram_social_url',
                            event.target.value
                          )
                        }
                      />
                       <NodeToggle
            handleDataRef={e => changeSocial('instagram_social_status', e)}
            dataToggle={{
              callId: 'instagram_social_status',
            callRef: 'instagram_social_status'}}
            activeDefault={widgetStyleData.instagram_social_status}
          />
            </div>        
                    
      </div> */}
        <div className="popup-font"  >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px'
          }}>
            <img width={35} className="mr-2" src={lineIcon} alt="line" />

            <h3 className="call-title widget-sub-heading" style={{ fontSize: '18px', flex: 1 }}>Line</h3>
          </div>
          <div className="header-swich">



            <NodeToggle
              handleDataRef={e => changeSocial('social_home_visible_line', e)}
              dataToggle={{
                callId: 'social_home_visible_line',
                callRef: 'social_home_visible_line'
              }}
              activeDefault={widgetStyleData?.social_home_visible_line}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}

            />


          </div>

        </div>

        {/* <div className="button-shape">
          <CommonGroupButton
            title=" "
            identity="social_home_visible_line"
            active={widgetStyleData?.social_home_visible_line ? "btnLeft" : "btnRight"}
            leftBtn="Visible"
            rightBtn="Hidden"
            handleGroupBtnData={() => changeSocial('social_home_visible_line', widgetStyleData?.social_home_visible_line ? 0 : 1)}
          />
        </div> */}
        <div className="popup-font"  >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px'
          }}>
            <img width={35} className="mr-2" src={telegramIcon} alt="telegram" />

            <h3 className="call-title widget-sub-heading" style={{ fontSize: '18px', flex: 1 }}>Telegram ID</h3>
          </div>
          <div className="header-swich">



            <NodeToggle
              handleDataRef={e => changeSocial('social_home_visible_telegram', e)}
              dataToggle={{
                callId: 'social_home_visible_telegram',
                callRef: 'social_home_visible_telegram'
              }}
              activeDefault={widgetStyleData?.social_home_visible_telegram}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}


            />
          </div>

        </div>
        {/* <div className="button-shape">
          <CommonGroupButton
            title=" "
            identity="social_home_visible_telegram"
            active={widgetStyleData?.social_home_visible_telegram ? "btnLeft" : "btnRight"}
            leftBtn="Visible"
            rightBtn="Hidden"
            handleGroupBtnData={() => changeSocial('social_home_visible_telegram', widgetStyleData?.social_home_visible_telegram ? 0 : 1)}
          />
        </div> */}
        <div className="popup-font"  >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px'
          }}>
            <img width={35} className="mr-2" src={messangerIcon} alt="messanger" />

            <h3 className="call-title widget-sub-heading" style={{ fontSize: '18px', flex: 1 }}>Messenger ID</h3>
          </div>
          <div className="header-swich">

            <NodeToggle
              handleDataRef={e => changeSocial('social_home_visible_messanger', e)}
              dataToggle={{
                callId: 'social_home_visible_messanger',
                callRef: 'social_home_visible_messanger'
              }}
              activeDefault={widgetStyleData?.social_home_visible_messanger}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}


            />

          </div>

        </div>
        {/* <div className="button-shape">
          <CommonGroupButton
            title=" "
            identity="social_home_visible_messanger"
            active={widgetStyleData?.social_home_visible_messanger ? "btnLeft" : "btnRight"}
            leftBtn="Visible"
            rightBtn="Hidden"
            handleGroupBtnData={() => changeSocial('social_home_visible_messanger', widgetStyleData?.social_home_visible_messanger ? 0 : 1)}
          />
        </div> */}
        <div className="popup-font"  >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px'
          }}>
            <img width={35} className="mr-2" src={email} alt="messanger" />

            <h3 className="call-title widget-sub-heading" style={{ fontSize: '18px', flex: 1 }}>Email ID</h3>
          </div>
          <div className="header-swich">
            <NodeToggle
              handleDataRef={e => changeSocial('social_home_visible_email', e)}
              dataToggle={{
                callId: 'social_home_visible_email',
                callRef: 'social_home_visible_email'
              }}
              activeDefault={widgetStyleData?.social_home_visible_email}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}

            />

          </div>

        </div>
        {/* <div className="button-shape">
          <CommonGroupButton
            title=" "
            identity="social_home_visible_email"
            active={widgetStyleData?.social_home_visible_email ? "btnLeft" : "btnRight"}
            leftBtn="Visible"
            rightBtn="Hidden"
            handleGroupBtnData={() => changeSocial('social_home_visible_email', widgetStyleData?.social_home_visible_email ? 0 : 1)}
          />
        </div> */}
        <div className="popup-font"  >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px'
          }}>
            <img width={35} className="mr-2" src={sms} alt="messanger" />

            <h3 className="call-title widget-sub-heading" style={{ fontSize: '18px', flex: 1 }}>SMS</h3>
          </div>
          <div className="header-swich">
            <NodeToggle
              handleDataRef={e => changeSocial('social_home_visible_sms', e)}
              dataToggle={{
                callId: 'social_home_visible_sms',
                callRef: 'social_home_visible_sms'
              }}
              activeDefault={widgetStyleData?.social_home_visible_sms}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}

            />

          </div>

        </div>
        {/* <div className="button-shape">
          <CommonGroupButton
            title=" "
            identity="social_home_visible_sms"
            active={widgetStyleData?.social_home_visible_sms ? "btnLeft" : "btnRight"}
            leftBtn="Visible"
            rightBtn="Hidden"
            handleGroupBtnData={() => changeSocial('social_home_visible_sms', widgetStyleData?.social_home_visible_sms ? 0 : 1)}
          />
        </div> */}
     
        {/* <div className="popup-font">
          <div>
            <h3 className="call-title widget-sub-heading">Ultimate Custom Link 1</h3>

          </div>
          <div className="header-swich" >

            <NodeToggle
              style={{
              }}
              handleDataRef={e => changeSocial(`social_home_visible_customlink`, e)}

              dataToggle={{
                callId: `link1`,
                callRef: `link1`,
              }}
              activeDefault={widgetStyleData?.[`social_home_visible_customlink`]}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}


            />

          </div>

        </div>
        <div className="popup-font">
          <div>
            <h3 className="call-title widget-sub-heading">Ultimate Custom Link 2</h3>

       
     
          </div>
          <div className="header-swich" >

            <NodeToggle
              style={{
              }}
              handleDataRef={e => changeSocial(`social_home_visible_customlink`, e)}

              dataToggle={{
                callId: `link2`,
                callRef: `link2`,
              }}
              activeDefault={widgetStyleData?.[`social_home_visible_customlink`]}
              setText={{ 'off': 'Hidden', 'on': 'Visible' }}


            />

          </div>

        </div> */}
        {[1,2]?.map((link, index) =>
          <div className="popup-font">
            <div>
              <h3 className="call-title widget-sub-heading">Custom Link {index + 1}</h3>

              {/* <div className="popup-font-select" style={{ width: '35%' }}>
              <CommonSelect
                name="popupFont"
                className="widget-button-icon"
                placeholder="Select Icon"
                options={[...icons?.map((obj) => <img style={{ maxHeight: '4rem', width: '50%' }} src={`${process.env.REACT_APP_BASE_APP_URL}/${obj}`} alt={obj} />)]}
               value={<img src={`${process.env.REACT_APP_BASE_APP_URL}/${existingLinks?.[index]?.icon}`}/>}
                onChange={(event, data) => {
                  updateExistingLinks(
                    index,
                    'icon',
                    data?.value?.props || data?.value
                  )
                }
                }
              />
          
             </div> */}
            </div>
            <div className="header-swich" >

              <NodeToggle
                style={{
                }}
                handleDataRef={e => changeSocial(`social_home_visible_customlink${index + 1}`, e)}

                dataToggle={{
                  callId: `${index} link`,
                  callRef: `${index} link`,
                }}
                activeDefault={widgetStyleData?.[`social_home_visible_customlink${index + 1}`]}
                setText={{ 'off': 'Hidden', 'on': 'Visible' }}


              />

            </div>

          </div>
        )}
        {/* <div className="button-shape">
          <CommonGroupButton
            title=" "
            identity="social_home_visible_customlink1"
            active={widgetStyleData?.social_home_visible_customlink1 ? "btnLeft" : "btnRight"}
            leftBtn="Visible"
            rightBtn="Hidden"
            handleGroupBtnData={() => changeSocial('social_home_visible_customlink1', widgetStyleData?.social_home_visible_customlink1 ? 0 : 1)}
          />
        </div> */}



      </section>

      {isInputChange ? (
        <div className="isInputChange">
          <CommonButton
            onClick={submitWidget}
            type="button"
            content="Save"
            background="blue"
          />
          <CommonButtons
            onClick={onCancel}
            type="reset"
            content="Cancel"
            background="grey"
          />
        </div>
      ) : null}
      <Modal
        className="local-number-modal"
        open={!!deleteModal}
        onClose={e => setDeleteModal(null)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this link ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={e => setDeleteModal(null)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={deleteSelected}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}
