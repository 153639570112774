import React, { useEffect, useState } from 'react'

import { Dimmer, Dropdown, Loader, Modal, Tab, Table } from 'semantic-ui-react'
import icon from '../assets/images/settingIcon.png'
import CommonButtons from '../common/CommonButtons'
import CommonCheckbox from '../common/CommonCheckbox'
import CommonInput from '../common/CommonInput'
import CommonSelect from '../common/CommonSelectCountry'

import CommonSelect2 from '../common/CommonSelectRegion'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CountryList from '../helpers/CountryList'
import { CommonNotify } from '../common/CommonNotify'
import Axios from 'axios'
import PhoneInput from 'react-phone-input-2'
import FormTracking from './FormTracking'
import TrackingCode from './TrackingCode'

const CallTracking = () => {
    const [openModel, setOpenModel] = useState(false)
    const [type, setType] = useState('static')
    const [screen, setScreen] = useState(1)
    const [state, setState] = useState({})
    const [countryListt, setCountryListt] = useState([])
    const [tableData, setTableData] = useState({})
    const [numberLoader, setNumberLoader] = useState(false)
    const [sideTab, setSideTab] = useState(1)
    useEffect(() => {
        fetchCountries()
    }, [])

    const fetchCountries = async () => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/numbers/countries?number_type=local`
        Axios.get(url)
            .then(async res => {
                var cArray = []

                for (let value of Object.values(res.data.data)) {
                    var temp = {
                        name: value,
                        regions: []
                    }
                    cArray.push(temp)
                }
                if (
                    cArray?.some(({ name }) => name?.toLowerCase() === 'united states')
                ) {
                    var t = {
                        name: 'United States',
                        regions: []
                    }

                    let reg = getRegion('United States')
                    setState(prev => ({ ...prev, selectedCountry: 'United States' }))

                    if (reg?.length) {
                        setState(prev => ({ ...prev, haveRegion: true, regionList: reg }))
                    } else {
                        setState(prev => ({
                            ...prev,
                            haveRegion: false,
                            regionList: [],
                            selectedRegion: ''
                        }))
                        cArray.push(t)
                    }
                } else {
                    setState(prev => ({
                        ...prev,
                        haveRegion: false,
                        regionList: [],
                        selectedRegion: '',
                        selectedCountry: cArray?.[0]?.name
                    }))
                }
                setCountryListt(cArray)
            })
            .catch(error => {
                CommonNotify('Not able to get Countries.')
            })
    }
    const panes = [
        {
            menuItem: 'Tracking numbers',
            render: ({ }) => (
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Number</Table.HeaderCell>
                            <Table.HeaderCell>Source</Table.HeaderCell>
                            <Table.HeaderCell>Friendly Name</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Destination</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {[1, 2]?.map(obj => (
                            <Table.Row>
                                <Table.Cell>123</Table.Cell>
                                <Table.Cell style={{ color: 'blue' }}>+424324324523</Table.Cell>
                                <Table.Cell>
                                    {' '}
                                    <Dropdown
                                        className="w-50"
                                        placeholder="Select Source"
                                        selection
                                    />
                                </Table.Cell>
                                <Table.Cell>emi jonson</Table.Cell>
                                <Table.Cell>Hellooo</Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        className="w-50"
                                        placeholder="Select Numbers"
                                        selection
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )
        },
        {
            menuItem: 'Destination Numbers',
            render: ({ }) => (
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Number</Table.HeaderCell>
                            <Table.HeaderCell>Source</Table.HeaderCell>
                            <Table.HeaderCell>Friendly Name</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Destination</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body></Table.Body>
                </Table>
            )
        }
    ]
    const getRegion = country => {
        return CountryList.filter((row, index) => row.name === country)?.[0]
            ?.regions
    }

    const changeCountry = (p1, p2) => {
        const val = p2.value
        if (val == '') {
            return
        }
        let reg = getRegion(p2.value)
        setState(prev => ({ ...prev, selectedCountry: val }))
        if (reg?.length) {
            setState(prev => ({ ...prev, haveRegion: true, regionList: reg }))
        } else {
            setState(prev => ({
                ...prev,
                haveRegion: false,
                regionList: [],
                selectedRegion: ''
            }))
        }
    }
    const changeRegion = (p1, p2) => {
        const val = p2.value
        if (val == '') {
            return
        }
        setState(prev => ({ ...prev, selectedRegion: val }))
    }

    const getLocalNo = async () => {
        const plan = await localStorage.getItem('plan_name')
        setNumberLoader(true)
        const params = `key=null&type=local&country=${state?.selectedCountry}&region=${state?.selectedRegion}&fax=true&mms=true&voice=true&sms=true`

        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/get-available-phone-numbers?${params}`

        Axios.get(url)
            .then(res => {
                if (res.data.data) {
                    setNumberLoader(false)
                    tableData.tableContentData = []
                    let rows = []
                    const amount = res.data.data.price
                    const kyc = res.data.data.required_regulatory_bundle
                        ? res.data.data.required_regulatory_bundle
                        : false

                    if (!res.data.data.phoneNumbers?.length) {
                        // CommonNotify('Number Not Available', 'warning')
                        tableData.tableContentData = rows
                        setTableData({ ...tableData, noRecord: true })
                        return
                    }

                    res.data.data.phoneNumbers.map(row => {
                        let obj = {}
                        obj.phone = row
                        obj.price = amount
                        obj.kyc = kyc
                        rows.push(obj)
                    })
                    tableData.tableContentData = rows
                    setTableData({ ...tableData, noRecord: true })
                }
            })
            .catch(error => {
                setNumberLoader(false)
                const errors = { ...error }
                if (errors.response.data.errors) {
                    CommonNotify(errors.response.data.errors[0])
                } else {
                    CommonNotify('Some thing went wrong')
                }
            })
    }
    return (
        <>
              <div className="sidebarWrap">
          <div className="meetingSidebar">
            <div className="sideHeader">
              {/* <h1>Campaign</h1> */}
            </div>
          
            <label
              className={sideTab == 1 ? 'sideTab' : ''}
              onClick={() => {

               setSideTab(1)

              }}
            >
              Numbers
            </label>
            <label
              className={sideTab == 2 ? 'sideTab' : ''}
              onClick={() => {
                // setMessageConfirmation(false)
               setSideTab(2)


              }}
            >
              Forms
            </label>
            <label
              className={sideTab == 3 ? 'sideTab' : ''}
              onClick={() => {
                // setMessageConfirmation(false)
                  setSideTab(3)


              }}
            >
              Tracking Code
            </label>
           

          </div>

         




        </div>
     
        <div
            className="fullwidth-container number-container"
        >
             {sideTab===1 ?  
            <div className="numbers-banner-container manage_number_main">
                <div className="my-number-tab manage_table">
                    <div className="d-flex justify-content-end">

                        <CommonButtons
                            content="Add Campaign"
                            btnClass="btn-blue"
                            onClick={() => {
                                setScreen(1)
                                setOpenModel(!openModel)
                            }}
                        />
                    </div>
                    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                </div>
            </div>:''}
             {sideTab===2 ?  
<FormTracking />
            :''}
             {sideTab===3 ?  
                    <TrackingCode />
            :''}
        </div>
                <Modal
                    style={{ width: '660px' }}
                    open={openModel}
                    onClose={() => setOpenModel(!openModel)}
                >
                    <Modal.Header>New Tracking Number Pool</Modal.Header>
                    <Modal.Content>
                        <div className="p-3">
                            {screen === 1 ? (
                                <>
                                    {' '}
                                    <div className="new-campaign">
                                        <label>Type of call tracking</label>
                                        <CommonButtons
                                            onClick={() => setType('static')}
                                            background={type === 'static' ? 'blue' : 'lightblue'}
                                        >
                                            Static
                                        </CommonButtons>
                                        <CommonButtons
                                            onClick={() => setType('dynamic')}
                                            background={type === 'dynamic' ? 'blue' : 'lightblue'}
                                        >
                                            Dynamic
                                        </CommonButtons>
                                    </div>
                                    <div className="new-campaign">
                                        <label>Friendly Name</label>
                                        <CommonInput placeholder="Name your campaign" />
                                    </div>
                                    <div className="new-campaign">
                                        <label>Description</label>
                                        <textarea placeholder="Add description" />
                                    </div>
                                    <div className="new-campaign">
                                        <label>Campaign Source</label>
                                        <Dropdown
                                            className="w-50"
                                            placeholder="Select Source"
                                            selection
                                        />
                                    </div>{' '}
                                </>
                            ) : (
                                ''
                            )}
                            <div className="p-4">
                                {screen > 1 && screen < 5 ? <p style={{ color: 'blue' }} onClick={() => setScreen(screen - 1)}> <ArrowBackIosIcon /> Back</p> : ''}
                                {screen === 2 ? (
                                    <>
                                        <div className="new-campaign text-center">
                                            <h3 style={{ margin: '0 auto' }}>
                                                How many tracking numbers do you need?
                                            </h3>
                                        </div>
                                        <div className="text-center">
                                            <CommonInput
                                                type="number"
                                                id="quantity"
                                                name="quantity"
                                                min="1"
                                                max="5"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}

                                {screen === 3 ? (
                                    <>
                                        <div className="new-campaign text-center">
                                            <h3 style={{ margin: '0 auto' }}>
                                                Select your tracking number prefix:
                                            </h3>
                                        </div>
                                        <div className="d-flex">
                                            <CommonSelect
                                                onChange={changeCountry}
                                                name="config"
                                                defaultValue={state?.selectedCountry}
                                                className="popup-font-select"
                                                placeholder="Select Country"
                                                style={{ width: '50%' }}
                                                options={countryListt || []}
                                            />

                                            <CommonSelect2
                                                onChange={changeRegion}
                                                name="config"
                                                defaultValue={state?.selectedRegion}
                                                className="popup-font-select"
                                                style={{ width: '50%' }}
                                                placeholder="Select Region"
                                                options={state?.regionList || []}
                                                disabled={!state?.haveRegion}
                                            />
                                            <CommonButtons
                                                type="button"
                                                content="Search"
                                                background="blue"
                                                onClick={getLocalNo}
                                                btnClass="ml-4"
                                            />
                                        </div>
                                        <div className='mt-4'>
                                            <Table>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Phone Number</Table.HeaderCell>
                                                        <Table.HeaderCell>Price</Table.HeaderCell>
                                                        <Table.HeaderCell>KYC</Table.HeaderCell>
                                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {numberLoader ?

                                                        <Dimmer active={numberLoader}>
                                                            <Loader />
                                                        </Dimmer>

                                                        : tableData?.tableContentData?.map((obj) =>
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    {obj?.phone}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {obj?.price}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {obj?.kyc ? 'Required' : 'Not Required'}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <CommonButtons
                                                                        type="button"
                                                                        content="Select"
                                                                        background="blue"
                                                                    //   onClick={e => cancelModalOpen(data.phone, data.price)}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )}

                                                </Table.Body>
                                            </Table>
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                                {screen === 4 ? (
                                    <>
                                        <div className="new-campaign text-center">
                                            <h3 style={{ margin: '0 auto' }}>
                                                Where should the calls be forwarded to?
                                            </h3>
                                        </div>
                                        <div className="text-center " style={{display:'flex',justifyContent:'center',margin:'0 auto'}} >
                                            <PhoneInput
                                            style={{width:'50%',}}
                                                international
                                                className="text-color"
                                                // country={'us'}
                                                // onlyCountries={['us']}
                                                // value={sendMsgData?.to}
                                                // onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>

                            {type === 'dynamic' && (
                                <>
                                    <div className="new-campaign">
                                        <label>Order Time</label>
                                        <div
                                            class="input-group input-group-sm mb-3"
                                            style={{ width: '30%' }}
                                        >
                                            <input
                                                type="number"
                                                class="form-control"
                                                style={{ background: 'white' }}
                                                readOnly
                                                aria-label="Recipient's username"
                                                aria-describedby="button-addon2"
                                            />
                                            <div
                                                class="input-group-prepend"
                                                style={{ maxHeight: '28px', height: '100%' }}
                                            >
                                                <button
                                                    class="btn btn-outline-secondary"
                                                    style={{ background: 'lightGray' }}
                                                    type="button"
                                                    id="button-addon2"
                                                >
                                                    S
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="font-bold">Parametry UTM</p>
                                    <div className="new-campaign">
                                        <label>UTM Source</label>
                                        <CommonInput />
                                    </div>
                                    <div className="new-campaign">
                                        <label>UTM Medium</label>
                                        <CommonInput />
                                    </div>
                                    <div className="new-campaign">
                                        <label>UTM Campaign</label>
                                        <CommonInput />
                                    </div>
                                    <div className="new-campaign">
                                        <label>UTM Term</label>
                                        <CommonInput />
                                    </div>
                                    <div className="new-campaign">
                                        <label>UTM Content</label>
                                        <CommonInput />
                                    </div>
                                    <p className="font-bold">Landing URLs</p>
                                    <div className="new-campaign">
                                        <label>Trusted URLs</label>
                                        <div
                                            class="input-group input-group-sm mb-3"
                                            style={{ width: '30%' }}
                                        >
                                            <div
                                                class="input-group-prepend"
                                                style={{ maxHeight: '28px', height: '100%' }}
                                            >
                                                <button
                                                    class="btn btn-outline-secondary"
                                                    style={{ background: 'lightGray' }}
                                                    type="button"
                                                    id="button-addon2"
                                                >
                                                    https://
                                                </button>
                                            </div>
                                            <input
                                                type="number"
                                                class="form-control"
                                                style={{ background: 'white' }}
                                                readOnly
                                                aria-label="Recipient's username"
                                                aria-describedby="button-addon2"
                                                placeholder="Enter trusted URL"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        {type === 'dynamic' ? (
                            <>
                                <CommonButtons background="blue">Save</CommonButtons>

                                <CommonButtons onClick={() => setOpenModel(!openModel)}>
                                    Cancel
                                </CommonButtons>
                            </>
                        ) : type === 'static' && screen < 4 ? (
                            <CommonButtons
                                background="blue"
                                onClick={() => setScreen(screen + 1)}
                            >
                                Next
                            </CommonButtons>
                        ) : (
                            <CommonButtons background="blue">Submit</CommonButtons>
                        )}
                    </Modal.Actions>
                </Modal>
        </>
    )
}

export default CallTracking
