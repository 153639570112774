import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'

export const analytics = Analytics({
    app: 'Limecall',
    plugins: [
        segmentPlugin({
            writeKey: '7pjC9B9S2tnME3wVf8HXFWkDeH89dFY1'
        })
    ]
})