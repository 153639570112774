import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { connect } from 'react-redux'
import { Dimmer, Dropdown, Loader, Modal } from 'semantic-ui-react'
import { getWidgetCountries } from '../actions/settings'
import close from '../assets/images/close.png'
import CountryData from '../helpers/data/countryCode.json'
import CommonButtons from './CommonButtons'
import { CommonNotify } from './CommonNotify'
import CommonInput from './CommonInput'
import Datetime from 'react-datetime'

const ScheduleModal = ({
  open,
  isModalClose,
  leadData,
  getWidgetCountries,
  widgetCountries,
}) => {
  const [allowedCountries, setAllowedCountries] = useState([])
  const [agentList, setAgentList] = useState([])
  const [loader, setLoader] = useState(false)
  const [sendMsgData, setSendMsgData] = useState({
    lead_id: leadData?.id,
    from: null,
    to: leadData?.phone_number
  })
  const apiKey=localStorage?.getItem('data-api-key')
const [dateTime, setDateTime] = useState({})
  useEffect(() => {
    getAgentList()
  }, [])

  const getAgentList = async () => {
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`
    await Axios.get(url)
      .then(res => {
        if (res?.data?.data) {
          setLoader(false)
          setAgentList([res?.data?.data?.admin,...res?.data?.data?.members] || [])
        }
      })
      .catch(error => {
        setLoader(false)
      })
  }
  const getDateTime = async (id) => {
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/widget/modify-slots/${apiKey}/GMT+05:30?member_id=${id}`
    await Axios.get(url)
      .then(res => {
        if (res?.data?.data) {
          setLoader(false)
setDateTime(res?.data?.data)
        }
      })
      .catch(error => {
        setLoader(false)
      })
  }
  const getAvailable = async (id) => {
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/request-call?team=${id}&source=http:%2F%2Flocalhost:3000%2F&type=SCHEDULE_CALL&scheduled_at=2023-07-05+18:01:00&time_zone=Asia%2FKolkata`
    await Axios.get(url)
      .then(res => {
        if (res?.data?.data) {
          setLoader(false)
setDateTime(res?.data?.data)
        }
      })
      .catch(error => {
        setLoader(false)
      })
  }

  useEffect(() => {
    if (sendMsgData?.close === true) {
      isModalClose()
      setSendMsgData({
        ...sendMsgData,
        close: false
      })
    }
  }, [sendMsgData])

  useEffect(() => {
    resetWidgetCountries(widgetCountries?.data?.allowed_countries)
  }, [widgetCountries])

  useEffect(() => {
    if (!open) return
    if (allowedCountries?.length) return

    getWidgetCountries()
  }, [open])
  const resetWidgetCountries = countries => {
    const allCountry = CountryData
    const allowed = allCountry
      ?.filter(item => {
        if (countries?.includes(item.name)) {
          return item
        }
      })
      .map(item => item.code.toLowerCase())

    setAllowedCountries(allowed)
  }

  const handleMakeCallback = async () => {
    if (agentList && !agentList?.length) {
      CommonNotify(
        'First you need to add agent from setting then after try again.'
      )
      return
    }
    if (!sendMsgData?.from) {
      CommonNotify('Please select Agent')
      return
    }
    if (!sendMsgData?.to) {
      CommonNotify('To number is required')
      return
    }

    const payload = {
      phone_number: sendMsgData?.to,
      country_code: contryCode(sendMsgData?.to),
      to_agent: sendMsgData?.from
    }
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/customer-direct-call`
    await Axios.post(url, payload)
      .then(res => {
        CommonNotify('Callback request is successfully')
        setLoader(false)
        isModalClose()
      })
      .catch(error => {
        setLoader(false)
        CommonNotify(error?.response?.data?.errors?.[0])
      })
    return
  }

  const contryCode = value => {
    let values = ''
    const count = value?.length - 10
    values = value?.substring(0, count)
    if (value?.substring(0, 1) !== '+') {
      values = '+' + values
    }
    return values
  }

  return (
    <Modal
      className="send-message-modal"
      onClose={isModalClose}
      closeIcon
      open={open}
      size="tiny"
    >
      <Dimmer active={loader}>
        <Loader />
      </Dimmer>
      <div className="modal-header align-items-center">
        <h2 className="mx-auto mb-0">Schedule Meeting</h2>
        <div className="close-icon" onClick={() => isModalClose(false)}>
          <img src={close} alt="close" />
        </div>
      </div>
      <Modal.Content style={{ padding: '30px' }}>
        <p>
          First, we'll call your phone. Once you answer, we'll call the
          customer's phone. From their perspective, it's like you called
          directly from your tracking number.
        </p>
        <div className="send-message__dropdown">
          <label>Call From *</label>
          <Dropdown
            name="from"
            placeholder="Select Agent"
            selection
            options={agentList.map(option => ({
              key: option?.id,
              text: `${option?.first_name} ${option?.last_name}`,
              value: option?.id,
              id: option?.id
            }))}
            onChange={(e, data) =>
            {  setSendMsgData({
                ...sendMsgData,
                from: data?.value,
                agentId: data?.value || data?.id
              })
              getDateTime(data?.value || data?.id)
              getAvailable(data?.value || data?.id)}
            }
          />
        </div>
        {/* <div className="send-message__input">
          <label>Call To *</label>
          <PhoneInput
            placeholder="To"
            className="text-color"
            onlyCountries={allowedCountries}
            value={leadData?.phone_number}
            onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
          />
        </div> */}
        <div className="send-message__input">
          <label>Date</label>
          <Dropdown
          // options=[]
            // value={formValue?.created_at}
            placeholder="Select Date and Time"
            // onChange={e => handleChange('created_at', e)}
          />
        </div>
        <div className="send-message__input">
          <label>Customer Email</label>
          <CommonInput
           style={{width:'100%'}}
            // placeholder="To"
            // className="text-color"
            // onlyCountries={allowedCountries}
            // value={leadData?.phone_number}
            // onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
          />
        </div>
           <div className="send-message__input">
          <label>Customer Phone *</label>
          <PhoneInput
            placeholder="To"
            className="text-color"
            onlyCountries={allowedCountries}
            value={leadData?.phone_number}
            onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
          />
        </div>
        <div className="send-message__input">
          <label>Name</label>
          <CommonInput
         style={{width:'100%'}}
            // placeholder="To"
            // className="text-color"
            // onlyCountries={allowedCountries}
            // value={leadData?.phone_number}
            // onChange={value => setSendMsgData({ ...sendMsgData, to: value })}
          />
        </div>
      </Modal.Content>
      <Modal.Actions className="send-message__btn">
        <CommonButtons
          content="Schedule Meeting"
          style={{ background: '#9da3b1', color: 'white' }}
          onClick={e => handleMakeCallback()}
        />
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = state => {
  return { widgetCountries: state.settings.widgetCountries }
}

export default connect(mapStateToProps, { getWidgetCountries })(ScheduleModal)
