import moment from 'moment'
import React from 'react'

import MissedCall from '../../assets/images/orangeCall.png'
import PickedCall from '../../assets/images/greenCall.png'
import cutCall from '../../assets/images/callRed.jpeg'

const successIcon = [
  'completed',
  'agent_call_completed',
  'customer_call_completed'
]
const warningIcon = [
  'in-progress',
  'waiting_for_agent',
  'busy',
  'no-answer',
  'customer_connected',
  'scheduled',
  'rescheduled',
  'ringing',
  'agent_connected',
  'connecting_agent',
  'connecting_customer'
]

const ContactLogData = ({ logData }) => {
  const callLogs = logData?.logData
  const checkIcon = status => {
    if (successIcon.includes(status)) {
      return PickedCall
    }
    if (warningIcon.includes(status)) {
      return MissedCall
    }
    return cutCall
  }

  return (
    <div className="contact-card logTab">
      {callLogs &&
        Object.keys(callLogs)?.reverse()?.map(index => {
          return (
            <>
              <div className="logHead">
                <p style={{ color: 'gray' }}>{index}</p>
              </div>
              {callLogs[index].map(log => (
                <div className="contact-card-boxes logDetail">
                  <div className="logData">
                    {log.final_status && (
                      <img src={checkIcon(log.final_status)} alt="" />
                    )}
                    <label>
                      {log.duration ? ` ${log.duration}s ` : null}{' '}
                      {log.cost ? ` ${log.cost}$ ` : null}{' '}
                      {log.type ? ` ${log.type}` : null}{' '}
                      {log.log.details ? ` ${log.log.details}` : null}{' '}
                    </label>
                    <p>{log?.agent_name || index?.agent_name}</p>
                    <p>
                      {moment
                        .utc(log?.created_at)
                        .local()
                        .startOf('seconds')
                        .fromNow()}
                    </p>
                  </div>
                </div>
              ))}
            </>
          )
        })}
    </div>
  )
}

export default ContactLogData
