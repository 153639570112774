import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import call from '../assets/images/call.png'
import web from '../assets/images/web.png'
// import chat from '../assets/images/chat.png'

import axios from 'axios'
import moment from 'moment'

export default function MarketingSource({ date }) {
    const [TypeLead, setTypeLead] = useState({
        series: [44, 55, 41, 17, 15, 16, 17],
        options: {
            chart: {
                width: 380,
                type: 'donut',
                dropShadow: {
                    enabled: true,
                    color: '#111',
                    top: -1,
                    left: 3,
                    blur: 3,
                    opacity: 0.2
                }
            },
            stroke: {
                width: 0
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                showAlways: true,
                                show: true
                            }
                        }
                    }
                }
            },
            labels: [
                'Phone Calls',
                'Form',
                'Chats',
                'Callback',
                'Webhook',
                'Messages',
                'Emails',
                'Digital Calls'],
            dataLabels: {
                dropShadow: {
                    blur: 3,
                    opacity: 0.8
                }
            },
            states: {
                hover: {
                    filter: 'none'
                }
            },
            theme: {
                palette: 'palette2'
            },
            legend: {
                position: 'bottom'
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ]
        }
    })
    const [counter, setCounter] = useState({})

   
    const [TypeStatus, setStatusLead] = useState({
        series: [44, 55, 41, 17],
        options: {
            chart: {
                width: 480,
                type: 'donut',
                dropShadow: {
                    enabled: true,
                    color: '#111',
                    top: -1,
                    left: 3,
                    blur: 3,
                    opacity: 0.2
                }
            },
            stroke: {
                width: 0
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                showAlways: true,
                                show: true
                            }
                        }
                    }
                }
            },
            labels: ['Qualified', 'Pending', 'Not Qualified', 'Spam',],
            dataLabels: {
                dropShadow: {
                    blur: 3,
                    opacity: 0.8
                }
            },
            states: {
                hover: {
                    filter: 'none'
                }
            },
            theme: {
                palette: 'palette2'
            },
            legend: {
                position: 'bottom'
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }
            ]
        }
    })
    const [values, setValues] = useState({})

    useEffect(() => {
        if (values && Object?.keys(values)?.length) {
            const type = [
                values?.phone_calls || 0,
                values?.web_forms || 0,
                values?.chats || 0,
                values?.callbacks || 0,
                values?.webhooks || 0,
                values?.messages || 0,
                values?.emails || 0,
                values?.calls || 0,
            ]
            const status = [
                values?.qualified_leads || 0,
                values?.pending_leads || 0,
                values?.not_qualified_leads || 0,
                values?.spam || 0
            ]
            setStatusLead({
                ...TypeStatus,
                series: status
            })
            setTypeLead({
                ...TypeLead,
                series: type
            })
        }
    }, [values])

    useEffect(() => {
        if (date) {
            fetchLeadCounter(date)

            handleGetLeadData()
        }
    }, [date])


    const handleGetLeadData = () => {
        var url = `${process.env.REACT_APP_BASE_APP_URL
            }/api/v1/leads/call-tracking-analytics?date_from=${moment(
                date?.startDateFilter
            ).format('YYYY/MM/DD')}&date_to=${moment(date?.endDateFilter).format(
                'YYYY/MM/DD'
            )}`
        axios
            .get(url)
            .then(res => {
                setValues(res?.data?.data)
            })
            .catch(error => { })
    }

    const fetchLeadCounter = async (date1) => {
        const today = moment().subtract(1, "d")
        const token = await localStorage.getItem('access_token')
        const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/leads-counters?date_from=${moment(date1?.startDateFilter)?.format('YYYY/MM/DD')}&date_to=${moment(date1?.endDateFilter)?.format(
            'YYYY/MM/DD'
        )}`
        const settings = {
            url: URL,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            }
        }
        axios(settings)
            .then(res => {
                setCounter(res?.data?.data || {})
            })

            .catch(err => {
                if (err?.response?.status === 401) {
                    return
                }
                if (!err?.response) {
                }
            })
    }
    return (
        <div className="cardbox-wrapper deatil_box">
            <div className="container-trial">
                
                <Row className="mb-5">
                  
               
                    <Col md={4}>
                        <div className="banner-description-box h-100">
                            <h5 className="mb-3" style={{ fontSize: '17.5px' }}>Tags</h5>
                            <ul className="lead-overview">



                                {values?.tags && Object?.entries(values?.tags)?.map(([key,value])=>
                                
                                
                                <li>
                                    <div>
                                        <span
                                            className="status"
                                            style={{ backgroundColor: '#b7c3c8' }}
                                        ></span>

                                            {key}
                                    </div>
                                    {/* <div className="leads-count">{values?.spam_rate || 0}% */}
                                    <div className="leads-count">{value} </div>


                                </li>
                                )}
                             
                            </ul>
                        </div>
                    </Col>
                    <Col md={4}>


                        <div className="banner-description-box h-100">
                            <h5 className="mb-3" style={{ fontSize: '17.5px' }}>
                                UTM Source
                            </h5>
                            <ul className="lead-overview">
                                <li>
                                    <div className="lead-overview-title">
                                        <img src={call} alt="" /> UTM Source Direct
                                    </div>
                                    <div className="leads-count">
                                        {values?.utm_source_direct || 0} 
                                    </div>
                                
                                </li>
                                <li>
                                    <div className="lead-overview-title">
                                        {' '}
                                        <img src={web} alt="" /> UTM Source Facebook
                                    </div>
                                    <div className="leads-count">
                                        {values?.utm_source_facebook || 0} 

                                    </div>

                             </li>
                                <li>
                                    <div className="lead-overview-title">
                                        {' '}
                                        <img src={web} alt="" /> UTM Source Google
                                    </div>
                                    <div className="leads-count">
                                        {values?.utm_source_google || 0} 

                                    </div>

                             </li>

                            </ul>
                        </div>
                    </Col>
                </Row>

       
            </div>
        </div>
    )
}
