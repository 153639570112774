import axios from 'axios'
import moment from 'moment'
import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import { Checkbox, Modal, Table } from 'semantic-ui-react'
import { getWebHook } from '../../actions/settings'
import CommonButtons from '../../common/CommonButtons'
import CommonInput from '../../common/CommonInput'
import { CommonNotify } from '../../common/CommonNotify'

const initialWebhook = {
  hookName: '',
  name_field: '',
  country_code_field: '',
  phone_field: '',
  email_field: '',
  is_auto: false
}
class WebHooksSettings extends Component {
  state = {
    isCancelModalOpen: false,
    webhook: {
      hookName: '',
      name_field: '',
      country_code_field: '',
      phone_field: '',
      email_field: ''
    },
    webhooks: [],
    onSaveLoader: false,
    isEdit: false,
    id: null,
    webHookError: {
      hookName: '',
      name_field: '',
      country_code_field: '',
      phone_field: '',
      email_field: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.webHook !== prevProps.webHook) {
      this.props.loading(false)
      this.setState({ webhooks: this.props.webHook.data })
    }
  }

  componentDidMount = () => {
    if (this.props.webHook) {
      this.props.loading(false)
      this.setState({ webhooks: this.props.webHook.data })
    } else {
      this.fetchWebHook()
    }
  }

  fetchWebHook = async () => {
    this.props.loading(true)
    this.props.getWebHook()
  }

  changeHookValue = e => {
    const value = e.target.value
    const index = e.target.name
    const errorMessageName = {
      hookName: 'Hook Name',
      name_field: 'Name',
      country_code_field: 'Country code',
      phone_field: 'Phone number',
      email_field: 'Email'
    }
    let webHookError = { ...this.state.webHookError }
    if (value === '') {
      webHookError[index] = `${errorMessageName[index]} is required`
      this.setState({ webHookError: webHookError })
    } else {
      webHookError[index] = ''
      this.setState({ webHookError: webHookError })
    }
    let webHook = { ...this.state.webhook }
    webHook[index] = value
    this.setState({ webhook: webHook })
  }

  createWebHook = async () => {
    this.setState({ onSaveLoader: true })
    if (
      this.state.webhook.hookName === '' ||
      this.state.webhook.name_field === '' ||
      this.state.webhook.country_code_field === '' ||
      this.state.webhook.phone_field === '' ||
      this.state.webhook.email_field === ''
    ) {
      CommonNotify('All field are required', 'warning')
      this.setState({ onSaveLoader: false })
      return
    }
    let url = ''
    this.props.loading(true)
    if (this.state.isEdit) {
      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/web-hook/${this.state?.id}`
      let payload = { ...this.state.webhook }
      if (payload?.is_auto) {
        payload.is_auto = 1
      } else {
        payload.is_auto = 0
      }
      axios
        .put(url, payload)
        .then(res => {
          this.props.loading(false)
          CommonNotify('WebHook Updated', 'success')
          this.setState({
            onSaveLoader: false,
            webhook: {
              ...this.state.webhook,
              is_auto: null
            }
          })
          this.handleModal()
          this.fetchWebHook()
        })
        .catch(err => {
          this.props.loading(false)
          const errors = { ...err }
          if (errors?.response?.data?.errors) {
            CommonNotify(errors?.response?.data?.errors?.[0])
            this.setState({ onSaveLoader: false })
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ onSaveLoader: false })
          }
        })
    } else {
      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/create-web-hook`
      let payload = { ...this.state.webhook }
      if (payload?.is_auto) {
        payload.is_auto = 1
      } else {
        payload.is_auto = 0
      }
      axios
        .post(url, payload)
        .then(res => {
          this.props.loading(false)
          CommonNotify('WebHook Created', 'success')
          this.handleModal()
          this.fetchWebHook()
          this.setState({
            onSaveLoader: false,
            webhook: {
              ...this.state.webhook,
              is_auto: null
            }
          })
        })
        .catch(err => {
          this.props.loading(false)
          const errors = { ...err }
          if (errors.response.data.errors) {
            CommonNotify(errors.response.data.errors[0])
            this.setState({ onSaveLoader: false })
          } else {
            CommonNotify('Some thing went wrong')
            this.setState({ onSaveLoader: false })
          }
        })
    }
  }

  removeHook = async id => {
    this.props.loading(true)
    const apiToken = await localStorage.getItem('access_token')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/delete-hook`

    axios
      .post(url, { hook_id: id })
      .then(res => {
        this.props.loading(false)
        CommonNotify('WebHook Deleted', 'success')
        this.fetchWebHook()
        this.setState({ onSaveLoader: false })
      })
      .catch(err => {
        this.props.loading(false)
        const errors = { ...err }
        if (errors.response.data.errors) {
          CommonNotify(errors.response.data.errors[0])
          this.setState({ onSaveLoader: false })
        } else {
          CommonNotify('Some thing went wrong')
          this.setState({ onSaveLoader: false })
        }
      })
  }

  edithook = value => {
    this.setState({
      isCancelModalOpen: true,
      isEdit: true,
      id: value?.id,
      webhook: {
        hookName: value?.name,
        name_field: value?.name_field,
        country_code_field: value?.country_code_field,
        phone_field: value?.phone_field,
        email_field: value?.email_field,
        is_auto: +value?.is_auto === 1 ? true : false
      }
    })
  }

  handleModal = () => {
    let { isCancelModalOpen } = this.state
    isCancelModalOpen = !isCancelModalOpen
    const emptyErrorMessage = {
      hookName: '',
      name_field: '',
      country_code_field: '',
      phone_field: '',
      email_field: ''
    }
    this.setState({
      isCancelModalOpen,
      webHookError: emptyErrorMessage,
      webhook: { ...initialWebhook },
      isEdit: false,
      id: null
    })
  }

  handleCloseCancelModal = () => this.setState({ isCancelModalOpen: false })

  handleUpdateCheckbox = e => {
    let webHook = { ...this.state.webhook }
    webHook.is_auto = e
    this.setState({ webhook: webHook })
  }

  updateStatus = (id, value) => {
    this.props.loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/web-hook/${id}`
    const payload = {
      status: value
    }
    axios
      .put(url, payload)
      .then(res => {
        this.props.loading(false)
        CommonNotify('WebHook Status Updated', 'success')
        this.fetchWebHook()
        this.setState({ onSaveLoader: false })
      })
      .catch(err => {
        this.props.loading(false)
        const errors = { ...err }
        if (errors.response.data.errors) {
          CommonNotify(errors?.response?.data?.errors?.[0])
          this.setState({ onSaveLoader: false })
        } else {
          CommonNotify('Some thing went wrong')
          this.setState({ onSaveLoader: false })
        }
      })
  }

  render() {
    return (
      <div className="web-hooks-wrapper">
        <h2 className="accordion-title">Webhook Settings</h2>
        {/* <div style={{fontSize: '16px',  marginBottom: '30px',  fontWeight: '600'}}>
          Create Webhooks to integrate with your own  extensions as well as other web applications
        </div> */}
        <div
          className="accordion-description"
          style={{
            fontSize: '16px',
            fontWeight: 300,
            color: '#c1c1c1',
            marginBottom: '30px',
            fontWeight: '600'
          }}
        >
          Webhooks notify your application about events that occur in LimeCall
          asynchronously through HTTP POST requests.Please read our
          documentation for more details.
        </div>
        <CommonButtons
          type="button"
          content="+ Add new webhook"
          background="blue"
          onClick={this.handleModal}
        />
        <Table>
          {this.state.webhooks?.length ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Hook Name</Table.HeaderCell>
                  <Table.HeaderCell>Hook URL</Table.HeaderCell>
                  <Table.HeaderCell>Last Triggered</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.webhooks.map((col, index, row) => (
                  <Table.Row>
                    <Table.Cell>{col.name}</Table.Cell>
                    <Table.Cell>{`${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/hook-call/${col.unique_key}`}</Table.Cell>
                    <Table.Cell>
                      {col?.last_triggered
                        ? moment(col?.last_triggered).format('DD-MM-YY,h:mm a')
                        : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        defaultChecked={+col?.status === 1 ? true : false}
                        toggle
                        onChange={() => {
                          this.updateStatus(col?.id, +col?.status === 1 ? 0 : 1)
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <CommonButtons
                        type="button"
                        content="Edit"
                        background="red"
                        onClick={() => this.edithook(col)}
                      />
                      <CommonButtons
                        type="button"
                        content="Delete"
                        background="red"
                        onClick={() => this.removeHook(col.id)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </>
          ) : (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    You Don't have any webhooks yet
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    To add a new webhook click the + Add new webhook button
                    above
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </>
          )}
        </Table>

        <Modal
          className="webhook-modal creat_web_hook"
          open={this.state.isCancelModalOpen}
          onClose={this.handleCloseCancelModal}
        >
          <Modal.Content style={{ padding: '25px 20px' }}>
            <div className="modal-content create__webhook">
              <h3>
                {this.state?.isEdit ? 'Update a webhook' : 'Create a webhook'}
              </h3>
              <CommonInput
                name="hookName"
                title="Hook Name"
                placeholder="Hook Name"
                value={this.state?.webhook?.hookName}
                onChange={this.changeHookValue}
              />
              {this.state.webHookError.hookName ? (
                <span className="error-message">
                  {this.state.webHookError.hookName}
                </span>
              ) : null}
              <h3>WebHook Fields</h3>
              <CommonInput
                name="country_code_field"
                title="Country Code"
                placeholder="Country Code"
                value={this.state?.webhook?.country_code_field}
                onChange={this.changeHookValue}
              />
              {this.state.webHookError.country_code_field ? (
                <span className="error-message">
                  {this.state.webHookError.country_code_field}
                </span>
              ) : null}
              <CommonInput
                name="email_field"
                title="E-Mail"
                placeholder="E-Mail"
                value={this.state?.webhook?.email_field}
                onChange={this.changeHookValue}
              />
              {this.state.webHookError.email_field ? (
                <span className="error-message">
                  {this.state.webHookError.email_field}
                </span>
              ) : null}
              <CommonInput
                name="phone_field"
                title="Phone"
                value={this.state?.webhook?.phone_field}
                placeholder="Phone"
                onChange={this.changeHookValue}
              />
              {this.state.webHookError.phone_field ? (
                <span className="error-message">
                  {this.state.webHookError.phone_field}
                </span>
              ) : null}
              <CommonInput
                name="name_field"
                title="Name"
                placeholder="Name"
                onChange={this.changeHookValue}
                value={this.state?.webhook?.name_field}
              />
              {this.state.webHookError.name_field ? (
                <span className="error-message">
                  {this.state.webHookError.name_field}
                </span>
              ) : null}
              <Checkbox
                label="Automated Callback"
                name="automatedCallback"
                defaultChecked={this.state?.webhook?.is_auto ? true : false}
                onChange={(e, data) => this.handleUpdateCheckbox(data?.checked)}
              />
              <div className="footer">
                <CommonButtons
                  type="button"
                  content="Close"
                  background="blue"
                  onClick={this.handleModal}
                />
                <CommonButtons
                  type="button"
                  content={this.state?.isEdit ? 'Update Hook' : 'Create Hook'}
                  background="blue"
                  onClick={this.createWebHook}
                  loading={this.state.onSaveLoader}
                />
              </div>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  webHook: state.settings.webHook
})

export default connect(mapStateToProps, { getWebHook })(WebHooksSettings)
