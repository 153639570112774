import React from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const AccountInside = () => {
    const location=useLocation()
  return (
    <div>
        
        dfhtrtjhtrhtr
    </div>
  )
}

export default AccountInside