import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import CloseIcon from '@mui/icons-material/Close'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'

const defaultStage = {
  invite_member: 0,
  notification_configuration: 0,
  widget_installed: 0,
  incoming_number: 0
}

const def_names = {
  notification_configuration: 'Configure Notifications',
  widget_installed: 'Install your widget',
  business_hour_check: 'Setup Business working hours',
  setup_call_forwarding: 'Setup Call Forwarding',
  personal_hour_check: 'Setup personal working hours',
  personalize_widget: 'Configure your widget',
  invite_member: 'Invite a team member',
  integrate_calendar: 'Integrate your calendar'
  // incoming_number: 'Add a number',
  // setup_integration: 'Setup a integration',
}

const HomeChecklist = () => {
  const [currentStage, setCurrentStage] = useState({})
  const [stageComplete, setStageComplete] = useState(false)
  const [currentStagePercantage, setCurrentStagePercantage] = useState(0)
  const [expand, setExpand] = useState(true)
  const [isActive, setActive] = useState(false)

  // useEffect(() => {
  //   if(!window.location.href?.includes('/analytics')){
  //     fetchChecklistStatus()
  //   }
  // }, [])
  const history = useHistory()

  const handleManageSequance = value => {
    if (value && value?.length) {
      let arrayList = value?.slice()
      if (value?.[7]) {
        arrayList[1] = value[7]
        arrayList[7] = value[1]
      }
      return arrayList
    } else {
      return value
    }
  }

  const fetchChecklistStatus = () => {
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/checklist`
    axios
      .get(url)
      .then(res => {
        let dt = res.data.data
        let flag = false
        let counter = 0

        let cStage = { ...currentStage }

        Object.entries(dt).forEach(item => {
          cStage[item[0]] = item[1]
          if (item[1] === 1 && Object.keys(def_names).includes(item[0])) {
            counter++
          }
        })

        if (counter >= 10) {
          setStageComplete(true)
        }

        setStageComplete(flag)
        const def_name_keys = Object.keys(def_names)
        const temp = Object.entries(cStage)?.filter(([k, v]) => {
          return def_name_keys.includes(k)
        })
        temp.push(temp.shift())
        setCurrentStage(temp)
        setCurrentStagePercantage(
          (parseInt(counter) * 100) / temp?.length || 0
        )
      })
      .catch(err => {})
  }

  const pages = {
    // business_hour_check: '/settings/company/business_hours',
    widget_installed: '/settings/installation',
    setup_call_forwarding: '/settings/personal/call_forwarding',
    incoming_number: '/addNumbers',
    invite_member: '/settings/users',
    notification_configuration: '/settings/company/notifications',
    personal_hour_check: '/settings/personal/working_hours',
    personalize_widget: '/widgets',
    setup_integration: '/settings/integration/integration',
    integrate_calendar: '/settings/personal/integrations'
  }

  const hoverEvent = () => {
    setActive(true)
  }

  const hoverOutEvent = () => {
    setActive(false)
  }

  return (
    <>
      {(!stageComplete && currentStage &&
              currentStage?.length )&& (
        <div
          className={`checklist-popup ${currentStagePercantage >= 100 ? 'disable':''} ${!expand ? 'hide-menu' : ''}`}
          style={{ zIndex: 4 }}
          onMouseEnter={hoverEvent}
          onMouseLeave={hoverOutEvent}
        >
          <div className="header row">
            <div
              className="col-item item-1 cursor-pointer"
              onClick={() => setExpand(!expand)}
            >
              {!expand ? <OpenInFullIcon /> : <CloseIcon />}
            </div>
            <div
              className={`col-item item-2 ${
                expand ? 'extentd-show' : 'extentd-hide'
              }`}
            >
              Get Started Checklist
            </div>
            {/* <div
              className={`col-item item-3 ${
                expand ? 'extentd-show' : 'extentd-hide'
              }`}
            >
              <p>{currentStagePercantage.toFixed(2)}% </p>
            </div> */}
          </div>
          <div className="content">
            {currentStage &&
              currentStage?.length &&
              handleManageSequance(currentStage).map((item, index) => {
                return def_names[item[0]] ? (
                  <Link to={pages[item[0]]}>
                    <div
                      className={
                        item[1]
                          ? 'checklist-item-active row'
                          : 'checklist-item row'
                      }
                    >
                      <div className="col-item item-1 ">
                        <div className="round">
                          <input
                            type="checkbox"
                            checked={item[1] === 1 ? true : false}
                            id={`checkbox-${index + 1}`}
                          />
                          <label for="checkbox"></label>
                        </div>
                      </div>
                      {expand && (
                        <>
                          <div className="col-item item-2 extentd-show">
                            <span className="text">{def_names[item[0]]}</span>
                          </div>
                          {/* <div className="col-item item-3 extentd-show">
                            <span className="badge">{index + 1}</span>
                          </div> */}
                        </>
                      )}
                    </div>
                  </Link>
                ) : null
              })}
          </div>
        </div>
      )}
    </>
  )
}

export default HomeChecklist
