import React, { useState, useEffect } from 'react'
import { Tab, Table, Dropdown, Icon, Popup, Image } from 'semantic-ui-react'

import {
  onCallRequest,
  onChangeInterseted3,
  onLeadStageHandler,
  onLeadScoreHandler,
  onStatusChangeHandler3,
  onChangeOwner,
  getLeadOwner
} from '../../config/leadAPI'

import TableContent from './MyLeadsTableContent'
import axios from 'axios'
import moment from 'moment'

const apiToken = localStorage.getItem('access_token')

const options = [
  { key: 1, text: 'Good Fit', value: 1 },
  { key: 2, text: 'Pending', value: 2 },
  { key: 3, text: 'Bad Fit', value: 3 }
]

const statusOptions = [
  { key: 1, text: 'Awaiting Review', value: 1 },
  { key: 2, text: 'In progress', value: 2 },
  { key: 3, text: 'Qualified', value: 3 },
  { key: 4, text: 'Closed', value: 4 }
]

const leadScoreOption = [
  { key: 1, text: 'Bad Fit', value: 'Bad fit' },
  { key: 2, text: 'Qualified', value: 'Qualified' }
]

const increment = 10

const MyleadsDetail = ({
  type,
  setRunQuery,
  runQuery,
  handleRightPaneOpen,
  queryList,
  setQueryList,
  resetQuery,
  activeCallType,
  activeSegmentsLead,
  activeTagsLead,
  activeSeg,
  setSegmentsLeads,
  reloadTotal,
  queryOption,
  dateFilter,
  sortOption,
  allTags,
  setAllTags,
  totalPaginated,
  setTotalPaginated,
  totalAssigned,
  totalPending,
  totalQualified,
  totalSubscribed,
  getMainTabQuery,
  mainTab,
  updateTagCount,
  tagCount
}) => {
  const [open, setOpen] = useState(false)
  const [leadsData, setLeadsData] = useState([])
  const [leadsTempData, setLeadsTempData] = useState([])
  const [cancelModal, setCancelModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [owner, setOwner] = useState([])
  const [owners, setOwners] = useState([])
  const [selectedLead, setSelectedLead] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [playingAudio, setPlayingAudio] = useState(0)
  const [playingAudioContext, setPlayingAudioContext] = useState(null)
  const [leadStage, setLeadStage] = useState([])
  const [next, setNext] = useState(0)
  const [prev, setPrev] = useState(0)

  useEffect(() => {
    setQueryList(resetQuery)
    //console.info('changed')

    if (!allTags?.length) return
    fetchData()
  }, [
    mainTab,
    sortOption,
    dateFilter,
    activeCallType,
    type,
    totalPaginated,
    allTags?.length
  ])

  useEffect(() => {
    if (!activeSeg) {
      setLeadsTempData(leadsData)
      return
    }
    setQueryList(resetQuery)
    setLeadsTempData(activeSegmentsLead)
  }, [activeSegmentsLead, activeSeg])

  useEffect(() => {
    if (activeTagsLead !== '') {
      filterTags(activeTagsLead)
    } else {
      setLeadsTempData(leadsData)
    }
  }, [activeTagsLead])

  // useEffect(() => {

  // }, [allTags?.length])

  // useEffect(() => {
  //   setQueryList(resetQuery)
  //   fetchData()
  // }, [activeCallType])

  useEffect(() => {
    if (!runQuery) return

    if (runQuery) {
      setRunQuery(false)
    }

    const dropDowns = ['agent', 'final_status', 'id', 'owner', 'status']

    if (queryList[queryList?.length - 1].condition !== 'between') {
      if (queryList[queryList?.length - 1].query?.length) {
        executeQuery()
      }
    } else {
      if (queryList[queryList?.length - 1].end?.length) {
        executeQuery()
      }
    }

    if (dropDowns.includes(queryList[queryList?.length - 1].col)) {
      executeQuery()
    }

    if (queryList[queryList?.length - 1].condition !== 'between') {
      if (queryList[queryList?.length - 1].query?.length === 0) {
        setLeadsTempData(leadsData)
      }
    } else {
      if (queryList[queryList?.length - 1].end?.length === 0) {
        setLeadsTempData(leadsData)
      }
    }
  }, [runQuery])

  const filterTags = tag => {
    const leads = leadsData

    let newLeads = leads?.filter(lead => {
      let t = lead.tags
      if (t) {
        if (t.includes(tag)) {
          return t
        }
      }
    })

    setLeadsTempData(newLeads)
  }

  const getArrayIndex = (list, find) => {
    for (const [i, v] of list.entries()) {
      if (v.id == find) {
        return i
      }
    }
    return -1
  }

  const getNextPrev = id => {
    const currIndex = getArrayIndex(leadsTempData, id)

    let nextIndex = 0
    let prevIndex = 0

    if (currIndex == 0) {
      nextIndex = 1
      prevIndex = leadsTempData?.length - 1
    } else if (currIndex == leadsTempData?.length - 1) {
      nextIndex = 0
      prevIndex = currIndex - 1
    } else {
      nextIndex = currIndex + 1
      prevIndex = currIndex - 1
    }

    if (!leadsTempData[nextIndex]) {
      nextIndex = currIndex
    }

    if (!leadsTempData[prevIndex]) {
      prevIndex = currIndex
    }

    let pLead = leadsTempData[prevIndex].id
    let nLead = leadsTempData[nextIndex].id

    return [nLead, pLead]
  }

  const executeQuery = () => {
    let datas = [...leadsData]
    let data = []

    if (queryList?.length === 1) {
      data = simpleQuery(queryList[0], datas)
    } else if (queryList[0].oparator === 'and') {
      data = andQuery(queryList)
    } else if (queryList[0].oparator === 'or') {
      data = orQuery(queryList)
    }

    let dt = []

    dt = data.map(row => {
      return row.id
    })

    setLeadsTempData(data)
    setSegmentsLeads(dt)
  }

  const andQuery = query => {
    let data = []
    let datas = [...leadsData]
    if (query?.length === 3) {
      data = simpleQuery(query[0], datas)
      data = simpleQuery(query[1], data)
      data = simpleQuery(query[2], data)
    } else {
      data = simpleQuery(query[0], datas)
      data = simpleQuery(query[1], data)
    }

    return data
  }

  const orQuery = query => {
    let data = []
    let data1 = []
    let data2 = []
    let datas = [...leadsData]
    if (query?.length === 3) {
      data = simpleQuery(query[0], datas)
      data1 = simpleQuery(query[1], datas)
      data2 = simpleQuery(query[2], datas)
      data = [...data, ...data1, ...data2]
    } else {
      data = simpleQuery(query[0], datas)
      data1 = simpleQuery(query[1], datas)
      data = [...data, ...data1]
    }

    return data
  }

  const simpleQuery = (query, datas) => {
    let data = []

    if (query.condition === 'equals') {
      data = datas?.filter((row, key) => {
        return row[query.col] == query.query
      })
    }

    if (query.condition === 'not-equals') {
      data = datas?.filter((row, key) => {
        return row[query.col] != query.query
      })
    }

    if (query.condition === 'less-then') {
      data = datas?.filter((row, key) => {
        return row[query.col] < parseInt(query.query)
      })
    }

    if (query.condition === 'greater-then') {
      data = datas?.filter((row, key) => {
        return row[query.col] > parseInt(query.query)
      })
    }

    if (query.condition === 'between') {
      data = datas?.filter((row, key) => {
        return row[query.col] >= query.start && row[query.col] <= query.end
      })
    }

    if (query.condition === 'contains') {
      data = datas?.filter((row, key) => {
        if (row[query.col]) {
          if (typeof row[query.col] === 'string') {
            return row[query.col].includes(query.query)
          }
          return String(row[query.col]).includes(query.query)
        }
      })
    }

    if (query.condition === 'starts-with') {
      data = datas?.filter((row, key) => {
        if (row[query.col]) {
          if (typeof row[query.col] === 'string') {
            return row[query.col].includes(query.query)
          }
          return String(row[query.col]).startsWith(query.query)
        }
      })
    }

    if (query.condition === 'ends-with') {
      data = datas?.filter((row, key) => {
        if (row[query.col]) {
          if (typeof row[query.col] === 'string') {
            return row[query.col].includes(query.query)
          }
          return String(row[query.col]).endsWith(query.query)
        }
      })
    }

    return data
  }

  const dataContains = (col, query) => {
    return col.includes(query)
  }

  const getWidget = async () => {
    const w_id = await localStorage.getItem('widget_id')

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/get-widget-call-settings?widget_id=${w_id}`

    axios
      .get(url)
      .then(res => {
        const data = res.data.data.scores_tags
        const updateLeadScore =
          data.allowed_scores &&
          data.allowed_scores.map((item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          })

        const updateStageScore =
          data.allowed_stages &&
          data?.allowed_stages?.map((item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          })

        if (
          !Number.isInteger(updateLeadScore[updateLeadScore?.length - 1].key)
        ) {
          updateLeadScore.splice([updateLeadScore?.length - 1, 1])
        }

        setLeadStage(updateStageScore)
      })

      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  const playAudio = (id, url) => {
    if (playingAudio) return

    setPlayingAudio(id)
    //url = "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3";
    const audio = new Audio(url)
    audio.play()
    setPlayingAudioContext(audio)
  }
  const onStageValueChanged = (a, b, c) => {
    const s = b.value

    onLeadStageHandler(s, c)
  }

  const handleClickSideModal = () => {}

  useEffect(() => {
    getOwner()
    getWidget()
  }, [])

  const getOwner = async () => {
    const owner = [
      {
        key: 'unassign owner',
        text: 'Unassign owner',
        value: null
      }
    ]
    const allMembers = []
    await getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin
        await owner.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.id
        })

        await allMembers.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.id
        })

        await res.data.data.members.map(team => {
          owner.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
          allMembers.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
        })

        setOwner(owner)
        setOwners(allMembers)
      })
      .catch(err => {})
  }

  const reloadTable = () => {
    fetchData()
    reloadTotal()
  }

  const fetchData = data => {
    const { sortField, sortValue, sortMain, pageNumber } = sortOption

    const { selectedValue, query, filterCondition } = queryOption

    setLoading(true)
    const apiToken = localStorage.getItem('access_token')
    const header = {
      Authorization: 'Bearer ' + apiToken
    }

    var params = {
      page: 1,
      sort_field: sortField,
      sort_value: sortValue,
      sort: sortMain
    }

    const uID = localStorage.getItem('id')
    const uName =
      localStorage.getItem('first_name') +
      ' ' +
      localStorage.getItem('last_name')

    if (dateFilter.startDateFilter !== '' || dateFilter.endDateFilter !== '') {
      params[
        'filter[created_at][between]'
      ] = `${dateFilter.startDateFilter},${dateFilter.endDateFilter}`
    }

    if (type === 1) {
      params['limit'] = 1000
      params['filter[owner][is]'] = uName
    } else if (type === 2) {
      params['limit'] = 1000
      params['filter[status][is]'] = 1
      params['filter[owner][is]'] = uName
    } else if (type === 3) {
      params['limit'] = 1000
      params['filter[status][is]'] = 2
      params['filter[owner][is]'] = uName
    } else if (type === 4) {
      params['limit'] = 1000
      params['filter[owner][is]'] = uName
      params['filter[status][is]'] = 3
    } else if (type === 5) {
      params['limit'] = 1000
      params['filter[owner][is]'] = uName
      params['filter[status][is]'] = 4
    } else if (type === 6) {
      params['limit'] = 1000
      params['filter[status][is]'] = 5
      params['filter[owner][is]'] = uName
    } else {
      params['filter[owner][is]'] = uName
    }

    params['filter[type][in]'] = getMainTabQuery()

    if (query) {
      if (selectedValue === 'Date') {
        var cond = 'like'
        filterCondition === '!is' ? (cond = '!like') : (cond = 'like')
        params = {
          [`filter[created_at][${cond}]`]: query,
          limit: 1000,
          page: pageNumber,
          sort_field: sortField,
          sort_value: sortValue
        }
      } else {
        params = {
          [`filter[${selectedValue}][${filterCondition}]`]: query,
          limit: 1000,
          page: pageNumber,
          sort_field: sortField,
          sort_value: sortValue,
          sort: sortMain
        }
      }
    } else {
      if (selectedValue != '') {
        params = {
          [`filter[${selectedValue}][${filterCondition}]`]: query,
          limit: 1000,
          page: pageNumber,
          sort_field: sortField,
          sort_value: sortValue,
          sort: sortMain
        }
      }
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads`

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: params
      })
      .then(res => {
        setLoading(false)
        setLeadsData(res.data.data.leads.data)
        calculateTags(res.data.data.leads.data)
        setLeadsTempData(res.data.data.leads.data)
      })
      .catch(err => {
        setLoading(false)

        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }

  const calculateTags = data => {
    if (!data?.length) return 0

    let tagsData = data?.filter(item => {
      if (item.tags) {
        return item.tags
      }
    })

    tagsData = tagsData.map(item => {
      return JSON.parse(item.tags)
    })

    var merged = tagsData.reduce(function(prev, next) {
      return prev.concat(next)
    })

    let allT = [...allTags]
    const newTags = allT.map(tag => {
      return {
        ...tag,
        count: getTagCount(tag.tag, merged)
      }
    })


    setAllTags(newTags)
  }

  const getTagCount = (tag, list) => {
    try {
      const items = list?.filter((item, row) => {
        if (item === tag) {
          return item
        }
      })
      return items?.length
    } catch (err) {
      return 0
    }
  }

  return (
    <Tab.Pane attached={false}>
      <Table singleLine style={{ minHeight: '300px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Date & Time</Table.HeaderCell>
            <Table.HeaderCell>Contact</Table.HeaderCell>
            <Table.HeaderCell>Source</Table.HeaderCell>
            <Table.HeaderCell>Pipeline</Table.HeaderCell>
            <Table.HeaderCell>Lead status</Table.HeaderCell>
            {/* <Table.HeaderCell>Type</Table.HeaderCell> */}
            <Table.HeaderCell>Call info</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Agent</Table.HeaderCell>
            <Table.HeaderCell>Tags</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {leadsTempData.map((data, key) => {
            return (
              <TableContent
                updateTagCount={updateTagCount}
                getNextPrev={getNextPrev}
                handleRightPaneOpen={handleRightPaneOpen}
                handleClickSideModal={handleClickSideModal}
                leadStage={leadStage}
                allTags={allTags}
                key={data.id}
                data={data}
                onStageValueChanged={onStageValueChanged}
                onChangeOwner={onChangeOwner}
                onStatusChangeHandler={onStatusChangeHandler3}
                onChangeInterseted={onChangeInterseted3}
                type={type}
                owners={owners}
                next={next}
                prev={prev}
                reloadTable={reloadTable}
              />
            )
          })}
        </Table.Body>
      </Table>
    </Tab.Pane>
  )
}
export default MyleadsDetail
