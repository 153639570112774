import React, { useState, useEffect } from 'react'
import { Tab, Checkbox, Select, Dropdown } from 'semantic-ui-react'
import { Dimmer, Loader } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import axios from 'axios'

import Title from '../common/Title'
import CreateMeetingType from '../components/meetings/createMeetingType'
import apiConfig from '../config/apiconfig.json'
import MeetingModalSide from '../components/meetings/detailSideModal'
import ScheduleModalSide from '../components/meetings/scheduleSideModal'
import DetailMeeting from '../components/meetings/detailsMeeting'
import filterIcon from '../assets/images/filter.png'
import circlePlus from '../assets/images/plusicon-set.png'
import MeetingTypeTeam from '../components/meetings/meetingTypeTeam'
import MeetingSideModal from '../components/meetings/MeetingsSideModal'
import { Box } from '@mui/system'
import CardMetting from './cardMeeting'
import EditMeetingPage from './EditMeetingPage'
import MeetingPageDesigner from '../components/settings/MeetingPageDesigner'
import CommonDateRangePicker from '../components/daterangepicker/CommonDateRangePicker'
import moment from 'moment'
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { connect } from 'react-redux'
import { CommonNotify } from '../common/CommonNotify'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import AddPersonalLink from '../components/settings/AddPersonalLink'

const title = {
  titleTwo: 'Meetings'
}
const options = [
  { key: 1, text: 'Today', value: 'Today' },
  { key: 2, text: 'Yesterday', value: 'Yesterday' },
  { key: 3, text: 'This week', value: 'This week' },
  { key: 4, text: 'Last week', value: 'Last week' },
  { key: 5, text: 'This month', value: 'This month' },
  { key: 6, text: 'Last month', value: 'Last month' },
  { key: 7, text: 'Custom range', value: 'Select custom' }
]

const countryOptions = [
  { key: 'af', value: 'af', text: 'Afghanistan' },
  { key: 'ax', value: 'ax', text: 'Aland Islands' },
  { key: 'al', value: 'al', text: 'Albania' },
  { key: 'dz', value: 'dz', text: 'Algeria' },
  { key: 'as', value: 'as', text: 'American Samoa' },
  { key: 'ad', value: 'ad', text: 'Andorra' },
  { key: 'ao', value: 'ao', text: 'Angola' },
  { key: 'ai', value: 'ai', text: 'Anguilla' },
  { key: 'ag', value: 'ag', text: 'Antigua' },
  { key: 'ar', value: 'ar', text: 'Argentina' },
  { key: 'am', value: 'am', text: 'Armenia' },
  { key: 'aw', value: 'aw', text: 'Aruba' },
  { key: 'au', value: 'au', text: 'Australia' },
  { key: 'at', value: 'at', text: 'Austria' },
  { key: 'az', value: 'az', text: 'Azerbaijan' },
  { key: 'bs', value: 'bs', text: 'Bahamas' },
  { key: 'bh', value: 'bh', text: 'Bahrain' },
  { key: 'bd', value: 'bd', text: 'Bangladesh' },
  { key: 'bb', value: 'bb', text: 'Barbados' },
  { key: 'by', value: 'by', text: 'Belarus' },
  { key: 'be', value: 'be', text: 'Belgium' },
  { key: 'bz', value: 'bz', text: 'Belize' },
  { key: 'bj', value: 'bj', text: 'Benin' }
]



const Meeting = ({ profileData, widgetData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setDate] = React.useState()
  const [detailPopUp, setDetailPopUp] = useState(false)
  const [evergreenModal, setEvergreenModal] = useState(false)
  const [sidePaneData, setSidePaneData] = useState(null)
const [tableFullData, setTableFullData] = useState({})
  const [sideTab, setSideTab] = useState(1)
  const today = ()=> new Date()
  const [schedulePopUp, setSchedulePopUp] = useState(false)
  const [editMeeting, setEditMeeting] = useState(false)
  const [createMeetingType, setMeetingType] = useState(false)
  const [menuOpen, setMenu] = useState(false)
  const [meetingData, setMeetData] = useState([])
  const [Events, setEvents] = useState([])
  const [fiterAgent, setFilterAgent] = useState(false)
  const [selectedPickerOption, setSelectedPickerOption] = useState('Today')
const [filterTable, setFilterTable] = useState({startDateFilter:'',endDateFilter:''})
  const selectDateHandler = d => {
    setDate(d)
  }
  const panes = [
    {
      menuItem: 'Upcoming',
      render: ({ setDetailPopUp}) => (
        <MeetingTypeTeam fiterAgent={fiterAgent} filterTable={filterTable} setDetailPopUp={setDetailPopUp} status={'scheduled'}/>

      )
    },
    {
      menuItem: 'Past',
      render: ({ setDetailPopUp, meetingData, setModalData, setEditMeeting }) => (
        <MeetingTypeTeam fiterAgent={fiterAgent} filterTable={filterTable} setDetailPopUp={setDetailPopUp} status={'completed'}/>

      )
    },
    {
      menuItem: 'Canceled',
      render: ({ setDetailPopUp,meetingData, setEditMeeting }) => (
        <MeetingTypeTeam fiterAgent={fiterAgent} filterTable={filterTable} setDetailPopUp={setDetailPopUp} status={'canceled'}/>


      )
    },
  ]
  const panesType = [
    {
      menuItem: 'Team',
      render: ({ setDetailPopUp, detailPopUp }) =>   
      <Box display="flex" mt={1}>
       { Events?.map((event)=>
       
        
        event?.participation!=='one_to_one' &&

        <CardMetting event={event} setEditMeeting={setEditMeeting} />
        ) }
           </Box>
        
    },
    {
      menuItem: 'Personal',
      render: ({ setDetailPopUp, detailPopUp }) =>  
      
      
      <Box display="flex" mt={1}>
        {Events?.map((event)=>
       
        
      event?.participation==='one_to_one' &&

      <CardMetting event={event} setEditMeeting={setEditMeeting} />
      ) }
      </Box>
      
    }
  ]
  
 
  const setModalData = async data => {
    setEvergreenModal(true)
    setSidePaneData(data)
  }

  const setEvergreenModalStatus = async data => {
    setEvergreenModal(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        var bodyFormData = new FormData()
        bodyFormData.append('email', 'app@limecall.com')
        bodyFormData.append('password', 'limecalladmin')
        const user_id = localStorage.getItem('id')

        const result1 = await axios
          .post(`${apiConfig.calenderURL}/api/login`, bodyFormData)
          .then(response => {
            return response.data.token
          })
        localStorage.setItem('meeting_token', result1)

        const result2 = await axios.get(
          `${apiConfig.calenderURL}/api/event/retrieve/${user_id}`,
          {
            headers: {
              Authorization: 'Bearer ' + result1
            }
          }
        )
        setIsLoading(false)
        setMeetData(result2?.data?.data)
      } catch (e) {
        setIsLoading(false)
      }
    }

    fetchData()
    fetchEvents()
  
  }, [])


const fetchEvents = async() => { 
await axios?.get(`${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/calendar/events?status=1`).then((res)=>{
  if(res.data.data){
    setEvents(res.data.data)
  }
}).catch((e)=>{

})
  
 }
  const yesterday = async () => {
    setFilterTable({
      startDateFilter: moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      endDateFilter: moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
    })
  }

  const lastWeek = () => {
    setFilterTable({
      startDateFilter: moment()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
        .format('YYYY-MM-DD'),
      endDateFilter: moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
        .format('YYYY-MM-DD')
    })
  }

  const thisWeek = () => {
    const startOfWeek = moment()
      .startOf('isoWeek')
      .format('YYYY-MM-DD')
    const endOfWeek = moment()
      .endOf('isoWeek')
      .format('YYYY-MM-DD')
    setFilterTable({
      startDateFilter: startOfWeek,
      endDateFilter: endOfWeek
    })
  }

  const thisMonth = () => {
    const startOfMonth = moment()
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD')
    const endOfMonth = moment()
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD')
    setFilterTable({
      startDateFilter: startOfMonth,
      endDateFilter: endOfMonth
    })
  }
  const onCopyText = async jsCode => {
    try {
      await navigator.clipboard.writeText(jsCode)
      CommonNotify('Copied!', 'success')
    } catch (err) {
      CommonNotify('Failed to copy!')
    }
  }

  const lastMonth = () => {
    const prevMonthFirstDay = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD')
    const prevMonthLastDay = moment()
      .subtract(1, 'months')
      .endOf('month')
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD')
    setFilterTable({
      startDateFilter: prevMonthFirstDay,
      endDateFilter: prevMonthLastDay
    })
  }
  const handleChange = (e, { value }) => {
    if (value === 'Select custom') {
      setSelectedPickerOption(value)

      setFilterTable( {
          startDateFilter: moment(),
          endDateFilter: moment()
        })

      return
    }
    setSelectedPickerOption( value)
    switch (value) {
      case 'Today':
        today()
        break
      case 'Yesterday':
        yesterday()
        break
      case 'This week':
        thisWeek()
        break
      case 'Last week':
        lastWeek()
        break
      case 'This month':
        thisMonth()
        break
      case 'Last month':
        lastMonth()
        break
      default:
        today()
    }
  }
  const handleApply = (event, picker) => {
    setFilterTable(
      {
        startDateFilter: moment(picker.startDate).format('YYYY-MM-DD'),
        endDateFilter: moment(picker.endDate).format('YYYY-MM-DD')

      }
    )
  }
  const handleCancel = () => {}

  return (
    <>
      <div className="sidebarWrap">
        <div className="meetingSidebar">
          <div className="sideHeader">
          </div>
          <label
            className={sideTab == 1 ? 'sideTab' : ''}
            onClick={() => setSideTab(1)}
          >
            Scheduled Meetings
          </label>
          <label
            className={sideTab == 0 ? 'sideTab' : ''}
            onClick={() => setSideTab(0)}
          >
           Page Designer
          </label>
       
          {/* <label
            className={sideTab == 2 ? 'sideTab' : 'none'}
            onClick={() => setSideTab(2)}
          >
           Meeting Types
          </label> */}
        </div>
        {!editMeeting ? <div className="meetWrapper">
          <div className="meet-container">
            <Dimmer active={isLoading} style={{ position: 'fixed' }}>
              <Loader />
            </Dimmer>
            <Title
              data={title}
              setMenu={setMenu}
              menuOpen={menuOpen}
              detailPopUp={detailPopUp}
              schedulePopUp={schedulePopUp}
            />
          </div>
          {sideTab == 1 ? (
            <div>
              <div className="meet-holder">
                <div className="meet-title-holder">
                  <div className="schedule_meeting">
                    <div className="meeting-time">
                      {/* <button className="">Meet Now</button> */}
                    </div>
                  </div>
                  <div className="metting-filter-section">
                    <Checkbox  label="My Meetings only" onChange={(e,data)=>setFilterAgent(data?.checked)}/>
                    <div className="date-selct-metting">
                      <div className="date-range-parent custom-range-parent range-dropdown" style={{minWidth:'145px'}}>
                        <Dropdown
                          onChange={handleChange}
                          className="mr-3"
                          options={options}
                          selection
                          value={selectedPickerOption}
                        />
                        {selectedPickerOption === 'Select custom' ? (
                          <CommonDateRangePicker
                            handleApply={handleApply}
                            handleCancel={handleCancel}
                            initialSettings={{
                              startDate: filterTable.startDateFilter,
                              endDate: filterTable.endDateFilter,
                              maxDate: moment()
                            }}
                          />
                        ) : null}
                      </div>
                      {/* <DatePicker
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select the date"
                        selected={startDate}
                        onChange={selectDateHandler}
                        // minDate={today}
                        todayButton={'Today'}
                      /> */}
                    </div>
                    {/* <div className="filter-metting">
                      <Select
                        style={{ margin: '5px' }}
                        placeholder="Filter"
                        options={countryOptions}
                      />
                      <img style={{ marginLeft: '10px' }} src={filterIcon} />
                    </div> */}
                  </div>
                </div>
              </div>
              {detailPopUp ? (
                <MeetingModalSide
                  detailPopUp={detailPopUp}
                  setDetailPopUp={setDetailPopUp}
                />
              ) : (
                ''
              )}
              {schedulePopUp ? (
                <ScheduleModalSide
                  schedulePopUp={schedulePopUp}
                  setSchedulePopUp={setSchedulePopUp}
                />
              ) : (
                ''
              )}
     
              <div className="metting-event">
                <div style={{ width: '330px' }}>
                  <Link to='/settings/my_page' style={{ fontWeight: 'unset !important', fontSize: '17.5px' }} className="font-weight-bold" >Personal Booking Link</Link>
                  <div class="input-group input-group-sm mb-3" >
                    <input type="text" class="form-control" style={{ background: 'white' }} readOnly placeholder={profileData?.new_personal_link} aria-label="Recipient's username" aria-describedby="button-addon2" />
                    <div class="input-group-prepend" style={{ maxHeight: '28px', height: '100%' }}>
                      <button class="btn btn-outline-secondary" style={{ background: 'lightGray' }} type="button" onClick={() => onCopyText(profileData?.new_personal_link)} id="button-addon2"><FileCopyIcon /></button>
                      <button class="btn btn-outline-primary" style={{ background: 'lightGray' }} type="button" onClick={() => window.open(profileData?.new_personal_link)} id="button-addon2"><LinkIcon /></button>

                    </div>
                  </div>


                </div>
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={panes}
                  setModalData={setModalData}
                  setDetailPopUp={setDetailPopUp}
                  detailPopUp={detailPopUp}
                  meetingData={meetingData}
                  setEditMeeting={() => setEditMeeting(true)}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          {sideTab == 2 ? (
            <div>
              <div className="meet-holder">
                <div className="meet-title-holder">
                  <div className="schedule_meeting">
                    <button className="" onClick={() => setMeetingType(true)}>
                      Create Meeting Type <img src={circlePlus} />
                    </button>
                  </div>
                </div>
              </div>

              {createMeetingType ? (
                <CreateMeetingType
                  createMeetingType={createMeetingType}
                  setMeetingType={setMeetingType}
                />
              ) : (
                ''
              )}
              <div className="metting-event">
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={panesType}
                  setDetailPopUp={setDetailPopUp}
                  detailPopUp={detailPopUp}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          {sideTab == 0 ? (
            <div className='tab-wrapper'>
              <AddPersonalLink />
            </div>
          ) : (
            ''
          )}
      
          <div className="metting-table"></div>{' '}
          <MeetingSideModal
            sidePaneData={sidePaneData}
            setEvergreenModalStatus={setEvergreenModalStatus}
            visibility={evergreenModal}
          ></MeetingSideModal>
        </div> : <EditMeetingPage handleBackClick={() => setEditMeeting(false)} /> }

        
      </div>
    </>
  )
}


const mapStateToProps = state => ({
  profileData: state.settings?.profile?.data,
  widgetData: state.home?.widget?.data?.[0],

})


export default connect(mapStateToProps, {})(Meeting)