import React, { useState, useEffect } from 'react'
import { Grid, Dropdown, Checkbox, Button } from 'semantic-ui-react'

import hostIcon from '../../assets/images/host-img.png'
import zoomIcon from '../../assets/images/Zoom.png'
import graybellIcon from '../../assets/images/graybell.png'
import plusIcon from '../../assets/images/plusIcon.png'
import AddGuestModal from './addGuestModal'
import { getLeadOwner } from '../../config/leadAPI'





const options = [
  {
    key: 'Jenny Hess',
    text: 'Jenny Hess',
    value: 'Jenny Hess',
    image: { avatar: true, src: zoomIcon }
  },
  {
    key: 'Justen Kitsune',
    text: 'Justen Kitsune',
    value: 'Justen Kitsune',
    image: { avatar: true, src: zoomIcon }
  }
]

const location = [
  {
    value: 'google_meeting',
    text: 'Google Meet',
    image: { avatar: true, src: zoomIcon }
  },
  {
    value: 'limecall_meeting',
    text: 'Limecall Meeting',
    image: { avatar: true, src: zoomIcon }
  },
  {
    value: 'zoom_meeting',
    text: 'Zoom Meeting',
    image: { avatar: true, src: zoomIcon }
  }
]
const remainder = [
  {
    key: 'Before 10 minutes',
   text: 'Before 10 minutes',
   value: 'Before 10 minutes',
  },
  {
    key: 'Before 1 Hour',
   text: 'Before 1 Hour',
   value: 'Before 1 Hour',
  },
  {
    key: 'Before 1 day',
   text: 'Before 1 day',
   value: 'Before 1 day',
  },
]

const ScheduleDetailPane = ({
  handleChange,
  handleSubmit,
  setSchedulePopUp,    
  meetingData
}) => {
  const [open, setOpen] = useState(false)
  const [members, setMembers] =  useState([])

  useEffect(() => {
    getOwner()
  }, [])


  const  getOwner = async () => {
    const owner = [{
      key: 'unassign owner',
      text: 'Unassign owner',
      value : null
    }]
    const allMembers = []
    await getLeadOwner()
      .then(async res => {
        const admin = res.data.data.admin
        await owner.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.id
        })

        await allMembers.push({
          key: admin.id,
          text: admin.first_name + ' ' + admin.last_name,
          value: admin.first_name + ' ' + admin.last_name
        })

        await res.data.data.members.map(team => {
          owner.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.id
          })
          allMembers.push({
            key: team.id,
            text: team.first_name + ' ' + team.last_name,
            value: team.first_name + ' ' + team.last_name
          })
        })

         
          setMembers(allMembers) 
          
        })
        .catch(err => {
        
        })

  }

  return (
    <div className="contact-card schedulePopup">
     
      <div className="contact-card-boxes">
        <p className="card-boxes-title">GUESTS</p>
        <Grid className="card-boxes-text">
          <Grid.Row>
            <Grid.Column>
              <div className="senddropdown">
                <img className="ui medium  image" src={hostIcon} alt="" />
                <Dropdown
                  text="George Black"
                  placeholder="Linda Crowford"
                  fluid
                  selection
                  options={options}
                  className="hostdropdown"
                />
              </div>
              <div className="addGuest" onClick={() => setOpen(true)}>
                <img className="ui medium  image" src={plusIcon} alt="" />
                <label>Add guests</label>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="contact-card-boxes">
        <p className="card-boxes-title">HOST</p>
        <Grid className="card-boxes-text">
          <Grid.Row>
            <Grid.Column>
              <div className="senddropdown">
                <img className="ui medium  image" src={hostIcon} alt="" />
                { members?.length && (
                  <Dropdown
                    placeholder="Select Host"
                    fluid
                    selection
                   
                    options={members}
                    className="hostdropdown"
                  />
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="contact-card-boxes">
        <p className="card-boxes-title">LOCATION</p>
        <Grid className="card-boxes-text">
          <Grid.Row>
            <Grid.Column>
              <div className="senddropdown">
                <img className="ui medium  image" src={zoomIcon} alt="" />
                <Dropdown
                  text="Zoom"
                  placeholder="Linda Crowford"
                  fluid
                  selection
                  options={location}
                  className="hostdropdown"
                  onChange={() => handleChange('location', 'zoom_meeting')}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="contact-card-boxes sheduletabMain">
        <p className="card-boxes-title">SEND MEETING REMINDER</p>
        <div className="schedulebellWrap">
          <div className="scheduletabdropdown">
            <img className="ui medium  image" src={graybellIcon} alt="" />
            <Dropdown defaultValue="Before 10 minutes" options={remainder} simple item />
          </div>
          <div className="scheduleswitch">
            <Checkbox toggle />
          </div>
        </div>
      </div>
      <div className="scheduleButton">
        <Button className="btnCancle" onClick={() => setSchedulePopUp(false)}>
          Cancel
        </Button>
        <Button className="btnSave" onClick={handleSubmit}>
          Schedule
        </Button>
      </div>
      <AddGuestModal open={open} setOpen={setOpen} />
    </div>
  )
}
export default ScheduleDetailPane
