import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Accordion } from 'semantic-ui-react'
import editIcon from '../assets/images/edit-icon.svg'
import { CommonNotify } from '../common/CommonNotify'
import EditDetailOpen from '../components/contactsPanels/editDetailsPopUp'
import { useGetContactsQuery } from '../features/api/apiSlice'

const intialEditData = {
  name: '',
  phone_number: '',
  phone_number: '',
  email: '',
  country: '',
  type: '',
  city: '',
  owner_id: '',
  owner_name: '',
  first_name: '',
  organization_name: ''
}

function ContactData({ data, refresh }) {
  const resContacts = useGetContactsQuery({ date: 'all', type: 'all' })
  const [activeIndex, setActiveIndex] = useState(0)
  const [companyData, setCompanyData] = useState()
  const [editContact, setEditContact] = useState(intialEditData)
  const [editContactModal, setEditContactModal] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (resContacts.isSuccess) {
      let contactData = resContacts?.data?.data
      const responseCompany = contactData?.filter(contact => {
        return contact.type == 'organization'
      })
      setCompanyData(responseCompany)
    }
  }, [resContacts])

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const activeIndexs = activeIndex
    const newIndex = activeIndexs === index ? -1 : index
    setActiveIndex(newIndex)
  }

  const fetchContactByIds = id => {
    if (id) {
      setLoading(true)
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${id}`
      axios
        .get(url)
        .then(res => {
          setLoading(false)
          setEditContact({
            ...editContactModal,
            name: res?.data?.data?.name,
            email: res?.data?.data?.email,
            phone_number: res?.data?.data?.phone_number,
            owner_id: res?.data?.data?.owner?.id
              ? res?.data?.data?.owner?.id
              : 0,
            owner_name:
              res?.data?.data?.owner?.first_name +
              ' ' +
              res?.data?.data?.owner?.last_name
                ? res?.data?.data?.owner?.first_name +
                  ' ' +
                  res?.data?.data?.owner?.last_name
                : '',
            country: res?.data?.data?.country,
            type: res?.data?.data?.type,
            city: res?.data?.data?.city,
            first_name: res?.data?.data?.owner?.first_name,
            organization_name: res?.data?.data?.organization_name
              ? res?.data?.data?.organization_name
              : 'test'
          })
        })
        .catch(error => {
          setLoading(false)
          CommonNotify(
            error?.response?.data?.errors?.[0] || 'something went to wrong!'
          )
        })
    }
  }

  const handleInputChange = (event, code, type) => {
    if (type === 'phone_number') {
      setEditContact({
        ...editContact,
        phone_number: `+${event}`,
        phone_country: code.countryCode
      })
    } else {
      const { name, value } = event.target
      editContact[name] = value
      setEditContact(prevContactData => ({
        ...prevContactData,
        [name]: value
      }))
    }
  }

  const onclickOwner = () => {
    let retData = editContact
    if (editContact.owner_id == 0) {
      delete editContact.owner_id
      delete editContact.owner_name
    }
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/update/${data?.callData?.contact?.id}`
    axios
      .post(url, retData)
      .then(res => {
        setLoading(false)
        CommonNotify('Contact successfully updated!', 'success')
        setEditContactModal(false)
        setEditContact(intialEditData)
        refresh && refresh()
      })
      .catch(error => {
        setLoading(false)
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Cant update contact!'
        )
      })
  }

  return (
    <>
      <Accordion className="Lead_wrapper" fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          Contact{' '}
          {data?.callData?.contact?.id && (
            <img
              className="ml-10"
              src={editIcon}
              onClick={d => {
                setEditContactModal(true)
                fetchContactByIds(data?.callData?.contact?.id)
              }}
            />
          )}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-33.svg'
                  }
                  className="lead-data-icon"
                />
                <label>ID</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data?.callData?.contact?.id}</b>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/i copy-39.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Name</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data?.callData?.contact?.name}</b>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-24.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Phone</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data?.callData?.contact?.phone_number}</b>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/i copy-39.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Email</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data?.callData?.contact?.email}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-31.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Company</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data?.callData?.company_name || ''}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-31.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Role</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data.callData?.contact?.role || ''}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-31.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Owner</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data?.callData?.contact?.organization_name}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-31.svg'
                  }
                  className="lead-data-icon"
                />
                <label>country</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data?.callData?.contact?.country}</b>
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-4">
              <p className="mb-0 text-color lead_title">
                <img
                  src={
                    process.env.REACT_APP_BASE_APP_URL +
                    '/assets/lead_details_icons/Lead popup edited-32.svg'
                  }
                  className="lead-data-icon"
                />
                <label>Type</label>
              </p>
            </div>
            <div className="col-md-1 ">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>{data?.callData?.contact?.type}</b>
              </p>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      {editContactModal && (
        <EditDetailOpen
          companyList={companyData}
          setDetailOpen={setEditContactModal}
          editDetailOpen={editContactModal}
          handleInputChange={handleInputChange}
          editContact={editContact}
          setEditContact={setEditContact}
          onclickOwner={onclickOwner}
          loading={loading && editContactModal}
        />
      )}
    </>
  )
}

export default ContactData
