import React from 'react'
import loginLogo from '../assets/images/logo-limecall.svg'
import { Image } from 'semantic-ui-react'

const Suspended = () => {
  return (
    <div id="admin-verification">
        <div className='logo-container'>
            <div className="logo"><Image src={loginLogo} /></div>
        </div>
        <div className="container">
            <h2>Account Suspended</h2>
            <p>This account has been suspended.<br/>Please contact support for more information</p>
        </div>
    </div>
  )
}

export default Suspended