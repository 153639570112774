import React, { Component } from 'react'
import { Accordion, Input, Dimmer, Loader, Popup, Grid } from 'semantic-ui-react'
import CommonInput from '../../common/CommonInput'
import CommonTextArea from '../../common/CommonTextArea'
import Dropzone from 'react-dropzone-uploader'
import CommonButtons from '../../common/CommonButtons'
import axios from 'axios'
import CommonSelect from '../../common/CommonSelect3'
import { CommonNotify } from '../../common/CommonNotify'
import Toggle from '../../common/CommonToggle'
import { connect } from 'react-redux'
import { getProfile } from '../../actions/settings'
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { Icon, Checkbox } from 'semantic-ui-react'
import personalPreview from '../../assets/images/personal_preview.jpg'
import LinkIcon from '@mui/icons-material/Link';
import { Button } from '@mui/material'

const apiToken = localStorage.getItem('access_token')

const meetingPlaces = [
    {
        key: 0,
        text: "Call back",
        value: "limecal"
    },
    {
        key: 1,
        text: "Zoom",
        value: "zoom"
    },
    {
        key: 2,
        text: "Google meet",
        value: "google meet"
    }
]

class MeetingPageDesigner extends Component {
    state = {
        emailRequired: false,
        nameRequired: false,
        liveCallEmbed: false,
        activeIndexs: [0, 1],
        selectedMeetingPlace: "limecal",
        isProfileData: false,
        isTwitterEnable: false,
        isLinkedInEnable: false,
        complete_name: '',
        data: {
            designation: '',
            twitter: '',
            linkedin: '',
            bio: '',
            time_zone: 'Africa/cairo',
            full_name: 'Muhammad Magd',
            company_name: "Mario's",
            selectedFile: null
        },
        profile_pic: null,
        role: '',
        selectedFile: null,
        personalUrl: '',
        personalLinkCopyText: '',
        twitterToggle: {
            callId: 'twitterToggleSnippet'
        },
        linkedInToggle: {
            callId: 'linkedInToggleSnippet'
        },
        isLoader: false
    }

    componentDidUpdate(prevProps) {
        if (this.props.profile !== prevProps.profile) {
            this.getPersonalLink(this.props.profile)
        }
    }

    componentDidMount() {
        if (this.props.profile) {
            this.getPersonalLink(this.props.profile)
        } else {
            this.props.getProfile()
        }
    }

    getPersonalLink = (res) => {

        if (!res) {
            res = this.props.profile
        }

        const urlData = res.data?.personal_link?.split('me/').pop()
        const personalData = res.data
        const roleData = res.data.role.name
        const full_name = res.data.first_name + ' ' + res.data.last_name
        if (res.data.profile_image) {
            localStorage.setItem('profile_pic', res.data.profile_image ? `${process.env.REACT_APP_BASE_APP_URL}/storage` + res.data.profile_image : null)
        }
        this.setState({
            profile_pic: res.data.profile_image ? `${process.env.REACT_APP_BASE_APP_URL}/storage` + res.data.profile_image : null,
            org_profile_pic: res.data.profile_image ? `${process.env.REACT_APP_BASE_APP_URL}/storage` + res.data.profile_image : null,
            personalLinkCopyText: res.data.personal_link,
            personalUrl: urlData,
            role: roleData,
            data: personalData,
            emailRequired: res.data.email_required_before_call == 1 ? true : false,
            nameRequired: res.data.name_required_before_call == 1 ? true : false,
            liveCallEmbed: res.data.live_call_embed == 1 ? true : false,
            isLoader: false,
            complete_name: full_name,
            selectedMeetingPlace: res.data.default_meeting_place
        })
        /* const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/profile`
        this.setState({ isLoader: true, selectedFile: null })
        
        axios
          .get(url)
          .then(res => {
            const urlData = res.data.data.personal_link.split('me/').pop()
            const personalData = res.data.data
            const roleData = res.data.data.role.name
            const full_name = res.data.data.first_name + ' ' + res.data.data.last_name
            if(res.data.data.profile_image)
            {
              localStorage.setItem('profile_pic', res.data.data.profile_image ? `${process.env.REACT_APP_BASE_APP_URL}/storage` + res.data.data.profile_image : null)
            }
            this.setState({
              profile_pic : res.data.data.profile_image ? `${process.env.REACT_APP_BASE_APP_URL}/storage` + res.data.data.profile_image : null,
              personalLinkCopyText: res.data.data.personal_link,
              personalUrl: urlData,
              role: roleData,
              data: personalData,
              emailRequired : res.data.data.email_required_before_call == 1 ? true : false,
              nameRequired : res.data.data.name_required_before_call == 1 ? true : false,
              isLoader: false,
              complete_name : full_name,
              selectedMeetingPlace: res.data.data.default_meeting_place
            })
          })
          .catch(error => {       
            this.setState({ isLoader: false })
          }) */
    }

    cancelData = () => {
        window.location.reload();
        return;
        const { org_profile_pic } = this.state
        this.setState({ profile_pic: org_profile_pic }, () => this.getPersonalLink())
    }

    postData = (key, value) => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/personal-page-setting`

        this.setState({ isLoader: true, selectedFile: null })
        let data = null;
        if (key === 'name_required_before_call') {
            this.setState({ nameRequired: value })
            data = {
                name_required_before_call: value ? 1 : 0,
                live_call_embed: this.state.liveCallEmbed ? 1 : 0,
                email_required_before_call: this.state.emailRequired ? 1 : 0
            }
        } else if (key === 'email_required_before_call') {
            this.setState({ emailRequired: value })
            data = {
                name_required_before_call: this.state.nameRequired ? 1 : 0,
                live_call_embed: this.state.liveCallEmbed ? 1 : 0,
                email_required_before_call: value ? 1 : 0
            }
        } else if (key === 'live_call_embed') {
            this.setState({ liveCallEmbed: value })
            data = {
                name_required_before_call: this.state.nameRequired ? 1 : 0,
                live_call_embed: value ? 1 : 0,
                email_required_before_call: this.state.emailRequired ? 1 : 0,
            }
        }




        axios
            .post(url, data)
            .then(res => {
                this.setState({ isLoader: false })
                if (res.data) {

                    CommonNotify('Mypage setting updated', 'success')
                }
            })
            .catch(error => {
                CommonNotify('cant update mypage settings')
                this.setState({ isLoader: false })
            })
    }

    onChangeProfileData = e => {
        const { name, value } = e.target
        if (!value) {
            this.setState({ isProfileData: false })
        } else {
            this.setState({ isProfileData: true })
        }
        const { data } = this.state
        if (name === "company") {
            let company = data.company
            company["company_name"] = value
            data["company"] = company
        } else {
            data[name] = value
        }


        this.setState({ value })
    }

    onChangeUrlData = e => {
        const { value } = e.target
        if (!value) {
            this.setState({ isPersonalLink: false })
        } else {
            this.setState({ isPersonalLink: true })
        }
        this.setState({ personalUrl: value })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexs } = this.state
        const newIndex = activeIndexs
        const currentIndexPosition = activeIndexs.indexOf(index)
        if (currentIndexPosition > -1) {
            newIndex.splice(currentIndexPosition, 1)
        } else {
            newIndex.push(index)
        }

        this.setState({ activeIndexs: newIndex })
    }
    onUpdate = event => {
        event.preventDefault()


        if (this.state.personalUrl?.length > 15) {
            return (CommonNotify("The code may not be greater than 15 characters."))
        }
        this.setState({ isLoader: true })
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/personal-link`
        const data = {
            code: this.state.personalUrl
        }
        axios
            .post(url, data)
            .then(res => {
                this.setState({ isLoader: false })
                if (res.data) {
                    CommonNotify(res.data.message[0], 'success')
                    //this.getPersonalLink()

                } else {
                    CommonNotify(res.data.message[0])
                }
            })
            .catch(error => {
                if (error) {
                    CommonNotify("cant save profile data")
                    this.setState({ isLoader: false })
                }
            })
    }
    handleLinkedInToggleData = toggleData => {
        this.setState({
            ...this.state,
            isLinkedInEnable: toggleData
        })
    }
    handleTwitterInToggleData = toggleData => {
        this.setState({
            ...this.state,
            isTwitterEnable: toggleData
        })
    }

    updateCompanyData = e => {
        e.preventDefault()
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/update-profile`
        this.setState({ isLoader: true })


        const form = new FormData()
        form.append('role', this.state.role)
        form.append('designation', this.state.data.designation)
        form.append('twitter', this.state.data.twitter)
        form.append('linkedin', this.state.data.linkedin)
        form.append('bio', this.state.data.bio)
        form.append('time_zone', this.state.data.time_zone)
        form.append('full_name', this.state.data.name)
        form.append('company_name', this.state.data.company?.company_name)

        if (this.state.selectedFile) {
            form.append('profile_image', this.state.profile_pic)
        }


        axios
            .post(url, form)
            .then(res => {
                if (res.data) {
                    this.getPersonalLink()
                    this.setState({ isLoader: false })
                    CommonNotify('Profile Information Successfully Updated', 'success')
                }
            })
            .catch(error => {
                CommonNotify('error')
                this.setState({ isLoader: false })
            })
    }


    onCopyText = async jsCode => {
        try {
            await navigator.clipboard.writeText(jsCode)
            CommonNotify('Copied!', 'success')
        } catch (err) {
            CommonNotify('Failed to copy!')
        }
    }

    handleChangeStatus = ({ meta, file }, status) => {
        const { data } = this.state
        var selectedFile = ''

        if (status === 'removed') {
            selectedFile = null
        } else {
            selectedFile = file
        }

        this.setState({
            profile_pic: selectedFile,
            isProfileData: true,
            selectedFile: selectedFile
        })
    }

    onChangeSelectMeetingPlace = val => {
        this.setState({ selectedMeetingPlace: val })
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/update-profile-api`

        this.setState({ isLoader: true })


        const form = {
            "default_meeting_place": val?.name,
            "full_name": this.state.complete_name,
            'role': this.state.role,
            'designation': this.state.data.designation,
            'twitter': this.state.data.twitter,
            'linkedin': this.state.data.linkedin,
            'bio': this.state.data.bio,
            'time_zone': this.state.data.time_zone,
            'company_name': this.state.data.company_name
        }





        axios
            .post(url, form)
            .then(res => {

                this.setState({ isLoader: false })
                CommonNotify('Meeting place updated', 'success')
            })
            .catch(error => {
                CommonNotify('Cant update Meeting place')
                this.setState({ isLoader: false })
            })
    }
    onChangeSocial = e => {
        const { name, value } = e.target
        const { data } = this.state

        // data[name] = value

        this.setState({ data: { ...data, company: { ...data?.company, [name]: value } } })
    }


    render() {
        return (
            <>
                <Dimmer style={{ position: 'fixed' }} active={this.state.isLoader}>
                    <Loader />
                </Dimmer>
                <div className="link-Sharing add_personal_link" style={{padding:'50px 75px'}}>
                    <div className="share-your-link-wrapper profile_url">
                        <div className="share-your-link change-personal">
                            <Accordion>
                                <Accordion.Title
                                    active={this.state.activeIndexs.includes(1)}
                                    index={1}
                                    onClick={this.handleClick}
                                    style={{ padding: '15px' }}
                                >
                                    <p className="bold-text accordion-title">My Booking Page</p>
                                    <p className="subtext accordion-desc">
                                        Create your profile link and share it with your customer.
                                    </p>
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeIndexs.includes(1)}>
                                    <div className='personal-link-wrapper'>
                                        <div style={{ flex: 1 }}>
                                            <form method="" action="">
                                                <div className="holder-change-personal">
                                                    <div className="personal-link-container">
                                                        <Input
                                                            label={process.env.REACT_APP_BASE_APP_URL === 'https://qa.limecall.com' ? "https://qa.limecall.me/" : 'https://limecall.me/'}
                                                            onChange={this.onChangeUrlData}
                                                            value={this.state.personalUrl}
                                                            placeholder="XYZ"
                                                            name="personalUrl"
                                                        />
                                                        <Popup
                                                            content="Copy personal URL"
                                                            trigger={
                                                                <Icon
                                                                    style={{ margin: '0 28px' }}
                                                                    name="copy"
                                                                    onClick={() =>
                                                                        this.onCopyText(this.state.personalLinkCopyText)
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        <Popup
                                                            content="Re"
                                                            trigger={
                                                                <LinkIcon
                                                                    onClick={() =>
                                                                        this.onCopyText(this.state.personalLinkCopyText)
                                                                    }
                                                                />
                                                            }
                                                        />

                                                    </div>

                                                </div>

                                                {/* <div className="holder-change-personal" style={{ marginTop: "30px" }}>
                        <p className="subtext accordion-desc" style={{ marginBottom: "20px" }}>
                          Channels
                        </p>
                      </div> */}
                                                <form>

                                                    {/* <div  className="holder-input">                          
                          <div className="input-wrapper">
                              <label className="default-text input-title">Name Field Required</label>
                              <Checkbox  checked={this.state.nameRequired} onChange={(e, value) => this.postData('name_required_before_call' ,value.value ? false : true) }  style={{ marginLeft: "50px" }} toggle value={this.state.nameRequired} />
                            </div>  
                        </div> */}

                                                    {/* <div  className="holder-input">                          
                          <div className="input-wrapper">
                              <label className="default-text input-title">Email Field Required</label>
                              
                              <Checkbox  checked={this.state.emailRequired}  onChange={(e, value) => this.postData('email_required_before_call', value.value ? false : true)} style={{ marginLeft: "50px" }} toggle value={this.state.emailRequired} />
                            </div> 
                        </div> */}

                                                    {/* <div className="holder-input">
                            <div className="input-wrapper">
                              <label style={{minWidth:'150px'}} className="default-text input-title "> Live Call Embedded</label>                            
                              <Checkbox  checked={this.state.liveCallEmbed}  onChange={(e, value) => this.postData('live_call_embed', value.value ? false : true)} style={{ marginLeft: "50px" }} toggle value={this.state.liveCallEmbed} />

                            </div>
                          </div> */}
                                                    {/* <div className="holder-input">
                            <div className="input-wrapper">
                            <label style={{minWidth:'150px'}} className="default-text input-title ">Meetings Embedded</label>   
                            <Checkbox  checked={this.state.liveCallEmbed}  onChange={(e, value) => this.postData('live_call_embed', value.value ? false : true)} style={{ marginLeft: "50px" }} toggle value={this.state.liveCallEmbed} />


                            </div>
                          </div>
                          <div className="holder-input">
                            <div className="input-wrapper">
                            <label style={{minWidth:'150px'}} className="default-text input-title">Whatsapp Embedded</label>   
                            <Checkbox  checked={this.state.liveCallEmbed}  onChange={(e, value) => this.postData('live_call_embed', value.value ? false : true)} style={{ marginLeft: "50px" }} toggle value={this.state.liveCallEmbed} />
                            

                            </div>
                          </div>
                          <div className="holder-input">
                            <div className="input-wrapper">
                            <label style={{minWidth:'150px'}} className="default-text input-title">Contact Form Embedded</label>  
                            <Checkbox  checked={this.state.liveCallEmbed}  onChange={(e, value) => this.postData('live_call_embed', value.value ? false : true)} style={{ marginLeft: "50px" }} toggle value={this.state.liveCallEmbed} />


                            </div>
                          </div> */}

                                                    {/* <div  className="holder-input">                          
                          <div className="input-wrapper">
                              <label className="default-text input-title">Phone Field Required</label>
                              <div className="ui fitted toggle checkbox" style={{ marginLeft: "50px" }}>
                                  <input type="checkbox" className="hidden" readonly="" tabindex="0"/>
                                  <label></label>
                              </div> 
                            </div> 
                        </div> */}


                                                </form>

                                                {this.state.isPersonalLink && (
                                                    <div className="save-cancel">
                                                        <CommonButtons
                                                            onClick={e => this.onUpdate(e)}
                                                            type="submit"
                                                            content="Save"
                                                            background="blue"
                                                        />
                                                        <CommonButtons
                                                            onClick={e => this.cancelData()}
                                                            type="reset"
                                                            content="Cancel"
                                                            background="grey"
                                                        />
                                                    </div>
                                                )}
                                            </form>
                                            <form>

                                                {/* <div className="dropZone mt-4">
                          <div style={{ width: '200px' }}>
                            {this.state.profile_pic ? (
                              <Dropzone
                                canCancel={true}
                                canRemove={true}
                                inputContent={
                                  <img src={this.state.profile_pic} />
                                }
                                onChangeStatus={this.handleChangeStatus}
                                accept="image/*"
                                ref={this.dropzoneRef}
                              />
                            ) : (
                              <Dropzone
                                canCancel={true}
                                canRemove={true}
                                onChangeStatus={this.handleChangeStatus}
                                accept="image/*"
                                ref={this.dropzoneRef}
                              />
                            )}
                          </div>

                        
                      

                        </div> */}
                                                <div className="holder-input mt-4">
                                                    <div className='holder-inner-wrapper'>


                                                        <CommonInput
                                                            onChange={this.onChangeProfileData}
                                                            name="name"
                                                            title="Name"
                                                            required={true}
                                                            background="gray"
                                                            value={this.state.data.name ? this.state.data.name : ''}
                                                        />
                                                        <CommonInput
                                                            onChange={this.onChangeProfileData}
                                                            name="designation"
                                                            title="Designation"
                                                            required={true}
                                                            background="gray"
                                                            value={this.state.data.designation ? this.state.data.designation : ''}
                                                        />
                                                        <CommonInput
                                                            onChange={this.onChangeProfileData}
                                                            name="company"
                                                            title="Company"
                                                            required={true}
                                                            background="gray"
                                                            value={this.state.data.company ? this.state.data.company?.company_name : ''}
                                                        />


                                                        {/* <div className="linkedin__field">
                          <CommonInput
                            onChange={this.onChangeProfileData}
                            name="linkedin"
                            type="text"
                            title="LinkedIn"
                            background="gray"
                            required={true}
                            value={this.state.data.linkedin}

                          />

                        </div> */}

                                                        {/* <div className="twitter__field">
                          <CommonInput
                            onChange={this.onChangeProfileData}
                            name="twitter"
                            type="text"
                            title="Twitter"
                            background="gray"
                            required={true}
                            value={this.state.data.twitter}

                          />

                        </div> */}
                                                        <CommonTextArea
                                                            onChange={this.onChangeProfileData}
                                                            value={this.state.data.bio}
                                                            //placeholder="Custom CSS"
                                                            background="gray"
                                                            title="Bio"
                                                            name="bio"
                                                        />
                                                        <Button variant='contained' as="label" htmlFor="file" type="button">
                                                            Upload Profile Picture
                                                        </Button>
                                                        <CommonInput
                                                            id="file"
                                                            style={{ display: "none" }}
                                                            name="greeting"
                                                            inputProps={{ accept: 'audio/*;capture=microphone' }}
                                                            type="file"
                                                            onChange={(e) => this.onChangeSelectMeetingPlace(e?.target?.files?.[0])}
                                                        />
                                                        {/* <br/>  <br/> 
                          <div className="holder-input">  
                          <label className="default-text input-title">Meeting Place</label>      
                              <CommonSelect
                                title="Select Default meeting place"
                                name="dropdown"
                                placeholder={"Select Default meeting place"}
                                options={meetingPlaces}
                                isMulti={false}
                                value={this.state.selectedMeetingPlace}
                                onChange={(e, result) => this.onChangeSelectMeetingPlace(result.value)}
                                style={{ width: "300px" }}
                                />
                            </div> */}
                                                    </div>
                                                </div>
                                                {this.state.isProfileData && (
                                                    <>
                                                        <CommonButtons
                                                            onClick={this.updateCompanyData}
                                                            type="submit"
                                                            content="Save"
                                                            background="blue"
                                                        />
                                                        <CommonButtons
                                                            onClick={e => this.cancelData()}
                                                            type="reset"
                                                            content="Cancel"
                                                            background="grey"
                                                        />
                                                    </>
                                                )}
                                            </form>
                                        </div>


                                        <div className='upload-section'>

                                            <div className='img-wrapper'>
                                                <img src={personalPreview} alt="profile" />
                                            </div>

                                            <div class="input-group input-group-sm mb-3" style={{ width: '351px', marginLeft: '27px' }} >
                                                <input type="text" class="form-control" style={{ background: 'white' }} readOnly placeholder={this.props.profile?.data?.new_personal_link} aria-label="Recipient's username" aria-describedby="button-addon2" />
                                                <div class="input-group-prepend" style={{ maxHeight: '28px', height: '100%' }}>
                                                    <button class="btn btn-outline-secondary" style={{ background: 'lightGray' }} type="button" onClick={() => this.onCopyText(this.props.profile?.data?.new_personal_link)} id="button-addon2"><FileCopyIcon /></button>
                                                    <button class="btn btn-outline-primary" style={{ background: 'lightGray' }} type="button" onClick={() => window.open(this.props.profile?.data?.new_personal_link)} id="button-addon2"><LinkIcon /></button>

                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </Accordion.Content>
                            </Accordion>
                        </div>
                    </div>

                    <div className="share-your-link-wrapper profile_information">
                        <div className="share-your-link personal-info-wrapper">
                            <Accordion.Title
                                index={1}
                                onClick={this.handleClick}
                                style={{ marginBottom: '30px' }}
                            >
                                <p className="bold-text accordion-title">Social Media</p>
                                <p className="subtext accordion-desc">
                                    Enter your company’s information to help visitors recognize and connect with your brand.
                                </p>
                            </Accordion.Title>
                            <Accordion.Content>
                                <form method="" action="">
                                    <div className="holder-input">

                                        <Grid className="grid-social">
                                            <div className='d-flex align-items-center'>
                                                <CommonInput
                                                    onChange={this.onChangeSocial}
                                                    name="company_domain"
                                                    title="Website URL"
                                                    required={true}
                                                    background="gray"
                                                    value={this.state.data.company?.company_domain}
                                                />


                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <CommonInput
                                                    onChange={this.onChangeSocial}
                                                    name="company_linkedin_link"
                                                    title="LinkedIn"
                                                    required={true}
                                                    background="gray"
                                                    value={this.state.data.company?.company_linkedin_link}
                                                />


                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <CommonInput
                                                    onChange={this.onChangeSocial}
                                                    name="company_twitter_link"
                                                    title="Twitter"
                                                    required={true}
                                                    background="gray"
                                                    value={this.state.data.company?.company_twitter_link}
                                                />

                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <CommonInput
                                                    onChange={this.onChangeSocial}
                                                    name="company_facebook_link"
                                                    title="Facebook"
                                                    required={true}
                                                    background="gray"
                                                    value={this.state.data.company?.company_facebook_link}
                                                />

                                            </div>

                                            {/* <CommonInput
                        onChange={this.onChangeSocial}
                        name="companyDirectoryUrl"
                        title="Company Directory URL"
                        required={true}
                        background="gray"
                        value={this.state.data.company?.company_directory_url}
                      /> */}


                                        </Grid>
                                        <Grid className="grid-social">


                                        </Grid>

                                    </div>
                                    {true && (
                                        <>
                                            <CommonButtons
                                                onClick={this.updateCompanyData}
                                                type="submit"
                                                content="Save"
                                                background="blue"
                                            />
                                            <CommonButtons
                                                onClick={() => this.getCompanyData(this.props.profile)}
                                                type="reset"
                                                content="Cancel"
                                                background="grey"
                                            />
                                        </>
                                    )}
                                </form>
                            </Accordion.Content>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.settings.profile,
});

export default connect(
    mapStateToProps,
    { getProfile }
)(MeetingPageDesigner)
