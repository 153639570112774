import axios from 'axios'
import React, { useEffect, useState } from 'react'
import StarRatings from 'react-star-ratings'
import { Grid, Label, Menu, Popup } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

// import editIcon from '../../../assets/images/edit-icon.svg'
import { CommonNotify } from '../../../common/CommonNotify'
import { useGetContactsQuery } from '../../../features/api/apiSlice'
import EditDetailOpen from '../../contactsPanels/editDetailsPopUp'
import QualifyFooter from './QualifyFooter'
import BlockIcon from '@mui/icons-material/Block'
import editLead from '../../../assets/images/Dashboard-35.png'
import reschedule from '../../../assets/images/reschedule.png'
import UpdateLeadModal from '../../../common/UpdateLeadModal'
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment'
import Reschedule from '../../../common/reScheduleModal'
import Axios from 'axios'
import DownloadIcon from '@mui/icons-material/Download';
import unlike from '../../../assets/images/unlike.png'
import { Tooltip } from '@mui/material'

const intialEditData = {
  name: '',
  phone_number: '',
  phone_number: '',
  email: '',
  country: '',
  type: '',
  city: '',
  owner_id: '',
  owner_name: '',
  first_name: '',
  organization_name: ''
}
const LeadAboutRight = ({ leadData, leadScore, handleReGetValue, leadInterest, handleItemClick,setRejectModal,leadAbout, changesInterested, isSpan, qualify }) => {
  const resContacts = useGetContactsQuery({ date: 'all', type: 'all' })
  const [companyData, setCompanyData] = useState()
  const [leadUpdated, setLeadUpdated] = useState(true)
  const [editContactModal, setEditContactModal] = useState(false)
  const [dropdownValue, setDropdownValue] = useState(null)
  const [leadDetail, setLeadDetail] = useState({
    message: leadData?.lead?.messages_within_call,
    email: leadData?.lead?.email,
    name: leadData?.lead?.customer_name
  })
  const [editContact, setEditContact] = useState(intialEditData)
  const [openLeadModal, setOpenLeadModal] = useState(false)
  const [openReschedule, setOpenReschedule] = useState(false)

  useEffect(() => {
    const widgetData = sessionStorage.getItem('widget')
    if (!widgetData) {
      getWidget()
    }
  }, [])

  useEffect(() => {
    if (openLeadModal && !dropdownValue?.length) {
      getIntrest()
    }
  }, [openLeadModal])

  useEffect(() => {
    if (resContacts.isSuccess) {
      let contactData = resContacts?.data?.data
      const responseCompany = contactData?.filter(contact => {
        return contact.type == 'organization'
      })
      setCompanyData(responseCompany)
    }
  }, [resContacts])

  useEffect(() => {
    let lUpdated = !leadUpdated
    setLeadUpdated(lUpdated)
  }, [leadData])

  const handleCancel =async(second) => { 

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadData.lead.id}/cancel`
    const res = await Axios.post(url)

 }


  const getIntrest = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    axios
      .get(url)
      .then(res => {
        const setInterestedData = res.data.data[0].interested
        const updateLeadInterest = res.data.data[0].interested.map(
          (item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          }
        )
        setDropdownValue({
          interestData: [...updateLeadInterest],
          interestedData: [...setInterestedData]
        })
      })
      .catch(err => {})
  }

  const getWidget = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    axios
      .get(url)
      .then(res => {
        let tempObject = {
          id: res.data.data[0].id,
          script: res.data.data[0].script_id,
          business_hours_status: res.data.data[0].business_hours_status
        }
        localStorage.setItem('widget_id', res?.data?.data?.[0]?.id)
        localStorage.setItem('widget_active', res.data.data[0].active)
        sessionStorage.setItem('widget', JSON.stringify(tempObject))
        const setInterestedData = res.data.data[0].interested
        const updateLeadInterest = res.data.data[0].interested.map(
          (item, index) => {
            return {
              key: index,
              text: item,
              value: item
            }
          }
        )
        setDropdownValue({
          interestData: [...updateLeadInterest],
          interestedData: [...setInterestedData]
        })
      })
      .catch(err => {})
  }

  const trimUrl = (url = '') => {
    if (!url || url == '') return ''
    const newUrl = url.split('/', 3)
    if (!url?.length) return ''

    url = newUrl[newUrl?.length - 1]
    return url
  }

  // const fetchContactByIds = id => {
  //   if (id) {
  //     const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/${id}`
  //     axios
  //       .get(url)
  //       .then(res => {
  //         setEditContact({
  //           ...editContactModal,
  //           name: res?.data?.data?.name,
  //           email: res?.data?.data?.email,
  //           phone_number: res?.data?.data?.phone_number,
  //           owner_id: res?.data?.data?.owner?.id
  //             ? res?.data?.data?.owner?.id
  //             : 0,
  //           owner_name:
  //             res?.data?.data?.owner?.first_name +
  //             ' ' +
  //             res?.data?.data?.owner?.last_name
  //               ? res?.data?.data?.owner?.first_name +
  //                 ' ' +
  //                 res?.data?.data?.owner?.last_name
  //               : '',
  //           country: res?.data?.data?.country,
  //           type: res?.data?.data?.type,
  //           city: res?.data?.data?.city,
  //           first_name: res?.data?.data?.owner?.first_name,
  //           organization_name: res?.data?.data?.organization_name
  //             ? res?.data?.data?.organization_name
  //             : 'test'
  //         })
  //       })
  //       .catch(error => {
  //         CommonNotify(
  //           error?.response?.data?.errors?.[0] || 'something went to wrong!'
  //         )
  //       })
  //   }
  // }

  const handleInputChange = (event, code, type) => {
    if (type === 'phone_number') {
      setEditContact({
        ...editContact,
        phone_number: `+${event}`,
        phone_country: code.countryCode
      })
    } else {
      const { name, value } = event.target
      editContact[name] = value
      setEditContact(prevContactData => ({
        ...prevContactData,
        [name]: value
      }))
    }
  }

  const onclickOwner = () => {
    let retData = editContact
    if (editContact.owner_id == 0) {
      delete editContact.owner_id
      delete editContact.owner_name
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/update/${leadData?.lead?.contact?.id}`
    axios
      .post(url, retData)
      .then(res => {
        CommonNotify('Contact successfully updated!', 'success')
        setEditContactModal(false)
        setEditContact(intialEditData)
      })
      .catch(error => {
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Cant update contact!'
        )
      })
  }

  const handleBlock = value => {
    if (value) {
      const data = {
        block_reason: 'spammer',
        widget_id: localStorage.getItem('widget_id'),
        ip: value
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/blacklist-user`
      axios
        .post(url, data)
        .then(res => {
          CommonNotify('User IP Added to BlockList', 'success')
        })
        .catch(error => {
          CommonNotify(error?.response?.data?.errors?.[0], 'warning')
        })
    } else {
      CommonNotify("IP address is not available so, We can't block this IP")
    }
  }

  const handleEmailBlock = value => {
    if (value) {
      const data = {
        block_reason: 'spammer',
        widget_id: localStorage.getItem('widget_id'),
        email: value
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/blacklist-user`
      axios
        .post(url, data)
        .then(res => {
          CommonNotify('User Email Added to BlockList', 'success')
        })
        .catch(error => {
          CommonNotify(error?.response?.data?.errors?.[0], 'warning')
        })
    } else {
      CommonNotify(
        "Email address is not available so, We can't block this Email"
      )
    }
  }

  const onUpdateRequest = async data => {
    if (leadData?.lead?.id) {
      if (data.phone_number) {
        if (data.phone_number?.length < 10) {
          return CommonNotify(
            'Validation error invalid phone number',
            'warning'
          )
        }
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/${leadData?.lead?.id}/info`
      const postData = {
        customer_name: data.customer_name,
        phone_number: data.phone_number,
        email: data.email,
        notes: data.interest,
        country: data.country,
        role: data.role,
        company_name: data.company,
        industry: data.industry,
        employees: data.companySize,
        lead_interest: data.lead_interest
      }
      axios
        .post(url, postData)
        .then(res => {
          CommonNotify('Lead info updated', 'success')
          setOpenLeadModal(false)
          handleReGetValue()
        })
        .catch(err => {
          CommonNotify(
            err?.response?.data?.errors?.[0] || 'Cant Update Lead Detail'
          )
        })
    }
  }

  const leads = leadData?.lead
  const message = leadDetail?.message
    ? JSON?.parse(leadDetail?.message)?.[0]?.message
    : leadDetail?.message
    
    const getFileName = (path) => { 
     const lastIndex= path.lastIndexOf('/')
      const textAfterLastSlash = path.substring(lastIndex + 1)
      return textAfterLastSlash
     }

  return (
    <div className="contact-card aboutTabpane">
      {/* <div >
      <div
        className="lead_page"
        style={{
          backgroundColor: 'transparent',
          padding: '0px 32px'
        }}
      >
        <div className="main_wrapper">
          <div className="good-fit-menu">
            <div className="titleCall"></div>

            <Label
              style={{
                fontWeight: 300,
                color: 'black',
                backgroundColor: '#ffffff',
                borderColor: '#9a9393',
                marginRight: 20
              }}
            >
              Qualified?
            </Label>

            <Menu>
              <Popup
                content="Mark as Qualified"
                trigger={
                  <Menu.Item
                    name="1"
                    active={leadInterest == '1'}
                    onClick={(e, name) =>
                      handleItemClick(e, { name }, 1)
                    }
                  >
                    <i className="fa fa-check"></i>
                  </Menu.Item>
                }
              />

              <Popup
                content="Not Reviewed"
                trigger={
                  <Menu.Item
                    name="2"
                    active={leadInterest == '2'}
                    onClick={(e, name) =>
                      handleItemClick(e, { name }, 2)
                    }
                  >
                    <i className="fa fa-question"></i>
                  </Menu.Item>
                }
              />
              <Popup
                content="Mark as Not Qualified"
                trigger={
                  <Menu.Item
                    name="3"
                    active={leadInterest == '3'}
                    onClick={
                      // (e, name) => handleItemClick(e, { name }, 3)
                      () => setRejectModal(true)
                    }
                  >
                    <i className="fa fa-times"></i>
                  </Menu.Item>
                }
              />
            </Menu>
          </div>
        </div>
      </div>  
      </div> */}
      {/* <div className="detail-title-icon" style={{ textAlign: 'center' }}>
        <div className="favorite-section" style={{ display: 'flex' }}>
          {(leadAbout?.interseted !== 3 && leadAbout?.interseted !== 1 )?


        <Tooltip title={leadAbout?.interseted === 3 ? 'Set back to pending' : 'Mark as qualified'}>

          <button
            className={isSpan() ? 'active common-btn ' : 'common-btn'}

            onClick={e => {
              changesInterested(1)
            }}
          >
            <img src={qualify} className="mr-2" />
            {leadAbout?.interseted === 3 ? 'Undo Bad Fit' : 'Good Fit'}
          </button>
        </Tooltip>
        : null}

          {leadAbout?.interseted !== 3 ?
            <Tooltip title='Disqualify lead'>

              <button
                className={'common-btn'}

                onClick={e => {
                  changesInterested(leadAbout?.interseted === 3 ? 2 :3)
                }}
              >
                <img src={unlike} className="mr-2" />

                Bad Fit
              </button>
            </Tooltip>
            : null} 
        </div>
        </div> */}
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Lead Form :</h4>
          {leadDetail.name ? (
            <Grid.Row>
              <Grid.Column width={4}>
                <p className="card-boxes-left-text">Name:</p>
              </Grid.Column>
              <Grid.Column width={12}>
                <p className="card-boxes-right-text">{leadDetail.name}</p>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          {leadDetail.email ? (
            <Grid.Row>
              <Grid.Column width={4}>
                <p className="card-boxes-left-text">Email:</p>
              </Grid.Column>
              <Grid.Column width={12}>
                <p title="Block user email" className="card-boxes-right-text">
                  {leadDetail?.email}

                  {leadDetail?.email && leadDetail?.email !== 'undefined' && (
                    <BlockIcon
                      className="cursor-pointer ml-2"
                      onClick={() => handleEmailBlock(leadDetail.email)}
                    />
                  )}
                </p>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          {leadData?.lead?.widget_message || message ? (
            <Grid.Row>
              <Grid.Column width={4}>
                <p className="card-boxes-left-text">Message:</p>
              </Grid.Column>
              <Grid.Column width={12}>
                <p className="card-boxes-right-text">
                  {leadData?.lead?.widget_message || ''}{' '}
                </p>
                <p className="card-boxes-right-text">{message || ''} </p>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          {leads?.referral ? (
            <Grid.Row>
              <Grid.Column width={4}>
                <p className="card-boxes-left-text">Referral:</p>
              </Grid.Column>
              <Grid.Column width={12}>
                <a
                  className="card-boxes-right-text"
                  href={'https://' + leads?.referral}
                  target="_blank"
                >
                  <p className="card-boxes-right-text text-truncate">
                    {leads.referral}{' '}
                  </p>
                </a>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          {leadDetail?.company ? (
            <Grid.Row>
              <Grid.Column width={4}>
                <p className="card-boxes-left-text">Company:</p>
              </Grid.Column>
              <Grid.Column width={12}>
                <p className="card-boxes-right-text">
                  {leadDetail?.company || ''}{' '}
                </p>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          {leadDetail?.companySize ? (
            <Grid.Row>
              <Grid.Column width={4}>
                <p className="card-boxes-left-text">Company Size:</p>
              </Grid.Column>
              <Grid.Column width={12}>
                <p className="card-boxes-right-text">
                  {leadDetail?.companySize || ''}{' '}
                </p>
              </Grid.Column>
            </Grid.Row>
          ) : null}
        {leads?.files?.length ?    <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">File Attachment:</p>
            </Grid.Column>
            <Grid.Column width={12} style={{height:'50%'}}>

              {leads?.files?.map((obj)=>
              <div style={{marginRight:'10px'}}>
                  <Link to={obj?.path} >{getFileName(obj?.path)} <DownloadIcon/></Link>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>: null}
      
        </Grid>
      </div>{' '}
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Call Data:</h4>
          {/* {leadData?.lead?.contact?.id && (
            <img
              src={editIcon}
              onClick={d => {
                setEditContactModal(true)
                fetchContactByIds(leadData?.lead?.contact?.id)
              }}
            />
          )} */}
          <a onClick={e => setOpenLeadModal(true)}>
            <img className="edit-icon-position" src={editLead} />
          </a>
          <a onClick={e => setOpenReschedule(true)}>
            <img
              className="edit-icon-position"
              height={20}
              width={20}
              src={reschedule}
            />
          </a>
          <a onClick={e => handleCancel()}>
            <CancelIcon className="edit-icon-position" />
          </a>
          {leadData?.lead?.type === 'SCHEDULE_CALL' ? (
            <>
              <Grid.Row>
                <Grid.Column width={4}>
                  <p className="card-boxes-left-text">Agent:</p>
                </Grid.Column>
                <Grid.Column width={12}>
                  <p className="card-boxes-right-text">
                    {leadData?.lead?.agent}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </>
          ) : null}
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">IP:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text" title="Block IP address">
                {`${leadData?.lead?.ip_address} : ${leadData?.ip_country}`}{' '}
                {leadData?.lead?.ip_address && (
                  <BlockIcon
                    className="cursor-pointer ml-2"
                    onClick={() => handleBlock(leadData?.lead?.ip_address)}
                  />
                )}
              </p>
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Initiated Time:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.createdAtDate} {leads?.createdAtTime}{' '}
                {moment.tz.guess()}
              </p>
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Timezone:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">UTC</p>
            </Grid.Column>
          </Grid.Row> */}
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Last interaction:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.trackingData?.last_interaction || '-'}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Time on site:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.trackingData?.time_on_site || '-'}
              </p>
            </Grid.Column>
          </Grid.Row> */}
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Call Type:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">{leads?.type || '-'}</p>
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Status:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p>
                {' '}
                {leads?.final_status == 'failed_to_connect_agent' ? (
                  <p>Failed to Connect</p>
                ) : null}
                {leads?.final_status == 'no_agents_available' ? (
                  <p>No Agents Available</p>
                ) : null}
                {leads?.final_status == 'completed' ? (
                  <p>connected</p>
                ) : (
                  leads?.final_status
                )}
                {leads?.duration > 0 && `${leads?.duration} Sec`}
              </p>
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Duration:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.duration > 0 ? 60 * leads?.duration : 0} Sec
              </p>
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Recording :</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.recording_url ? (
                  <audio controls>
                    <source src={leads?.recording_url} type="audio/mp3" />
                  </audio>
                ) : (
                  <p>Not Available</p>
                )}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Team:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">{leads?.team}</p>
            </Grid.Column>
          </Grid.Row>{' '}
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Handled by:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {' '}
                {leads?.owner?.first_name} {'  '}
                {leads?.owner?.last_name}
                {!leads?.owner ? 'Not Assigned' : ''}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">File Attachment:</p>
            </Grid.Column>
            <Grid.Column width={12} style={{ height: '50%' }}>
              {leads?.files?.map(obj => (
                <div style={{ marginRight: '10px' }}>
                  <Link to={obj?.path}>
                    {getFileName(obj?.path)}
                    <DownloadIcon />
                  </Link>
                </div>
              ))}
            </Grid.Column>
          </Grid.Row>{' '}
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Landing Page</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <a href={'https://' + leads?.first_source} target="_blank">
                {trimUrl(leads?.first_source)}
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Source</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <a href={'https://' + leads?.source} target="_blank">
                {trimUrl(leads?.source)}
              </a>
            </Grid.Column>
          </Grid.Row> */}
        </Grid>
      </div>{' '}
      <div className="contact-card-boxes">
        <Grid className="card-boxes-text">
          <h4>Lead Data:</h4>
          {/* {leadData?.lead?.contact?.id && (
            <img
              src={editIcon}
              onClick={d => {
                setEditContactModal(true)
                fetchContactByIds(leadData?.lead?.contact?.id)
              }}
            />
          )} */}
          <a onClick={e => setOpenLeadModal(true)}>
            <img className="edit-icon-position" src={editLead} />
          </a>
          <a onClick={e => setOpenReschedule(true)}>
            <img
              className="edit-icon-position"
              height={20}
              width={20}
              src={reschedule}
            />
          </a>
          <a onClick={e => handleCancel()}>
            <CancelIcon className="edit-icon-position" />
          </a>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Lead Id:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">{leadData?.lead?.id}</p>
            </Grid.Column>
          </Grid.Row>
          {leadData?.lead?.type === 'SCHEDULE_CALL' ? (
            <>
              <Grid.Row>
                <Grid.Column width={4}>
                  <p className="card-boxes-left-text">Agent:</p>
                </Grid.Column>
                <Grid.Column width={12}>
                  <p className="card-boxes-right-text">
                    {leadData?.lead?.agent}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <p className="card-boxes-left-text">Meeting:</p>
                </Grid.Column>
                <Grid.Column width={12}>
                  <p className="card-boxes-right-text">
                    {leadData?.lead?.request_call_time
                      ? moment(leadData?.lead?.request_call_time)
                          // .utc(leadData?.lead?.request_call_time)
                          .format('dddd, MMMM, DD, YYYY')
                      : ''}

                    <br></br>

                    {leadData?.lead?.request_call_time
                      ? moment
                          .utc(leadData?.lead?.request_call_time)
                          .local()
                          // .utc(leadData?.lead?.request_call_time)
                          .format('hh:mm A') +
                        ' ' +
                        moment.tz.guess()
                      : // ' to ' +
                        // moment(leadData?.lead?.request_call_time)
                        //   // .utc(leadData?.lead?.request_call_time)
                        //   .format('hh:mm A')
                        ''}
                    <br></br>

                    {leadData?.lead?.meeting_location}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <p className="card-boxes-left-text">Final Status:</p>
                </Grid.Column>
                <Grid.Column width={12}>
                  <p className="card-boxes-right-text">
                    {leadData?.lead?.final_status
                      ? `${leadData?.lead?.final_status}`
                      : '-'}
                    <br></br>
                  </p>
                </Grid.Column>
              </Grid.Row>
            </>
          ) : null}
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">IP:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text" title="Block IP address">
                {`${leadData?.lead?.ip_address} : ${leadData?.ip_country}`}{' '}
                {leadData?.lead?.ip_address && (
                  <BlockIcon
                    className="cursor-pointer ml-2"
                    onClick={() => handleBlock(leadData?.lead?.ip_address)}
                  />
                )}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Initiated Time:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.createdAtDate} {leads?.createdAtTime}{' '}
                {moment.tz.guess()}
              </p>
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Timezone:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">UTC</p>
            </Grid.Column>
          </Grid.Row> */}
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Last interaction:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.trackingData?.last_interaction || '-'}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Time on site:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.trackingData?.time_on_site || '-'}
              </p>
            </Grid.Column>
          </Grid.Row> */}
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Call Type:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">{leads?.type || '-'}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Status:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              {' '}
              {leads?.final_status == 'failed_to_connect_agent' ? (
                <p>Failed to Connect</p>
              ) : null}
              {leads?.final_status == 'no_agents_available' ? (
                <p>No Agents Available</p>
              ) : null}
              {leads?.final_status == 'completed' ? (
                <p>connected</p>
              ) : (
                leads?.final_status
              )}
            </Grid.Column>
          </Grid.Row> */}
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Duration:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.duration > 0 ? 60 * leads?.duration : 0} Sec
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Recording :</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {leads?.recording_url ? (
                  <audio controls>
                    <source src={leads?.recording_url} type="audio/mp3" />
                  </audio>
                ) : (
                  <p>Not Available</p>
                )}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Team:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">{leads?.team}</p>
            </Grid.Column>
          </Grid.Row>{' '}
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Handled by:</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <p className="card-boxes-right-text">
                {' '}
                {leads?.owner?.first_name} {'  '}
                {leads?.owner?.last_name}
                {!leads?.owner ? 'Not Assigned' : ''}
              </p>
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Landing Page</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <a href={'https://' + leads?.first_source} target="_blank">
                {trimUrl(leads?.first_source)}
              </a>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <p className="card-boxes-left-text">Source</p>
            </Grid.Column>
            <Grid.Column width={12}>
              <a href={'https://' + leads?.source} target="_blank">
                {trimUrl(leads?.source)}
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>{' '}
      <QualifyFooter
        leadUpdated={leadUpdated}
        leadData={leadData.lead}
        leadScore={leadScore}
      />
      {editContactModal && (
        <EditDetailOpen
          companyList={companyData}
          setDetailOpen={setEditContactModal}
          editDetailOpen={editContactModal}
          handleInputChange={handleInputChange}
          editContact={editContact}
          setEditContact={setEditContact}
          onclickOwner={onclickOwner}
        />
      )}
      {openLeadModal && (
        <UpdateLeadModal
          isModalClose={() => setOpenLeadModal(false)}
          isModalOpen={() => setOpenLeadModal(true)}
          open={openLeadModal}
          leadData={leads}
          onUpdateRequest={onUpdateRequest}
          leadId={leadData?.lead?.id}
          interested={
            (dropdownValue?.interestedData &&
              dropdownValue?.interestedData?.length &&
              dropdownValue?.interestedData) ||
            []
          }
          lead_interest={leads?.lead_interest}
        />
      )}
      {openReschedule && (
        <Reschedule
          {...{
            open: true || openReschedule,
            isModalClose: () => setOpenReschedule(!openReschedule),
            id: leadData?.lead?.id
          }}
        />
      )}
    </div>
  )
}

export default LeadAboutRight
