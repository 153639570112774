import axios from 'axios'
import React, { Component, Fragment } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import { Button, Input, Label } from 'semantic-ui-react'
import countryList from '../lib/Country'
import { CommonNotify } from './CommonNotify'
import Toggle from './CommonToggleClass1'
import SaveIcon from '@mui/icons-material/Save'
import { analytics } from '../Segmant'
const AppMobile = ({ Number }) => (
  <div className="phone-number-wrapper">
    <Label color="green">Phone</Label>
    <Input value={Number} readOnly />
    {/* <i className="fas fa-info-circle"></i> */}
  </div>
)

const AppWeb = () => (
  <div className="phone-number-wrapper">
    <Label color="blue">WEB</Label>
    <p className="default-text">Receive Calls in Browser</p>
  </div>
)

const AppMobileApp = () => (
  <div className="phone-number-wrapper">
    <Label color="green">Mobile</Label>
    <p className="default-text">Receive Calls in App</p>
  </div>
)

const Sip = ({ textTitle, Number, onUpdateSip, updateSip }) => {
  return (
    <div className="phone-number-wrapper">
      <Label onClick={updateSip} color="orange">
        {textTitle ? 'UPDATE' : 'SIP'}
      </Label>
      <Input
        defaultValue={Number}
        onChange={e => onUpdateSip(e.target.value)}
      />
      {/* <p className="default-text">{Number}</p> */}
    </div>
  )
}
let values = {}
class CallFowardingToggle extends Component {
  state = {
    sipValue: '',
    sipStatus: false,
    activeToggle: false,
    toggleRef: this.props.dataToggle.forwardRef,
    countryName: '',
    whiteList: [],
    web: false,
    sip: false,
    phone: false,
    webTime: 0,
    sipTime: 0,
    phoneTime: 0,
    timeout: false,
    save: false,
    timeOutValue: {}
  }

  componentDidMount = () => {
    this.statusUpdate()
    // this.getGeoInfo()
    if (this.props?.dataToggle?.type === 'select') {
      const availableFlag = this.props?.whitelist.find(
        name => name === 'United States'
      )
      if (availableFlag) {
        this.setState({ countryName: 'us' })
      }
    }
    const items = this.props?.whitelist
    if (this.props.whitelist?.length) {
      let list = countryList
        ?.filter(function(c) {
          return items?.includes(c.name)
        })
        .map(function(c2) {
          return c2.code.toLowerCase()
        })
      this.setState({ whiteList: list })
    }
  }

  statusUpdate = () => {
    if (this.props?.timeout) {
      this.setState({
        web: this.props?.web || false,
        sip: this.props?.sip || false,
        phone: this.props?.phone || false,
        timeout: true,
        timeOutValue: this.props?.value || {},
        webTime: this.props?.web
          ? this.props?.value?.browser_ringing_timeout
          : 0,
        sipTime: this.props?.sip
          ? this.props?.value?.sip_ringing_timeout
          : 0,
        phoneTime: this.props?.phone ? this.props?.value?.ringing_timeout : 0
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props?.value !== prevProps.value) {
      this.statusUpdate()
    }
  }

  onUpdateSip = value => {

    this.setState({ sipValue: value, sipStatus: true })
  }

  updateSip = () => {
    const sip = this.state.sipValue

    if (this.state.sipStatus === false) {
      return
    }

    if (sip.toLowerCase().includes('sip:')) {

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/sip`

      axios
        .put(url, { sip })
        .then(response => {
          this.setState({ sipStatus: false })
        })
        .catch(error => {})
    } else {
      CommonNotify('Please enter a valid SIP address')
    }
  }

  getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then(response => {
        let data = response.data
        const countryName = data.country.toLowerCase()
        this.setState({
          countryName: countryName
        })
      })
      .catch(error => {})
  }

  handleToggleData = toggleData => {
    this.setState({ activeToggle: toggleData }, () => {
      return this.props.handleDataRef(
        this.state.activeToggle,
        this.state.toggleRef,
        this.props.number
      )
    })
  }

  handleToggleData2 = toggleData => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/sip-status`

    axios
      .put(url, { sip_status: toggleData ? 1 : 0 })
      .then(response => {
        analytics.track('Call Forwarding setup')
        
      })
      .catch(error => {})
  }

  handleTimeout = (name, value) => {
    values[name] = +value ? (+value > 0 ? +value : 0) : 0
    values[name + 'API'] = true
    this.setState({
      ...this.state,
      ...values,
      save: true
    })
  }

  handleSave = async type => {
    let url = ''
    switch (type) {
      case 'webTime':
        url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/browser-ringing-timeout`
        axios
          .put(url, {
            browser_ringing_timeout_status: 1,
            browser_ringing_timeout: +this.state.webTime || 60
          })
          .then(response => {
            CommonNotify('Updated Successfully')
            this.props.refresh()
            this.setState({
              ...this.state,
              [type + 'API']: false,
              save: false
            })
          })
          .catch(error => {
            CommonNotify(error?.response?.data?.errors?.[0])
          })
        break
      case 'sipTime':
        url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/sip-ringing-timeout`
        axios
          .put(url, {
            sip_ringing_timeout_status: 1,
            sip_ringing_timeout: +this?.state?.sipTime || 60
          })
          .then(response => {
            CommonNotify('Updated Successfully')
            this.props.refresh()
            this.setState({
              ...this.state,
              [type + 'API']: false,
              save: false
            })
          })
          .catch(error => {
            CommonNotify(error?.response?.data?.errors?.[0])
          })
        break
      case 'phoneTime':
        url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state?.timeOutValue?.id}/ringing-timeout`
        axios
          .put(url, {
            ringing_timeout_status: 1,
            ringing_timeout: +this?.state?.phoneTime || 60
          })
          .then(response => {
            CommonNotify('Updated Successfully')
            this.props.refresh()
            this.setState({
              ...this.state,
              [type + 'API']: false,
              save: false
            })
          })
          .catch(error => {
            CommonNotify(error?.response?.data?.errors?.[0])
          })
        break

      default:
        break
    }
  }

  render() {
    const {
      dataToggle,
      onChangeCountry,
      dataNumber,
      noToggle = false,
      isButton,
      addNumforVerify,
      addNumberLoader,
      whitelist,
      countryName,
      active
    } = this.props

    return (
      <div className="toogle-forwading-wrapper">
        <div className="input-contact">
          {dataToggle.forwardingTitle ? (
            <Fragment>
              <div className="forwarding-title-holder">
                <p className="forwarding-title">
                  {dataToggle.forwardingTitle[0]}
                </p>
              </div>
            </Fragment>
          ) : (
            ''
          )}
          {dataToggle.type === 'select' ? (
            <Fragment>
              <div
                className="input-select-wrapper input-wrapper"
                style={{ marginTop: '0 !important' }}
              >
                {this.state.whiteList?.length && (
                  <ReactPhoneInput
                    className="text-color"
                    country={
                      this.state.countryName ||
                      this.props?.dataToggle?.type === 'select'
                        ? 'us'
                        : this.state.whiteList?.[0] || 'us'
                    }
                    value={dataNumber}
                    onlyCountries={this.state.whiteList}
                    onChange={onChangeCountry}
                    placeholder="+1 (702) 123-4567"
                  />
                )}
                <Button
                  loading={addNumberLoader}
                  onClick={() => addNumforVerify()}
                >
                  Verify
                </Button>
              </div>
            </Fragment>
          ) : (
            <Fragment>
            
              {
                
                  dataToggle.phoneValue === 'mobileNewApp' ? (
                <AppMobileApp Number={this.props.number} />
                ):
                dataToggle.phoneValue === 'mobile' ? (
                <AppMobile Number={this.props.number} />
              )
               : dataToggle.phoneValue === 'web' ? (
                <>
                <AppWeb />
            
           
                </>
              ) : (
                <Sip
                  textTitle={this.state.sipStatus}
                  Number={dataToggle.number}
                  onUpdateSip={this.onUpdateSip}
                  updateSip={this.updateSip}
                />
              )}
            </Fragment>
          )}
        </div>

        <div className="toggle-enable-holder">
          {dataToggle.forwardingTitle ? (
            <Fragment>
              <div className="forwarding-title-holder">
                <p className="forwarding-title">Timeout(Sec)</p>
              </div>
            </Fragment>
          ) : (
            ''
          )}
          {this.state?.timeout &&
            (this.state?.phone ? (
              <div style={{display:"flex", alignItems:"center" , justifyContent:"space-between"}}>
                <Input
                  type="number"
                  style={{ width: '80px' }}
                  value={this.state?.phoneTime || 0}
                  onChange={e =>
                    this.handleTimeout('phoneTime', e?.target?.value)
                  }
                ></Input>
                {this.state?.phoneTimeAPI && (
                  <SaveIcon
                    className="cursor-pointer"
                    onClick={() => this.handleSave('phoneTime')}
                  />
                )}
              </div>
            ) : this.state?.sip ? (
              <div style={{display:"flex", alignItems:"center" , justifyContent:"space-between"}}>
                <Input
                  type="number"
                  style={{ width: '80px' }}
                  value={this.state?.sipTime || 0}
                  onChange={e =>
                    this.handleTimeout('sipTime', e?.target?.value)
                  }
                />
                {this.state?.sipTimeAPI && (
                  <SaveIcon
                    className="cursor-pointer"
                    onClick={() => this.handleSave('sipTime')}
                  />
                )}
              </div>
            ) : this.state?.web ? (
              <>
              
              <div style={{display:"flex", alignItems:"center" , justifyContent:"space-between"}}>
                <Input
                  type="number"
                  style={{ width: '80px' }}
                  value={this.state?.webTime || 0}
                  onChange={e =>
                    this.handleTimeout('webTime', e?.target?.value)
                  }
                />
                {this.state?.webTimeAPI && (
                  <SaveIcon
                    className="cursor-pointer"
                    onClick={() => this.handleSave('webTime')}
                  />
                )}
              </div>
              </>
            ) : null)}
        </div>

        <div className="toggle-enable-holder">
          {dataToggle.forwardingTitle ? (
            <Fragment>
              <div className="forwarding-title-holder">
                <p className="forwarding-title">
                  {dataToggle.forwardingTitle[2]}
                  <i className="fas fa-info-circle"></i>
                </p>
              </div>
            </Fragment>
          ) : (
            ''
          )}

          {noToggle == false && (
            <Toggle
              handleToggleData={this.handleToggleData}
              dataToggleActive={this.state.activeToggle}
              dataStateToggle={dataToggle}
              activeDefault={this.props.active}
            />
          )}

          {noToggle == true && (
            <Toggle
              handleToggleData={this.handleToggleData2}
              dataToggleActive={this.state.activeToggle}
              dataStateToggle={dataToggle}
              activeDefault={this.props.active}
            />
          )}
        </div>
      </div>
    )
  }
}

export default CallFowardingToggle
