import React, { Component } from 'react'
import { Tab } from 'semantic-ui-react'
import { Dimmer, Loader } from 'semantic-ui-react'
import Title from '../common/Title'
import leadLogo from '../assets/images/lead-logo.svg'
import CallerIdMeets from '../components/meets/CallerIdMeets'
import CommonInput from '../common/CommonInput'
import { TimeInput } from 'semantic-ui-calendar-react'
import axios from 'axios'
import { CommonNotify } from '../common/CommonNotify'
import DatePicker from 'react-date-picker'
import moment from 'moment'

const DropDown = ({ selectedValue, disabled, options, onChange }) => {
  return (
    <select onChange={onChange} disabled={disabled}>
      {options.map(o => (
        <option value={o} selected={o == selectedValue}>
          {o}
        </option>
      ))}
    </select>
  )
}

class Meets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      numbers: [
        'Now',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ], // 3 should disable the second dropdown
      names: ['john', 'jane', 'eric'],
      selectedNumber: 'Now',
      selectedName: '',
      fromTime: '',
      setOfficeHour2: {
        addOfficeHour2: '',
        link: 'Link',
        officeHourFrom: '',
        officeHourTo: ''
      },
      addOfficeHour2: [1],
      activeIndex: 0,
      countryInfo: {},
      date: new Date(),
      currentDate: new Date(),
      nameOfCaller: '',
      isLoading: false
    }
  }

  componentDidMount = () => {
    document.title = 'Meets'
  }
  onNumbersChange(e) {
    this.setState({ selectedNumber: e.target.value })
  }

  onNamesChange(e) {
    this.setState({ selectedName: e.target.value })
  }

  onChangeCountry = (value, country) => {
    this.setState({ numberAppNumber: value, countryInfo: country })
  }

  handleRangeChange = e => this.setState({ activeIndex: e.target.value })
  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })

  cloneSetHoursWrapper2 = () => {
    const currentDiv2 = this.state.addOfficeHour2
    currentDiv2.push(1)
    this.setState({ addOfficeHour2: currentDiv2 })
  }

  onClickAddOfficeHour2 = () => {
    const { setOfficeHour2 } = this.state
    const link = this.state.setOfficeHour2.link
    const officeHourFrom = this.state.setOfficeHour2.officeHourFrom
    const officeHourTo = this.state.setOfficeHour2.officeHourTo
    const addOfficeHour2 = 'addOfficeHour2'
    const addOfficeHourItem2 = link + ' ' + officeHourFrom + ' ' + officeHourTo

    setOfficeHour2[addOfficeHour2] = addOfficeHourItem2

    this.setState({ setOfficeHour2 })

    this.cloneSetHoursWrapper2()
  }

  onClickRemoveOfficeHours2 = () => {
    const currentDiv2 = this.state.addOfficeHour2
    currentDiv2.pop()
    this.setState({ addOfficeHour2: currentDiv2 })
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value })
    }
  }

  onChangeInput = e => {
    this.setState({ nameOfCaller: e.target.value })
  }
  setDate = (date, e) => {
    this.setState({
      date: date
    })
  }
  startCall = async () => {
    const apiToken = await localStorage.getItem('access_token')

    const data = {
      phone: this.state.numberAppNumber,
      date: moment(this.state.date).format('YYYY-MM-DD'),
      time: this.state.fromTime,
      country_code: this.state.countryInfo.dialCode
    }
    this.setState({ isLoading: true })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/dial-customer`
    axios
      .post(url, data)
      .then(res => {
        if (res.data.message == 'Successfully') {
          this.setState({ isLoading: false })
          CommonNotify('Successfully', 'success')
        }
      })
      .catch(error => {
        const errors = { ...error }.response.data.errors[0]
        CommonNotify(errors, 'error')
        this.setState({ isLoading: false })
      })
  }

  tabSetting = () => {
    const { activeIndex, numbers, selectedNumber } = this.state
    const panes = [
      {
        menuItem: {
          key: 'phone',
          icon: 'phone',
          content: 'Phone'
        },
        render: () => (
          <Tab.Pane attached={false}>
            <div className="meet-holder-item">
              <p className="meet-head-title">
                You need to setup your phone number before you can make calls.
              </p>

              <p className="callerID-title">Recipient number</p>
              <CallerIdMeets
                onChange={(value, country) =>
                  this.onChangeCountry(value, country)
                }
                value={this.state.numberAppNumber}
                defaultCountry={'us'}
              />
              <div className="meet-time-holder">
                <div className="meet-time-title">
                  <p>Time of Call</p>
                </div>
                <div className="meet-time">
                  <div className="meet-dropdown-select">
                    <DatePicker
                      onChange={(date, e) => this.setDate(date, e)}
                      value={this.state.date}
                      format="y-MM-dd"
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className="meet-time">
                  <TimeInput
                    name="fromTime"
                    placeholder="00:00"
                    inlineLabel={true}
                    value={this.state.fromTime}
                    icon={false}
                    onChange={this.handleChange}
                    disabled={
                      this.state.date === this.state.currentDate ? true : false
                    }
                  />
                </div>
                <div className="meet-name">
                  <CommonInput
                    onChange={this.onChangeInput}
                    name="widgetName"
                    type="text"
                    placeholder="Name of Call"
                  />
                </div>
                <div className="meet-btn">
                  <button className="btn-save" onClick={() => this.startCall()}>
                    Start Call
                  </button>
                </div>
              </div>
            </div>
          </Tab.Pane>
        )
      }
    ]
    return panes
  }
  tabExampleSecondary = () => {
    return (
      <Tab
        menu={{ secondary: true }}
        panes={this.tabSetting()}
        activeIndex={this.state.activeIndex}
        onTabChange={this.handleTabChange}
      />
    )
  }

  render() {
    const title = {
      type: 'image',
      titleOne: leadLogo,
      titleTwo: 'Meets'
    }
    const { activeIndex, numbers, selectedNumber } = this.state
    return (
      <div className="meet-container">
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>
        <Title data={title} />
        <div className="meet-holder">
          <div className="meet-title-holder">
            <p>Start your meeting instantly</p>
            <p>Meeting type</p>
          </div>
        </div>
        {this.tabExampleSecondary()}
      </div>
    )
  }
}

export default Meets
