import React, { useState } from 'react'
import Accordion from '../../common/Accordion'
import AccordionQuickSetup from '../../common/AccordionQuickSetup'
import ContentFooter from './ContentFooter'
import axios from 'axios'
import CommonButtons from '../../common/CommonButtons'

import { Button } from 'semantic-ui-react'
const Install = ({ onClick, script, prev, next }) => {
  const [loading, setLoading] = useState(false)

  const handleDataRef = (DataRef, DataState) => {
    this.setState({
      [DataRef]: DataState
    })
  }

  const increaseStep = async () => {
    setLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/increase-onboarding`
    axios
      .post(url, {})
      .then(res => {
        next()
      })
      .catch(err => {})
  }

  return (
    <div className="install">
      <div className="install-title-wrapper">
        <h2 className="install-title">Install Limecall on your website</h2>
      </div>

      <Accordion script={script} handleDataRef={handleDataRef} />

      <div
        className="quicksetup-footer footer-button mt-auto"
        style={{ justifyContent: 'space-between' }}
      >
        <CommonButtons
          content="Back"
          // background="grey"
          btnClass="btn-modal-style btn-back"
          onClick={prev}
        />

        <CommonButtons
          content="Next"
          background="blue"
          btnClass="btn-modal-style"
          loading={loading}
          onClick={increaseStep}
        />
      </div>
    </div>
  )
}

export default Install
