import React from 'react'
import CreatableSelect from 'react-select/creatable';

const CreatableSelectDropdown = ({
  options,
  components,
  onChange,
  handleCreate,
  inputValue,
  onInputChange,
  handleKeyDown,
  name,
  value,
  title = '',
  required = false,
  isMulti = true,
  isSearchable = true,
  closeMenuOnSelect = true
}) => {
  const setValue =
    value && typeof value !== 'object' && options && options?.length
      ? options.find(item => item.value === value)
      : value

  return (
    <div className="input-wrapper">
    
        <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={onChange}
        onInputChange={onInputChange}
        onKeyDown={handleKeyDown}
        // placeholder={title}
        value={value}
      />
    </div>
  )
}

export default CreatableSelectDropdown
