import React from 'react'
import CommonButtons from '../common/CommonButtons'
import { Table } from 'semantic-ui-react'

const SubRouters = () => {
  return (
      <div>    <div
          className="my-number-tab manage_table"
          style={{ backgroundColor: '#f4f4f4' ,  }}
      >
          {/* <div className="table-heading-wrapper" style={{ justifyContent: 'end' }}>
              <CommonButtons
                //   onClick={() => setModal(!modal)}
                  content="Add Form"
                  btnClass="btn-blue"
              />
          </div> */}
          <Table style={{ tableLayout: 'fixed' }}>
              <Table.Header>
                  <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Associated Forms</Table.HeaderCell>
                      <Table.HeaderCell>Routed to</Table.HeaderCell>
                      <Table.HeaderCell>ID</Table.HeaderCell>

                  </Table.Row>
              </Table.Header>
              <Table.Body>

              </Table.Body>
          </Table>
      </div></div>
  )
}

export default SubRouters