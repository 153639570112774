import React, { useState } from 'react'
import { Accordion } from 'semantic-ui-react'
import { Rating } from 'semantic-ui-react'

function AgentData({ data }) {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const activeIndexs = activeIndex
    const newIndex = activeIndexs === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <>
      <Accordion className="Lead_wrapper" fluid styled>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
        >
          Agent Feedback
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
        
        
         
         
          <div className="form-group row">
            <div className="col-md-6">
              <p className="mb-0 text-color lead_title">
                <img
                  src={process.env.REACT_APP_BASE_APP_URL + "/assets/lead_details_icons/Lead popup edited-32.svg"}
                  className="lead-data-icon"
                />
                <label>Rating (By Agent) </label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <div className="lead-details-star-rating">
                  <Rating
                    icon="star"
                    defaultRating={
                      data.callData && data.callData.agent_feedback_rate
                    }
                    maxRating={5}
                  />
                </div>
              </p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-6">
              <p className="mb-0 text-color lead_title">
                <img
                  src={process.env.REACT_APP_BASE_APP_URL + "/assets/lead_details_icons/Lead popup edited-32.svg"}
                  className="lead-data-icon"
                />
                <label>Feedback (By Agent)</label>
              </p>
            </div>
            <div className="col-auto px-0">: </div>
            <div className="col-md-5">
              <p className="detail_text">
                <b>
                  {data.callData.agent_feedback_text
                    ? data.callData.agent_feedback_text
                    : '-'}
                </b>
              </p>
            </div>
          </div>

         
        
        </Accordion.Content>
      </Accordion>
    </>
  )
}

export default AgentData
