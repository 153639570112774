import React, { Fragment } from 'react'
import { Table } from 'semantic-ui-react'

import deleteIcon from '../assets/images/delete-icon.png'

import NodeToggle from './NodeToggleTeam'
import ManageTeamModal from './ManageTeamModal'

const CommonTable3 = props => {
  const {
    deleteHandle,
    loading,
    roleData,
    sendVerification,
    allTeams,
    onClickOpenModal,
    deleteInvite
  } = props
  const updatedRoles = []
  roleData.map(item => {
    if (item?.text) {
      if (item.text === 'Team Leader' || item.text === 'Member') {
        item.text = 'Member'
        updatedRoles.push(item)
      }
      if (item.text === 'Manager' || item.text === 'Admin') {
        item.text = 'Admin'
        updatedRoles.push(item)
      }
      if (item.text === 'Client' || item.text === 'Owner') {
        item.text = 'Owner'
        updatedRoles.push(item)
      }
    }
  })

  return (
    <div className="common-table-wrapper">
      <Table singleLine>
        <Table.Header className="top-table-header">
          <Table.Row>
            {props.dataTable.header.map((data, i) => {
              return (
                <Table.HeaderCell
                  key={i}
                  className="default-text header-modal-style"
                >
                  {data.headerTitle}
                </Table.HeaderCell>
              )
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Fragment>
            {props.dataTable.tableContentData.map((data, i) => {
              return (
                <Table.Row key={i} className="table-content-row">
                  {data.id && (
                    <Table.Cell onClick={e => onClickOpenModal(data.id)}>
                      {data.id}
                    </Table.Cell>
                  )}

                  <Table.Cell>{data.name}</Table.Cell>
                  <Table.Cell>{data.email}</Table.Cell>
                  <Table.Cell>{data.role.text}</Table.Cell>
                  <Table.Cell>
                    {data.statusLine === 'Invited' ? (
                      <div onClick={e => sendVerification(data.id, data.name)}>
                        <div>{data.statusLine}</div>
                        <div style={{ color: 'blue' }}>Resend Email</div>
                      </div>
                    ) : data.role.text === 'Owner' ? (
                      <div>{data.accountStatus ? 'Active' : 'Suspended'}</div>
                    ) : (
                      <div className="togggle__btn">
                        <input
                          type="checkbox"
                          className="toggle"
                          onClick={e =>
                            props.handleMemberState(
                              e,
                              data.id,
                              data.accountStatus
                            )
                          }
                          id={'state-' + data.id}
                          checked={data.accountStatus ? true : false}
                        />{' '}
                        <label
                          htmlFor={'state-' + data.id}
                          style={{ width: '110px' }}
                        >
                          <span className="off">Suspended</span>
                          <span className="on">Active</span>
                        </label>{' '}
                      </div>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    <div className="togggle__btn">
                      {data.statusLine === 'Invited' ? null : (
                        <>
                          <input
                            type="checkbox"
                            className="toggle"
                            onClick={e =>
                              props.handleDataRef(e, data.id, data.availability)
                            }
                            id={'availability-' + data.id}
                            checked={data.availability ? true : false}
                          />{' '}
                          <label
                            htmlFor={'availability-' + data.id}
                            style={{ width: '110px' }}
                          >
                            <span className="off">Offline</span>
                            <span className="on">Online</span>
                          </label>{' '}
                        </>
                      )}
                    </div>
                    {/* <NodeToggle
                      handleDataRef={e =>
                        props.handleDataRef(e, data.id, data.blocked)
                      }
                      dataToggle={{
                        callTitle: '',
                        callDesc: '',
                        callId: data.id,
                        callRef: ''
                      }}
                      activeDefault={data.blocked !== 0 ? true : false}
                    /> */}
                  </Table.Cell>
                  <Table.Cell>
                    <Fragment>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {data.statusLine === 'Invited' ? null : (
                          <ManageTeamModal
                            allTeams={allTeams}
                            data={data}
                            fetchData={props.fetchData}
                            roleData={updatedRoles}
                          />
                        )}
                        <div
                          style={{
                            marginLeft: '10px',
                            display: 'inline-block'
                          }}
                        >
                          {data.role.text === 'Owner' ? null : (
                            <img
                              onClick={e => deleteInvite(data.id, data.name)}
                              src={deleteIcon}
                              className="delete-icon cursor-pointer"
                              alt="delete"
                            />
                         )} 
                          {/* {data.role.text != 'Member' &&
                          data.statusLine !== 'Invited' ? null : (
                            <img
                              onClick={e => deleteHandle(data.id, data.name)}
                              src={deleteIcon}
                              className="delete-icon"
                              alt="delete"
                            />
                          )} */}
                        </div>
                      </div>
                    </Fragment>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Fragment>
        </Table.Body>
      </Table>
    </div>
  )
}

export default CommonTable3
